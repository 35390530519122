export const topics = [
  {
    name: 'JavaScript',
    description:
      'The language of the web, essential for frontend and backend development.',
    uses: ['Web Development', 'Mobile App Development', 'Backend Development'],
  },
];

export const bTechTopics = [
  {
    name: 'PROGRAMMING FOR PROBLEM SOLVING',
    description:
      'An introductory course focused on developing problem-solving skills using programming. It emphasizes algorithms, flowcharts, and coding techniques to solve real-world computational problems.',
    uses: [
      'Algorithm Development',
      'Problem Analysis and Solution Design',
      'Foundation for Advanced Programming Languages',
    ],
  },
];

export const filesParentPath = {
  JavaScript: 'JavaScript',
  'PROGRAMMING FOR PROBLEM SOLVING': 'BTech/PROGRAMMING-FOR-PROBLEM-SOLVING',
};

export const jsConcepts = [
  {
    order: 1,
    name: 'UNIT - I: JavaScript Basics',
    sections: [
      {
        order: 1,
        name: '1.1 Fundamentals',
        topics: [
          { order: 1, name: 'Variables', topic_id: '1.1.1' },
          { order: 2, name: 'Data Types', topic_id: '1.1.2' },
          { order: 3, name: 'Operators', topic_id: '1.1.3' },
          { order: 4, name: 'Conditionals', topic_id: '1.1.4' },
          { order: 5, name: 'Statements', topic_id: '1.1.5' },
        ],
      },
      {
        order: 2,
        name: '1.2 Loops and Functions',
        topics: [
          { order: 6, name: 'Loops', topic_id: '1.2.1' },
          { order: 7, name: 'Functions', topic_id: '1.2.2' },
          { order: 8, name: 'Variable Scope', topic_id: '1.2.3' },
          { order: 9, name: 'this', topic_id: '1.2.4' },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Advanced JavaScript',
    sections: [
      {
        order: 1,
        name: '2.1 Data Structures',
        topics: [
          { order: 10, name: 'Arrays', topic_id: '2.1.1' },
          { order: 11, name: 'Methods', topic_id: '2.1.2' },
          { order: 12, name: 'Mutators', topic_id: '2.1.3' },
          { order: 13, name: 'Sort', topic_id: '2.1.4' },
          { order: 14, name: 'Substring', topic_id: '2.1.5' },
          { order: 15, name: 'Strings', topic_id: '2.1.6' },
        ],
      },
      {
        order: 2,
        name: '2.2 Objects and Access',
        topics: [
          { order: 16, name: 'Objects', topic_id: '2.2.1' },
          { order: 17, name: 'Accessors', topic_id: '2.2.2' },
          { order: 18, name: 'Constructors', topic_id: '2.2.3' },
          { order: 19, name: 'Map', topic_id: '2.2.4' },
          { order: 20, name: 'Sets', topic_id: '2.2.5' },
        ],
      },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Functions and Asynchronous JavaScript',
    sections: [
      {
        order: 1,
        name: '3.1 Function Concepts',
        topics: [
          { order: 21, name: 'Hoisting', topic_id: '3.1.1' },
          { order: 22, name: 'Arrow Functions', topic_id: '3.1.2' },
          { order: 23, name: 'Callbacks', topic_id: '3.1.3' },
          { order: 24, name: 'Closures', topic_id: '3.1.4' },
        ],
      },
      {
        order: 2,
        name: '3.2 Asynchronous JavaScript',
        topics: [
          { order: 25, name: 'Events', topic_id: '3.2.1' },
          { order: 26, name: 'JSON', topic_id: '3.2.2' },
          { order: 27, name: 'AJAX', topic_id: '3.2.3' },
          { order: 28, name: 'Promise', topic_id: '3.2.4' },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Modules and Error Handling',
    sections: [
      {
        order: 1,
        name: '4.1 Modules and Tools',
        topics: [
          { order: 29, name: 'Modules', topic_id: '4.1.1' },
          { order: 30, name: 'npm', topic_id: '4.1.2' },
        ],
      },
      {
        order: 2,
        name: '4.2 Error Handling',
        topics: [
          { order: 31, name: 'Dates', topic_id: '4.2.1' },
          { order: 32, name: 'Errors', topic_id: '4.2.2' },
          { order: 33, name: 'Try/Catch', topic_id: '4.2.3' },
          { order: 34, name: 'Type Coercion', topic_id: '4.2.4' },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Advanced Concepts',
    sections: [
      {
        order: 1,
        name: '5.1 Storage and Optimization',
        topics: [
          { order: 35, name: 'Storage', topic_id: '5.1.1' },
          { order: 36, name: 'Hash Tables', topic_id: '5.1.2' },
          { order: 37, name: 'Memoization', topic_id: '5.1.3' },
        ],
      },
      {
        order: 2,
        name: '5.2 Iterators and Other Advanced Topics',
        topics: [
          { order: 38, name: 'Iterators', topic_id: '5.2.1' },
          { order: 39, name: 'Rest Parameters', topic_id: '5.2.2' },
          { order: 40, name: 'Enums', topic_id: '5.2.3' },
          { order: 41, name: 'RegExp', topic_id: '5.2.4' },
          { order: 42, name: 'Bitwise Operators', topic_id: '5.2.5' },
        ],
      },
      {
        order: 3,
        name: '5.3 Miscellaneous Topics',
        topics: [
          { order: 43, name: 'Requests', topic_id: '5.3.1' },
          { order: 44, name: 'window', topic_id: '5.3.2' },
          { order: 45, name: 'jQuery', topic_id: '5.3.3' },
        ],
      },
    ],
  },
];

export const ppsConcepts = [
  {
    order: 1,
    name: 'UNIT - I: Introduction to Programming and C Programming Language',
    sections: [
      {
        order: 1,
        name: '1.1 Introduction to Programming',
        topics: [
          {
            order: 1,
            name: 'Compilers, Compiling and Executing a Program',
            topic_id: '1.1.1',
          },
          { order: 2, name: 'Representation of Algorithm', topic_id: '1.1.2' },
          {
            order: 3,
            name: 'Algorithms: Roots of Quadratic Equations, Finding Min/Max of a Set, Checking Prime Numbers',
            topic_id: '1.1.3',
          },
          {
            order: 4,
            name: 'Flowchart/Pseudocode with Examples',
            topic_id: '1.1.4',
          },
          {
            order: 5,
            name: 'Program Design and Structured Programming',
            topic_id: '1.1.5',
          },
        ],
      },
      {
        order: 2,
        name: '1.2 Introduction to C Programming Language',
        topics: [
          {
            order: 6,
            name: 'Variables (Data Types and Space Requirements)',
            topic_id: '1.2.1',
          },
          {
            order: 7,
            name: 'Syntax and Logical Errors, Object and Executable Code',
            topic_id: '1.2.2',
          },
          {
            order: 8,
            name: 'Operators, Expressions, Precedence, Expression Evaluation',
            topic_id: '1.2.3',
          },
          {
            order: 9,
            name: 'Storage Classes: auto, extern, static, register',
            topic_id: '1.2.4',
          },
          { order: 10, name: 'Type Conversion', topic_id: '1.2.5' },
          {
            order: 11,
            name: 'The Main Method and Command Line Arguments',
            topic_id: '1.2.6',
          },
          {
            order: 12,
            name: 'Bitwise Operations: AND, OR, XOR, NOT',
            topic_id: '1.2.7',
          },
          {
            order: 13,
            name: 'Conditional Branching and Loops',
            topics: [
              {
                order: 13,
                name: 'Writing/Evaluating Conditionals: if, if-else, switch-case, Ternary Operator, goto',
                topic_id: '1.2.8.1',
              },
              {
                order: 14,
                name: 'Iteration: for, while, do-while Loops',
                topic_id: '1.2.8.2',
              },
            ],
          },
          {
            order: 15,
            name: 'I/O',
            topics: [
              {
                order: 15,
                name: 'Input/Output: scanf, printf, Formatted I/O',
                topic_id: '1.2.9.1',
              },
              {
                order: 16,
                name: 'Introduction to stdin, stdout, stderr',
                topic_id: '1.2.9.2',
              },
              {
                order: 17,
                name: 'Command Line Arguments',
                topic_id: '1.2.9.3',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    order: 2,
    name: 'UNIT - II: Arrays, Strings, Structures, and Pointers',
    sections: [
      {
        order: 18,
        name: 'Arrays',
        topics: [
          {
            order: 18,
            name: 'One and Two-Dimensional Arrays, Creating, Accessing, and Manipulating Array Elements',
            topic_id: '2.1.1',
          },
        ],
      },
      {
        order: 19,
        name: 'Strings',
        topics: [
          {
            order: 19,
            name: 'Strings as Arrays of Characters',
            topic_id: '2.2.1',
          },
          {
            order: 20,
            name: 'Basic String Functions (strlen, strcat, strcpy, strstr, etc.)',
            topic_id: '2.2.2',
          },
          { order: 21, name: 'Arrays of Strings', topic_id: '2.2.3' },
        ],
      },
      {
        order: 22,
        name: 'Structures',
        topics: [
          {
            order: 22,
            name: 'Defining and Initializing Structures',
            topic_id: '2.3.1',
          },
          { order: 23, name: 'Unions', topic_id: '2.3.2' },
          { order: 24, name: 'Array of Structures', topic_id: '2.3.3' },
        ],
      },
      {
        order: 25,
        name: 'Pointers',
        topics: [
          {
            order: 25,
            name: 'Idea of Pointers, Defining Pointers',
            topic_id: '2.4.1',
          },
          {
            order: 26,
            name: 'Pointers to Arrays and Structures',
            topic_id: '2.4.2',
          },
          {
            order: 27,
            name: 'Self-Referential Structures and Linked Lists (No Implementation)',
            topic_id: '2.4.3',
          },
        ],
      },
      { order: 28, name: 'Enumeration Data Type', topic_id: '2.5.1' },
    ],
  },
  {
    order: 3,
    name: 'UNIT - III: Preprocessor and File Handling in C',
    sections: [
      {
        order: 29,
        name: 'Preprocessor',
        topics: [
          {
            order: 29,
            name: 'Common Preprocessor Commands: include, define, undef, if, ifdef, ifndef',
            topic_id: '3.1.1',
          },
        ],
      },
      {
        order: 30,
        name: 'Files',
        topics: [
          { order: 30, name: 'Text and Binary Files', topic_id: '3.2.1' },
          {
            order: 31,
            name: 'Creating, Reading, and Writing Text/Binary Files',
            topic_id: '3.2.2',
          },
          {
            order: 32,
            name: 'Appending Data to Existing Files',
            topic_id: '3.2.3',
          },
          {
            order: 33,
            name: 'Writing/Reading Structures Using Binary Files',
            topic_id: '3.2.4',
          },
          {
            order: 34,
            name: 'Random Access: fseek, ftell, rewind',
            topic_id: '3.2.5',
          },
        ],
      },
    ],
  },
  {
    order: 4,
    name: 'UNIT - IV: Function and Dynamic Memory Allocation',
    sections: [
      {
        order: 35,
        name: 'Functions',
        topics: [
          {
            order: 35,
            name: 'Designing Structured Programs',
            topic_id: '4.1.1',
          },
          {
            order: 36,
            name: 'Declaring Functions, Signature, Parameters, and Return Type',
            topic_id: '4.1.2',
          },
          {
            order: 37,
            name: 'Passing Parameters: Call by Value',
            topic_id: '4.1.3',
          },
          {
            order: 38,
            name: 'Passing Arrays and Pointers to Functions',
            topic_id: '4.1.4',
          },
          { order: 39, name: 'Idea of Call by Reference', topic_id: '4.1.5' },
          {
            order: 40,
            name: 'C Standard Functions and Libraries',
            topic_id: '4.1.6',
          },
        ],
      },
      {
        order: 41,
        name: 'Recursion',
        topics: [
          {
            order: 41,
            name: 'Simple Recursive Programs: Factorial, Fibonacci',
            topic_id: '4.2.1',
          },
          { order: 42, name: 'Limitations of Recursion', topic_id: '4.2.2' },
        ],
      },
      {
        order: 43,
        name: 'Dynamic Memory Allocation',
        topics: [
          {
            order: 43,
            name: 'Allocating/Freeing Memory, Allocating Arrays of Different Data Types',
            topic_id: '4.3.1',
          },
        ],
      },
    ],
  },
  {
    order: 5,
    name: 'UNIT - V: Searching and Sorting',
    sections: [
      {
        order: 44,
        name: 'Searching and Sorting',
        topics: [
          {
            order: 44,
            name: 'Linear and Binary Search Algorithms',
            topic_id: '5.1.1',
          },
          {
            order: 45,
            name: 'Sorting Algorithms: Bubble, Insertion, Selection',
            topic_id: '5.2.1',
          },
          {
            order: 46,
            name: 'Order of Complexity (Example Programs)',
            topic_id: '5.3.1',
          },
        ],
      },
    ],
  },
];
