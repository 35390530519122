import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar.js';
import './AdminDashboard.css'; // Import the CSS for this component

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />

      <Container className="mt-5 admin-dashboard-container">
        <Row>
          <Col md={4}>
            <Card className="admin-dashboard-card">
              <Card.Body>
                <Card.Title>User Management</Card.Title>
                <Card.Text>Manage users and their profiles.</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => navigate('/admin/users')}
                >
                  Manage Users
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="admin-dashboard-card">
              <Card.Body>
                <Card.Title>Course Management</Card.Title>
                <Card.Text>Manage courses and their categories.</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => navigate('/admin/courses')}
                >
                  Manage Courses
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="admin-dashboard-card">
              <Card.Body>
                <Card.Title>Badge Management</Card.Title>
                <Card.Text>Manage badges and user badges.</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => navigate('/admin/badges')}
                >
                  Manage Badges
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Add more cards as needed for other models */}
      </Container>
    </>
  );
};

export default AdminDashboard;
