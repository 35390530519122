import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import { topics } from './docsData.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const TopicsList = () => {
  const navigate = useNavigate();

  const handleCardClick = (languageName) => {
    navigate(`/resources/docs/${languageName}`);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <h1 className="text-center mb-4">Docs</h1>
      <Container className="mt-5">
        <Row>
          {topics.map((language, index) => (
            <Col key={index} md={6} className="mb-4">
              <Card
                className="shadow-sm cursor-pointer"
                onClick={() => handleCardClick(language.name)}
              >
                <Card.Body>
                  <h3 className="text-primary">{language.name}</h3>
                  <p>{language.description}</p>
                  <h5>Common Uses:</h5>
                  <ul>
                    {language.uses.map((use, useIndex) => (
                      <li key={useIndex}>{use}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TopicsList;
