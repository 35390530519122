/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  getCareerPathById,
  getCourseById,
  // getCourseProgrammingLanguageByCourseId,
} from '../../services/api.js';
import { Accordion, ListGroup, Spinner, Offcanvas } from 'react-bootstrap';
// import LessonView from '../Lessons/LessonView.js';
import QuizView from '../Quizzes/QuizView.js';
// import CodingActivityView from '../Coding/CodingActivityView.js';
// import HTMLCodingActivityView from '../Coding/HTMLCodingActivityView.js';
import HTMLCodingActivityViewNew from '../Coding/HTMLCodingActivityViewNew.js';
// import ReactCodeEditor from '../Coding/ReactCodeEditor.js';
import ActivityNavbar from '../../components/ActivityNavbar.js';
import { fetchCareerPathProgressData } from '../../helpers/apiHelper.js';
import { DisplayCareerPathActivityProgress } from '../../helpers/componentsHelper.js';
import '../Course/ActivityPage.css'; // Import the CSS file
import LessonViewNew from '../Lessons/LessonViewNew.js';
import LoadProblemStatement from '../Coding/LoadProblemStatement.js';
import { generateCourseId } from '../../helpers/formattingHelpr.js';

const CareerPathActivityPageNew = () => {
  const { careerPathId, courseId, sectionId, activityType, activityId } =
    useParams();
  const [course, setCourse] = useState(null);
  const [syllabus, setSyllabus] = useState(null);
  const [carrerPathsyllabus, setCarrerPathSyllabus] = useState(null);

  const [programmingLanguage, setProgrammingLanguage] = useState('HTML');
  const [progress, setProgress] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    const fetchAndSetProgress = async () => {
      const progressData = await fetchCareerPathProgressData(careerPathId);
      setProgress(progressData);
    };

    fetchAndSetProgress();
  }, [careerPathId]);

  useEffect(() => {
    if (showSidebar) {
      const fetchLatestProgress = async () => {
        const progressData = await fetchCareerPathProgressData(careerPathId);
        setProgress(progressData);
      };

      fetchLatestProgress();
    }
  }, [showSidebar, careerPathId]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const handleKeyDown = (event) => {
        // Check if Ctrl (or Cmd on Mac) and 's' are pressed
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
          event.preventDefault();
          alert(
            'No need to press Ctrl+S! We’ve got everything covered for you 😊'
          );
        }

        // Check if the F12 key is pressed
        if (event.key === 'F12') {
          event.preventDefault();
          alert(
            'Developer tools are restricted for a better user experience! 🚫🔧'
          );
        }

        if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
          event.preventDefault();
          alert('Printing is disabled on this page.');
        }
      };

      const handleContextMenu = (event) => {
        event.preventDefault();
        alert('Right-click is disabled for a smoother browsing experience! 😊');
      };

      // Add the event listeners
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('contextmenu', handleContextMenu);

      // Clean up the event listeners when the component is unmounted
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const handleDragStart = (event) => {
        event.preventDefault();
      };

      window.addEventListener('dragstart', handleDragStart);

      return () => {
        window.removeEventListener('dragstart', handleDragStart);
      };
    }
  }, []);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const token = localStorage.getItem('token');
        const careerPathResponse = await getCareerPathById(token, careerPathId);
        console.log(`Career Patch Activity`);

        let selectedCourseIndex;
        careerPathResponse.data.syllabus.sections.map((course, courseIndex) => {
          const findCourseId = generateCourseId(course.title);
          if (courseId === findCourseId) {
            selectedCourseIndex = courseIndex;
          }
        });

        console.log(`selectedCourseIndex: ${selectedCourseIndex}`);
        let selectedCourse =
          careerPathResponse.data.syllabus.sections[selectedCourseIndex];

        console.log(
          `selectedCourse: ${JSON.stringify(selectedCourse, null, 4)}`
        );
        // Sort sections by order
        const sortedSyllabus = {
          ...selectedCourse.syllabus,
          sections: selectedCourse.syllabus.sections.sort(
            (a, b) => a.order - b.order
          ),
        };

        console.log(
          `sortedSyllabus: ${JSON.stringify(sortedSyllabus, null, 4)}`
        );

        // Sort activities within each section by order
        // sortedSyllabus.sections = sortedSyllabus.sections.map((section) => ({
        //   ...section,
        // activities: section.activities.sort((a, b) => a.order - b.order),
        // }));

        setProgrammingLanguage(selectedCourse.codeExecutionLanguage);
        setCourse(selectedCourse);
        // setSyllabus(sortedSyllabus);
        setCarrerPathSyllabus(careerPathResponse.data.syllabus);

        // Convert sectionId to a zero-based index for array access
        const sectionIndex = parseInt(sectionId, 10) - 1;
        const activityOrder = parseInt(activityId, 10);

        // Ensure sectionId and activityOrder are valid
        if (
          isNaN(sectionIndex) ||
          sectionIndex < 0 ||
          sectionIndex >= sortedSyllabus.sections.length
        ) {
          console.error(`Invalid section ID: ${sectionId}`);
          return;
        }

        // Find the specified section and then find the activity in that section
        const currentSection = sortedSyllabus.sections[sectionIndex];
        const currentActivity = currentSection.activities.find(
          (activity) => activity.order === activityOrder
        );

        setSelectedActivity(currentActivity);
      } catch (error) {
        console.error(
          'Error fetching course data:',
          error.response?.data || error.message
        );
      }
    };

    fetchCourseData();
  }, [careerPathId, courseId, sectionId, activityId]);

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const closeSidebar = () => setShowSidebar(false);

  if (!course || !selectedActivity) {
    return (
      <div className="d-flex justify-content-center align-items-center activity-loading-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const renderActivityContent = () => {
    // const selectedAcitivityId = generateActivityId(selectedActivity.title);

    console.log(
      `selectedActivity: ${JSON.stringify(selectedActivity, null, 4)}`
    );
    if (selectedActivity.activity_type === 'Lesson') {
      return (
        <LessonViewNew
          courseId={courseId}
          sectionId={sectionId}
          activityType={activityType}
          activityId={selectedActivity.order}
          codeExecutionLanguage={programmingLanguage}
        />
      );
    } else if (selectedActivity.activity_type === 'Quiz') {
      return (
        <QuizView
          courseId={courseId}
          sectionId={sectionId}
          activityType={activityType}
          activityId={selectedActivity.order}
          codeExecutionLanguage={programmingLanguage}
        />
      );
    } else if (selectedActivity.activity_type === 'ProblemStatement') {
      return (
        <LoadProblemStatement
          courseId={courseId}
          sectionId={sectionId}
          activityType={activityType}
          activityId={selectedActivity.order}
          codeExecutionLanguage={programmingLanguage}
        />
      );
    } else if (selectedActivity.activity_type === 'CodingActivity') {
      return (
        <HTMLCodingActivityViewNew
          careerPathId={careerPathId}
          courseId={courseId}
          sectionId={sectionId}
          activityType={activityType}
          activityId={selectedActivity.order}
          codeExecutionLanguage={programmingLanguage}
          // programmingActivityId={selectedActivity.activity}
          // onProgressUpdate={setProgressData}
        />
      );
    } else {
      return <div>No content available for this activity.</div>;
    }
  };

  return (
    <div className="d-flex flex-column vh-100 activity-page">
      <ActivityNavbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <div
        className={`overlay ${showSidebar ? 'active' : ''}`}
        onClick={closeSidebar}
      />

      <div className="d-flex flex-grow-1 activity-content">
        <Offcanvas
          show={showSidebar}
          onHide={toggleSidebar}
          scroll
          backdrop={false}
          className="activity-sidebar"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Syllabus</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Accordion defaultActiveKey="0">
              {carrerPathsyllabus.sections.map((section, index) => (
                <Accordion.Item
                  eventKey={`${index}`}
                  key={section.title}
                  className="mb-3"
                >
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>
                    {section.syllabus.sections &&
                    section.syllabus.sections.length > 0 ? (
                      <ListGroup variant="flush">
                        {section.syllabus.sections.map(
                          (courseSection, courseSectionIndex) => (
                            <Accordion
                              defaultActiveKey={`${index}-0`}
                              key={courseSectionIndex}
                            >
                              <Accordion.Item
                                eventKey={`${index}-${courseSectionIndex}`}
                              >
                                <Accordion.Header>
                                  {courseSection.order}. {courseSection.title}
                                </Accordion.Header>
                                <Accordion.Body>
                                  {courseSection.activities &&
                                  courseSection.activities.length > 0 ? (
                                    <ListGroup variant="flush">
                                      {courseSection.activities.map(
                                        (activity) => (
                                          <ListGroup.Item
                                            key={`${activity.title}-${activity.order}`}
                                          >
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                <Link
                                                  to={`/career-path/${careerPathId}/${generateCourseId(section.title)}/${courseSection.order}/activity/${activity.activity_type}/${activity.order}`}
                                                  className="text-decoration-none"
                                                  onClick={closeSidebar}
                                                >
                                                  {activity.activity_type ===
                                                    'Lesson' &&
                                                    activity && (
                                                      <i className="bi bi-journal-text me-2 text-primary"></i>
                                                    )}
                                                  {activity.activity_type ===
                                                    'Quiz' &&
                                                    activity && (
                                                      <i className="bi bi-question-circle me-2 text-success"></i>
                                                    )}
                                                  {activity.activity_type ===
                                                    'CodingActivity' &&
                                                    activity && (
                                                      <i className="bi bi-code-slash me-2 text-danger"></i>
                                                    )}
                                                  {activity?.title}
                                                </Link>
                                              </div>
                                              <DisplayCareerPathActivityProgress
                                                progress={progress}
                                                activity={activity}
                                              />
                                            </div>
                                          </ListGroup.Item>
                                        )
                                      )}
                                    </ListGroup>
                                  ) : (
                                    <p className="text-muted">
                                      No activities in this section.
                                    </p>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          )
                        )}
                      </ListGroup>
                    ) : (
                      <p className="text-muted">
                        No activities in this section.
                      </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Offcanvas.Body>
        </Offcanvas>

        <div className="flex-grow-1 activity-main-content">
          {renderActivityContent()}
        </div>
      </div>
    </div>
  );
};

export default CareerPathActivityPageNew;
