export const problemTitle = `### Understanding and Solving **Divisor Game** Problem`;
export const initialInputValue = '2';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine the winner of the **Divisor Game**:`,
  `###### **Step 2:** Program to Determine the Winner:`,
  `###### **Step 3:** Input an integer to determine the result of the Divisor Game:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Alice and Bob take turns playing a game, with Alice starting first. Initially, there is a number \`n\` on the board. On each player's turn, that player makes a move consisting of:
- Choosing any \`x > 0\` such that \`n % x == 0\`.
- Replacing the number \`n\` on the board with \`n - x\`.

If a player cannot make a move, they lose the game.

Return \`true\` if Alice wins the game assuming both players play optimally, otherwise return \`false\`.

#### Problem Statement (Elaborated):
The goal is to determine whether Alice, who always starts, will win the game. If the number on the board becomes 0 during Bob's turn, Alice wins.

#### Testing Examples
1. **Example 1**: Input: \`n = 2\`
   - **Output**: true (Alice takes 1 on her turn, Bob cannot make a move).
2. **Example 2**: Input: \`n = 3\`
   - **Output**: false (No matter what Alice does, Bob can win).

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: To check winning conditions.
2. **Loops**: To simulate the game for different values of \`n\`.
3. **Mathematical Operations**: To determine valid moves and update \`n\`.
4. **Optimization**: Avoid unnecessary calculations for better efficiency.

#### Real-World Concepts Covered:
1. **Game Theory**: Optimizing strategies to determine the winner.
2. **Pattern Recognition**: Identifying patterns in numbers to simplify decisions.

#### How Does the Program Work?
1. Use dynamic programming to precompute whether Alice wins for all numbers from 1 to \`n\`.
2. For each number, check if there exists a move that forces Bob to lose.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Start with small values of \`n\` and simulate the game to find patterns.
2. **Hint 2**: Notice that Alice wins if she can force Bob into a losing position.
3. **Hint 3**: Use dynamic programming to avoid recomputing results for the same values of \`n\`.

#### Why Do We Do This?
This problem introduces dynamic programming in a strategic game setting, helping learners understand how to optimize decisions and use previously computed results to solve larger problems.
`;

export const initialAlgorithm = `1. If \`n\` is 1, Alice loses immediately.
2. Use a dynamic programming array \`dp\` where \`dp[i]\` represents if Alice wins when the number is \`i\`.
3. Initialize \`dp[1] = false\` (Alice loses for \`n = 1\`).
4. For each \`i\` from 2 to \`n\`:
   - a. Set \`dp[i] = false\` initially.
   - b. Loop through all possible divisors \`x\` of \`i\`.
   - c. If \`dp[i - x]\` is false for any \`x\`, set \`dp[i] = true\` (Alice forces Bob to lose).
5. Return \`dp[n]\` as the result.`;

export const initialProblemCode = `1  function divisorGame(n) {
2    if (n === 1) return false;
3    let dp = new Array(n + 1).fill(false);
4    dp[1] = false; // Alice loses for n = 1
5    for (let i = 2; i <= n; i++) {
6      for (let x = 1; x < i; x++) {
7        if (i % x === 0 && !dp[i - x]) {
8          dp[i] = true;
9          break;
10       }
11     }
12   }
13   return dp[n];
14 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function divisorGame is called with n = ${n}`, {
    n,
  });

  if (n === 1) {
    addTrace(
      executionTrace,
      2,
      `n = 1, Alice cannot make a move, return false`,
      { n }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Alice loses for n = 1.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let dp = new Array(n + 1).fill(false);
  addTrace(executionTrace, 3, `Initialize dp array with all false`, { dp });

  dp[1] = false;
  addTrace(executionTrace, 4, `Set dp[1] = false (Alice loses for n = 1)`, {
    dp,
  });

  for (let i = 2; i <= n; i++) {
    addTrace(executionTrace, 5, `Start processing for i = ${i}`, { i });
    for (let x = 1; x < i; x++) {
      if (i % x === 0 && !dp[i - x]) {
        dp[i] = true;
        addTrace(
          executionTrace,
          7,
          `Found x = ${x} such that i % x === 0 and dp[i - x] = false. Set dp[${i}] = true`,
          { i, x, dp }
        );
        break;
      }
    }
    addTrace(
      executionTrace,
      12,
      `Finish processing for i = ${i}. dp[${i}] = ${dp[i]}`,
      { i, dp }
    );
  }

  let result = dp[n];
  addTrace(executionTrace, 13, `Return dp[n] = dp[${n}] = ${result}`, {
    result,
  });

  let resultMessage = result
    ? `Alice wins for n = ${n}.`
    : `Alice loses for n = ${n}.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
