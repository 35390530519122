export const problemTitle = `### Understanding and Solving **Image Smoother** Problem`;
export const initialInputValue = `[[1,1,1],[1,0,1],[1,1,1]]`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to smooth an image matrix:`,
  `###### **Step 2:** Program to Smooth an **Image Matrix**:`,
  `###### **Step 3:** Input a matrix to smooth the image:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D matrix representing an image, smooth the image such that each cell becomes the average of itself and its neighbors (including diagonals). The average should be rounded down to the nearest integer.

#### Problem Statement(Elaborated):
The smoothing process involves replacing each cell's value with the average of itself and all its neighboring cells. If a cell has no neighbor in a particular direction (e.g., corner cells), ignore that direction.

#### Testing Examples
1. **Example 1: Input = [[1,1,1],[1,0,1],[1,1,1]]**
   - **Output**: [[0,0,0],[0,0,0],[0,0,0]]
2. **Example 2: Input = [[100,200,100],[200,50,200],[100,200,100]]**
   - **Output**: [[137,141,137],[141,138,141],[137,141,137]]

#### What is Image Smoothing?
Image smoothing involves reducing the variation between neighboring pixels by averaging their values. It is commonly used in image processing to blur an image and reduce noise.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To traverse the matrix and access neighbors.
2. **Conditional Statements**: To handle edge cases where neighbors do not exist.
3. **Mathematical Operations**: To calculate averages and round them down.

#### Real-World Concepts Covered
1. **Image Processing**: Understanding basic operations on image matrices.
2. **Matrix Traversal**: Efficient navigation through a 2D array.
3. **Logical Thinking**: Developing a step-by-step algorithm for smoothing.

#### How Does the Program Work?
1. Traverse the matrix cell by cell.
2. For each cell, calculate the average of its value and its neighbors.
3. Replace the cell's value with the calculated average.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse the matrix.
2. **Hint 2**: Check bounds to handle edge cases for corners and borders.
3. **Hint 3**: Keep a separate matrix to store the updated values while iterating through the original matrix.

#### Why Do We Do This?
Smoothing helps in reducing noise in an image and is a fundamental operation in image processing and computer vision.
`;

export const initialAlgorithm = `1. Initialize a new matrix (\`smoothedMatrix\`) with the same dimensions as the input matrix.
2. Traverse the matrix cell by cell:
   - a. For each cell, calculate the average of its value and all valid neighboring cells.
   - b. Store the calculated average in the corresponding position in \`smoothedMatrix\`.
3. Return the \`smoothedMatrix\`.`;

export const initialProblemCode = `1  function imageSmoother(matrix) {
2    let rows = matrix.length;
3    let cols = matrix[0].length;
4    let smoothedMatrix = []; // Line 4: Initialize smoothedMatrix
5    for (let i = 0; i < rows; i++) smoothedMatrix.push(new Array(cols).fill(0)); // Line 5: Prepare smoothedMatrix rows
6    for (let i = 0; i < rows; i++) { // Line 6: Traverse rows
7      for (let j = 0; j < cols; j++) { // Line 7: Traverse columns
8        let sum = 0;
9        let count = 0;
10       for (let ni = i - 1; ni <= i + 1; ni++) { // Line 10: Check neighbors' rows
11         for (let nj = j - 1; nj <= j + 1; nj++) { // Line 11: Check neighbors' columns
12           if (ni >= 0 && ni < rows && nj >= 0 && nj < cols) { // Line 12: Check bounds
13             sum += matrix[ni][nj];
14             count++;
15           }
16         }
17       }
18       smoothedMatrix[i][j] = Math.floor(sum / count); // Line 18: Calculate and assign average
19     }
20   }
21   return smoothedMatrix; // Line 21: Return smoothed matrix
22 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let matrix;
  try {
    matrix = JSON.parse(userInput);
    if (
      !Array.isArray(matrix) ||
      matrix.some(
        (row) =>
          !Array.isArray(row) || row.some((cell) => typeof cell !== 'number')
      )
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid matrix of numbers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let rows = matrix.length;
  let cols = matrix[0].length;
  let smoothedMatrix = [];
  let executionTrace = [];

  addTrace(
    executionTrace,
    1,
    `Function imageSmoother is called with matrix = ${JSON.stringify(matrix)}`,
    { matrix }
  );

  for (let i = 0; i < rows; i++) smoothedMatrix.push(new Array(cols).fill(0));
  addTrace(
    executionTrace,
    5,
    `Initialize smoothedMatrix with dimensions (${rows}x${cols})`,
    { smoothedMatrix }
  );

  for (let i = 0; i < rows; i++) {
    addTrace(executionTrace, 6, `Processing row ${i}`, { i });
    for (let j = 0; j < cols; j++) {
      addTrace(executionTrace, 7, `Processing cell matrix[${i}][${j}]`, {
        i,
        j,
      });

      let sum = 0;
      let count = 0;

      for (let ni = i - 1; ni <= i + 1; ni++) {
        for (let nj = j - 1; nj <= j + 1; nj++) {
          if (ni >= 0 && ni < rows && nj >= 0 && nj < cols) {
            sum += matrix[ni][nj];
            count++;
            addTrace(
              executionTrace,
              13,
              `Add neighbor matrix[${ni}][${nj}] = ${matrix[ni][nj]} to sum (${sum})`,
              { ni, nj, sum, count }
            );
          }
        }
      }

      smoothedMatrix[i][j] = Math.floor(sum / count);
      addTrace(
        executionTrace,
        18,
        `Set smoothedMatrix[${i}][${j}] to floor(${sum} / ${count}) = ${smoothedMatrix[i][j]}`,
        { i, j, sum, count, value: smoothedMatrix[i][j] }
      );
    }
  }

  addTrace(executionTrace, 21, `Return smoothedMatrix`, { smoothedMatrix });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The smoothed matrix is ${JSON.stringify(smoothedMatrix)}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
