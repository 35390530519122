import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllCareerPaths, getAllCourses } from '../../services/api.js';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Home.css';

const Home = () => {
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [ongoingCareerPaths, setOngoingCareerPaths] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getAllCourses(token);
        const responseCareerPath = await getAllCareerPaths(token);
        // Assuming response.data is an array of CareerPath, we can split it into ongoing and recommended
        setOngoingCourses(response.data.slice(0, 4)); // Example split, adjust as necessary
        setOngoingCareerPaths(responseCareerPath.data.slice(0, 4));
        console.log(
          `response.data.slice(0, 4): ${JSON.stringify(response.data.slice(0, 4), null, 4)}`
        );
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  return (
    <Container fluid className="p-3">
      <Row className="mb-4">
        <Col>
          <h3>HLS Bootcamps</h3>
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <Badge bg="warning" text="dark">
                Registration Open
              </Badge>
              <Card.Title className="mt-2">
                Complete front end development with React
              </Card.Title>
              <Card.Text>Build AI image generator + 10 more projects</Card.Text>
              <Button variant="outline-primary" className="me-2">
                View Details
              </Button>
              <Button variant="primary">Register Now</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h3>Career Paths</h3>
          <Row className="g-3">
            {ongoingCareerPaths.map((careerPath) =>
              careerPath.course_type === 'CareerPath' ? (
                <Col key={careerPath.course_id} md={6} lg={3}>
                  <Link
                    to={`/career-path/${careerPath.course_id}`}
                    className="text-decoration-none"
                  >
                    <Card className="h-100 shadow-sm">
                      <Card.Body>
                        <Card.Title>{careerPath.title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ) : null
            )}
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h3>Ongoing Courses</h3>
          <Row className="g-3">
            {ongoingCourses.map((course) =>
              course.course_type !== 'CareerPath' ? (
                <Col key={course.course_id} md={6} lg={3}>
                  <Link
                    to={`/course/${course.course_id}`}
                    className="text-decoration-none"
                  >
                    <Card className="h-100 shadow-sm">
                      <Card.Body>
                        <Card.Title>{course.title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ) : null
            )}
          </Row>
        </Col>
      </Row>

      {/* <Row className="mb-4">
        <Col>
          <h3>Keep Learning</h3>
          <Card className="mb-3 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <Card.Title>Full-Stack Engineer</Card.Title>
                <span>4% complete</span>
              </div>
              <Card.Text>Getting Started on Full-Stack Engineering</Card.Text>
              <Button variant="outline-primary" className="me-2">
                View path
              </Button>
              <Button variant="primary">Resume</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Home;
