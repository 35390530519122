export const problemTitle = `### Understanding and Solving **Flood Fill** Problem`;
export const initialInputValue = `[[1,1,1],[1,1,0],[1,0,1]], 1, 1, 2`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to perform **flood fill**:`,
  `###### **Step 2:** Program to Implement **Flood Fill**:`,
  `###### **Step 3:** Input a matrix, a starting point, and a new color:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D matrix (image), a starting cell (sr, sc), and a new color, flood fill the image. The flood fill operation changes the color of the starting cell and all adjacent cells with the same original color to the new color. Adjacent cells are connected horizontally or vertically.

#### Problem Statement(Elaborated):
Flood fill changes the region connected to the starting cell. If the starting cell's color is the same as the new color, no changes are made.

#### Testing Examples
1. **Example 1: Input = [[1,1,1],[1,1,0],[1,0,1]], sr = 1, sc = 1, newColor = 2**
   - **Output**: [[2,2,2],[2,2,0],[2,0,1]]
2. **Example 2: Input = [[0,0,0],[0,0,0]], sr = 0, sc = 0, newColor = 0**
   - **Output**: [[0,0,0],[0,0,0]] (no changes since the color is already 0)

#### What is Flood Fill?
Flood fill is a technique to modify a contiguous region in an image starting from a seed cell. It is similar to the "fill" tool in graphic editing programs.

#### What Topics of JavaScript Will Be Covered?
1. **Recursion**: To visit and update neighboring cells.
2. **Conditional Statements**: To ensure bounds and color match.
3. **Matrix Traversal**: To navigate and modify cells in a 2D matrix.

#### Real-World Concepts Covered
1. **Image Processing**: Modifying specific regions in an image.
2. **Matrix Navigation**: Efficient traversal techniques for 2D grids.
3. **Problem Decomposition**: Breaking down recursive problems.

#### How Does the Program Work?
1. Check if the starting cell already has the new color. If so, return the matrix as is.
2. Define a recursive function to change the color of the current cell and its neighbors.
3. Apply the recursive function starting from the given cell.
4. Return the modified matrix.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use recursion to propagate changes to all valid neighbors.
2. **Hint 2**: Use boundary checks to prevent accessing out-of-bound indices.
3. **Hint 3**: If the starting cell color is the same as the new color, avoid unnecessary recursion.

#### Why Do We Do This?
Flood fill is used in applications like image editing, region growing in computer vision, and game development.
`;

export const initialAlgorithm = `1. Check if the starting cell already has the new color. If so, return the matrix as is.
2. Define a recursive function \`floodFillHelper\`:
   - a. If the cell is out of bounds or its color is not the same as the original color, return.
   - b. Change the cell's color to the new color.
   - c. Recursively call \`floodFillHelper\` on all four neighbors (up, down, left, right).
3. Call \`floodFillHelper\` on the starting cell.
4. Return the modified matrix.`;

export const initialProblemCode = `1  function floodFill(matrix, sr, sc, newColor) {
2    let rows = matrix.length;
3    let cols = matrix[0].length;
4    let originalColor = matrix[sr][sc]; // Line 4: Get the original color
5    if (originalColor === newColor) return matrix; // Line 5: Check if no change is needed
6    
7    function floodFillHelper(r, c) { // Line 7: Define helper function
8      if (r < 0 || r >= rows || c < 0 || c >= cols || matrix[r][c] !== originalColor) return; // Line 8: Boundary check and color check
9      matrix[r][c] = newColor; // Line 9: Change color
10     floodFillHelper(r - 1, c); // Line 10: Recur up
11     floodFillHelper(r + 1, c); // Line 11: Recur down
12     floodFillHelper(r, c - 1); // Line 12: Recur left
13     floodFillHelper(r, c + 1); // Line 13: Recur right
14   }
15   
16   floodFillHelper(sr, sc); // Line 16: Start flood fill from the starting cell
17   return matrix; // Line 17: Return the modified matrix
18 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let input, sr, sc, newColor;
  try {
    let parsedInput = userInput.split(',');
    input = JSON.parse(parsedInput[0]);
    sr = parseInt(parsedInput[1], 10);
    sc = parseInt(parsedInput[2], 10);
    newColor = parseInt(parsedInput[3], 10);
    if (
      !Array.isArray(input) ||
      input.some((row) => !Array.isArray(row)) ||
      isNaN(sr) ||
      isNaN(sc) ||
      isNaN(newColor)
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid matrix, starting cell, and new color (e.g., [[1,1,1],[1,1,0],[1,0,1]], 1, 1, 2).',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let rows = input.length;
  let cols = input[0].length;
  let originalColor = input[sr][sc];
  let executionTrace = [];

  addTrace(
    executionTrace,
    1,
    `Function floodFill is called with matrix = ${JSON.stringify(
      input
    )}, sr = ${sr}, sc = ${sc}, newColor = ${newColor}`,
    { input, sr, sc, newColor }
  );

  addTrace(
    executionTrace,
    4,
    `Original color of starting cell = ${originalColor}`,
    { originalColor }
  );

  if (originalColor === newColor) {
    addTrace(
      executionTrace,
      5,
      `Starting cell color is the same as newColor (${newColor}). No changes needed.`,
      { originalColor, newColor }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The matrix remains unchanged as the starting cell already has the new color.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  function floodFillHelper(r, c) {
    if (
      r < 0 ||
      r >= rows ||
      c < 0 ||
      c >= cols ||
      input[r][c] !== originalColor
    ) {
      addTrace(
        executionTrace,
        8,
        `Cell (${r}, ${c}) is out of bounds or does not match the original color.`,
        { r, c, originalColor, currentColor: input[r]?.[c] }
      );
      return;
    }

    input[r][c] = newColor;
    addTrace(
      executionTrace,
      9,
      `Update cell (${r}, ${c}) to new color = ${newColor}`,
      { r, c, newColor }
    );

    floodFillHelper(r - 1, c); // Recur up
    floodFillHelper(r + 1, c); // Recur down
    floodFillHelper(r, c - 1); // Recur left
    floodFillHelper(r, c + 1); // Recur right
  }

  addTrace(executionTrace, 16, `Start flood fill from cell (${sr}, ${sc})`, {
    sr,
    sc,
  });

  floodFillHelper(sr, sc);

  addTrace(executionTrace, 17, `Return the modified matrix`, { matrix: input });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The modified matrix is ${JSON.stringify(input)}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
