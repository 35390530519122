export const problemTitle = `### Understanding and Solving **Roman to Integer Using Mathematics** Problem`;
export const initialInputValue = 'XII';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to convert a Roman numeral to an **integer** using mathematics:`,
  `###### **Step 2:** Program to Convert **Roman to Integer** Using Mathematics:`,
  `###### **Step 3:** Input a Roman numeral to convert to an **integer**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a Roman numeral, convert it to an **integer**.

#### Problem Statement (Elaborated):
Write a program that **converts** a **Roman numeral** (e.g., \`XII\`, \`IV\`) to its corresponding **integer value**. Roman numerals are represented by **seven symbols**:

| Symbol | Value |
|--------|-------|
| I      | 1     |
| V      | 5     |
| X      | 10    |
| L      | 50    |
| C      | 100   |
| D      | 500   |
| M      | 1000  |

#### Testing Examples
1. **Example 1: Input = 'III'**
   - **Step-by-step**: \`I + I + I = 3\`. Therefore, the **output** is **3**.
2. **Example 2: Input = 'IV'**
   - **Step-by-step**: \`5 - 1 = 4\`. Therefore, the **output** is **4**.
3. **Example 3: Input = 'IX'**
   - **Step-by-step**: \`10 - 1 = 9\`. Therefore, the **output** is **9**.

#### How Roman Numerals Work:
1. Symbols are **added** when the symbol of **greater value comes before** a **smaller value** (e.g., \`XII = 10 + 1 + 1 = 12\`).
2. Symbols are **subtracted** when a **smaller value** comes **before a greater value** (e.g., \`IV = 5 - 1 = 4\`).

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to **handle subtraction cases**.
2. **Loops**: To **traverse each character** in the Roman numeral.
3. **Mathematical Operations**: **Adding** or **subtracting** based on conditions.

#### How Does the Program Work?
1. Assign **integer values** to each **Roman numeral symbol**.
2. Traverse the **Roman numeral string** from **left to right**.
3. If the **current value** is **greater than or equal to the next value**, **add** the value to the total.
4. If the **current value** is **less than the next value**, **subtract** it.
5. Return the **total value** after traversing the string.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Define a function to **map Roman symbols** to their **integer values**.
2. **Hint 2**: Remember the **subtraction rule** when a **smaller numeral** appears before a **larger numeral**.
3. **Hint 3**: Use a loop to **iterate** through the Roman numeral and apply the **rules** accordingly.
`;

export const initialAlgorithm = `1. Define a function to **map Roman symbols** to their **integer values**.
2. Initialize a variable **total** to \`0\`.
3. Loop through the **Roman numeral string**:
   - a. **Get the current value** of the symbol.
   - b. **Check the next symbol**:
      - If **current value >= next value**, **add current value** to **total**.
      - If **current value < next value**, **subtract current value** from **total**.
4. Return **total**.`;

export const initialProblemCode = `1  function romanToInteger(roman) {
2    function getValue(symbol) {
3      if (symbol === 'I') return 1;
4      if (symbol === 'V') return 5;
5      if (symbol === 'X') return 10;
6      if (symbol === 'L') return 50;
7      if (symbol === 'C') return 100;
8      if (symbol === 'D') return 500;
9      if (symbol === 'M') return 1000;
10     return 0; // Default case (invalid symbol)
11   }
12   
13   let total = 0;
14   for (let i = 0; i < roman.length; i++) {
15     let current = getValue(roman[i]);
16     let next = i + 1 < roman.length ? getValue(roman[i + 1]) : 0;
17     if (current >= next) {
18       total += current;
19     } else {
20       total -= current;
21     }
22   }
23   return total;
24 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  if (!userInput || typeof userInput !== 'string') {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid Roman numeral!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function romanToInteger is called with roman = '${userInput}'`,
    { roman: userInput }
  );

  const getValue = (symbol) => {
    let value;
    switch (symbol) {
      case 'I':
        value = 1;
        break;
      case 'V':
        value = 5;
        break;
      case 'X':
        value = 10;
        break;
      case 'L':
        value = 50;
        break;
      case 'C':
        value = 100;
        break;
      case 'D':
        value = 500;
        break;
      case 'M':
        value = 1000;
        break;
      default:
        value = 0;
    }
    addTrace(executionTrace, 2, `Get value of symbol '${symbol}': ${value}`, {
      symbol,
      value,
    });
    return value;
  };

  let total = 0;
  addTrace(executionTrace, 13, `Initialize total = 0`, { total });

  for (let i = 0; i < userInput.length; i++) {
    let current = getValue(userInput[i]);
    let next = i + 1 < userInput.length ? getValue(userInput[i + 1]) : 0;

    addTrace(
      executionTrace,
      15,
      `Get current and next values: current = ${current}, next = ${next}`,
      { current, next }
    );

    if (current >= next) {
      total += current;
      addTrace(
        executionTrace,
        18,
        `Add current to total: total = total + ${current} = ${total}`,
        { total }
      );
    } else {
      total -= current;
      addTrace(
        executionTrace,
        20,
        `Subtract current from total: total = total - ${current} = ${total}`,
        { total }
      );
    }
  }

  addTrace(executionTrace, 23, `Final total value = ${total}`, { total });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The integer value of Roman numeral '${userInput}' is ${total}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
