import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './Footer.css'; // Import the component-specific CSS file

const Footer = () => {
  return (
    <footer className="footer bg-primary text-center p-4 mt-5">
      <Container>
        <div className="footer-links mb-3">
          <Link to="/about">About</Link>
          <Link to="/careers" className="mx-3">
            Careers
          </Link>
          <Link to="/terms">Terms</Link>
          <Link to="/privacy" className="mx-3">
            Privacy Policy
          </Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className="social-icons mb-3">
          <i className="bi bi-facebook mx-2"></i>
          <i className="bi bi-twitter mx-2"></i>
          <i className="bi bi-instagram mx-2"></i>
        </div>
        <p>&copy; 2024 Happy Learning Solutions. All Rights Reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
