import PERN_MERN from '../assets/images/javascript/PERN, MERN.png';
import BACK_END from '../assets/images/javascript/HLS Course Back-End.png';
import FRONT_END from '../assets/images/javascript/HLS Course Front-End.png';
import PERNMERNPDF from '../assets/pdfs/HLS Full Stack.pdf';
import BackEndPDF from '../assets/pdfs/HLS Back End.pdf';
import FrontEndPDF from '../assets/pdfs/HLS Front End.pdf';

export const careerPathsDetails = {
  'pern-mern': {
    title: 'Full Stack Engineer Job Role Skill Development Program',
    description:
      'Transform Your Career with Our Full-Stack Engineer Skill Development Program. Our program equips you with the skills to develop complete web applications from start to finish. Ideal for beginners, this comprehensive online program covers both front-end and back-end development, ensuring you become a versatile full-stack developer ready to tackle any project.',
    image: PERN_MERN,
    pdf: PERNMERNPDF,
    highlights: [
      'Expert-Led Instruction: Learn from industry professionals through interactive live video sessions and high-quality content.',
      'Hands-On Learning: Engage in practical projects to apply what you’ve learned and build a professional portfolio.',
      'Comprehensive Curriculum: Master JavaScript, Node.js, SQL, Express.js, React, TDD, and more.',
      'Job-Ready Skills: Develop essential skills needed to secure a job in the tech industry, including front-end and back-end development, API creation, and database management.',
    ],
    skills: [
      {
        icon: '💻',
        title: 'JavaScript',
        description:
          'Master JavaScript to create dynamic and interactive web applications.',
      },
      {
        icon: '⚙️',
        title: 'Node.js',
        description:
          'Build scalable server-side applications with JavaScript runtime.',
      },
      {
        icon: '🖥️',
        title: 'Express.js',
        description: 'Create robust server-side frameworks with Express.js.',
      },
      {
        icon: '🗄️',
        title: 'PostgreSQL',
        description:
          'Develop skills in PostgreSQL for efficient relational database querying.',
      },
      {
        icon: '🗄️',
        title: 'MongoDB',
        description:
          'Gain expertise in MongoDB for flexible NoSQL database solutions.',
      },
      {
        icon: '⚛️',
        title: 'React',
        description: 'Build dynamic user interfaces using React.',
      },
      {
        icon: '🧪',
        title: 'TDD (Test-Driven Development)',
        description:
          'Learn testing methodologies to ensure your applications are robust and error-free.',
      },
    ],
    programDetails: {
      skillLevel: 'Beginner',
      timeToComplete: '150 hours',
      certification: 'Yes',
      prerequisites: 'None',
    },
    careerSupport: [
      'Job-Readiness Checker: Evaluate how well your skills and experience meet job requirements using our AI-powered job-readiness checker.',
      'Portfolio Projects: Create recruiter-ready projects to showcase your skills.',
      'Interview Simulator: Improve your interview skills with AI-driven simulations.',
      'Job Listings: Access personalized job postings and connect with potential employers.',
    ],
    benefits: [
      'Flexible Learning: Study from anywhere, and fit learning around your schedule.',
      'Supportive Community: Join a community of learners and receive support from instructors and peers.',
      'Proven Success: Our learners have gone on to secure roles at top tech companies.',
    ],
    callToAction:
      'Transform your learning journey with our comprehensive Full-Stack Engineer Skill Development Program. Gain the skills, confidence, and support needed to start your career in tech. Enroll now and start building the future you want.',
  },
  'back-end': {
    title: 'Back-End Engineer Job Role Skill Development Program',
    description:
      'Our Back-End Engineer Skill Development Program is meticulously designed to equip you with the skills necessary to build robust, secure, and efficient back-end systems. Ideal for beginners, this comprehensive online program covers all the essential technologies and skills you need to become a proficient back-end developer.',
    image: BACK_END,
    pdf: BackEndPDF,
    highlights: [
      'Expert-Led Instruction: Learn from industry professionals through interactive live video sessions and high-quality content.',
      'Hands-On Learning: Engage in practical, hands-on projects that allow you to apply what you’ve learned and build a professional portfolio.',
      'Comprehensive Curriculum: Gain in-depth knowledge of Node.js, Express.js, PostgreSQL, SQL, and more.',
      'Job-Ready Skills: Develop the essential skills needed to secure a job in the tech industry, including API development and database management.',
    ],
    skills: [
      {
        icon: '⚙️',
        title: 'Node.js',
        description:
          'Master JavaScript runtime to build scalable server-side applications.',
      },
      {
        icon: '🖥️',
        title: 'Express.js',
        description:
          'Learn to create robust server-side frameworks with Express.js.',
      },
      {
        icon: '🗄️',
        title: 'PostgreSQL',
        description: 'Manage and manipulate databases using PostgreSQL.',
      },
      {
        icon: '🗄️',
        title: 'MongoDB',
        description:
          'Gain expertise in MongoDB for flexible NoSQL database solutions.',
      },
      {
        icon: '🔐',
        title: 'Security',
        description:
          'Implement essential security practices to protect your applications.',
      },
    ],
    programDetails: {
      skillLevel: 'Beginner',
      timeToComplete: '100 hours',
      certification: 'Yes',
      prerequisites: 'None',
    },
    careerSupport: [
      'Job-Readiness Checker: Evaluate how well your skills and experience meet job requirements using our AI-powered job-readiness checker.',
      'Portfolio Projects: Create recruiter-ready projects to showcase your skills.',
      'Interview Simulator: Improve your interview skills with AI-driven simulations.',
      'Job Listings: Access personalized job postings and connect with potential employers.',
    ],
    benefits: [
      'Flexible Learning: Study from anywhere, and fit learning around your schedule.',
      'Supportive Community: Join a community of learners and receive support from instructors and peers.',
      'Proven Success: Our learners have gone on to secure roles at top tech companies.',
    ],
    callToAction:
      'Transform your learning journey with our comprehensive Back-End Engineer Skill Development Program. Gain the skills, confidence, and support needed to start your career in tech. Enroll now and start building the future you want.',
    contactInfo: {
      phoneNumbers: ['+91 (991) 217-8555', '+91 (988) 879-9934'],
      email: 'happylearningsolutions8@gmail.com',
    },
    footerLinks: [
      'Home',
      'About us',
      'Products',
      'Terms of Services',
      'Privacy Policy',
      'Forum',
    ],
    copyright: 'Copyright © Happy Learning Solutions',
    poweredBy: 'Powered by Odoo - Create a free website',
  },
  'front-end': {
    title: 'Front-End Engineer Job Role Skill Development Program',
    description:
      'Our Front-End Engineer Skill Development Program is designed to help you build beautiful, functional, and fast websites by mastering essential front-end development skills. Perfect for beginners, this comprehensive online program covers everything you need to become a job-ready front-end engineer.',
    image: FRONT_END,
    pdf: FrontEndPDF,
    highlights: [
      'Expert-Led Instruction: Learn from industry professionals through interactive live video sessions and high-quality content.',
      'Hands-On Learning: Engage in practical, hands-on projects that allow you to apply what you’ve learned and build a professional portfolio.',
      'Comprehensive Curriculum: Gain in-depth knowledge of HTML, CSS, JavaScript, React.js, and more.',
      'Job-Ready Skills: Develop the essential skills needed to secure a job in the tech industry, including web app development and user interface design.',
    ],
    skills: [
      {
        icon: '🌐',
        title: 'HTML',
        description: 'Learn to structure websites with HTML.',
      },
      {
        icon: '🎨',
        title: 'CSS',
        description:
          'Style your web pages with CSS to make them visually appealing.',
      },
      {
        icon: '⚛️',
        title: 'React.js',
        description: 'Build dynamic user interfaces using React.js.',
      },
      {
        icon: '💻',
        title: 'JavaScript',
        description:
          'Master JavaScript to create interactive and responsive web applications.',
      },
      {
        icon: '📱',
        title: 'Web Apps',
        description:
          'Develop sophisticated web applications with cutting-edge technologies.',
      },
    ],
    programDetails: {
      skillLevel: 'Beginner',
      timeToComplete: '115 hours',
      certification: 'Yes',
      prerequisites: 'None',
    },
    careerSupport: [
      'Job-Readiness Checker: Evaluate how well your skills and experience meet job requirements using our AI-powered job-readiness checker.',
      'Portfolio Projects: Create recruiter-ready projects to showcase your skills.',
      'Interview Simulator: Improve your interview skills with AI-driven simulations.',
      'Job Listings: Access personalized job postings and connect with potential employers.',
    ],
    benefits: [
      'Flexible Learning: Study at your own pace, from anywhere, and fit learning around your schedule.',
      'Supportive Community: Join a community of learners and receive support from instructors and peers.',
      'Proven Success: Our learners have gone on to secure roles at top tech companies.',
    ],
    callToAction:
      'Transform your learning journey with our comprehensive Front-End Engineer Skill Development Program. Gain the skills, confidence, and support needed to start your career in tech. Enroll now and start building the future you want.',
    contactInfo: {
      phoneNumbers: ['+91 (991) 217-8555', '+91 (988) 879-9934'],
      email: 'happylearningsolutions8@gmail.com',
    },
    footerLinks: [
      'Home',
      'About us',
      'Products',
      'Terms of Services',
      'Privacy Policy',
      'Forum',
    ],
    copyright: 'Copyright © Happy Learning Solutions',
    poweredBy: 'Powered by Odoo - Create a free website',
  },
};
