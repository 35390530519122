import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the TwoSumDemo component
const problemTitle = `### Understanding and Solving **Two Sum** Problem`;
const initialInputValue = '2, 7, 11, 15|9'; // Format: numbers separated by commas | target sum
const stepsTitles = [
  `###### **Step 1:** Algorithm to find **two numbers** that sum up to the **target**:`,
  `###### **Step 2:** Program to Find **Two Sum**:`,
  `###### **Step 3:** Input the **array** and **target** to find the **two numbers**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an **array of integers** \`nums\` and an **integer** \`target\`, return the **indices** of the **two numbers** such that they **add up** to \`target\`.

#### Problem Statement(Elaborated):
Write a program that **finds** two **distinct numbers** in a given **array** that **add up** to a **target sum**. The program should **take an array and a target number as input** and **find two numbers** whose **sum equals the target**. Return the **indices of these numbers**.

#### Testing Examples
1. **Example 1: Input = [2, 7, 11, 15], Target = 9**
   - **Step-by-step**: The **sum of 2 and 7** equals **9**. Therefore, the **output** is **[0, 1]**.
2. **Example 2: Input = [1, 2, 3, 4, 5], Target = 10**
   - **Step-by-step**: No **pair of numbers** adds up to **10**. Therefore, the **output** is **null**.
3. **Example 3: Input = [3, 3], Target = 6**
   - **Step-by-step**: The **sum of 3 and 3** equals **6**. Therefore, the **output** is **[0, 1]**.

#### What is the Two Sum Problem?
The **two sum problem** is about finding **two numbers** in an **array** that **add up** to a given **target**. It tests **logical thinking** and **problem-solving skills** by requiring one to identify **complementary pairs**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate through the array** and **find pairs**.
2. **Conditional Statements**: Using \`if-else\` to **check conditions** during iteration.
3. **Mathematical Operations**: Using **addition** to **check sums**.
4. **Basic Index Handling**: Understanding how to **access elements** in an array and **store indices**.
5. **Edge Case Handling**: Understanding how to **handle inputs** like **empty arrays** or **no solution**.

#### Real-World Concepts Covered
1. **Combination Problems**: Learning how to **combine elements** to achieve a desired outcome.
2. **Logical Thinking**: Enhancing **problem-solving skills** through **logical comparisons**.
3. **Data Processing**: Iterating through data and **finding patterns**.

#### How Does the Program Work?
1. **Iterate** through the **array** using a **loop**.
2. For each **number**, **look for another** number that **completes the sum**.
3. If a **pair** is found, **return their indices**.
4. If **no pairs** are found, return **null**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use **two loops** for a **brute force approach**, where you **compare each number** with all the others.
2. **Hint 2**: Store the **index** of the **first number** and **find its complement**.
3. **Hint 3**: Remember to **check for different indices**; the **same number cannot be used twice**.
4. **Hint 4**: Handle **edge cases** like an **array with less than 2 elements**.
5. **Hint 5**: Start with **simple examples** to understand the **iteration process**.

#### Why Do We Do This?
We **perform this check** to understand how **data combinations** work in **real-world scenarios**. It teaches us how to **identify pairs** or **complementary items**.
`;

const initialAlgorithm = `1. Iterate through the **array** using a **loop**:
2. For each **number** at index **i**, iterate again through the **remaining numbers** starting from **i + 1**:
   - a. **Check if the sum** of the **current number** and the **next number** equals the **target**.
3. If a **pair** is found, **return** their **indices**.
4. If **no pairs** are found after all iterations, **return null**.`;

const initialProblemCode = `1  function twoSum(nums, target) {
2    for (let i = 0; i < nums.length; i++) {
3      for (let j = i + 1; j < nums.length; j++) {
4        if (nums[i] + nums[j] === target) {
5          return [i, j];
6        }
7      }
8    }
9    return null;
10 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the TwoSumDemo
const TwoSumDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let nums, target;
    try {
      const [numsStr, targetStr] = numberInput.split('|');
      nums = numsStr.split(',').map((str) => parseInt(str.trim(), 10));
      target = parseInt(targetStr.trim(), 10);

      if (nums.some(isNaN) || isNaN(target)) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter numbers and a target in the format "2,7,11,15|9".',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function twoSum is called with nums = [${nums}] and target = ${target}`,
      { nums, target }
    );

    // Line 2: Start of the outer loop
    for (let i = 0; i < nums.length; i++) {
      addTrace(
        executionTrace,
        2,
        `Outer loop iteration ${i}: checking element nums[${i}] = ${nums[i]}`,
        { i, nums }
      );

      // Line 3: Start of the inner loop
      for (let j = i + 1; j < nums.length; j++) {
        addTrace(
          executionTrace,
          3,
          `Inner loop iteration ${j}: checking nums[${i}] + nums[${j}] = ${nums[i]} + ${nums[j]}`,
          { i, j, nums }
        );

        // Line 4: Check if the sum matches the target
        addTrace(
          executionTrace,
          4,
          `Check if nums[${i}] + nums[${j}] == target: ${nums[i]} + ${nums[j]} == ${target}`,
          { i, j, target }
        );

        if (nums[i] + nums[j] === target) {
          addTrace(
            executionTrace,
            5,
            `Found the solution: indices [${i}, ${j}]`,
            { i, j }
          );

          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `Indices: [${i}, ${j}]`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
      }
    }

    // Line 9: If no solution is found
    addTrace(
      executionTrace,
      9,
      `No pair found that adds up to ${target}. Returning null.`,
      {}
    );

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'No pair found that adds up to the target.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default TwoSumDemo;
