import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import the Navbar component
import Footer from './Footer.js'; // Import the Footer component
import 'bootstrap/dist/css/bootstrap.min.css';

const Blog = () => {
  // Sample blog posts data
  const blogPosts = [
    {
      id: 1,
      title: 'The Importance of Learning Full-Stack Development',
      excerpt:
        'In today’s tech landscape, full-stack development is an essential skill...',
      content:
        'Full content of the blog post goes here. In today’s tech landscape, full-stack development is an essential skill for developers who want to be versatile and work on both frontend and backend.',
      date: 'August 16, 2024',
      image: 'path-to-image1.jpg', // Replace with the path to your image
    },
    {
      id: 2,
      title: 'How to Master JavaScript in 2024',
      excerpt:
        'JavaScript remains the backbone of web development. Here’s how you can...',
      content:
        'Full content of the blog post goes here. JavaScript remains the backbone of web development. Here’s how you can master it in 2024: practice regularly, understand the core concepts, and build projects.',
      date: 'July 25, 2024',
      image: 'path-to-image2.jpg',
    },
    {
      id: 3,
      title: 'Top 10 Tips for Effective Learning',
      excerpt:
        'Whether you’re learning to code or mastering a new technology...',
      content:
        'Full content of the blog post goes here. Whether you’re learning to code or mastering a new technology, these top 10 tips will help you stay focused, organized, and motivated.',
      date: 'June 10, 2024',
      image: 'path-to-image3.jpg',
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleShowModal = (post) => {
    setSelectedPost(post);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPost(null);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar /> {/* Header/Navbar Component */}
      <Container className="flex-grow-1 mt-5">
        <h1 className="text-center mb-4">Our Blog</h1>
        <Row>
          {blogPosts.map((post) => (
            <Col md={4} key={post.id} className="mb-4">
              <Card className="h-100 shadow-sm">
                <Card.Img variant="top" src={post.image} alt={post.title} />
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>{post.excerpt}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleShowModal(post)}
                  >
                    Read More
                  </Button>
                </Card.Body>
                <Card.Footer>
                  <small className="text-muted">Published on {post.date}</small>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer /> {/* Footer Component */}
      {/* Modal for displaying full blog post */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPost?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPost && (
            <>
              <img
                src={selectedPost.image}
                alt={selectedPost.title}
                className="img-fluid mb-3"
              />
              <p>{selectedPost.content}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Blog;
