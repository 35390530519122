export const problemTitle = `### Understanding and Solving **Convert a Number to Hexadecimal** Problem`;
export const initialInputValue = '26';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to convert a number to **hexadecimal**:`,
  `###### **Step 2:** Program to Convert Number to **Hexadecimal**:`,
  `###### **Step 3:** Input a number to convert to **hexadecimal**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`num\`, return its hexadecimal representation as a **string**.

#### Problem Statement(Elaborated):
Write a program that **converts a number to hexadecimal format**. The hexadecimal representation includes digits \`0-9\` and letters \`a-f\` (for values 10-15). For negative numbers, represent the result in **two's complement** notation, assuming a 32-bit integer.

#### Testing Examples
1. **Example 1: Input = 26**
   - **Step-by-step**: 
     - Divide repeatedly by 16 and collect remainders.
     - Result: \`1a\` (where \`1\` corresponds to \`16^1\` and \`a\` to \`10\`).
2. **Example 2: Input = -1**
   - **Step-by-step**: 
     - Compute \`two's complement\` for -1 as a 32-bit number.
     - Result: \`ffffffff\` (all bits are 1 in a 32-bit integer).

#### What is Hexadecimal?
A **hexadecimal number** is a base-16 number system that uses 16 unique symbols: \`0, 1, 2, ..., 9, a, b, c, d, e, f\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: For repetitive division by 16 and building the hexadecimal result.
2. **Conditionals**: To handle special cases, such as \`num = 0\` or negative numbers.
3. **Bitwise Operations**: To compute the two's complement for negative numbers.

#### Real-World Concepts Covered
1. **Encoding**: Hexadecimal representation is commonly used in computer systems.
2. **Number Systems**: Transition between decimal and hexadecimal formats.
3. **Bit Manipulation**: Understanding how integers are stored in binary.

#### How Does the Program Work?
1. If the number is zero, return "0".
2. If the number is negative, add \`2^32\` to represent it in two's complement notation.
3. Use repeated division by 16:
   - Append the remainder to the result, mapping values 10-15 to \`a-f\`.
   - Continue until the number becomes 0.
4. Reverse the result and return.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: The remainder when dividing by 16 determines the last hexadecimal digit.
2. **Hint 2**: Use a lookup table for digits \`a-f\` (values 10-15).
3. **Hint 3**: For negative numbers, adjust by adding \`2^32\` to ensure the number is positive.
`;

export const initialAlgorithm = `1. If the number (\`num\`) is 0, return "0".
2. If \`num < 0\`, adjust it by adding \`2^32\` (to convert it to a positive representation in two's complement).
3. Initialize an empty string \`hexString\`.
4. While \`num > 0\`:
   - a. Compute the remainder: \`remainder = num % 16\`.
   - b. Map the remainder to a hexadecimal digit:
      - If \`remainder < 10\`, append \`'0' + remainder\`.
      - Else, append \`'a' + (remainder - 10)\`.
   - c. Update \`num\`: \`num = Math.floor(num / 16)\`.
5. Reverse \`hexString\` to get the final hexadecimal representation.
6. Return \`hexString\`.`;

export const initialProblemCode = `1  function toHex(num) {
2    if (num === 0) return "0";
3    if (num < 0) num += Math.pow(2, 32);
4    let hexString = "";
5    while (num > 0) {
6      let remainder = num % 16;
7      if (remainder < 10) {
8        hexString = String.fromCharCode(48 + remainder) + hexString;
9      } else {
10       hexString = String.fromCharCode(87 + remainder) + hexString;
11     }
12     num = Math.floor(num / 16);
13   }
14   return hexString;
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num;
  try {
    num = parseInt(userInput, 10);
    if (isNaN(num)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function toHex is called with num = ${num}`, {
    num,
  });

  // Line 2: Check if num === 0
  addTrace(executionTrace, 2, `Check if num is 0: num === 0 = ${num === 0}`, {
    num,
  });
  if (num === 0) {
    addTrace(executionTrace, 2, `Since num is 0, return "0"`, { num });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Hexadecimal representation of ${num} is "0".`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Handle negative numbers
  if (num < 0) {
    addTrace(
      executionTrace,
      3,
      `Adjust num by adding 2^32: num = ${num} + 2^32`,
      {
        num,
      }
    );
    num += Math.pow(2, 32);
    addTrace(executionTrace, 3, `Updated num = ${num}`, { num });
  }

  let hexString = '';
  addTrace(executionTrace, 4, `Initialize hexString = ""`, { hexString });

  // Line 5: Start while loop
  while (num > 0) {
    addTrace(executionTrace, 5, `Check while condition: num > 0 = ${num > 0}`, {
      num,
    });

    // Line 6: Compute remainder
    let remainder = num % 16;
    addTrace(
      executionTrace,
      6,
      `Calculate remainder: remainder = num % 16 = ${num} % 16 = ${remainder}`,
      { remainder }
    );

    // Line 7-10: Map remainder to hex digit
    if (remainder < 10) {
      hexString = String.fromCharCode(48 + remainder) + hexString;
      addTrace(
        executionTrace,
        8,
        `Append digit to hexString: hexString = "${hexString}"`,
        { hexString }
      );
    } else {
      hexString = String.fromCharCode(87 + remainder) + hexString;
      addTrace(
        executionTrace,
        10,
        `Append hex letter to hexString: hexString = "${hexString}"`,
        { hexString }
      );
    }

    // Line 12: Update num
    let previousNum = num;
    num = Math.floor(num / 16);
    addTrace(
      executionTrace,
      12,
      `Update num: num = Math.floor(${previousNum} / 16) = ${num}`,
      { num }
    );
  }

  // Line 14: Return hexString
  addTrace(executionTrace, 14, `Return hexString: "${hexString}"`, {
    hexString,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Hexadecimal representation of ${userInput} is "${hexString}".`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
