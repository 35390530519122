export const problemTitle = `### Understanding and Solving **Add to Array-Form of Integer** Problem`;
export const initialInputValue = '12345, 67';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to add an integer to the **Array-Form of an Integer**:`,
  `###### **Step 2:** Program to Perform **Add to Array-Form of Integer**:`,
  `###### **Step 3:** Input an integer array and a number to add:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array-form of an integer \`num[]\` and an integer \`k\`, add the integer \`k\` to the integer represented by the array.

#### Problem Statement(Elaborated):
Write a program that takes:
1. An array of digits representing a number (\`num[]\`).
2. An integer \`k\`.

It calculates the **sum** of the number represented by \`num[]\` and \`k\`, returning the result as an array of digits.

#### Testing Examples
1. **Example 1: Input = [1, 2, 3, 4, 5], k = 67**
   - **Step-by-step**:
     - The number represented by \`num[]\` is \`12345\`.
     - Add \`k = 67\`: \`12345 + 67 = 12412\`.
     - Convert \`12412\` back to an array-form: [1, 2, 4, 1, 2].
   - **Output**: [1, 2, 4, 1, 2].

2. **Example 2: Input = [9, 9, 9], k = 1**
   - **Step-by-step**:
     - The number represented by \`num[]\` is \`999\`.
     - Add \`k = 1\`: \`999 + 1 = 1000\`.
     - Convert \`1000\` back to an array-form: [1, 0, 0, 0].
   - **Output**: [1, 0, 0, 0].

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through the array-form.
2. **Mathematical Operations**: Performing addition with carry handling.
3. **Array Manipulation**: Modifying and extending the array to represent the sum.

#### Real-World Concepts Covered
1. **Numerical Representation**: Adding numbers digit-by-digit.
2. **Carry Propagation**: Managing overflow when adding digits.

#### How Does the Program Work?
1. Process the digits of the array from right to left, simulating manual addition.
2. Add \`k\` to the last digit of the array, propagating the carry if necessary.
3. If there is a carry at the end, prepend it to the array.
4. Return the resulting array-form of the sum.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Start the addition from the least significant digit (rightmost).
2. **Hint 2**: Handle the carry after adding each digit.
3. **Hint 3**: Add remaining digits of \`k\` to the result if it has more digits than \`num[]\`.
`;

export const initialAlgorithm = `1. Start from the last digit of \`num[]\` and the least significant digit of \`k\`.
2. Initialize a carry variable as 0.
3. For each digit of \`num[]\` from right to left:
   - a. Add the current digit, the last digit of \`k\`, and the carry.
   - b. Update the current digit of \`num[]\` with the sum modulo 10.
   - c. Update the carry as the sum divided by 10 (integer division).
   - d. Remove the last digit from \`k\` by integer division.
4. If \`k > 0\`, continue adding its digits to \`num[]\`.
5. If carry remains after all digits are processed, prepend it to \`num[]\`.
6. Return the updated \`num[]\` as the result.`;

export const initialProblemCode = `1  function addToArrayForm(num, k) {
2    let carry = 0;
3    let i = num.length - 1;
4    while (i >= 0 || k > 0) {
5      let currentDigit = i >= 0 ? num[i] : 0;
6      let kDigit = k % 10;
7      let sum = currentDigit + kDigit + carry;
8      if (i >= 0) {
9        num[i] = sum % 10;
10      } else {
11        num.unshift(sum % 10);
12      }
13      carry = Math.floor(sum / 10);
14      k = Math.floor(k / 10);
15      i--;
16    }
17    if (carry > 0) {
18      num.unshift(carry);
19    }
20    return num;
21 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num, k;
  try {
    const inputs = userInput.split(',');
    num = inputs[0]
      .trim()
      .split('')
      .map((digit) => parseInt(digit, 10));
    k = parseInt(inputs[1].trim(), 10);
    if (num.some(isNaN) || isNaN(k)) throw new Error();
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please provide an array of digits and an integer separated by a comma!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function addToArrayForm is called with num = [${num}] and k = ${k}`,
    { num, k }
  );

  let carry = 0;
  let i = num.length - 1;

  // Line 4: Process digits from right to left
  while (i >= 0 || k > 0) {
    let currentDigit = i >= 0 ? num[i] : 0;
    let kDigit = k % 10;
    let sum = currentDigit + kDigit + carry;

    addTrace(
      executionTrace,
      7,
      `Calculate sum: currentDigit(${currentDigit}) + kDigit(${kDigit}) + carry(${carry}) = ${sum}`,
      { currentDigit, kDigit, carry, sum }
    );

    if (i >= 0) {
      num[i] = sum % 10;
      addTrace(executionTrace, 9, `Update num[${i}] to sum % 10 = ${num[i]}`, {
        num,
      });
    } else {
      num.unshift(sum % 10);
      addTrace(executionTrace, 11, `Prepend sum % 10 = ${sum % 10} to num`, {
        num,
      });
    }

    carry = Math.floor(sum / 10);
    k = Math.floor(k / 10);
    i--;

    addTrace(
      executionTrace,
      13,
      `Update carry to Math.floor(sum / 10) = ${carry}, Update k = Math.floor(k / 10) = ${k}`,
      { carry, k }
    );
  }

  // Line 17: Add carry if remaining
  if (carry > 0) {
    num.unshift(carry);
    addTrace(
      executionTrace,
      18,
      `Carry remains. Prepend carry = ${carry} to num`,
      { num }
    );
  }

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The result is [${num}].`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
