import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import './Pricing.css'; // Import the component-specific CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import CenteredText from './CenteredText.js';

// PricingCard Component: Displays program details, pricing, and features
const PricingCard = ({
  price,
  discountedPrice,
  scholarship,
  features,
  onButtonClick,
}) => (
  <Card className="pricing-card p-4 mb-4 shadow-sm">
    <Card.Body>
      {/* Header Section */}
      <p className="lead">6/9 Months Intensive Program</p>
      <h2 className="display-4 text-primary">
        <span
          style={{
            textDecoration: 'line-through',
            color: 'red',
            fontSize: '24px',
          }}
        >
          {price}
        </span>{' '}
        {discountedPrice}
      </h2>

      {/* Payment Options */}
      <div
        style={{
          textDecoration: 'line-through',
          fontSize: '33px',
          padding: '10px',
          border: '1px solid #007bff',
          borderRadius: '5px',
          textAlign: 'center',
          backgroundColor: '#f0f8ff',
          color: '#007bff',
        }}
      >
        12% of Annual CTC
        <br />
        <span style={{ fontSize: '33px', fontWeight: 'normal' }}>
          Pay after you get a job
        </span>
      </div>

      {/* Scholarship Information */}
      <p style={{ color: 'green', fontSize: '18px', fontWeight: 'bold' }}>
        Additional Scholarships Up To {scholarship}
      </p>

      {/* Call to Action */}
      <p
        style={{
          fontSize: '18px',
          padding: '10px',
          borderRadius: '5px',
          textAlign: 'center',
          lineHeight: '1.6',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        Seize the opportunity to build a successful career as a Software
        Engineer.
      </p>

      {/* Features List */}
      <h4 className="mb-3">Program Features</h4>
      <ul className="list-unstyled">
        {features.map((feature, index) => (
          <li
            key={index}
            style={{
              fontWeight: 'bold',
              padding: '5px',
              margin: '5px 0',
            }}
          >
            {feature}
          </li>
        ))}
      </ul>

      {/* Booking Button */}
      <Button variant="primary" className="w-100 mt-3" onClick={onButtonClick}>
        Book an Appointment Today!
      </Button>
    </Card.Body>
  </Card>
);

// ContactCard Component: Displays contact details
const ContactCard = () => (
  <Card className="contact-card p-4 mb-4 shadow-sm">
    <Card.Body>
      <h5>Contact Us Anytime</h5>
      <p>
        <strong>Call us:</strong>
        <br />
        +91 (991) 217-8555
        <br />
        +91 (988) 879-9934
      </p>
      <p>
        <strong>Email:</strong> happylearningsolutions8@gmail.com
      </p>
    </Card.Body>
  </Card>
);

// Pricing Component: Main container for the pricing page
const Pricing = ({ hideHeaderFooter }) => {
  const navigate = useNavigate();

  // Program features array
  const features = [
    'Interactive Live Video Sessions',
    'Learning by Doing: Daily Practice',
    'Industry Project Implementation',
    'Job Readiness, Support and Progress Tracking',
    'Mock Interviews, One-to-One Sessions',
    'Globally Recognized Certification',
    'Instant setup, success guarantee',
  ];

  // Button click handler to navigate to contact page
  const handleButtonClick = () => {
    navigate('/contact');
  };

  return (
    <div className="pricing-container d-flex flex-column min-vh-100">
      {/* Header Section */}
      {!hideHeaderFooter && <Navbar />}

      <Container className="flex-grow-1 mt-5">
        <CenteredText as="h1" centered className="text-center display-4">
          Skill Development Programs Pricing
        </CenteredText>

        {/* Pricing Card */}
        <Row className="mt-4">
          <Col md={6} className="mx-auto">
            <PricingCard
              price="₹1,30,000"
              discountedPrice="₹91,000"
              scholarship="₹16,000"
              features={features}
              onButtonClick={handleButtonClick}
            />
          </Col>
        </Row>

        {/* Additional Details Section */}
        <Row className="mt-4 text-center">
          <Col>
            <Card className="details-card mb-5">
              <Card.Body>
                <h4>For More Details</h4>
                <p>
                  Take the next step in your professional development journey.
                  Browse our upcoming skill development programs, choose the one
                  that best suits your goals, and secure your spot today.
                </p>
                <p>
                  If you have any questions or need assistance, please contact
                  our support team. We&apos;re here to help you succeed!
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mx-auto">
            <ContactCard />
          </Col>
        </Row>
      </Container>

      {/* Footer Section */}
      {!hideHeaderFooter && <Footer />}
    </div>
  );
};

// Default Props
Pricing.defaultProps = {
  hideHeaderFooter: false,
};

export default Pricing;
