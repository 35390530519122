import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import CenteredText from './CenteredText.js';
import './Benefits.css'; // Import the component-specific CSS file

const Benefits = () => {
  return (
    <section className="benefits mt-5 text-center">
      <Container>
        <CenteredText as="h3" centered className="text-center display-4">
          Why Choose HLS?
        </CenteredText>
        <Card className="p-4 mb-5 shadow-sm">
          <Row className="mt-4">
            <Col md={4}>
              <i className="bi bi-laptop display-4 mb-3"></i>
              <h5>Interactive Learning</h5>
              <p>
                Experience hands-on learning with real-world projects and
                exercises.
              </p>
            </Col>
            <Col md={4}>
              <i className="bi bi-diagram-3 display-4 mb-3"></i>
              <h5>Professional Pathways</h5>
              <p>
                Follow structured paths to build the skills needed for top tech
                careers.
              </p>
            </Col>
            <Col md={4}>
              <i className="bi bi-people display-4 mb-3"></i>
              <h5>Community Support</h5>
              <p>
                Join a vibrant community of learners, mentors, and industry
                experts.
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
    </section>
  );
};

export default Benefits;
