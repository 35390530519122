export const problemTitle = `### Understanding and Solving **Can Place Flowers** Problem`;
export const initialInputValue = '{"flowerbed": [1,0,0,0,1], "n": 1}';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve the **Can Place Flowers** problem using Greedy approach:`,
  `###### **Step 2:** Program to Solve **Can Place Flowers** Problem:`,
  `###### **Step 3:** Input a flowerbed array and number of flowers to place:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You have a flowerbed represented as an array containing 0s and 1s, where:
- **0** represents an empty spot.
- **1** represents a spot already occupied by a flower.

You are also given an integer \`n\`, the number of new flowers you want to plant. Flowers cannot be planted in adjacent spots (no two flowers can be next to each other). Return \`true\` if all \`n\` flowers can be planted without violating the rule, and \`false\` otherwise.

#### Testing Examples
1. **Example 1**: Input = {"flowerbed": [1,0,0,0,1], "n": 1}
   - **Step-by-step**:
     - Flower can be planted at position 2.
     - **Result**: true.
2. **Example 2**: Input = {"flowerbed": [1,0,0,0,1], "n": 2}
   - **Step-by-step**:
     - Flower can be planted at position 2, but no further flowers can be planted.
     - **Result**: false.
3. **Example 3**: Input = {"flowerbed": [0,0,1,0,0], "n": 1}
   - **Step-by-step**:
     - Flower can be planted at position 0 or 4.
     - **Result**: true.

#### What is the Can Place Flowers Problem?
The **Can Place Flowers** problem is about determining if it's possible to place \`n\` flowers in a given flowerbed without violating the rule that no two flowers can be adjacent.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through the flowerbed to find valid spots.
2. **Conditional Statements**: Checking adjacent spots for validity.
3. **Array Manipulation**: Understanding how arrays are used to represent the flowerbed.

#### Real-World Concepts Covered
1. **Optimization**: Placing flowers in a way that maximizes usage of empty spots.
2. **Logical Thinking**: Problem-solving through a greedy strategy.
3. **Resource Allocation**: Effectively using limited space under given constraints.

#### How Does the Program Work?
1. Traverse the flowerbed, checking each spot.
2. If a spot is empty and both adjacent spots (if they exist) are also empty, plant a flower there.
3. Decrement the number of flowers to place and continue.
4. If all flowers are planted successfully, return \`true\`. Otherwise, return \`false\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: An empty spot can only have a flower if:
   - It's the first or last spot in the array, or
   - Both adjacent spots are empty.
2. **Hint 2**: Use a loop to traverse the flowerbed array.
3. **Hint 3**: Stop early if all flowers are successfully placed.
4. **Hint 4**: Handle edge cases like an empty flowerbed or \`n = 0\`.

#### Why Do We Do This?
The **Can Place Flowers** problem illustrates how to use a **greedy strategy** to solve real-world optimization problems efficiently. It builds understanding of arrays, loops, and logical thinking.
`;

export const initialAlgorithm = `1. Traverse the flowerbed using a loop.
2. For each spot:
   - If the current spot is 0 and both adjacent spots (if they exist) are 0:
     - Plant a flower at the current spot (set it to 1).
     - Decrement \`n\` by 1.
3. Stop and return \`true\` if \`n = 0\`.
4. If the loop completes and \`n > 0\`, return \`false\`.`;

export const initialProblemCode = `1  function canPlaceFlowers(flowerbed, n) {
2    let count = 0; // Count of flowers placed
3    for (let i = 0; i < flowerbed.length; i++) {
4      if (flowerbed[i] === 0) {
5        let prev = i === 0 ? 0 : flowerbed[i - 1];
6        let next = i === flowerbed.length - 1 ? 0 : flowerbed[i + 1];
7        if (prev === 0 && next === 0) {
8          flowerbed[i] = 1; // Plant a flower
9          n--; // Decrement flowers to place
10         if (n === 0) return true; // All flowers placed
11       }
12     }
13   }
14   return n <= 0; // Return true if all flowers placed, else false
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let parsedInput;
  try {
    parsedInput = JSON.parse(userInput);
    if (
      !Array.isArray(parsedInput.flowerbed) ||
      typeof parsedInput.n !== 'number' ||
      parsedInput.flowerbed.some((val) => val !== 0 && val !== 1)
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid input: Please provide a valid flowerbed array and integer n!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let { flowerbed, n } = parsedInput;

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function canPlaceFlowers is called with flowerbed = [${flowerbed}] and n = ${n}`,
    { flowerbed, n }
  );

  let count = 0;
  addTrace(executionTrace, 2, `Initialize count = ${count}`, { count });

  for (let i = 0; i < flowerbed.length; i++) {
    addTrace(executionTrace, 3, `Loop iteration i = ${i}`, { i });

    if (flowerbed[i] === 0) {
      let prev = i === 0 ? 0 : flowerbed[i - 1];
      let next = i === flowerbed.length - 1 ? 0 : flowerbed[i + 1];
      addTrace(
        executionTrace,
        5,
        `Check adjacent spots: prev = ${prev}, next = ${next}`,
        { prev, next }
      );

      if (prev === 0 && next === 0) {
        flowerbed[i] = 1;
        n--;
        addTrace(
          executionTrace,
          8,
          `Plant flower at position ${i}, decrement n = ${n}`,
          { flowerbed, n }
        );
        if (n === 0) {
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `All flowers planted successfully. Remaining flowerbed: [${flowerbed}]`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
      }
    }
  }

  let resultMessage =
    n <= 0
      ? `All flowers planted successfully. Remaining flowerbed: [${flowerbed}]`
      : `Not enough space to plant all flowers. Remaining flowers to plant: ${n}`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
