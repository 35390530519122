import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CenteredText.css'; // Import the CSS file for custom styling

const CenteredText = ({
  as: Tag = 'h3',
  centered = true,
  className = '',
  children,
}) => {
  return (
    <Container>
      <Row className={centered ? 'justify-content-center' : ''}>
        <Col xs="auto">
          <Tag className={`centered-text ${className}`}>{children}</Tag>
        </Col>
      </Row>
    </Container>
  );
};

export default CenteredText;
