export const problemTitle = `### Understanding and Solving **Reshape the Matrix** Problem`;
export const initialInputValue = `[[1,2],[3,4]], 1, 4`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to reshape a matrix:`,
  `###### **Step 2:** Program to Reshape the **Matrix**:`,
  `###### **Step 3:** Input a matrix and desired dimensions to reshape it:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D array (matrix) and two integers, \`r\` and \`c\` (representing the number of rows and columns in the desired reshaped matrix), write a program to reshape the matrix. If it is not possible to reshape the matrix into the specified dimensions, return the original matrix.

#### Problem Statement(Elaborated):
The reshaping process maintains the elements' original order. It places elements row by row from the input matrix into the new matrix.

#### Testing Examples
1. **Example 1: Input = [[1, 2], [3, 4]], r = 1, c = 4**
   - **Output**: [[1, 2, 3, 4]]
2. **Example 2: Input = [[1, 2], [3, 4]], r = 2, c = 4**
   - **Output**: [[1, 2], [3, 4]] (since reshaping is not possible)
3. **Example 3: Input = [[1, 2], [3, 4]], r = 4, c = 1**
   - **Output**: [[1], [2], [3], [4]]

#### What is Matrix Reshaping?
Matrix reshaping involves rearranging the elements of a 2D matrix to fit the specified dimensions (\`r\` rows and \`c\` columns) while maintaining the original order of elements.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To traverse the matrix row by row and element by element.
2. **Conditional Statements**: To validate if the reshaping is possible.
3. **Mathematical Operations**: To calculate indices for placing elements into the new matrix.

#### Real-World Concepts Covered
1. **Grid Manipulation**: Understanding how to navigate and manipulate grid-like structures.
2. **Data Rearrangement**: Exploring methods to rearrange data while preserving order.
3. **Logical Thinking**: Building logic to validate conditions and handle edge cases.

#### How Does the Program Work?
1. Calculate the total number of elements in the input matrix (\`totalElements\`).
2. Check if reshaping is possible by ensuring \`totalElements == r * c\`. If not, return the original matrix.
3. Create the reshaped matrix:
   - Loop through all elements of the input matrix.
   - Place each element into the reshaped matrix based on its new position.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse the matrix.
2. **Hint 2**: Use mathematical calculations to determine the new row and column indices.
3. **Hint 3**: If reshaping is not possible, return the original matrix.

#### Why Do We Do This?
Reshaping a matrix allows us to organize data into a more usable form without losing any information. This can be useful in applications such as image processing, data analysis, and grid-based operations.
`;

export const initialAlgorithm = `1. Calculate the total number of elements in the input matrix (\`totalElements\`).
2. Check if reshaping is possible (\`totalElements == r * c\`):
   - If not, return the original matrix.
3. Initialize a new matrix (\`reshapedMatrix\`) with dimensions \`r\` and \`c\`.
4. Use nested loops to traverse the input matrix:
   - a. Calculate the new row and column indices for each element.
   - b. Place the element in the reshaped matrix.
5. Return the reshaped matrix.`;

export const initialProblemCode = `1  function reshapeMatrix(matrix, r, c) {
2    let rows = matrix.length;
3    let cols = matrix[0].length;
4    let totalElements = rows * cols; // Line 4: Calculate total elements
5    if (totalElements !== r * c) return matrix; // Line 5: Check if reshaping is possible
6    let reshapedMatrix = []; // Line 6: Initialize new matrix
7    let currentRow = 0;
8    let currentCol = 0;
9    for (let i = 0; i < r; i++) reshapedMatrix.push([]); // Line 9: Prepare reshapedMatrix rows
10   for (let i = 0; i < rows; i++) { // Line 10: Loop through original matrix rows
11     for (let j = 0; j < cols; j++) { // Line 11: Loop through original matrix columns
12       let element = matrix[i][j]; // Line 12: Get current element
13       reshapedMatrix[currentRow][currentCol] = element; // Line 13: Place element in reshapedMatrix
14       currentCol++; // Line 14: Increment column
15       if (currentCol === c) { // Line 15: Move to next row if column limit reached
16         currentCol = 0;
17         currentRow++;
18       }
19     }
20   }
21   return reshapedMatrix; // Line 21: Return reshaped matrix
22 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let input, r, c;
  try {
    let parsedInput = userInput.split(',');
    input = JSON.parse(parsedInput[0]);
    r = parseInt(parsedInput[1], 10);
    c = parseInt(parsedInput[2], 10);
    if (
      !Array.isArray(input) ||
      input.some((row) => !Array.isArray(row)) ||
      isNaN(r) ||
      isNaN(c)
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid matrix and dimensions (e.g., [[1,2],[3,4]], 1, 4).',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let rows = input.length;
  let cols = input[0].length;
  let totalElements = rows * cols;
  let executionTrace = [];

  addTrace(
    executionTrace,
    1,
    `Function reshapeMatrix is called with matrix = ${JSON.stringify(
      input
    )}, r = ${r}, c = ${c}`,
    { input, r, c }
  );

  addTrace(
    executionTrace,
    4,
    `Calculate totalElements = rows (${rows}) * cols (${cols}) = ${totalElements}`,
    { totalElements }
  );

  if (totalElements !== r * c) {
    addTrace(
      executionTrace,
      5,
      `Reshaping not possible as totalElements (${totalElements}) != r (${r}) * c (${c})`,
      { totalElements, r, c }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Reshaping not possible. Returning the original matrix.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let reshapedMatrix = [];
  for (let i = 0; i < r; i++) reshapedMatrix.push([]);
  let currentRow = 0;
  let currentCol = 0;

  addTrace(
    executionTrace,
    6,
    `Initialize reshapedMatrix and set currentRow = ${currentRow}, currentCol = ${currentCol}`,
    { reshapedMatrix, currentRow, currentCol }
  );

  for (let i = 0; i < rows; i++) {
    addTrace(executionTrace, 10, `Processing row ${i} of original matrix`, {
      i,
    });
    for (let j = 0; j < cols; j++) {
      let element = input[i][j];
      reshapedMatrix[currentRow][currentCol] = element;
      addTrace(
        executionTrace,
        13,
        `Place element ${element} at reshapedMatrix[${currentRow}][${currentCol}]`,
        { currentRow, currentCol, element }
      );

      currentCol++;
      if (currentCol === c) {
        currentCol = 0;
        currentRow++;
        addTrace(
          executionTrace,
          15,
          `Move to next row: set currentCol = 0, increment currentRow = ${currentRow}`,
          { currentRow, currentCol }
        );
      }
    }
  }

  addTrace(executionTrace, 21, `Return reshapedMatrix`, { reshapedMatrix });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The reshaped matrix is ${JSON.stringify(reshapedMatrix)}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
