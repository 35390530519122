import ImageSmootherMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/661ImageSmootherMatrixEasyDemo.js';
import FloodFillMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/733FloodFillMatrixEasyDemo.js';
import ProjectionAreaof3DShapesMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/883ProjectionAreaof3DShapesMatrixEasyDemo.js';
import IslandPerimeterMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/463IslandPerimeterMatrixEasyDemo.js';
import ValidWordSquareMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/422ValidWordSquareMatrixEasyDemo.js';
import ToeplitzMatrixMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/766ToeplitzMatrixMatrixEasyDemo.js';
import ReshapetheMatrixMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/566ReshapetheMatrixMatrixEasyDemo.js';
import AvailableCapturesforRookMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/999AvailableCapturesforRookMatrixEasyDemo.js';
import TransposeMatrixMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/867TransposeMatrixMatrixEasyDemo.js';
import SurfaceAreaof3DShapesMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/892SurfaceAreaof3DShapesMatrixEasyDemo.js';
import FlippinganImageMatrixEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Matrix/832FlippinganImageMatrixEasyDemo.js';

export const matrixEasyProblemStatements = (title, setComponent) => {
  if (title === 'Image Smoother') {
    setComponent(() => ImageSmootherMatrixEasyDemo);
  } else if (title === 'Flood Fill') {
    setComponent(() => FloodFillMatrixEasyDemo);
  } else if (title === 'Projection Area of 3D Shapes') {
    setComponent(() => ProjectionAreaof3DShapesMatrixEasyDemo);
  } else if (title === 'Island Perimeter') {
    setComponent(() => IslandPerimeterMatrixEasyDemo);
  } else if (title === 'Valid Word Square') {
    setComponent(() => ValidWordSquareMatrixEasyDemo);
  } else if (title === 'Toeplitz Matrix') {
    setComponent(() => ToeplitzMatrixMatrixEasyDemo);
  } else if (title === 'Reshape the Matrix') {
    setComponent(() => ReshapetheMatrixMatrixEasyDemo);
  } else if (title === 'Available Captures for Rook') {
    setComponent(() => AvailableCapturesforRookMatrixEasyDemo);
  } else if (title === 'Transpose Matrix') {
    setComponent(() => TransposeMatrixMatrixEasyDemo);
  } else if (title === 'Surface Area of 3D Shapes') {
    setComponent(() => SurfaceAreaof3DShapesMatrixEasyDemo);
  } else if (title === 'Flipping an Image') {
    setComponent(() => FlippinganImageMatrixEasyDemo);
  }
};
