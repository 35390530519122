export const problemTitle = `### Understanding and Solving **Flip Image using Matrix** Problem`;
export const initialInputValue = '[[1,0,1],[1,1,0],[0,0,0]]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to flip and invert an image matrix:`,
  `###### **Step 2:** Program to Flip and Invert Image Matrix:`,
  `###### **Step 3:** Input a matrix to flip and invert:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a binary matrix \`image\` (a 2D array), **flip the image horizontally**, then **invert it**. To flip the image horizontally means each row of the matrix should be reversed. To invert the image means changing all **0s** to **1s** and all **1s** to **0s**.

#### Problem Statement(Elaborated):
Write a program that takes a **binary matrix** (a 2D array where each element is either \`0\` or \`1\`) and:
1. Flips each **row** of the matrix **horizontally** (i.e., reverse the row).
2. Inverts each **bit** of the flipped matrix.

#### Testing Examples
1. **Example 1: Input = [[1,0,1],[1,1,0],[0,0,0]]**
   - **Step-by-step**:
     - Flip Horizontally: \`[[1,0,1] → [1,0,1], [1,1,0] → [0,1,1], [0,0,0] → [0,0,0]]\`
     - Invert Bits: \`[[1,0,1] → [0,1,0], [0,1,1] → [1,0,0], [0,0,0] → [1,1,1]]\`
     - Result: \`[[0,1,0],[1,0,0],[1,1,1]]\`.
2. **Example 2: Input = [[1,1,1],[0,0,0]]**
   - **Step-by-step**:
     - Flip Horizontally: \`[[1,1,1] → [1,1,1], [0,0,0] → [0,0,0]]\`
     - Invert Bits: \`[[1,1,1] → [0,0,0], [0,0,0] → [1,1,1]]\`
     - Result: \`[[0,0,0],[1,1,1]]\`.

#### What is Matrix Flipping and Inverting?
Matrix flipping means **reversing the order** of elements in each **row**, while inverting means **swapping 0s for 1s** and **1s for 0s**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Using **nested loops** to iterate over the matrix and rows.
2. **Conditional Statements**: To check and invert **binary values** (\`0 → 1\` and \`1 → 0\`).
3. **Indexing**: Understanding and working with **matrix indices** to reverse rows.
4. **Edge Case Handling**: What happens with **empty matrices** or **single-row matrices**.

#### Real-World Concepts Covered
1. **Data Transformation**: Modifying structured data (like matrices) step-by-step.
2. **Image Processing**: Flipping and inverting are common operations in **image processing** (e.g., creating photo negatives).
3. **Logical Thinking**: Breaking down tasks like flipping and inverting into simpler **iterative steps**.

#### How Does the Program Work?
1. Each **row** of the matrix is **reversed**.
2. Each **bit** in the **flipped rows** is **inverted** (\`0 → 1\` and \`1 → 0\`).

#### Hints to Consider While Solving the Problem
1. **Hint 1**: To flip a row horizontally, use a **loop** and swap the elements at **start** and **end** indices.
2. **Hint 2**: To invert a value, use a simple \`1 - value\` operation.
3. **Hint 3**: The program needs **nested loops**: the outer loop iterates through rows, and the inner loop processes each row.
4. **Hint 4**: Do not use advanced in-built methods if you're solving for a beginner-friendly approach.
5. **Hint 5**: Edge cases include **empty matrix**, **one-row matrix**, and **single-element rows**.

#### Why Do We Do This?
This transformation simulates common operations in **computer graphics** and **image processing**. It enhances understanding of **matrix manipulations** and logical problem-solving.`;

export const initialAlgorithm = `1. Iterate over each **row** of the matrix:
   - a. **Flip the row horizontally**:
      - Use two **pointers**: one starting at the **beginning** of the row and one at the **end**.
      - Swap the elements at the two pointers.
      - Move the **start pointer** forward and the **end pointer** backward until they meet or cross.
   - b. **Invert each element** in the row:
      - For every element in the row, set it to \`1 - element\`.
2. Return the transformed matrix.`;

export const initialProblemCode = `1  function flipAndInvertImage(image) {
2    for (let i = 0; i < image.length; i++) {
3      let start = 0;
4      let end = image[i].length - 1;
5      while (start <= end) {
6        // Swap and invert in one step
7        let temp = image[i][start];
8        image[i][start] = 1 - image[i][end];
9        image[i][end] = 1 - temp;
10       start++;
11       end--;
12     }
13   }
14   return image;
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let matrix;
  try {
    matrix = JSON.parse(userInput);
    if (!Array.isArray(matrix) || !matrix.every(Array.isArray)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid 2D binary matrix!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function flipAndInvertImage is called with matrix = ${JSON.stringify(matrix)}`,
    {
      matrix,
    }
  );

  for (let i = 0; i < matrix.length; i++) {
    addTrace(
      executionTrace,
      2,
      `Processing row ${i + 1}: ${JSON.stringify(matrix[i])}`,
      { row: matrix[i] }
    );
    let start = 0;
    let end = matrix[i].length - 1;

    addTrace(
      executionTrace,
      3,
      `Initialize pointers: start = ${start}, end = ${end}`,
      { start, end }
    );
    while (start <= end) {
      addTrace(
        executionTrace,
        5,
        `Check while condition: start (${start}) <= end (${end}) = ${start <= end}`,
        {
          start,
          end,
        }
      );

      let temp = matrix[i][start];
      addTrace(executionTrace, 7, `Store temporary value: temp = ${temp}`, {
        temp,
      });

      matrix[i][start] = 1 - matrix[i][end];
      addTrace(
        executionTrace,
        8,
        `Update matrix[i][start] = 1 - matrix[i][end] = 1 - ${matrix[i][end]} = ${matrix[i][start]}`,
        { updatedStart: matrix[i][start] }
      );

      matrix[i][end] = 1 - temp;
      addTrace(
        executionTrace,
        9,
        `Update matrix[i][end] = 1 - temp = 1 - ${temp} = ${matrix[i][end]}`,
        { updatedEnd: matrix[i][end] }
      );

      start++;
      end--;
      addTrace(
        executionTrace,
        10,
        `Move pointers: start = ${start}, end = ${end}`,
        { start, end }
      );
    }
  }

  addTrace(
    executionTrace,
    14,
    `Return the transformed matrix: ${JSON.stringify(matrix)}`,
    { result: matrix }
  );
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Result: ${JSON.stringify(matrix)}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
