export const problemTitle = `### Understanding and Solving **Largest Perimeter Triangle** Problem`;
export const initialInputValue = '[2, 1, 2]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Largest Perimeter Triangle** using Greedy approach:`,
  `###### **Step 2:** Program to Solve **Largest Perimeter Triangle** Problem:`,
  `###### **Step 3:** Input an array of side lengths to find the largest perimeter:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array of integers \`nums\` representing the side lengths of triangles, find the largest perimeter of a triangle with a non-zero area. Return 0 if no such triangle can be formed.

#### Problem Statement (Elaborated):
Write a program that takes an array of integers as input and:
1. Finds three side lengths that satisfy the triangle inequality (\`a + b > c\`, \`b + c > a\`, \`a + c > b\`).
2. Calculates the perimeter (\`a + b + c\`) of the triangle.
3. Returns the largest perimeter that can be formed or \`0\` if no valid triangle exists.

#### Testing Examples
1. **Example 1**: Input = [2, 1, 2]
   - **Step-by-step**:
     - The sides [2, 2, 1] satisfy the triangle inequality.
     - Perimeter = 2 + 2 + 1 = 5.
     - **Result**: 5.
2. **Example 2**: Input = [1, 2, 1]
   - **Step-by-step**:
     - No combination of sides satisfies the triangle inequality.
     - **Result**: 0.
3. **Example 3**: Input = [3, 2, 3, 4]
   - **Step-by-step**:
     - The sides [4, 3, 3] satisfy the triangle inequality.
     - Perimeter = 4 + 3 + 3 = 10.
     - **Result**: 10.

#### What is the Largest Perimeter Triangle Problem?
The **Largest Perimeter Triangle** problem involves selecting three side lengths that can form a triangle and have the maximum possible perimeter.

#### What Topics of JavaScript Will Be Covered?
1. **Sorting**: Arranging side lengths in descending order to simplify comparisons.
2. **Conditional Statements**: Checking the triangle inequality.
3. **Greedy Strategy**: Selecting the largest sides to maximize the perimeter.

#### Real-World Concepts Covered
1. **Optimization**: Maximizing the perimeter while satisfying constraints.
2. **Mathematics**: Applying geometric properties of triangles.
3. **Logical Thinking**: Translating mathematical rules into code.

#### How Does the Program Work?
1. Sort the array of side lengths in descending order.
2. Check consecutive triplets of side lengths to see if they satisfy the triangle inequality.
3. Return the perimeter of the first valid triplet found.
4. If no valid triplet exists, return 0.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Sorting simplifies finding the largest sides that might form a triangle.
2. **Hint 2**: Check if three sides satisfy the triangle inequality before calculating the perimeter.
3. **Hint 3**: Process the array greedily from largest to smallest.

#### Why Do We Do This?
This problem demonstrates how greedy strategies simplify optimization problems. It reinforces understanding of geometry and logical reasoning.
`;

export const initialAlgorithm = `1. Sort the array in descending order.
2. Loop through the sorted array:
   - a. Take three consecutive sides \`a\`, \`b\`, \`c\` (largest to smallest).
   - b. Check if they satisfy the triangle inequality (\`a < b + c\`).
   - c. If valid, return the perimeter (\`a + b + c\`).
3. If no valid triangle is found, return 0.`;

export const initialProblemCode = `1  function largestPerimeter(nums) {
2    // Sort the array in descending order
3    for (let i = 0; i < nums.length - 1; i++) {
4      for (let j = i + 1; j < nums.length; j++) {
5        if (nums[i] < nums[j]) {
6          let temp = nums[i];
7          nums[i] = nums[j];
8          nums[j] = temp;
9        }
10       }
11     }
12 
13   // Check for the largest valid triangle
14   for (let i = 0; i < nums.length - 2; i++) {
15     let a = nums[i];
16     let b = nums[i + 1];
17     let c = nums[i + 2];
18     if (a < b + c) {
19       return a + b + c;
20     }
21   }
22   return 0;
23 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let nums;
  try {
    nums = JSON.parse(userInput);
    if (!Array.isArray(nums) || nums.some((val) => typeof val !== 'number')) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please provide an array of numbers.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function largestPerimeter is called with nums = [${nums}]`,
    { nums }
  );

  // Step 1: Sort the array in descending order
  for (let i = 0; i < nums.length - 1; i++) {
    for (let j = i + 1; j < nums.length; j++) {
      if (nums[i] < nums[j]) {
        let temp = nums[i];
        nums[i] = nums[j];
        nums[j] = temp;
        addTrace(
          executionTrace,
          6,
          `Swapped nums[${i}] (${nums[i]}) and nums[${j}] (${nums[j]})`,
          { nums }
        );
      }
    }
  }
  addTrace(executionTrace, 3, `Sorted nums = [${nums}]`, { nums });

  // Step 2: Check for the largest valid triangle
  for (let i = 0; i < nums.length - 2; i++) {
    let a = nums[i];
    let b = nums[i + 1];
    let c = nums[i + 2];
    addTrace(
      executionTrace,
      15,
      `Checking sides a = ${a}, b = ${b}, c = ${c}`,
      { a, b, c }
    );

    if (a < b + c) {
      let perimeter = a + b + c;
      addTrace(
        executionTrace,
        19,
        `Valid triangle found with perimeter = ${perimeter}`,
        { a, b, c, perimeter }
      );
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: `The largest perimeter is ${perimeter}.`,
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }
  }

  // Step 3: No valid triangle found
  addTrace(executionTrace, 22, `No valid triangle found, returning 0`, {});
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `No valid triangle can be formed.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
