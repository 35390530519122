import ExcelSheetColumnTitleMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/168ExcelSheetColumnTitleMathsEasyDemo.js';
import RomantoIntegerMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/13RomantoIntegerMathsEasyDemo.js';
import MissingNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/268MissingNumberMathsEasyDemo.js';
import SimilarRGBColorMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/800SimilarRGBColorMathsEasyDemo.js';
import MaximumProductofThreeNumbersMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/628MaximumProductofThreeNumbersMathsEasyDemo.js';
import SqrtxMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/69SqrtxMathsEasyDemo.js';
import PalindromeNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/9PalindromeNumberMathsEasyDemo.js';
import PowerofFourMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/342PowerofFourMathsEasyDemo.js';
import PowerofThreeMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/326PowerofThreeMathsEasyDemo.js';
import SelfDividingNumbersMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/728SelfDividingNumbersMathsEasyDemo.js';
import ProjectionAreaof3DShapesMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/883ProjectionAreaof3DShapesMathsEasyDemo.js';
import AddBinaryMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/67AddBinaryMathsEasyDemo.js';
import AddtoArrayFormofIntegerMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/989AddtoArrayFormofIntegerMathsEasyDemo.js';
import NimGameMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/292NimGameMathsEasyDemo.js';
import ExcelSheetColumnNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/171ExcelSheetColumnNumberMathsEasyDemo.js';
import HappyNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/202HappyNumberMathsEasyDemo.js';
import ValidPerfectSquareMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/367ValidPerfectSquareMathsEasyDemo.js';
import PerfectNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/507PerfectNumberMathsEasyDemo.js';
import PrimeNumberofSetBitsinBinaryRepresentationMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/762PrimeNumberofSetBitsinBinaryRepresentationMathsEasyDemo.js';
import AddDigitsMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/258AddDigitsMathsEasyDemo.js';
import Base7MathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/504Base7MathsEasyDemo.js';
import SurfaceAreaof3DShapesMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/892SurfaceAreaof3DShapesMathsEasyDemo.js';
import FizzBuzzMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/412FizzBuzzMathsEasyDemo.js';
import ArrangingCoinsMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/441ArrangingCoinsMathsEasyDemo.js';
import XofaKindinaDeckofCardsMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/914XofaKindinaDeckofCardsMathsEasyDemo.js';
import ConvertaNumbertoHexadecimalMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/405ConvertaNumbertoHexadecimalMathsEasyDemo.js';
import SmallestRangeIMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/908SmallestRangeIMathsEasyDemo.js';
import PowerofTwoMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/231PowerofTwoMathsEasyDemo.js';
import LargestPerimeterTriangleMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/976LargestPerimeterTriangleMathsEasyDemo.js';
import RectangleOverlapMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/836RectangleOverlapMathsEasyDemo.js';
import FibonacciNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/509FibonacciNumberMathsEasyDemo.js';
import UglyNumberMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/263UglyNumberMathsEasyDemo.js';
import DivisorGameMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/1025DivisorGameMathsEasyDemo.js';
import ConstructtheRectangleMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/492ConstructtheRectangleMathsEasyDemo.js';
import RangeAdditionIIMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/598RangeAdditionIIMathsEasyDemo.js';
import PlusOneMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/66PlusOneMathsEasyDemo.js';
import ClimbingStairsMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/70ClimbingStairsMathsEasyDemo.js';
import LargestTriangleAreaMathsEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Maths/812LargestTriangleAreaMathsEasyDemo.js';

export const mathsEasyProblemStatements = (title, setComponent) => {
  if (title === 'Excel Sheet Column Title') {
    setComponent(() => ExcelSheetColumnTitleMathsEasyDemo);
  } else if (title === 'Roman to Integer') {
    setComponent(() => RomantoIntegerMathsEasyDemo);
  } else if (title === 'Missing Number') {
    setComponent(() => MissingNumberMathsEasyDemo);
  } else if (title === 'Similar RGB Color') {
    setComponent(() => SimilarRGBColorMathsEasyDemo);
  } else if (title === 'Maximum Product of Three Numbers') {
    setComponent(() => MaximumProductofThreeNumbersMathsEasyDemo);
  } else if (title === 'Sqrt x ') {
    setComponent(() => SqrtxMathsEasyDemo);
  } else if (title === 'Palindrome Number') {
    setComponent(() => PalindromeNumberMathsEasyDemo);
  } else if (title === 'Power of Four') {
    setComponent(() => PowerofFourMathsEasyDemo);
  } else if (title === 'Power of Three') {
    setComponent(() => PowerofThreeMathsEasyDemo);
  } else if (title === 'Self Dividing Numbers') {
    setComponent(() => SelfDividingNumbersMathsEasyDemo);
  } else if (title === 'Projection Area of 3D Shapes') {
    setComponent(() => ProjectionAreaof3DShapesMathsEasyDemo);
  } else if (title === 'Add Binary') {
    setComponent(() => AddBinaryMathsEasyDemo);
  } else if (title === 'Add to Array Form of Integer') {
    setComponent(() => AddtoArrayFormofIntegerMathsEasyDemo);
  } else if (title === 'Nim Game') {
    setComponent(() => NimGameMathsEasyDemo);
  } else if (title === 'Excel Sheet Column Number') {
    setComponent(() => ExcelSheetColumnNumberMathsEasyDemo);
  } else if (title === 'Happy Number') {
    setComponent(() => HappyNumberMathsEasyDemo);
  } else if (title === 'Valid Perfect Square') {
    setComponent(() => ValidPerfectSquareMathsEasyDemo);
  } else if (title === 'Perfect Number') {
    setComponent(() => PerfectNumberMathsEasyDemo);
  } else if (title === 'Prime Number of Set Bits in Binary Representation') {
    setComponent(() => PrimeNumberofSetBitsinBinaryRepresentationMathsEasyDemo);
  } else if (title === 'Add Digits') {
    setComponent(() => AddDigitsMathsEasyDemo);
  } else if (title === 'Base 7') {
    setComponent(() => Base7MathsEasyDemo);
  } else if (title === 'Surface Area of 3D Shapes') {
    setComponent(() => SurfaceAreaof3DShapesMathsEasyDemo);
  } else if (title === 'Fizz Buzz') {
    setComponent(() => FizzBuzzMathsEasyDemo);
  } else if (title === 'Arranging Coins') {
    setComponent(() => ArrangingCoinsMathsEasyDemo);
  } else if (title === 'X of a Kind in a Deck of Cards') {
    setComponent(() => XofaKindinaDeckofCardsMathsEasyDemo);
  } else if (title === 'Convert a Number to Hexadecimal') {
    setComponent(() => ConvertaNumbertoHexadecimalMathsEasyDemo);
  } else if (title === 'Smallest Range I') {
    setComponent(() => SmallestRangeIMathsEasyDemo);
  } else if (title === 'Power of Two') {
    setComponent(() => PowerofTwoMathsEasyDemo);
  } else if (title === 'Largest Perimeter Triangle') {
    setComponent(() => LargestPerimeterTriangleMathsEasyDemo);
  } else if (title === 'Rectangle Overlap') {
    setComponent(() => RectangleOverlapMathsEasyDemo);
  } else if (title === 'Fibonacci Number') {
    setComponent(() => FibonacciNumberMathsEasyDemo);
  } else if (title === 'Ugly Number') {
    setComponent(() => UglyNumberMathsEasyDemo);
  } else if (title === 'Divisor Game') {
    setComponent(() => DivisorGameMathsEasyDemo);
  } else if (title === 'Construct the Rectangle') {
    setComponent(() => ConstructtheRectangleMathsEasyDemo);
  } else if (title === 'Range Addition II') {
    setComponent(() => RangeAdditionIIMathsEasyDemo);
  } else if (title === 'Plus One') {
    setComponent(() => PlusOneMathsEasyDemo);
  } else if (title === 'Climbing Stairs') {
    setComponent(() => ClimbingStairsMathsEasyDemo);
  } else if (title === 'Largest Triangle Area') {
    setComponent(() => LargestTriangleAreaMathsEasyDemo);
  }
};
