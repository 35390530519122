import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the ConvertSortedArrayToBSTDemo component
const problemTitle = `### Understanding and Solving **Convert Sorted Array to Binary Search Tree** Problem`;
const initialInputValue = '-10, -3, 0, 5, 9'; // Input is a sorted array as a string of comma-separated numbers.
const stepsTitles = [
  `###### **Step 1:** Algorithm to **convert a sorted array** into a **binary search tree**:`,
  `###### **Step 2:** Program to **Convert Sorted Array to BST**:`,
  `###### **Step 3:** Input the **sorted array**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Resulting Tree Structure:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an **integer array** \`nums\` where the elements are sorted in **ascending order**, convert it to a **height-balanced binary search tree (BST)**.

#### Problem Statement (Elaborated):
Write a program that **converts a sorted array** into a **binary search tree** such that the **depth** of the two **subtrees** of every **node** never differs by more than **1**.

#### Testing Examples
1. **Example 1: Input = [-10, -3, 0, 5, 9]**
   - **Step-by-step**: The **middle element** is **0**, which becomes the **root**. **Left subtree** contains **[-10, -3]** and **right subtree** contains **[5, 9]**.
2. **Example 2: Input = [1, 3]**
   - **Step-by-step**: The **middle element** is **3**. **Left subtree** contains **[1]**.
3. **Example 3: Input = [0, 1, 2, 3, 4, 5, 6]**
   - **Step-by-step**: The **middle element** is **3**. **Left subtree** contains **[0, 1, 2]** and **right subtree** contains **[4, 5, 6]**.

#### What is the Convert Sorted Array to BST Problem?
The **convert sorted array to BST** problem involves **building a balanced BST** from a **sorted array** such that each subtree has **approximately equal height**.

#### What Topics of JavaScript Will Be Covered?
1. **Recursion**: To **divide the array** into **left** and **right subtrees**.
2. **Conditional Statements**: Using \`if-else\` to **check base conditions**.
3. **Tree Node Creation**: Understanding how to **build nodes** and **link them** to form a **tree**.

#### Real-World Concepts Covered
1. **Binary Trees**: Learning how to **construct and balance** binary trees.
2. **Divide and Conquer**: Understanding how to **split a problem** into smaller **sub-problems**.
3. **Data Organization**: Knowing how to **organize sorted data** into **hierarchical structures**.

#### How Does the Program Work?
1. **Find the middle** element of the **current array**.
2. **Create a tree node** using the **middle element**.
3. **Recursively** build the **left subtree** using elements **left** of the middle.
4. **Recursively** build the **right subtree** using elements **right** of the middle.
5. **Return** the **root node** of the **BST**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: The **middle element** of a sorted array is the **root** of the BST.
2. **Hint 2**: **Divide the array** into **left** and **right parts** recursively.
3. **Hint 3**: Use **base cases** to **stop recursion** when array **subsections** are empty.
4. **Hint 4**: Remember to **link nodes** correctly as **left** or **right children**.
5. **Hint 5**: Visualize the **tree structure** while constructing it.

#### Why Do We Do This?
We **perform this task** to understand how **balanced structures** can be built from **sorted data**, ensuring **optimized searching** and **access** time.
`;

const initialAlgorithm = `1. Define a **recursive function** \`convertToBST(start, end)\`:
   - a. If \`start > end\`, **return null** (base case).
   - b. Find the **middle index**: \`mid = (start + end) / 2\`.
   - c. Create a **new tree node** with \`nums[mid]\` as the value.
   - d. **Recursively assign** the left child to \`convertToBST(start, mid - 1)\`.
   - e. **Recursively assign** the right child to \`convertToBST(mid + 1, end)\`.
2. **Call** \`convertToBST(0, nums.length - 1)\` to start building the tree.
3. **Return** the **root** of the BST.`;

const initialProblemCode = `1  function sortedArrayToBST(nums) {
2    function convertToBST(start, end) {
3      if (start > end) return null;
4      let mid = Math.floor((start + end) / 2);
5      let node = { value: nums[mid], left: null, right: null };
6      node.left = convertToBST(start, mid - 1);
7      node.right = convertToBST(mid + 1, end);
8      return node;
9    }
10   return convertToBST(0, nums.length - 1);
11 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the ConvertSortedArrayToBSTDemo
const ConvertSortedArrayToBSTDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let nums;
    try {
      nums = numberInput.split(',').map((str) => parseInt(str.trim(), 10));

      if (nums.some(isNaN)) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter a sorted array of numbers separated by commas.',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function sortedArrayToBST is called with nums = [${nums}]`,
      { nums }
    );

    function convertToBST(start, end) {
      addTrace(
        executionTrace,
        2,
        `convertToBST called with start = ${start}, end = ${end}`,
        { start, end }
      );

      if (start > end) {
        addTrace(
          executionTrace,
          3,
          `Base case reached: start (${start}) > end (${end}), returning null.`,
          { start, end }
        );
        return null;
      }

      let mid = Math.floor((start + end) / 2);
      addTrace(
        executionTrace,
        4,
        `Calculated middle index: mid = ${mid}, nums[mid] = ${nums[mid]}`,
        { mid, value: nums[mid] }
      );

      let node = {
        value: nums[mid],
        left: null,
        right: null,
      };
      addTrace(executionTrace, 5, `Created node with value = ${nums[mid]}`, {
        node,
      });

      node.left = convertToBST(start, mid - 1);
      addTrace(
        executionTrace,
        6,
        `Set left child of node with value = ${nums[mid]}`,
        { leftChild: node.left }
      );

      node.right = convertToBST(mid + 1, end);
      addTrace(
        executionTrace,
        7,
        `Set right child of node with value = ${nums[mid]}`,
        { rightChild: node.right }
      );

      return node;
    }

    const root = convertToBST(0, nums.length - 1);

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `BST Root Node: ${JSON.stringify(root)}`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default ConvertSortedArrayToBSTDemo;
