import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getCourseById,
  createEnrollment,
  // getEnrollmentById,
  deleteEnrollment,
  getEnrollmentById,
} from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import {
  Card,
  Button,
  Accordion,
  ListGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';
import {
  // fetchProgressData,
  processProgressData,
} from '../../helpers/apiHelper.js';
import { DisplayActivityProgress } from '../../helpers/componentsHelper.js';
import './OngoingCoursePage.css'; // Import the CSS file

const OngoingCoursePageNew = () => {
  const { id } = useParams(); // 'id' is the courseId
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [syllabus, setSyllabus] = useState(null);
  const [enrolled, setEnrolled] = useState(false); // Default to not enrolled
  const [enrolling, setEnrolling] = useState(false);
  const [unenrolling, setUnenrolling] = useState(false); // State for unenrollment process
  const [progress, setProgress] = useState({}); // Store progress for each activity
  const [showEnrollModal, setShowEnrollModal] = useState(false);

  useEffect(() => {
    const fetchCourseDetailsAndProgress = async () => {
      try {
        const token = localStorage.getItem('token');
        const courseResponse = await getCourseById(token, id);
        console.log(
          'courseResponse.data:',
          JSON.stringify(courseResponse.data, null, 4)
        );
        setCourse(courseResponse.data);

        // Sort sections by order
        const sortedSyllabus = {
          ...courseResponse.data.syllabus,
          sections: courseResponse.data.syllabus.sections.sort(
            (a, b) => a.order - b.order
          ),
        };

        // Sort activities within each section by order
        sortedSyllabus.sections = sortedSyllabus.sections.map((section) => ({
          ...section,
          activities: section.activities.sort((a, b) => a.order - b.order),
        }));

        setSyllabus(sortedSyllabus);

        // Attempt to fetch enrollment and progress details
        try {
          const enrolledCourseResponse = await getEnrollmentById(token, id);
          console.log(
            `enrolledCourseResponse: ${JSON.stringify(enrolledCourseResponse, null, 4)}`
          );
          if (enrolledCourseResponse && enrolledCourseResponse.data) {
            setEnrolled(true); // User is enrolled

            // Fetch progress for all activities in this course
            try {
              // setProgress(await fetchProgressData(id));
              setProgress(await processProgressData(enrolledCourseResponse));
            } catch (error) {
              if (error.response && error.response.status === 404) {
                console.log('No progress found for user in this course');
              } else {
                throw error; // Rethrow if it's another error
              }
            }
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data.message === 'Enrollment not found'
          ) {
            setEnrolled(false); // User is not enrolled, but still show course content
          } else {
            throw error; // Rethrow if it's another error
          }
        }
      } catch (error) {
        console.error(
          'Error fetching course details or progress:',
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchCourseDetailsAndProgress();
  }, [id]);

  const handleEnroll = async () => {
    setEnrolling(true);
    try {
      const token = localStorage.getItem('token');
      const enrollment = {
        courseId: id,
      };
      await createEnrollment(token, enrollment);
      alert('Enrolled successfully!');
      setEnrolled(true); // Set enrolled to true after successful enrollment
    } catch (error) {
      console.error(
        'Error enrolling in course:',
        error.response ? error.response.data : error.message
      );
      alert('Error enrolling in course. Please try again.');
    } finally {
      setEnrolling(false);
    }
  };

  const handleUnenroll = async () => {
    setUnenrolling(true);
    try {
      const token = localStorage.getItem('token');
      await deleteEnrollment(token, id); // Assuming the API supports this
      alert('Unenrolled successfully!');
      setEnrolled(false); // Set enrolled to false after successful unenrollment
      setProgress({}); // Clear progress after unenrollment
    } catch (error) {
      console.error(
        'Error unenrolling from course:',
        error.response ? error.response.data : error.message
      );
      alert('Error unenrolling from course. Please try again.');
    } finally {
      setUnenrolling(false);
    }
  };

  if (!course) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '80vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const handleActivityClick = (event, path) => {
    if (!enrolled) {
      event.preventDefault();
      setShowEnrollModal(true); // Show prompt if not enrolled
    } else {
      navigate(path); // Navigate if enrolled
    }
  };

  const handleCloseEnrollModal = () => setShowEnrollModal(false);

  return (
    <div className="container-fluid d-flex flex-column p-0 ongoing-course-page">
      <Navbar />
      <div className="container mt-4 mb-5">
        <Card className="mb-4 shadow-sm">
          <Card.Body>
            <Card.Title as="h2" className="mb-3">
              {course.title}
            </Card.Title>
            <Card.Text>{course.longDescription}</Card.Text>
            {!enrolled ? (
              <Button
                variant="primary"
                onClick={handleEnroll}
                disabled={enrolling}
              >
                {enrolling ? 'Enrolling...' : 'Enroll in this course'}
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={handleUnenroll}
                disabled={unenrolling}
              >
                {unenrolling ? 'Unenrolling...' : 'Unenroll from this course'}
              </Button>
            )}
          </Card.Body>
        </Card>

        {syllabus && (
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title as="h3" className="mb-3">
                Syllabus: {syllabus.title}
              </Card.Title>
              <Accordion defaultActiveKey="0">
                {syllabus.sections.map((section, index) => (
                  <Accordion.Item eventKey={`${index}`} key={section.title}>
                    <Accordion.Header>
                      {section.order}. {section.title}
                    </Accordion.Header>
                    <Accordion.Body>
                      {section.activities && section.activities.length > 0 ? (
                        <ListGroup variant="flush">
                          {section.activities.map((activity) => (
                            <ListGroup.Item
                              key={`${activity.title}-${activity.order}`}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <span
                                    onClick={(event) =>
                                      handleActivityClick(
                                        event,
                                        `/course/${id}/${section.order}/activity/${activity.activity_type}/${activity.order}`
                                      )
                                    }
                                    className="text-decoration-none"
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                      textDecoration: 'underline',
                                    }}
                                  >
                                    {activity.activity_type === 'Lesson' && (
                                      <i className="bi bi-journal-text me-2 text-primary"></i>
                                    )}
                                    {activity.activity_type === 'Quiz' && (
                                      <i className="bi bi-question-circle me-2 text-success"></i>
                                    )}
                                    {activity.activity_type ===
                                      'ProblemStatement' && (
                                      <i className="bi bi-code-slash me-2 text-danger"></i>
                                    )}
                                    {activity.activity_type ===
                                      'CodingActivity' && (
                                      <i className="bi bi-code-slash me-2 text-danger"></i>
                                    )}
                                    {activity.title}
                                  </span>
                                </div>
                                {enrolled && (
                                  <div style={{ width: '200px' }}>
                                    <DisplayActivityProgress
                                      progress={progress}
                                      activity={activity}
                                    />
                                  </div>
                                )}
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <p className="text-muted">
                          No activities in this section.
                        </p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        )}

        <Modal show={showEnrollModal} onHide={handleCloseEnrollModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enroll Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please enroll in the course to access the activities.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEnrollModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleEnroll}>
              Enroll Now
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OngoingCoursePageNew;
