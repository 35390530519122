export const problemTitle = `### Understanding and Solving **Add Digits Using Mathematics** Problem`;
export const initialInputValue = '38';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the sum of digits until a single digit is obtained:`,
  `###### **Step 2:** Program to Add Digits Using Mathematics:`,
  `###### **Step 3:** Input a number to calculate its single-digit sum:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Write a program to repeatedly add all the digits of a number \`num\` until the result is a single digit.

#### Problem Statement(Elaborated):
Given an integer \`num\`, repeatedly add its digits until a single-digit number is obtained. For example, if \`num = 38\`, the process would be:
- 3 + 8 = 11
- 1 + 1 = 2
- Result = 2

#### Testing Examples
1. **Example 1: Input = 38**
   - **Step-by-step**:
     - Sum of digits: 3 + 8 = 11
     - Sum of digits: 1 + 1 = 2
     - Single digit: **2**.
     - Output: **2**.
2. **Example 2: Input = 123**
   - **Step-by-step**:
     - Sum of digits: 1 + 2 + 3 = 6
     - Single digit: **6**.
     - Output: **6**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To repeatedly sum the digits of the number until a single digit is obtained.
2. **Conditional Statements**: To terminate the loop when a single digit is achieved.
3. **Mathematical Operations**: Using the modulo (\`%\`) operator and division (\`/\`) to extract digits.

#### Real-World Concepts Covered
1. **Number Properties**: Exploring the mathematical patterns of digits and sums.
2. **Iterative Problem Solving**: Decomposing a problem into repeatable steps until a condition is satisfied.

#### How Does the Program Work?
1. While the number has more than one digit:
   - Extract each digit using modulo and division.
   - Add the digits together.
2. Return the final single-digit result.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use \`num % 10\` to get the last digit of a number.
2. **Hint 2**: Use \`Math.floor(num / 10)\` to remove the last digit from a number.
3. **Hint 3**: Sum the digits until the number becomes a single digit.

#### Why Do We Do This?
This problem helps us explore digit manipulation and repeated operations, which are useful for learning iteration and recursion.
`;

export const initialAlgorithm = `1. While the number has more than one digit:
   - a. Initialize \`sum = 0\`.
   - b. Extract digits using modulo and division.
   - c. Add the digits to \`sum\`.
   - d. Update the number with \`sum\`.
2. Return the single-digit number.`;

export const initialProblemCode = `1  function addDigits(num) {
2    while (num >= 10) {
3      let sum = 0;
4      while (num > 0) {
5        sum += num % 10;
6        num = Math.floor(num / 10);
7      }
8      num = sum;
9    }
10   return num;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num;
  try {
    num = parseInt(userInput, 10);
    if (isNaN(num)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function addDigits is called with num = ${num}`,
    {
      num,
    }
  );

  // Line 2: While num >= 10
  while (num >= 10) {
    addTrace(
      executionTrace,
      2,
      `Check while condition: num (${num}) >= 10 = ${num >= 10}`,
      { num }
    );

    // Line 3: Initialize sum
    let sum = 0;
    addTrace(executionTrace, 3, `Initialize sum = ${sum}`, { sum });

    // Line 4: Inner loop while num > 0
    while (num > 0) {
      addTrace(
        executionTrace,
        4,
        `Check inner loop condition: num (${num}) > 0 = ${num > 0}`,
        { num }
      );

      // Line 5: Add last digit to sum
      let digit = num % 10;
      let previousSum = sum;
      sum += digit;
      addTrace(
        executionTrace,
        5,
        `Add last digit to sum: sum = ${previousSum} + ${digit} = ${sum}`,
        { sum, digit }
      );

      // Line 6: Remove last digit
      let previousNum = num;
      num = Math.floor(num / 10);
      addTrace(
        executionTrace,
        6,
        `Remove last digit: num = floor(${previousNum} / 10) = ${num}`,
        { num }
      );
    }

    // Line 8: Update num with sum
    // let previousNum = num;
    num = sum;
    addTrace(executionTrace, 8, `Update num with sum: num = ${sum}`, { num });
  }

  // Line 10: Return single-digit num
  addTrace(executionTrace, 10, `Return single-digit num: ${num}`, { num });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The result is ${num}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
