import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import your existing Navbar component
import Footer from './Footer.js'; // Import your existing Footer component
import 'bootstrap/dist/css/bootstrap.min.css';
import CenteredText from './CenteredText.js';

const Careers = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar /> {/* Add Navbar at the top */}
      <Container className="mt-5 flex-grow-1">
        <Row>
          <Col>
            <CenteredText as="h1" centered>
              Careers at LearnSkill
            </CenteredText>
            <CenteredText as="p" centered>
              Spend each day doing some of the most meaningful work of your
              career.
            </CenteredText>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h2>Our Mission</h2>
            <p>
              Empower people to transform their personal and professional lives
              by helping them learn modern skills.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h3>50 mil.</h3>
                <p>People Helped</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h3>179</h3>
                <p>Countries Served</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h3>200+</h3>
                <p>Lessons to Explore</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h2>Our Values</h2>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>We think deeply</h4>
                <p>
                  We maintain an attitude of openness and eagerness when
                  approaching problems, just as a beginner would.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>We are learners</h4>
                <p>
                  We are a community of lifelong learners who are on a journey
                  to bridge the gap between our ambitions and abilities.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>We improve relentlessly</h4>
                <p>
                  LearnSkill is a mission-driven company made of people that
                  take initiative and sweat the details.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Body>
                <h4>We persevere</h4>
                <p>
                  We accept problems as challenges that we can tackle together.
                  We focus on long-term goals and strategize for future success.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <h4>We win as a team</h4>
                <p>
                  Collaborative teamwork is the key to success at LearnSkill. We
                  embrace a diversity of thoughts, backgrounds, and
                  perspectives.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h2>Benefits and Perks</h2>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Health and Wellness</h4>
                <ul>
                  <li>Medical, dental and vision insurance</li>
                  <li>Paid parental leave</li>
                  <li>Wellness reimbursement</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Work-life Balance</h4>
                <ul>
                  <li>Unlimited paid vacation</li>
                  <li>Flexible work-from-home policy</li>
                  <li>Fully-funded clubs and sports teams</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4>Lifestyle</h4>
                <ul>
                  <li>Coffee and matcha on tap</li>
                  <li>Daily restaurant-catered lunches</li>
                  <li>Professional development budget</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Add Footer at the bottom */}
    </div>
  );
};

export default Careers;
