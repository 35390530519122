/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import MonacoEditor from '@monaco-editor/react';
import prettier from 'prettier/standalone';
import * as parserBabel from 'prettier/plugins/babel';
import * as parserHtml from 'prettier/plugins/html';
import { Container, Row, Col, Button, Card, Accordion } from 'react-bootstrap';
import debounce from 'lodash/debounce.js';
import { ToastContainer, toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';
import './CodingActivityView.css'; // Import the component-specific CSS file
import {
  getProgrammingLessonActivityById,
  // getCodingSubmissionByActivityId,
  // createCodingSubmission,
  updateCodingSubmission,
  executeCodeOnline,
  createUserProgress,
  updateUserProgress,
  getCourseProgrammingLanguageByCourseId,
  getActivityJSONByCourseIdActivityID,
  updateUserCareerPathProgress,
  getActivityJSONByCareerPathIdActivityID,
} from '../../services/api.js';
import {
  handleCourseNextActivity,
  handleCoursePreviousActivity,
} from '../../helpers/componentsHelper.js';
import Split from '@uiw/react-split';
import CodingActivityInstructions from './CodingActivityInstructions.js';

// Helper function to extract language from code block
const extractLanguage = (node) => {
  const className = node?.properties?.className || [];
  const languageClass = className.find((cls) => cls.startsWith('language-'));
  return languageClass ? languageClass.replace('language-', '') : null;
};

const HTMLCodingActivityViewNew = ({
  careerPathId,
  courseId,
  sectionId,
  activityType,
  activityId,
  codeExecutionLanguage,
  // programmingActivityId,
  // onProgressUpdate,
}) => {
  const navigate = useNavigate();
  const [activity, setActivity] = useState(null);
  const [userProgress, setUserProgress] = useState([]);
  const [code, setCode] = useState(
    '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Document</title>\n  <style>\n    /* Add your CSS here */\n  </style>\n</head>\n<body>\n  <h1>Happy Learning</h1>\n  <script>\n    // Add your JavaScript here\n  </script>\n</body>\n</html>\n'
  );
  const [programmingLanguage, setProgrammingLanguage] = useState(
    codeExecutionLanguage
  );
  const [result, setResult] = useState('');

  const [submission, setSubmission] = useState(null);
  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(null); // To track the current instruction
  const [currentInstruction, setCurrentInstruction] = useState(null);
  const [currentInstructionProgress, setCurrentInstructionProgress] =
    useState(null);
  const [currentInstructionTestingStep, setCurrentInstructionTestingStep] =
    useState(null);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 5000); // reset copy status after 2 seconds
  };

  const renderers = {
    code: ({ node, children }) => {
      const language = extractLanguage(node); // Extract language from node properties
      const codeText = children || ''; // Extract text from children (assuming array)

      // Check if it's a block code (has a language class); inline code usually lacks this
      const isBlockCode = language != null;

      // For inline code, render without the "Copy Code" button
      if (!isBlockCode) {
        return <code>{codeText}</code>;
      }

      // For block code (any language), render within <pre> and add "Copy Code" button
      return (
        <pre style={{ position: 'relative' }}>
          <CopyToClipboard text={codeText} onCopy={handleCopy}>
            <button
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                padding: '5px',
                backgroundColor: copied ? 'green' : '#ddd',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                fontSize: '0.9em',
                zIndex: 1,
                borderRadius: '5px',
              }}
            >
              {copied ? 'Copied!' : 'Copy Code'}
            </button>
          </CopyToClipboard>
          <code className={`language-${language}`}>{codeText}</code>
        </pre>
      );
    },
  };

  function getIncompleteStepData(activityResponse) {
    const instructions = activityResponse.data?.activity?.instructions || [];
    const instructionsProgress =
      activityResponse.data?.progress?.instructions || [];

    // Find the index of the first incomplete step in currentInstructionProgress
    const incompleteStepIndex = instructionsProgress.findIndex(
      (instruction) => !instruction.step_completed
    );

    return { instructions, instructionsProgress, incompleteStepIndex };
  }

  const fetchActivityData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');

      // Fetch activity data
      let activityResponse;
      if (careerPathId === null || careerPathId === undefined) {
        activityResponse = await getActivityJSONByCourseIdActivityID(
          token,
          courseId,
          sectionId,
          activityType,
          activityId
        );
      } else {
        activityResponse = await getActivityJSONByCareerPathIdActivityID(
          token,
          careerPathId,
          courseId,
          sectionId,
          activityType,
          activityId
        );
      }
      console.log(
        `activityResponse: ${JSON.stringify(activityResponse, null, 4)}`
      );

      // Handle Instructions when they exist, else fallback to an empty array
      const { instructions, instructionsProgress, incompleteStepIndex } =
        getIncompleteStepData(activityResponse);

      console.log(
        `activityResponse.data?.progress.activity_completed: ${JSON.stringify(activityResponse.data?.progress, null, 4)}`
      );

      if (activityResponse.data?.progress?.activity_completed) {
        setCode(
          instructionsProgress[instructionsProgress.length - 1].user_code
        );
      } else if (incompleteStepIndex > 0) {
        setCode(instructionsProgress[incompleteStepIndex - 1].user_code);

        // Only for local Activities Solutions Testing
        // if (instructionsProgress?.[incompleteStepIndex - 1]) {
        //   setCode(
        //     (instructionsProgress[incompleteStepIndex - 1]?.user_code || '') +
        //       '\n' +
        //       (instructions?.[incompleteStepIndex]?.hints?.[0]?.test_cases?.[0]
        //         ?.solution_code || '')
        //   );
        // }
      } else {
        if (codeExecutionLanguage.toLowerCase().includes('reactjs')) {
          setCode(
            `import React from 'react';\n\nfunction App() {\n  return <h1>🎉 Happy Learning! Keep going, enjoy the journey! 😊📚</h1>;\n}\n\nexport default App;\n// Output: Displays '🎉 Happy Learning! Keep going, enjoy the journey! 😊📚'\n`
          );
        } else if (
          codeExecutionLanguage.toLowerCase().includes('html') ||
          codeExecutionLanguage.toLowerCase().includes('css')
        ) {
          setCode(
            '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <title>Document</title>\n  <style>\n    /* Add your CSS here */\n  </style>\n</head>\n<body>\n  <h1>🎉 Happy Learning! Keep going, enjoy the journey! 😊📚</h1>\n  <script>\n    // Add your JavaScript here\n  </script>\n</body>\n</html>\n'
          );
        } else {
          setCode(
            'console.log("🎉 Happy Learning! Keep going, enjoy the journey! 😊📚");'
          );
        }

        // Only for local Activities Solutions Testing
        // if (instructions?.[incompleteStepIndex]) {
        //   setCode(
        //     instructions?.[incompleteStepIndex].hints?.[0]?.test_cases?.[0]
        //       ?.solution_code || ''
        //   );
        // }
      }

      console.log(`33 incompleteStepIndex: ${incompleteStepIndex}`);

      setCurrentInstructionTestingStep(incompleteStepIndex);

      // setCurrentInstructionIndex(currentIndex); // Set the current instruction index
      setCurrentInstruction(instructions);
      setCurrentInstructionProgress(instructionsProgress);
      setActivity({
        ...activityResponse.data.activity,
      });

      // setUserProgress(userProgresses);

      // Setting Execution Output To Empty
      const iframe = document.getElementById('outputIframe');
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write('');
      iframeDoc.close();
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error fetching coding activity:', errorMessage);
    }
  }, [courseId, sectionId, activityType, activityId, codeExecutionLanguage]);

  useEffect(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  const updateActivityUserProgress = async (
    isStepCompleted,
    incompleteStepIndex,
    user_code
  ) => {
    try {
      const token = localStorage.getItem('token');
      let progressData = {
        courseId,
        sectionId,
        activityId,
        stepNumber: incompleteStepIndex,
        completed: isStepCompleted,
        user_code,
      };

      let response;
      if (careerPathId === null || careerPathId === undefined) {
        response = await updateUserProgress(token, progressData);
      } else {
        progressData = {
          careerPathId,
          ...progressData,
        };
        console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);

        response = await updateUserCareerPathProgress(token, progressData);
      }

      console.log('Activity Completed Progress updated successfully!');

      // const data = await response.json();
      console.log(`response: ${JSON.stringify(response, null, 4)}`);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error updating progress:', errorMessage);
      alert('Failed to update progress. Please try again.');
    }
  };

  // Function to reset instruction progress
  const resetInstructionProgress = () => {
    const resetProgress = currentInstructionProgress.map((instruction) => ({
      ...instruction,
      step_completed: false,
    }));
    setCurrentInstructionProgress(resetProgress);
  };

  // Generic reset function
  const resetUserProgress = async ({
    sectionId = null,
    activityId = null,
    stepNumber = null,
  } = {}) => {
    try {
      const token = localStorage.getItem('token');
      let progressData = {
        courseId,
        sectionId,
        activityId,
        stepNumber,
        completed: false,
        user_code: '',
      };

      // const response = await updateUserProgress(token, progressData);
      let response;
      if (careerPathId === null || careerPathId === undefined) {
        response = await updateUserProgress(token, progressData);
      } else {
        progressData = {
          careerPathId,
          ...progressData,
        };
        console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);

        response = await updateUserCareerPathProgress(token, progressData);
      }
      console.log('Progress reset successfully!');
      console.log(`response: ${JSON.stringify(response, null, 4)}`);
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error resetting progress:', errorMessage);
      alert('Failed to reset progress. Please try again.');
    }
  };

  // Button click handlers with reset functionality
  const handleResetActivity = () => {
    resetInstructionProgress(); // Reset instructions in the current activity
    resetUserProgress({ sectionId, activityId });
  };

  const handleResetSection = () => {
    resetInstructionProgress(); // Reset instructions for the current section
    resetUserProgress({ sectionId });
  };

  const handleResetCourse = () => {
    resetInstructionProgress(); // Reset all instructions across the course
    resetUserProgress();
  };

  const runReactJSCode = () => {
    const reactJSCode = code
      .replace(`import React from 'react';`, '')
      .replace(`import './App.css';`, '')
      .replace(
        `export default App;`,
        `ReactDOM.render(<App />, document.getElementById('root'));`
      );
    const iframe = document.getElementById('outputIframe');
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    iframeDoc.open();
    iframeDoc.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>React Preview</title>
        <!-- React and ReactDOM -->
        <script crossorigin src="https://cdn.jsdelivr.net/npm/react@18/umd/react.development.js"></script>
        <script crossorigin src="https://cdn.jsdelivr.net/npm/react-dom@18/umd/react-dom.development.js"></script>
        <!-- Babel for JSX transpilation -->
        <script src="https://cdn.jsdelivr.net/npm/@babel/standalone/babel.min.js"></script>
        <style>
          body { font-family: Arial, sans-serif; padding: 20px; }
        </style>
      </head>
      <body>
        <div id="root"></div>
        <script type="text/babel">
          ${reactJSCode}
        </script>
      </body>
      </html>
    `);
    iframeDoc.close();
  };

  const executeCode = async () => {
    try {
      console.log(`REACHED executeCode: ${codeExecutionLanguage}`);

      console.log(`33 currentInstruction: ${currentInstruction.length}`);

      if (currentInstruction.length === 0) {
        await executeCodeBasic();
      } else {
        // await executeCodeBasic();
        // TODO: For Course Content
        await executeCodeComplete();
      }
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || 'Unknown error';
      setResult(`Error: ${errorMessage}`);
    }
  };

  const executeCodeBasic = async () => {
    try {
      if (codeExecutionLanguage.toLowerCase().includes('reactjs')) {
        runReactJSCode();
      } else if (
        codeExecutionLanguage.toLowerCase().includes('html') ||
        codeExecutionLanguage.toLowerCase().includes('css')
      ) {
        const html = code;
        const completeCode = `
          ${html}
        `;

        const iframe = document.getElementById('outputIframe');
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(completeCode);
        iframeDoc.close();
      } else {
        const token = localStorage.getItem('token');

        // Execute the code online
        const response = await executeCodeOnline(token, {
          language: programmingLanguage.toLowerCase(),
          code,
          test_type: '',
          test_code: '',
        });

        if (!response?.data?.run) {
          throw new Error('No valid response from the code execution API.');
        }

        console.log(
          `response.data.run: ${JSON.stringify(response.data.run, null, 4)}`
        );

        const {
          stdout = '',
          stderr = '',
          code: exitCode = 0,
        } = response.data.run;

        console.log(`stdout: ${stdout}`);

        // Prepare HTML content to display results
        let resultHtml = `
          <div>
              ${exitCode !== 0 ? `<pre style="color: red;">${stdout}</pre>` : `<pre style="color: green;">${stdout}</pre>`}
              ${stderr ? `<pre style="color: red;">Error: ${stderr}</pre>` : ''}
              ${exitCode !== 0 ? `<pre style="color: red;">Exit code: ${exitCode}</pre>` : ''}
          </div>
        `;

        // Write the result into the iframe
        const iframe = document.getElementById('outputIframe');
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(resultHtml);
        iframeDoc.close();

        // Set result for stdout and stderr (if you still want to keep this for other usage)
        if (stdout) setResult(stdout);
        if (stderr)
          setResult((prevResult) => `${prevResult}\nError: ${stderr}`);
        if (exitCode !== 0)
          setResult(
            (prevResult) => `${prevResult}\nError: Exit code: ${exitCode}`
          );
      }
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || 'Unknown error';
      setResult(`Error: ${errorMessage}`);
    }
  };

  const executeCodeComplete = async () => {
    try {
      console.log(`REACHED executeCodeComplete`);
      const token = localStorage.getItem('token');

      if (!currentInstruction) {
        throw new Error('Current instruction is not available');
      }

      console.log(
        `11 currentInstruction: ${JSON.stringify(currentInstruction, null, 4)}`
      );

      console.log(
        `22 currentInstructionProgress: ${JSON.stringify(currentInstructionProgress, null, 4)}`
      );

      // Find the index of the first incomplete step in currentInstructionProgress
      let incompleteStepIndex = currentInstructionProgress.findIndex(
        (instruction) => !instruction.step_completed
      );

      // Check if all instructions are completed
      if (incompleteStepIndex === -1) {
        alert('All instructions finished');
        return; // Stop further execution if all instructions are completed
      }

      console.log(`33 incompleteStepIndex: ${incompleteStepIndex}`);

      setCurrentInstructionTestingStep(incompleteStepIndex);

      // Retrieve `instructionTestingType` and `instructionTestingCode` based on the incomplete step index
      const instructionTestingType =
        currentInstruction?.[incompleteStepIndex]?.hints?.[0]?.test_cases?.[0]
          ?.test_type || '';
      const instructionTestingCode =
        currentInstruction?.[incompleteStepIndex]?.hints?.[0]?.test_cases?.[0]
          ?.test_code || '';

      console.log(`instructionTestingType: ${instructionTestingType}`);
      console.log(`code: ${code}`);
      console.log(`instructionTestingCode: ${instructionTestingCode}`);

      // Execute the code online
      const response = await executeCodeOnline(token, {
        language: programmingLanguage.toLowerCase(),
        user_code: code,
        test_type: instructionTestingType,
        test_code: instructionTestingCode,
      });

      if (!response?.data?.run) {
        throw new Error('No valid response from the code execution API.');
      }

      console.log(`response.data.run: ${JSON.stringify(response, null, 4)}`);

      const {
        stdout = '',
        stderr = '',
        code: exitCode = 0,
        code_executed_response, // To display execution of the code
      } = response.data.run;
      const instructionResults = {};

      // Prepare HTML content to display results
      let resultHtml = `
      <div>
        ${exitCode !== 0 ? `<pre style="color: red;">${code_executed_response + '\n\n' + stdout}</pre>` : `<pre style="color: green;">${code_executed_response + '\n\n' + stdout}</pre>`}
        ${stderr ? `<pre style="color: red;">Error: ${stderr}</pre>` : ''}
        ${exitCode !== 0 ? `<pre style="color: red;">Exit code: ${code_executed_response + '\n\n' + exitCode}</pre>` : ''}
      </div>
    `;

      // Write the result into the iframe
      const iframe = document.getElementById('outputIframe');
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(resultHtml);
      iframeDoc.close();

      // Set result for stdout and stderr
      if (stdout) setResult(`${code_executed_response + '\n\n' + stdout}`);
      if (stderr) setResult((prevResult) => `${prevResult}\nError: ${stderr}`);
      if (exitCode !== 0)
        setResult(
          (prevResult) => `${prevResult}\nError: Exit code: ${exitCode}`
        );

      let testStatus = false;
      // Show the first failed test case hint, if applicable
      if (exitCode) {
        const errorMessageMatch = stdout.match(/AssertionError:\s(.*?)(?=\.:)/);
        const errorMessage = errorMessageMatch ? errorMessageMatch[1] : null;
        toast.error(`Hint: ${errorMessage}`);
      } else {
        // Update the step_completed status of the first incomplete step
        const updatedInstructionProgress = currentInstructionProgress.map(
          (instruction, index) => {
            if (index === incompleteStepIndex) {
              return {
                ...instruction,
                step_completed: true, // Set the step as completed
              };
            }
            return instruction;
          }
        );

        // Set the updated instruction progress back to state
        setCurrentInstructionProgress(updatedInstructionProgress);
        await updateActivityUserProgress(true, incompleteStepIndex, code);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data || error.message || 'Unknown error';
      setResult(`Error: ${errorMessage}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await updateCodingSubmission(token, submission.submission_id, {
        code,
        result,
      });
      alert('Coding activity submitted successfully!');
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      console.error('Error submitting coding activity:', errorMessage);
    }
  };

  // Function to format code using Prettier
  const formatCodeWithPrettier = async () => {
    try {
      const parser =
        programmingLanguage.toLowerCase() === 'html' ||
        programmingLanguage.toLowerCase() === 'html,css'
          ? 'html'
          : 'babel';

      const plugins = [
        programmingLanguage.toLowerCase() === 'html' ||
        programmingLanguage.toLowerCase() === 'html,css'
          ? parserHtml
          : parserBabel,
      ];

      console.log('Type of Code:', typeof code);
      console.log(`Language: ${programmingLanguage.toLowerCase()}`);

      const formattedCode = await prettier.format(code, {
        parser,
        plugins,
        tabWidth: 2,
        semi: true,
        singleQuote: true,
        trailingComma: 'es5',
      });

      console.log('Formatted Code:', formattedCode);
      console.log('Type of Formatted Code:', typeof formattedCode);

      setCode(formattedCode);
    } catch (error) {
      console.error('Error formatting code with Prettier:', error);
    }
  };

  // Ref to store the last editor position
  const editorRef = useRef(null);
  const cursorPosition = useRef(null);

  // Track editor instance when mounting
  const handleEditorWillMount = (editor) => {
    editorRef.current = editor;
  };

  // Capture cursor position on change
  const handleEditorChange = (value) => {
    setCode(value || '');
    if (editorRef.current) {
      cursorPosition.current = editorRef.current.getPosition();
    }
  };

  // Set cursor position after code updates
  const setCursorPosition = useCallback(() => {
    if (editorRef.current && cursorPosition.current) {
      editorRef.current.setPosition(cursorPosition.current);
      editorRef.current.revealPosition(cursorPosition.current);
    }
  }, []);

  // Manage code change with cursor positioning
  const handleCodeChange = (value) => {
    setCode(value || '');
  };

  useEffect(() => {
    setCursorPosition();
  }, [code, setCursorPosition]);

  if (!activity) {
    return <div>Loading...</div>;
  }

  return (
    <Container fluid className="vh-100 d-flex flex-column coding-activity-view">
      <Split
        mode="horizontal"
        lineBar
        gutterSize={4}
        style={{ height: '100vh' }}
      >
        {/* Sidebar for instructions */}
        <Col md={4} className="instructions-sidebar">
          <div className="instructions-content">
            <h2>{activity.title}</h2>
            {activity.description ? (
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>Concept:</Accordion.Header>
                  <Accordion.Body>
                    <ReactMarkdown components={renderers}>
                      {activity.description || 'No description available'}
                    </ReactMarkdown>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
            {activity.activity_description_anology ? (
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>Analogy:</Accordion.Header>
                  <Accordion.Body>
                    <ReactMarkdown components={renderers}>
                      {activity.activity_description_anology ||
                        'No description available'}
                    </ReactMarkdown>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
            {activity.activity_description ? (
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>Concept:</Accordion.Header>
                  <Accordion.Body>
                    <ReactMarkdown components={renderers}>
                      {activity.activity_description ||
                        'No description available'}
                    </ReactMarkdown>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
            {activity.activity_syntax_example ? (
              <Accordion>
                <Accordion.Item>
                  <Accordion.Header>Syntax:</Accordion.Header>
                  <Accordion.Body>
                    <ReactMarkdown components={renderers}>
                      {activity.activity_syntax_example ||
                        'No description available'}
                    </ReactMarkdown>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : null}
            <CodingActivityInstructions
              activity={activity}
              currentInstructionProgress={currentInstructionProgress}
              currentInstructionIndex={currentInstructionIndex}
            />
          </div>
        </Col>

        {/* Code Editor Section */}
        <Col md={4} className="code-editor-section">
          <MonacoEditor
            height="80vh"
            defaultLanguage={
              programmingLanguage.toLowerCase().includes('reactjs')
                ? 'javascript'
                : programmingLanguage.toLowerCase().includes('html')
                  ? 'html'
                  : programmingLanguage.toLowerCase()
            }
            // defaultLanguage={'html'}
            value={code}
            onChange={handleCodeChange}
            className="editor-container"
            options={{
              scrollBeyondLastLine: false,
              wordWrap: 'on',
              minimap: { enabled: false },
              automaticLayout: true,
            }}
            theme={'vs-dark'}
            editorWillMount={handleEditorWillMount}
          />
          <br></br>
          <Row className="px-3">
            <Col className="d-flex">
              <Button variant="warning" onClick={executeCode}>
                Run
              </Button>
            </Col>
            <Col className="d-flex">
              <Button variant="warning" onClick={handleResetActivity}>
                Reset
              </Button>
            </Col>
            <Col className="d-flex">
              <Button variant="warning" onClick={formatCodeWithPrettier}>
                Format
              </Button>
            </Col>
            <Col className="d-flex">
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </Col>

        {/* Console Output Section */}
        <Col md={4} className="output-section">
          <Card className="flex-grow-1">
            {/* {result || 'No output available'} */}
            <iframe
              id="outputIframe"
              title="Output"
              style={{
                width: '100%',
                height: '80vh',
                border: '1px solid #ccc',
              }}
            ></iframe>
          </Card>
          <Row className="px-3">
            <Col className="d-flex justify-content-end">
              <Button
                variant="secondary"
                className="back-button"
                onClick={() =>
                  handleCoursePreviousActivity(
                    navigate,
                    careerPathId,
                    courseId,
                    sectionId,
                    activityType,
                    activityId
                  )
                }
              >
                Previous
              </Button>
            </Col>

            <Col className="d-flex justify-content-end">
              <Button
                variant="secondary"
                className="mt-3"
                onClick={() =>
                  handleCourseNextActivity(
                    navigate,
                    careerPathId,
                    courseId,
                    sectionId,
                    activityType,
                    activityId
                  )
                }
              >
                Next
              </Button>
            </Col>
          </Row>
        </Col>
      </Split>

      {/* Centered ToastContainer */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      />
    </Container>
  );
};

export default HTMLCodingActivityViewNew;
