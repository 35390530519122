import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import your existing Navbar component
import Footer from './Footer.js'; // Import your existing Footer component
import 'bootstrap/dist/css/bootstrap.min.css';
import CenteredText from './CenteredText.js';
import PERNMERNPDF from '../assets/pdfs/HLS Full Stack.pdf';
import BackEndPDF from '../assets/pdfs/HLS Back End.pdf';
import FrontEndPDF from '../assets/pdfs/HLS Front End.pdf';
import LoadPDF from './LoadPDF.js';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar /> {/* Add Navbar at the top */}
      <Container className="mt-5 flex-grow-1">
        {/* Introduction Section */}
        <Row className="mb-4 text-center">
          <Col>
            <CenteredText as="h1">About Us</CenteredText>
            <CenteredText as="p" className="lead">
              Empowering You to Master In-Demand Tech Skills with Affordable,
              High-Quality Training.
            </CenteredText>
          </Col>
        </Row>

        {/* Program Details */}
        <Row className="mt-4">
          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>
                  Full-Stack, Front-End & Back-End Engineer Programs
                </Card.Title>
                <Card.Text>
                  <strong>Our comprehensive curriculum covers:</strong>
                  <ul>
                    <li>Front-End: Master HTML, CSS, JavaScript, and React.</li>
                    <li>
                      Back-End: Master Node.js, Express.js, PostgreSQL, MongoDB.
                    </li>
                    <li>
                      PERN Stack: Master PostgreSQL, Express.js, React, Node.js.
                    </li>
                    <li>
                      MERN Stack: Master MongoDB, Express.js, React, Node.js.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Interactive Live Video Sessions</Card.Title>
                <Card.Text>
                  <ul>
                    <li>
                      Introduce New Concepts: Grasp essential technologies
                      quickly.
                    </li>
                    <li>
                      Active Learning: Stay engaged with hands-on experiences.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Learning Methodology and Support */}
        <Row className="mt-4">
          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Learning by Doing</Card.Title>
                <Card.Text>
                  <ul>
                    <li>
                      Immediate Application: Apply knowledge through projects.
                    </li>
                    <li>Problem-Solving: Develop critical thinking skills.</li>
                    <li>
                      Portfolio Development: Build a professional portfolio.
                    </li>
                    <li>Confidence Building: Gain the skills to excel.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Additional Support and Interaction</Card.Title>
                <Card.Text>
                  <ul>
                    <li>
                      Mock Interviews: Practice and get feedback from experts.
                    </li>
                    <li>
                      One-to-One Sessions: Personalized guidance for
                      improvement.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Program Features */}
        <Row className="mt-4">
          <Col>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Comprehensive Training Program Features</Card.Title>
                <Card.Text>
                  <ul>
                    <li>
                      Certification: Earn industry-recognized credentials.
                    </li>
                    <li>
                      Interactive Sessions: Stay engaged with regular live
                      sessions.
                    </li>
                    <li>
                      High-Quality Content: Access top-tier learning resources.
                    </li>
                    <li>
                      Daily Practice: Reinforce knowledge through consistent
                      practice.
                    </li>
                    <li>
                      Ongoing Support: Receive guidance and progress tracking.
                    </li>
                    <li>
                      Job Readiness: Be fully prepared to launch your tech
                      career.
                    </li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>
                  Personalized Learning with Happy Learning Solutions
                </Card.Title>
                <Card.Text>
                  At Happy Learning Solutions, we tailor our educational
                  offerings to meet your unique learning needs and career goals.
                  Our flexible programs and one-on-one guidance ensure that you
                  receive the support necessary to excel in your chosen path.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* PDF Section */}
        <LoadPDF
          title="View Our Full-Stack Engineer Curriculum"
          fileName={PERNMERNPDF}
        />
        <LoadPDF
          title="View Our Back-End Engineer Curriculum"
          fileName={BackEndPDF}
        />
        <LoadPDF
          title="View Our Front-End Engineer Curriculum"
          fileName={FrontEndPDF}
        />
      </Container>
      <Footer /> {/* Add Footer at the bottom */}
    </div>
  );
};

export default AboutUs;
