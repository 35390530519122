import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the LongestCommonPrefixDemo component
const problemTitle = `### Understanding and Solving **Longest Common Prefix** Problem`;
const initialInputValue = '["flower","flow","flight"]';
const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Longest Common Prefix**:`,
  `###### **Step 2:** Program to Find the **Longest Common Prefix**:`,
  `###### **Step 3:** Input an array of strings to find the **longest common prefix**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array of **strings**, write a function to find the **longest common prefix** among them. If there is **no common prefix**, return an **empty string**.

#### Problem Statement (Elaborated):
Write a program that **finds** the **longest prefix** that is **common** to **all strings** in a given **array**. If the array is **empty** or there is **no common prefix**, the **output** should be an **empty string**.

#### Testing Examples
1. **Example 1: Input = ["flower","flow","flight"]**
   - **Step-by-step**: The **common prefix** for all three strings is \`"fl"\`. Therefore, the **output** is \`"fl"\`.
2. **Example 2: Input = ["dog","racecar","car"]**
   - **Step-by-step**: There is **no common prefix** among these strings. Therefore, the **output** is \`""\`.

#### What is a Longest Common Prefix?
A **longest common prefix** is a **substring** that appears at the **beginning** of **all strings** in a given list. For example, in the words \`"flower"\`, \`"flow"\`, and \`"flight"\`, the **longest common prefix** is \`"fl"\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate** through **characters** of the strings.
2. **Conditional Statements**: Using \`if-else\` to **compare** characters.
3. **Edge Case Handling**: Understanding how to **handle inputs** like **empty arrays** or **arrays with one string**.

#### Real-World Concepts Covered
1. **Pattern Matching**: Understanding how to **find common patterns** in strings.
2. **Comparison**: Learning to **compare elements** of strings in a **sequential manner**.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **string comparisons**.

#### How Does the Program Work?
1. If the **array is empty**, return an **empty string**.
2. Assume the **first string** is the **prefix**.
3. **Iterate** through **each character** of the **first string**.
4. Compare the **current character** with the **corresponding character** in all other **strings**.
5. If **all match**, continue to the **next character**.
6. If **any character does not match**, **stop** and **return** the prefix up to that point.
7. If the **prefix remains valid** throughout, return it as the **result**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Compare **characters** one-by-one across all strings.
2. **Hint 2**: **Stop early** if any character **does not match**.
3. **Hint 3**: Handle **edge cases** like **arrays with a single string**.

#### Why Do We Do This?
We **perform this check** to **find the longest common starting sequence** across multiple strings. This concept can help in understanding **common patterns** and **prefix-based searches**.
`;

const initialAlgorithm = `1. If the **array is empty**, return an **empty string**.
2. Assume the **first string** is the **prefix**.
3. Iterate through **each character** of the **first string**.
   - a. For each character, **check** if it **matches** the **corresponding character** in all other strings.
   - b. If **any character does not match**, **return the prefix** up to that point.
4. If the **prefix remains valid**, **return** it.
`;

const initialProblemCode = `1  function longestCommonPrefix(arr) {
2    if (arr.length === 0) return "";
3    let prefix = arr[0];
4    for (let i = 0; i < prefix.length; i++) {
5      const char = prefix[i];
6      for (let j = 1; j < arr.length; j++) {
7        if (i >= arr[j].length || arr[j][i] !== char) {
8          return prefix.substring(0, i);
9        }
10     }
11   }
12   return prefix;
13 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component (same as PalindromeNumberDemo)
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

const LongestCommonPrefixDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);

  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };
      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let arr;
    try {
      arr = JSON.parse(numberInput);
      if (!Array.isArray(arr) || arr.some((item) => typeof item !== 'string')) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: 'Invalid input: Please enter a valid array of strings!',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function longestCommonPrefix is called with arr = ${JSON.stringify(arr)}`,
      { arr }
    );

    if (arr.length === 0) {
      addTrace(executionTrace, 2, 'The input array is empty, return ""', {});
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: 'There is no common prefix for an empty array.',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let prefix = arr[0];
    addTrace(
      executionTrace,
      3,
      `Assume the first string as the prefix: prefix = "${prefix}"`,
      { prefix }
    );

    for (let i = 0; i < prefix.length; i++) {
      const char = prefix[i];
      addTrace(executionTrace, 5, `Check character at index ${i}: "${char}"`, {
        i,
        char,
      });

      for (let j = 1; j < arr.length; j++) {
        addTrace(
          executionTrace,
          6,
          `Check if character "${char}" matches at index ${i} in string "${arr[j]}"`,
          { i, j, char, currentString: arr[j] }
        );

        if (i >= arr[j].length || arr[j][i] !== char) {
          addTrace(
            executionTrace,
            8,
            `Character does not match or index out of bounds, return prefix = "${prefix.substring(
              0,
              i
            )}"`,
            { prefix: prefix.substring(0, i) }
          );
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `The longest common prefix is "${prefix.substring(0, i)}".`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
      }
    }

    addTrace(
      executionTrace,
      12,
      `All characters matched, return prefix = "${prefix}"`,
      { prefix }
    );

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The longest common prefix is "${prefix}".`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default LongestCommonPrefixDemo;
