export const problemTitle = `### Understanding and Solving **Pascal's Triangle II** Problem`;
export const initialInputValue = '4';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to generate a specific row of Pascal's Triangle:`,
  `###### **Step 2:** Program to Generate Pascal's Triangle Row:`,
  `###### **Step 3:** Input the row index:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`rowIndex\`, return the \`rowIndex\`th (0-indexed) row of Pascal's Triangle. Each row of Pascal's Triangle is generated based on the sum of the two numbers directly above it in the previous row.

#### Problem Statement(Elaborated):
Write a program that **takes an integer as input** and generates the \`rowIndex\`th row of Pascal's Triangle. Each row starts and ends with \`1\`, and each number in between is the sum of the two numbers directly above it in the previous row.

#### Testing Examples
1. **Example 1: Input = 3**
   - **Output**: [1, 3, 3, 1]
2. **Example 2: Input = 4**
   - **Output**: [1, 4, 6, 4, 1]

#### What is Pascal's Triangle Row?
Pascal's Triangle row is the \`n\`th row in the triangular arrangement of numbers where:
1. The first and last numbers in the row are \`1\`.
2. Each number in between is the sum of the two numbers directly above it.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Generating each element in the row iteratively.
2. **Mathematical Operations**: Adding numbers to generate the row.
3. **Dynamic Programming Basics**: Using previously computed elements to calculate the current row.

#### Real-World Concepts Covered:
1. **Binomial Coefficients**: Pascal's Triangle rows represent coefficients in binomial expansion.
2. **Iterative Patterns**: Learning to generate patterns based on iterative logic.
3. **Logical Thinking**: Enhancing problem-solving skills through structured loops.

#### How Does the Program Work?
1. Start with the first row as \`[1]\`.
2. Use a single loop to compute each row up to the given \`rowIndex\` by modifying the previous row.
3. Continue until the desired row is generated.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Each row starts and ends with \`1\`.
2. **Hint 2**: Use a temporary row for calculations to avoid overwriting the previous row.
3. **Hint 3**: The sum of two adjacent elements from the previous row gives the current element.

#### Why Do We Do This?
Pascal's Triangle helps understand patterns in binomial coefficients and combinations, which are fundamental concepts in mathematics and computer science.
`;

export const initialAlgorithm = `1. Initialize the first row of Pascal's Triangle as \`[1]\`.
2. Loop from 1 to \`rowIndex\` (inclusive):
   - a. Use a temporary array to store the new row.
   - b. Start the new row with \`1\` (the first element).
   - c. For each element in the new row (except the last one), calculate the sum of two adjacent elements from the previous row.
   - d. End the row with \`1\` (the last element).
3. Replace the previous row with the new row after each iteration.
4. Return the row after \`rowIndex\` iterations.`;

export const initialProblemCode = `1  function getPascalsTriangleRow(rowIndex) {
2    if (rowIndex < 0) return []; // Invalid row index
3    let row = [1]; // Start with the first row
4    for (let i = 1; i <= rowIndex; i++) {
5      let newRow = [1]; // Start the new row with 1
6      for (let j = 1; j < row.length; j++) {
7        let value = row[j - 1] + row[j]; // Sum two adjacent elements
8        newRow.push(value); // Add the calculated value to the new row
9      }
10      newRow.push(1); // End the row with 1
11      row = newRow; // Replace the old row with the new one
12    }
13    return row; // Return the final row
14 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let rowIndex;
  try {
    rowIndex = parseInt(userInput, 10);
    if (isNaN(rowIndex) || rowIndex < 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a non-negative integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function getPascalsTriangleRow is called with rowIndex = ${rowIndex}`,
    { rowIndex }
  );

  if (rowIndex === 0) {
    addTrace(executionTrace, 2, `Base case: rowIndex is 0, return [1]`, {
      row: [1],
    });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Row 0 of Pascal's Triangle: [1]`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let row = [1];
  addTrace(executionTrace, 3, `Initialize row with first row: [1]`, { row });

  for (let i = 1; i <= rowIndex; i++) {
    let newRow = [1];
    addTrace(executionTrace, 5, `Generate row ${i}: Start new row with [1]`, {
      newRow,
    });

    for (let j = 1; j < row.length; j++) {
      let value = row[j - 1] + row[j];
      newRow.push(value);
      addTrace(
        executionTrace,
        7,
        `Calculate value for row ${i}, position ${j}: ${row[j - 1]} + ${row[j]} = ${value}`,
        { value, newRow }
      );
    }

    newRow.push(1);
    addTrace(
      executionTrace,
      10,
      `Complete row ${i} by adding 1 at the end: ${newRow}`,
      { newRow }
    );

    row = newRow;
    addTrace(executionTrace, 11, `Update row to: ${row}`, { row });
  }

  addTrace(executionTrace, 13, `Return the final row: ${row}`, { row });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Row ${rowIndex} of Pascal's Triangle generated successfully.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
