export const problemTitle = `### Understanding and Solving **Fizz Buzz** Problem`;
export const initialInputValue = '15';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve **Fizz Buzz**:`,
  `###### **Step 2:** Program to Solve **Fizz Buzz**:`,
  `###### **Step 3:** Input a number to compute **Fizz Buzz**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, print the numbers from \`1\` to \`n\`. But for multiples of three, print \`"Fizz"\` instead of the number and for multiples of five, print \`"Buzz"\`. For numbers that are multiples of both three and five, print \`"FizzBuzz"\`.

#### Testing Examples
1. **Example 1: Input = 3**
   - **Output**: [ "1", "2", "Fizz" ]
2. **Example 2: Input = 5**
   - **Output**: [ "1", "2", "Fizz", "4", "Buzz" ]
3. **Example 3: Input = 15**
   - **Output**: [ "1", "2", "Fizz", "4", "Buzz", "Fizz", "7", "8", "Fizz", "Buzz", "11", "Fizz", "13", "14", "FizzBuzz" ]

#### What is Fizz Buzz?
**Fizz Buzz** is a classic programming problem often used to teach beginners about loops and conditional statements. The goal is to process a sequence of numbers with specific conditions and output corresponding results.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through numbers from 1 to \`n\`.
2. **Conditional Statements**: To check divisibility and print corresponding values.
3. **Mathematical Operations**: Using the modulo operator (\`%\`) to determine divisibility.

#### Real-World Concepts Covered
1. **Decision Making**: Understanding how conditions control program flow.
2. **Pattern Recognition**: Identifying number patterns based on divisibility.
3. **Iterative Processes**: Performing repeated tasks efficiently using loops.

#### How Does the Program Work?
1. Loop through numbers from 1 to \`n\`.
2. For each number:
   - If it is divisible by both 3 and 5, append \`"FizzBuzz"\`.
   - If it is divisible by only 3, append \`"Fizz"\`.
   - If it is divisible by only 5, append \`"Buzz"\`.
   - Otherwise, append the number itself.
3. Print the results.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the modulo operator (\`%\`) to check divisibility.
2. **Hint 2**: Always check divisibility by both 3 and 5 first to avoid overlapping conditions.
3. **Hint 3**: Append results to the output in the correct sequence.

`;

export const initialAlgorithm = `1. Initialize an empty string array \`output\`.
2. Loop through all numbers \`i\` from 1 to \`n\`:
   - a. If \`i % 3 === 0\` and \`i % 5 === 0\`, append \`"FizzBuzz"\` to \`output\`.
   - b. Else if \`i % 3 === 0\`, append \`"Fizz"\` to \`output\`.
   - c. Else if \`i % 5 === 0\`, append \`"Buzz"\` to \`output\`.
   - d. Otherwise, append \`i\` (converted to a string) to \`output\`.
3. Return the \`output\`.`;

export const initialProblemCode = `1  function fizzBuzz(n) {
2    let output = [];
3    for (let i = 1; i <= n; i++) {
4      if (i % 3 === 0 && i % 5 === 0) {
5        output.push("FizzBuzz");
6      } else if (i % 3 === 0) {
7        output.push("Fizz");
8      } else if (i % 5 === 0) {
9        output.push("Buzz");
10     } else {
11       output.push(i.toString());
12     }
13   }
14   return output;
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function fizzBuzz is called with n = ${n}`, {
    n,
  });

  // Line 2: Initialize output
  let output = [];
  addTrace(executionTrace, 2, `Initialize output as an empty array`, {
    output,
  });

  // Line 3: Start for loop
  for (let i = 1; i <= n; i++) {
    addTrace(executionTrace, 3, `Loop iteration i = ${i}`, { i });

    // Line 4: Check for FizzBuzz
    if (i % 3 === 0 && i % 5 === 0) {
      output.push('FizzBuzz');
      addTrace(executionTrace, 5, `Append "FizzBuzz" to output for i = ${i}`, {
        output,
      });
    }
    // Line 6: Check for Fizz
    else if (i % 3 === 0) {
      output.push('Fizz');
      addTrace(executionTrace, 7, `Append "Fizz" to output for i = ${i}`, {
        output,
      });
    }
    // Line 8: Check for Buzz
    else if (i % 5 === 0) {
      output.push('Buzz');
      addTrace(executionTrace, 9, `Append "Buzz" to output for i = ${i}`, {
        output,
      });
    }
    // Line 10: Append number as string
    else {
      output.push(i.toString());
      addTrace(executionTrace, 11, `Append "${i}" to output for i = ${i}`, {
        output,
      });
    }
  }

  // Line 14: Return output
  addTrace(executionTrace, 14, `Return output: [${output.join(', ')}]`, {
    output,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `FizzBuzz sequence for ${n}: [${output.join(', ')}]`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
