export const problemTitle = `### Understanding and Solving **Counting Bits** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to count set bits (1s) in binary representation:`,
  `###### **Step 2:** Program to Count Bits:`,
  `###### **Step 3:** Input a number to calculate bits for numbers up to it:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, return an array \`ans\` such that for each \`i\` (0 <= i <= n), \`ans[i]\` is the number of 1's in the binary representation of \`i\`.

#### Problem Statement (Elaborated):
You need to count how many \`1's\` appear in the binary representation of every number from \`0\` to \`n\`.

#### Testing Examples:
1. **Example 1**: Input = \`5\`
   - **Output**: [0, 1, 1, 2, 1, 2] (Binary: 0 -> 0, 1 -> 1, 2 -> 10, 3 -> 11, 4 -> 100, 5 -> 101)
2. **Example 2**: Input = \`2\`
   - **Output**: [0, 1, 1] (Binary: 0 -> 0, 1 -> 1, 2 -> 10)

#### What Topics Will Be Covered?
1. **Dynamic Programming**: Breaking the problem into smaller overlapping subproblems.
2. **Bit Manipulation**: Understanding how to count set bits in binary numbers.
3. **Loops**: Iterating from \`0\` to \`n\`.
4. **Mathematical Calculations**: Using \`mod\` and \`division\` to count set bits.

#### Real-World Concepts Covered:
1. **Binary Representation**: Understanding how numbers are represented in binary.
2. **Logical Thinking**: Applying systematic approaches to solve problems.
3. **Efficient Calculations**: Minimizing operations using dynamic programming.

#### How Does the Program Work?
1. Initialize an array of size \`n + 1\` with all zeros.
2. Iterate through each number from \`1\` to \`n\`.
3. Use the formula \`bits[i] = bits[i >> 1] + (i & 1)\`:
   - a. \`i >> 1\`: Divides the number by 2, giving the bits of \`i\` without the least significant bit.
   - b. \`i & 1\`: Checks if the least significant bit is \`1\`.
4. Store the result for each number in the array.

#### Hints:
1. **Hint 1**: Use the property of \`i >> 1\` to calculate the bits of a smaller number.
2. **Hint 2**: Use \`i & 1\` to determine if the current number has a \`1\` in its last bit.

#### Why Do We Do This?
Counting set bits is a fundamental problem in understanding binary numbers. This approach demonstrates how to solve the problem efficiently using dynamic programming.
`;

export const initialAlgorithm = `1. Initialize an array \`bits\` of size \`n + 1\` with all elements as 0.
2. Loop through each number \`i\` from 1 to \`n\`:
   - a. Calculate \`bits[i]\` as \`bits[i >> 1] + (i & 1)\`.
   - b. Store the result in \`bits[i]\`.
3. Return the \`bits\` array as the result.`;

export const initialProblemCode = `1  function countBits(n) {
2    let bits = new Array(n + 1).fill(0);
3    for (let i = 1; i <= n; i++) {
4      bits[i] = bits[i >> 1] + (i & 1);
5    }
6    return bits;
7 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a non-negative integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function countBits is called with n = ${n}`, {
    n,
  });

  let bits = new Array(n + 1).fill(0);
  addTrace(
    executionTrace,
    2,
    `Initialize bits array of size ${n + 1} with all elements as 0.`,
    { bits: bits.slice() }
  );

  for (let i = 1; i <= n; i++) {
    addTrace(executionTrace, 3, `Calculating bits for i = ${i}.`, {
      currentNumber: i,
    });

    bits[i] = bits[i >> 1] + (i & 1);
    addTrace(
      executionTrace,
      4,
      `Update bits[${i}] = bits[${i >> 1}] + (${i} & 1) = ${bits[i]}.`,
      { bits: bits.slice() }
    );
  }

  addTrace(executionTrace, 6, `Return the final bits array.`, { bits });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Bits array: [${bits.join(', ')}]`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
