import React from 'react';
import { Accordion } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import './CodingActivityView.css';

const CodingActivityInstructions = ({
  activity,
  currentInstructionProgress,
  currentInstructionIndex,
}) => {
  return (
    <div>
      {activity?.instructions && activity?.instructions.length > 0 ? (
        <Accordion>
          <Accordion.Item>
            <Accordion.Body>
              {activity?.instructions && activity?.instructions.length > 0 ? (
                <h5>Practicing Instructions:</h5>
              ) : null}
              <div>
                {(activity?.instructions || []).map((instruction, index) => {
                  const isCompleted =
                    currentInstructionProgress[index]?.step_completed;
                  const isCurrent = index === currentInstructionIndex;

                  return (
                    <Accordion key={instruction?.id || index}>
                      <Accordion.Item>
                        <Accordion.Body>
                          <div
                            key={instruction?.id || index}
                            className="instruction-card"
                          >
                            <div>
                              <strong>Step {instruction?.step_number}:</strong>
                              <div>
                                {instruction?.description ? (
                                  <ReactMarkdown>
                                    {instruction?.description ||
                                      'No description available'}
                                  </ReactMarkdown>
                                ) : null}
                                {instruction?.instruction_description ? (
                                  <ReactMarkdown>
                                    {instruction?.instruction_description ||
                                      'No description available'}
                                  </ReactMarkdown>
                                ) : null}
                              </div>
                            </div>
                            <Accordion
                              defaultActiveKey={
                                isCurrent ? index.toString() : null
                              }
                              className={`accordion ${isCurrent ? 'current-instruction' : ''}`}
                            >
                              <Accordion.Item eventKey={index.toString()}>
                                <Accordion.Header>
                                  <input
                                    type="checkbox"
                                    checked={isCompleted}
                                    readOnly
                                    className="me-2"
                                  />
                                  <strong>Click For Hints</strong>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {instruction?.hints?.length > 0 ? (
                                    <div className="instruction-hints">
                                      <ul>
                                        {instruction?.hints.map((hint) => (
                                          <li key={hint?.hint_text}>
                                            <ReactMarkdown>
                                              {hint?.hint_text}
                                            </ReactMarkdown>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <p>No hints available</p>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : null}
    </div>
  );
};

export default CodingActivityInstructions;
