export const problemTitle = `### Understanding and Solving **Happy Number Using Mathematics** Problem`;
export const initialInputValue = '19';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine if a number is a **happy number**:`,
  `###### **Step 2:** Program to Check **Happy Number** Using Mathematics:`,
  `###### **Step 3:** Input a number to check if it is a **happy number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Write a program to determine if a number \`n\` is a **happy number**. A **happy number** is defined as a number that eventually reaches \`1\` when replaced by the sum of the squares of its digits.

#### Problem Statement(Elaborated):
If a number is not a happy number, it will fall into a loop that does not include \`1\`. The program should detect such loops and determine if the number is happy or not.

#### Testing Examples
1. **Example 1: Input = 19**
   - **Step-by-step**: 
     - 1² + 9² = 82
     - 8² + 2² = 68
     - 6² + 8² = 100
     - 1² + 0² + 0² = 1 (Happy number)
     - Therefore, the **output** is **true**.
2. **Example 2: Input = 2**
   - **Step-by-step**: The number will eventually fall into a cycle, so it is **not a happy number**.
   - Therefore, the **output** is **false**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate the sum of the squares of digits iteratively.
2. **Mathematical Operations**: Using modulo (\`%\`) and division (\`/\`) to extract and square digits.
3. **Conditional Statements**: To detect cycles and check for happiness.

#### Real-World Concepts Covered
1. **Number Patterns**: Identifying patterns and cycles in mathematical operations.
2. **Problem Solving**: Building a solution that detects infinite loops effectively.

#### How Does the Program Work?
1. Start with the given number \`n\`.
2. Compute the sum of the squares of its digits.
3. If the sum becomes \`1\`, the number is happy.
4. If a loop is detected (same sum appears twice), the number is not happy.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a slow and fast pointer approach to detect cycles (similar to detecting cycles in linked lists).
2. **Hint 2**: Square each digit of the number and add it to compute the sum.
3. **Hint 3**: Handle single-digit numbers directly.

#### Why Do We Do This?
The problem demonstrates how to detect cycles in processes and solve mathematical puzzles using iterative methods.
`;

export const initialAlgorithm = `1. Initialize:
   - \`slow = n\`
   - \`fast = getNext(n)\` (sum of the squares of the digits of \`n\`).
2. Use a loop to check for cycles:
   - a. Compute the next number for \`slow\` and \`fast\`.
   - b. If \`fast == 1\`, return \`true\` (happy number).
   - c. If \`slow == fast\`, return \`false\` (cycle detected).
3. Repeat until either condition is met.`;

export const initialProblemCode = `1  function isHappy(n) {
2    function getNext(num) {
3      let sum = 0;
4      while (num > 0) {
5        let digit = num % 10;
6        sum += digit * digit;
7        num = Math.floor(num / 10);
8      }
9      return sum;
10   }
11   let slow = n, fast = getNext(n);
12   while (fast !== 1 && slow !== fast) {
13     slow = getNext(slow);
14     fast = getNext(getNext(fast));
15   }
16   return fast === 1;
17 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function isHappy is called with n = ${n}`, {
    n,
  });

  function getNext(num) {
    let sum = 0;
    while (num > 0) {
      let digit = num % 10;
      addTrace(
        executionTrace,
        5,
        `Calculate digit: digit = num % 10 = ${num} % 10 = ${digit}`,
        { digit }
      );
      sum += digit * digit;
      addTrace(executionTrace, 6, `Update sum: sum += digit * digit = ${sum}`, {
        sum,
      });
      num = Math.floor(num / 10);
      addTrace(
        executionTrace,
        7,
        `Update num: num = Math.floor(num / 10) = ${num}`,
        { num }
      );
    }
    addTrace(executionTrace, 9, `Return sum of squares: ${sum}`, { sum });
    return sum;
  }

  let slow = n,
    fast = getNext(n);
  addTrace(
    executionTrace,
    11,
    `Initialize slow = ${slow}, fast = getNext(${n}) = ${fast}`,
    { slow, fast }
  );

  while (fast !== 1 && slow !== fast) {
    addTrace(
      executionTrace,
      12,
      `Check while condition: fast !== 1 (${fast !== 1}) && slow !== fast (${slow !== fast})`,
      { fast, slow }
    );

    slow = getNext(slow);
    addTrace(
      executionTrace,
      13,
      `Update slow: slow = getNext(slow) = ${slow}`,
      { slow }
    );

    fast = getNext(getNext(fast));
    addTrace(
      executionTrace,
      14,
      `Update fast: fast = getNext(getNext(fast)) = ${fast}`,
      { fast }
    );
  }

  let isHappy = fast === 1;
  let resultMessage = isHappy
    ? `${n} is a happy number.`
    : `${n} is not a happy number.`;

  addTrace(executionTrace, 16, `Return fast === 1: ${isHappy}`, {
    fast,
    isHappy,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
