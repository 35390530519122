export const problemTitle = `### Understanding and Solving **Missing Number** Problem`;
export const initialInputValue = '0, 1, 3';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **missing number**:`,
  `###### **Step 2:** Program to Find **Missing Number**:`,
  `###### **Step 3:** Input a sequence of numbers to find the **missing number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array containing \`n\` distinct numbers taken from \`0, 1, 2, ..., n\`, find the one that is missing from the array.

#### Problem Statement(Elaborated):
Write a program that takes a sequence of numbers and determines the missing number in the range from \`0\` to \`n\`.

#### Testing Examples
1. **Example 1: Input = [0, 1, 3]**
   - **Step-by-step**:
     - Calculate the expected sum of numbers from \`0\` to \`n\` (inclusive): \`(3 * (3 + 1)) / 2 = 6\`.
     - Calculate the actual sum of numbers in the input array: \`0 + 1 + 3 = 4\`.
     - Missing number: \`6 - 4 = 2\`.
     - Result: **2**.
2. **Example 2: Input = [0, 1, 2]**
   - **Step-by-step**:
     - Calculate the expected sum of numbers from \`0\` to \`n\`: \`(2 * (2 + 1)) / 2 = 3\`.
     - Calculate the actual sum of numbers in the input array: \`0 + 1 + 2 = 3\`.
     - Missing number: \`3 - 3 = 0\`.
     - Result: **0**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate the sum of array elements.
2. **Mathematical Operations**: Using formulas to calculate expected sum.

#### Real-World Concepts Covered
1. **Summation Formula**: Exploring mathematical methods for summing sequences.
2. **Problem Simplification**: Reducing the problem to basic arithmetic.

#### How Does the Program Work?
1. Calculate the expected sum of numbers from \`0\` to \`n\` using the formula \`n * (n + 1) / 2\`.
2. Calculate the actual sum of numbers in the input array.
3. Subtract the actual sum from the expected sum to find the missing number.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the formula \`n * (n + 1) / 2\` to compute the expected sum.
2. **Hint 2**: Loop through the array to compute the actual sum.
3. **Hint 3**: The difference between the expected sum and actual sum gives the missing number.
`;

export const initialAlgorithm = `1. Calculate the **expected sum** of numbers from \`0\` to \`n\` using the formula \`n * (n + 1) / 2\`.
2. Calculate the **actual sum** of numbers in the array.
3. The **missing number** is the difference: **expected sum - actual sum**.`;

export const initialProblemCode = `1  function findMissingNumber(nums, n) {
2    let expectedSum = n * (n + 1) / 2;
3    let actualSum = 0;
4    for (let i = 0; i < nums.length; i++) {
5      actualSum += nums[i];
6    }
7    return expectedSum - actualSum;
8  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let nums;
  let n;
  try {
    nums = userInput.split(',').map((str) => parseInt(str.trim(), 10));
    if (nums.some(isNaN)) {
      throw new Error();
    }
    n = nums.length; // Highest number expected is nums.length
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid sequence of integers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function findMissingNumber is called with nums = [${nums}] and n = ${n}`,
    { nums, n }
  );

  // Line 2: Calculate expectedSum
  let expectedSum = (n * (n + 1)) / 2;
  addTrace(
    executionTrace,
    2,
    `Calculate expectedSum = (n * (n + 1)) / 2 = (${n} * (${n} + 1)) / 2 = ${expectedSum}`,
    { expectedSum }
  );

  // Line 3: Initialize actualSum
  let actualSum = 0;
  addTrace(executionTrace, 3, `Initialize actualSum = ${actualSum}`, {
    actualSum,
  });

  // Line 4-6: Calculate actualSum
  for (let i = 0; i < nums.length; i++) {
    let previousActualSum = actualSum;
    actualSum += nums[i];
    addTrace(
      executionTrace,
      5,
      `Add nums[${i}] = ${nums[i]} to actualSum: actualSum = ${previousActualSum} + ${nums[i]} = ${actualSum}`,
      { actualSum }
    );
  }

  // Line 7: Calculate missing number
  let missingNumber = expectedSum - actualSum;
  addTrace(
    executionTrace,
    7,
    `Calculate missingNumber = expectedSum - actualSum = ${expectedSum} - ${actualSum} = ${missingNumber}`,
    { missingNumber }
  );

  let resultMessage = `The missing number is ${missingNumber}.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
