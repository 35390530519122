export const problemTitle = `### Understanding and Solving **Min Cost Climbing Stairs** Problem`;
export const initialInputValue = '[10, 15, 20]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **minimum cost to climb stairs**:`,
  `###### **Step 2:** Program to Compute Minimum Cost:`,
  `###### **Step 3:** Input an array of costs to compute the minimum climbing cost:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array \`cost\` where \`cost[i]\` represents the cost of stepping on the \`i-th\` stair, return the minimum cost to reach the top of the staircase.

#### Problem Statement (Elaborated):
You can start at either the 0-th or 1-st step. Each step requires you to pay the cost associated with it. After paying the cost, you can move to the next or skip one step forward.

#### Testing Examples
1. **Example 1**: Input: \`cost = [10, 15, 20]\`
   - **Output**: 15 (You can step on 1st stair (cost 15) and skip to the top).
2. **Example 2**: Input: \`cost = [1, 100, 1, 1, 1, 100, 1, 1, 100, 1]\`
   - **Output**: 6 (You can take the path 1 -> 3 -> 5 -> top).
3. **Example 3**: Input: \`cost = [0, 0, 0, 0, 0]\`
   - **Output**: 0 (There is no cost for any step).

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To compute minimum cost iteratively.
2. **Conditional Statements**: Handling edge cases.
3. **Mathematical Operations**: Adding and comparing costs.
4. **Variable Management**: Storing intermediate results to optimize computation.

#### Real-World Concepts Covered:
1. **Cost Optimization**: Minimize total cost while adhering to constraints.
2. **Dynamic Programming**: Solve problems efficiently by building solutions incrementally.

#### How Does the Program Work?
1. Use two variables to store the minimum cost to reach the last two steps.
2. Iteratively compute the minimum cost for each step based on the previous two steps.
3. Return the minimum cost to reach the top.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Initialize variables for the first two steps' costs.
2. **Hint 2**: Use a loop to compute the cost iteratively for steps from the third onward.
3. **Hint 3**: At each step, calculate the cost as the step cost plus the minimum of the two previous step costs.

#### Why Do We Do This?
This problem introduces dynamic programming in a practical scenario of cost minimization, helping learners understand efficient computation by building solutions incrementally.
`;

export const initialAlgorithm = `1. If the array has only one step, return its cost.
2. Initialize variables:
   - \`prev1 = cost[1]\` (cost of reaching the first step).
   - \`prev2 = cost[0]\` (cost of reaching the zeroth step).
3. Use a loop to compute the cost for each step from 2 to n:
   - a. Compute the cost for the current step as \`current = cost[i] + min(prev1, prev2)\`.
   - b. Update \`prev2 = prev1\` and \`prev1 = current\`.
4. Return \`min(prev1, prev2)\` as the minimum cost to reach the top.`;

export const initialProblemCode = `1  function minCostClimbingStairs(cost) {
2    if (cost.length === 1) return cost[0];
3    let prev1 = cost[1], prev2 = cost[0];
4    for (let i = 2; i < cost.length; i++) {
5      let current = cost[i] + Math.min(prev1, prev2);
6      prev2 = prev1;
7      prev1 = current;
8    }
9    return Math.min(prev1, prev2);
10 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let cost;
  try {
    cost = JSON.parse(userInput);
    if (!Array.isArray(cost) || cost.some((x) => typeof x !== 'number')) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid array of costs!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function minCostClimbingStairs is called with cost = ${JSON.stringify(
      cost
    )}`,
    { cost }
  );

  if (cost.length === 1) {
    addTrace(
      executionTrace,
      2,
      `cost has only one step, return cost[0] = ${cost[0]}`,
      { cost }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Minimum cost to climb stairs is ${cost[0]}`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let prev1 = cost[1],
    prev2 = cost[0];
  addTrace(executionTrace, 3, `Initialize prev1 = ${prev1}, prev2 = ${prev2}`, {
    prev1,
    prev2,
  });

  for (let i = 2; i < cost.length; i++) {
    addTrace(executionTrace, 4, `Loop iteration i = ${i}`, { i });
    let current = cost[i] + Math.min(prev1, prev2);
    addTrace(
      executionTrace,
      5,
      `Compute current = cost[i] + min(prev1, prev2) = ${cost[i]} + min(${prev1}, ${prev2}) = ${current}`,
      { current }
    );
    prev2 = prev1;
    addTrace(executionTrace, 6, `Update prev2 = prev1, prev2 = ${prev2}`, {
      prev2,
    });
    prev1 = current;
    addTrace(executionTrace, 7, `Update prev1 = current, prev1 = ${prev1}`, {
      prev1,
    });
  }

  let result = Math.min(prev1, prev2);
  addTrace(
    executionTrace,
    9,
    `Return min(prev1, prev2) = min(${prev1}, ${prev2}) = ${result}`,
    { prev1, prev2, result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Minimum cost to climb stairs is ${result}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
