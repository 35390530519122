import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './CustomModal.css'; // Import the component-specific CSS

const CustomModal = ({ show, onHide, title, message, redirectTo }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onHide(); // Close the modal
    if (redirectTo) {
      navigate(redirectTo); // Redirect to a specified path
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          className="custom-button"
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
