export const problemTitle = `### Understanding and Solving **Valid Word Square** Problem`;
export const initialInputValue = `["ball", "area", "lead", "lady"]`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if the matrix forms a **valid word square**:`,
  `###### **Step 2:** Program to Check **Valid Word Square**:`,
  `###### **Step 3:** Input a matrix to check if it is a **valid word square**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array of strings, return \`true\` if it forms a **valid word square**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
Write a program that **checks** whether the given **matrix** (array of strings) is a **valid word square**. In a **valid word square**, the words read **horizontally** must be the same as the words read **vertically**.

#### Testing Examples
1. **Example 1: Input = ["ball", "area", "lead", "lady"]**
   - **Step-by-step**: The **words read** horizontally and vertically **match**. Therefore, the **output** is **true**.
2. **Example 2: Input = ["abcd", "bnrt", "crmy", "dtye"]**
   - **Step-by-step**: The **words read** horizontally and vertically **match**. Therefore, the **output** is **true**.
3. **Example 3: Input = ["ball", "area", "lead", "lad"]**
   - **Step-by-step**: The **words read** horizontally and vertically **do not match** (e.g., last column). Therefore, the **output** is **false**.

#### What is a Valid Word Square?
A **valid word square** is a **square arrangement of words** such that the words read **horizontally** (row-wise) are identical to the words read **vertically** (column-wise). For example, the following is a valid word square:

\`\`\`
[
  "ball",
  "area",
  "lead",
  "lady"
]
\`\`\`

In this case:
- First column: **"ball"**
- Second column: **"area"**
- Third column: **"lead"**
- Fourth column: **"lady"**

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate over the **matrix** and compare values **row-wise** and **column-wise**.
2. **Conditional Statements**: Using \`if-else\` to **validate conditions** like matrix size and word matching.
3. **String Manipulation**: Accessing individual **characters** from strings and constructing **column words**.

#### Real-World Concepts Covered
1. **Matrix Validation**: Understanding how to validate **grid structures** for symmetry.
2. **Patterns and Symmetry**: Exploring how structures like **word squares** exhibit **patterns** and **symmetry**.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **grid-based validation** and **pattern matching**.

#### How Does the Program Work?
1. Check if the **number of rows** equals the **number of columns** (ensuring it is a square matrix).
2. For each row, construct the corresponding column word and **compare** it to the row word.
3. If all **rows** match their corresponding **columns**, return **true**.
4. Otherwise, return **false**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a **nested loop** to construct **column words** character by character.
2. **Hint 2**: Compare each **row word** with its **corresponding column word** to determine validity.
3. **Hint 3**: Handle **edge cases** where the matrix is not a square (e.g., different number of rows and columns).

#### Why Do We Do This?
We **perform this check** to ensure that the **horizontal arrangement** of the matrix **matches** its **vertical arrangement**, maintaining **symmetry** and validating the **word square property**.
`;

export const initialAlgorithm = `1. If the matrix is **empty**, return **true**.
2. Check if the **number of rows** equals the **number of columns**. If not, return **false**.
3. Loop through each **row** of the matrix:
   - a. For each row, construct the **corresponding column word**.
   - b. Compare the **row word** and the **column word**.
   - c. If they do **not match**, return **false**.
4. If all **row words** match their **column words**, return **true**.`;

export const initialProblemCode = `1  function isValidWordSquare(matrix) {
2    if (!matrix.length) return true; // Line 1: Check if matrix is empty
3    let rows = matrix.length;
4    for (let i = 0; i < rows; i++) { // Line 3: Loop through each row
5      let rowWord = matrix[i];
6      let columnWord = '';
7      for (let j = 0; j < rows; j++) { // Line 7: Construct column word
8        if (j < matrix.length && i < matrix[j].length) {
9          columnWord += matrix[j][i];
10       }
11     }
12     if (rowWord !== columnWord) return false; // Line 12: Compare row and column words
13   }
14   return true; // Line 14: All rows and columns matched
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let matrix;
  try {
    matrix = JSON.parse(userInput);
    if (
      !Array.isArray(matrix) ||
      matrix.some((row) => typeof row !== 'string')
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid array of strings!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isValidWordSquare is called with matrix = ${JSON.stringify(matrix)}`,
    { matrix }
  );

  if (!matrix.length) {
    addTrace(executionTrace, 2, `Matrix is empty, return true`, { matrix });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The matrix is empty, and thus it is a valid word square.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let rows = matrix.length;
  addTrace(executionTrace, 3, `Initialize rows = ${rows}`, { rows });

  for (let i = 0; i < rows; i++) {
    let rowWord = matrix[i];
    let columnWord = '';
    addTrace(
      executionTrace,
      5,
      `Start processing row ${i}: rowWord = "${rowWord}"`,
      { i, rowWord }
    );

    for (let j = 0; j < rows; j++) {
      addTrace(
        executionTrace,
        7,
        `Processing column ${j}: Add character matrix[${j}][${i}] if exists`,
        { i, j }
      );

      if (j < matrix.length && i < (matrix[j] || '').length) {
        columnWord += matrix[j][i];
        addTrace(
          executionTrace,
          9,
          `Update columnWord: columnWord = "${columnWord}"`,
          { columnWord }
        );
      }
    }

    addTrace(
      executionTrace,
      12,
      `Compare rowWord "${rowWord}" and columnWord "${columnWord}"`,
      { rowWord, columnWord }
    );

    if (rowWord !== columnWord) {
      addTrace(
        executionTrace,
        12,
        `rowWord and columnWord do not match, return false`,
        { rowWord, columnWord }
      );
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: `The matrix does not form a valid word square.`,
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }
  }

  addTrace(executionTrace, 14, `All rows and columns matched, return true`, {
    matrix,
  });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The matrix forms a valid word square.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
