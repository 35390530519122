import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Navbar as BootstrapNavbar,
  Nav,
  Container,
  Button,
} from 'react-bootstrap';
import { validateToken } from '../services/api.js';
import './Navbar.css'; // Import the component-specific CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await validateToken(token);

          if (response.data.valid) {
            setIsAuthenticated(true);
            setUserRole(response.data.user.role);

            if (window.location.pathname === '/') {
              if (response.data.user.role === 'admin') {
                navigate('/admin');
              } else if (response.data.user.role === 'student') {
                navigate('/learn');
              }
            }
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Error validating token:', error);
          setIsAuthenticated(false);
        }
      }
    };

    checkTokenValidity();

    // Apply stored theme
    const savedTheme = localStorage.getItem('theme') || 'light';
    document.body.classList.toggle('night-mode', savedTheme === 'dark');
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    window.location.href = '/';
  };

  const toggleNightMode = () => {
    document.body.classList.toggle('night-mode');
    const currentMode = document.body.classList.contains('night-mode')
      ? 'dark'
      : 'light';
    localStorage.setItem('theme', currentMode);
  };

  const getHomeLink = () => {
    if (userRole === 'admin') {
      return '/admin';
    } else if (userRole === 'student') {
      return '/learn';
    }
    return '/';
  };

  const getCourseLink = () => {
    if (isAuthenticated && userRole === 'student') {
      return '/courses';
    } else if (!isAuthenticated) {
      return '/public/courses';
    }
    return '/';
  };

  return (
    <BootstrapNavbar
      bg="primary"
      variant="dark"
      expand="lg"
      className="sticky-top custom-navbar mb-4"
    >
      <Container>
        <BootstrapNavbar.Brand as={Link} to={getHomeLink()}>
          <img
            src="/logo.png"
            alt="HLS"
            style={{ width: '20px', height: '20px' }}
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to={getHomeLink()}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to={getCourseLink()}>
              Courses
            </Nav.Link>
            <Nav.Link as={Link} to="/resources/docs">
              Docs
            </Nav.Link>
            <Nav.Link as={Link} to="/pricing">
              Pricing
            </Nav.Link>
            <Nav.Link as={Link} to="/community">
              Community
            </Nav.Link>
            <Nav.Link as={Link} to="/blog">
              Blog
            </Nav.Link>
          </Nav>
          <Nav>
            <Button
              variant="outline-light"
              className="me-2"
              onClick={toggleNightMode}
            >
              Toggle Night Mode
            </Button>
            {!isAuthenticated ? (
              <Nav.Link as={Link} to="/login">
                Sign In
              </Nav.Link>
            ) : (
              <>
                <Nav.Link as={Link} to="/profile">
                  Profile
                </Nav.Link>
                <Button variant="link" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
