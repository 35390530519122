const courseData = {
  course: {
    title: 'Full Stack Development',
    shortDescription: 'Full Stack Development.',
    longDescription: 'A comprehensive course on full stack development.',
    category: 'Web Development',
    difficulty: 'Intermediate',
    instructor_id: 1,
    syllabus: {
      sections: [
        {
          title: 'Introduction',
          order: 1,
          activities: [
            {
              activity_type: 'Lesson',
              title: 'What is Full Stack Development?',
              order: 1,
              description:
                'Full stack development involves both front-end and back-end development...',
            },
            {
              activity_type: 'Quiz',
              title: 'Basic Concepts Quiz',
              order: 2,
              questions: [
                {
                  question_text: 'What does HTML stand for?',
                  options: [
                    {
                      option_text: 'HyperText Markup Language',
                      is_correct: true,
                    },
                    {
                      option_text: 'HyperText Making Language',
                      is_correct: false,
                    },
                  ],
                },
                {
                  question_text:
                    'Which of the following is a JavaScript framework?',
                  options: [
                    {
                      option_text: 'React',
                      is_correct: true,
                    },
                    {
                      option_text: 'CSS',
                      is_correct: false,
                    },
                  ],
                },
              ],
            },
            {
              activity_type: 'CodingActivity',
              title: 'Hello World Program',
              order: 3,
              description:
                'In this coding activity, you will write your first JavaScript program. This activity is designed to get you comfortable with writing and executing basic code in JavaScript. You will learn how to use the `console.log()` function to output text to the console, which is a fundamental skill for testing and debugging your code.',
              code_template: "console.log('Hello World');",
              instructions: [
                {
                  step_number: 1,
                  description:
                    'Write a simple program that outputs "Hello World".',
                  code_snippet: "console.log('Hello World');",
                  hints: [
                    {
                      hint_text:
                        'Make sure to use the correct syntax for the `console.log` statement.',
                      test_cases: [
                        {
                          input: '',
                          test_type: 'componentTest',
                          test_code: 'Hello World',
                        },
                      ],
                    },
                  ],
                },
                {
                  step_number: 2,
                  description: 'Test your code by running the program.',
                  hints: [
                    {
                      hint_text:
                        'Check the console output to see if it matches the expected result.',
                      test_cases: [
                        {
                          input: '',
                          test_type: 'componentTest',
                          test_code: 'Hello World',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
};

export default courseData;
