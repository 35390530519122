import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  ProgressBar,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import {
  conceptName,
  quiz_collection,
} from './PSQuizQuestions/LoopsQuizQuestions.js';
import './Quiz.css';

const fisherYatesShuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Pick a random index
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

const LoopsQuiz = ({ understanding = false, memorization = true }) => {
  const [step, setStep] = useState(1);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [variant, setVariant] = useState('info');
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState([
    ...quiz_collection,
  ]);
  const [activeCategory, setActiveCategory] = useState('All Questions');

  const [isMemorizationMode, setIsMemorizationMode] = useState(memorization); // State for memorization mode
  const [isUnderstandingMode, setIsUnderstandingMode] = useState(understanding); // State for understanding mode

  const toggleMode = () => {
    setIsMemorizationMode(!isMemorizationMode); // Set memorization mode to true
    setIsUnderstandingMode(!isUnderstandingMode); // Set understanding mode to false
  };

  const handleAnswer = (index) => {
    setSelectedOptionIndex(index);
    if (index === currentQuestions[step - 1].correct) {
      setFeedback('Correct! ' + currentQuestions[step - 1].explanation);
      setScore(score + 1);
      setVariant('success');
    } else {
      setFeedback('Incorrect. ' + currentQuestions[step - 1].explanation);
      setVariant('danger');
    }
  };

  const nextStep = () => {
    setFeedback('');
    setSelectedOptionIndex(null);
    if (step < currentQuestions.length) {
      setStep(step + 1);
    } else {
      setStep(currentQuestions.length + 1);
    }
  };

  // New: Function to go to the previous step
  const previousStep = () => {
    if (step > 1) {
      setFeedback('');
      setSelectedOptionIndex(null);
      setStep(step - 1);
    }
  };

  const selectCategory = (questions, categoryName) => {
    const updatedQuestions = questions.map((question) => {
      const shuffledOptions = fisherYatesShuffle([...question.options]);
      const correctOptionIndex = shuffledOptions.indexOf(
        question.options[question.correct]
      );
      return {
        ...question,
        options: shuffledOptions,
        correct: correctOptionIndex,
      };
    });

    console.log('Updated Questions:', updatedQuestions);

    setCurrentQuestions(updatedQuestions);
    setStep(1);
    setScore(0);
    setFeedback('');
    setSelectedOptionIndex(null);
    setActiveCategory(categoryName);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center mb-4">
        <Col md={12} className="text-center">
          <Button
            variant="info"
            className="m-2"
            onClick={toggleMode} // Toggle memorization mode
          >
            Switch to{' '}
            {isMemorizationMode ? 'Understaning Mode' : 'Memorization Mode'}
          </Button>
          <Button
            variant={
              activeCategory === 'All Questions' ? 'success' : 'secondary'
            }
            className="m-2"
            onClick={() =>
              selectCategory([...quiz_collection], 'All Questions')
            }
          >
            All Questions
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="text-center">
            <Card.Header as="h5" className="bg-primary text-white">
              Quiz on {conceptName}
            </Card.Header>
            <Card.Body>
              {step <= currentQuestions.length && (
                <>
                  <ProgressBar
                    now={(step / currentQuestions.length) * 100}
                    label={`${step}/${currentQuestions.length}`}
                    className="mb-4"
                  />
                  <h6 className="text-secondary">
                    Question {step} ({currentQuestions[step - 1].level} Level)
                  </h6>
                  <h3 className="mb-4 text-start">
                    <ReactMarkdown>
                      {currentQuestions[step - 1].question}
                    </ReactMarkdown>
                  </h3>
                  {isUnderstandingMode ? (
                    <Row>
                      {currentQuestions[step - 1].options.map(
                        (option, index) => (
                          <Col xs={12} key={index} className="mb-3">
                            {console.log({
                              isUnderstandingMode,
                              isMemorizationMode,
                              correctOption: currentQuestions[step - 1].correct,
                              currentOptionIndex: index,
                              shouldDisplay:
                                isUnderstandingMode &&
                                index === currentQuestions[step - 1].correct,
                            })}
                            {isUnderstandingMode &&
                            index === currentQuestions[step - 1].correct ? (
                              <Button
                                variant="outline-primary"
                                size="lg"
                                onClick={() => handleAnswer(index)}
                                block
                                style={{
                                  width: '100%',
                                  height: '50px', // Fixed height
                                  overflow: 'auto', // Scrollable for overflow content
                                  whiteSpace: 'normal', // Allow text to wrap
                                  textAlign: 'left', // Align text to the left for better readability
                                }}
                              >
                                <ReactMarkdown>{option}</ReactMarkdown>
                              </Button>
                            ) : null}
                            {feedback && selectedOptionIndex === index && (
                              <Alert
                                variant={variant}
                                className="mt-2 custom-alert"
                                style={{
                                  backgroundColor:
                                    variant === 'success'
                                      ? '#d4edda'
                                      : variant === 'danger'
                                        ? '#f8d7da'
                                        : undefined,
                                  color:
                                    variant === 'success'
                                      ? '#155724'
                                      : variant === 'danger'
                                        ? '#721c24'
                                        : undefined,
                                  borderColor:
                                    variant === 'success'
                                      ? '#c3e6cb'
                                      : variant === 'danger'
                                        ? '#f5c6cb'
                                        : undefined,
                                }}
                              >
                                <ReactMarkdown>{feedback}</ReactMarkdown>
                              </Alert>
                            )}
                          </Col>
                        )
                      )}
                    </Row>
                  ) : null}
                  {isMemorizationMode ? (
                    <Row>
                      {currentQuestions[step - 1].options.map(
                        (option, index) => (
                          <Col xs={12} key={index} className="mb-3">
                            <Button
                              variant="outline-primary"
                              size="lg"
                              onClick={() => handleAnswer(index)}
                              block
                              style={{
                                width: '100%',
                                height: '50px', // Fixed height
                                overflow: 'auto', // Scrollable for overflow content
                                whiteSpace: 'normal', // Allow text to wrap
                                textAlign: 'left', // Align text to the left for better readability
                              }}
                            >
                              <ReactMarkdown>{option}</ReactMarkdown>
                            </Button>
                            {feedback && selectedOptionIndex === index && (
                              <Alert
                                variant={variant}
                                className="mt-2 custom-alert"
                                style={{
                                  backgroundColor:
                                    variant === 'success'
                                      ? '#d4edda'
                                      : variant === 'danger'
                                        ? '#f8d7da'
                                        : undefined,
                                  color:
                                    variant === 'success'
                                      ? '#155724'
                                      : variant === 'danger'
                                        ? '#721c24'
                                        : undefined,
                                  borderColor:
                                    variant === 'success'
                                      ? '#c3e6cb'
                                      : variant === 'danger'
                                        ? '#f5c6cb'
                                        : undefined,
                                }}
                              >
                                <ReactMarkdown>{feedback}</ReactMarkdown>
                              </Alert>
                            )}
                          </Col>
                        )
                      )}
                    </Row>
                  ) : null}
                  <Row className="mt-3">
                    <Col>
                      <Button
                        variant="secondary"
                        size="lg"
                        onClick={previousStep}
                        disabled={step === 1} // Disable if on the first question
                      >
                        Previous
                      </Button>
                    </Col>
                    {feedback && (
                      <Col>
                        <Button variant="success" size="lg" onClick={nextStep}>
                          Next
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {step > currentQuestions.length && (
                <>
                  <h4 className="text-success">Quiz Completed!</h4>
                  <p className="mt-3">
                    Your Score: {score} / {currentQuestions.length}
                  </p>
                  <Alert variant="info" className="mt-3">
                    Well done! Review the explanations to strengthen your
                    understanding.
                  </Alert>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoopsQuiz;
