export const problemTitle = `### Understanding and Solving **Perfect Number** Problem`;
export const initialInputValue = '28';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a number is a **Perfect Number**:`,
  `###### **Step 2:** Program to Check **Perfect Number**:`,
  `###### **Step 3:** Input a number to check if it is a **Perfect Number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, return \`true\` if \`n\` is a **perfect number**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
Write a program that **checks** whether a given **integer** is a **perfect number**. A **perfect number** is a positive integer that is equal to the sum of its **proper divisors** (excluding itself).

#### Testing Examples
1. **Example 1: Input = 28**
   - **Step-by-step**: The divisors of \`28\` are \`1, 2, 4, 7, 14\`. Their sum is \`1 + 2 + 4 + 7 + 14 = 28\`. Therefore, \`28\` is a **perfect number**.
2. **Example 2: Input = 6**
   - **Step-by-step**: The divisors of \`6\` are \`1, 2, 3\`. Their sum is \`1 + 2 + 3 = 6\`. Therefore, \`6\` is a **perfect number**.
3. **Example 3: Input = 12**
   - **Step-by-step**: The divisors of \`12\` are \`1, 2, 3, 4, 6\`. Their sum is \`1 + 2 + 3 + 4 + 6 = 16\`, which is not equal to \`12\`. Therefore, \`12\` is not a **perfect number**.

#### What is a Perfect Number?
A **perfect number** is a positive integer that is equal to the sum of its proper divisors (excluding itself). For example:
- **6** is perfect because \`1 + 2 + 3 = 6\`.
- **28** is perfect because \`1 + 2 + 4 + 7 + 14 = 28\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To find divisors and compute their sum.
2. **Conditional Statements**: To check the conditions for perfect numbers.
3. **Mathematical Operations**: Using modulo to find divisors.

#### Real-World Concepts Covered
1. **Number Theory**: Understanding divisors and their properties.
2. **Optimization**: Avoid unnecessary calculations by only checking up to the square root of the number.
3. **Problem-Solving**: Step-by-step approach to break down complex mathematical properties.

#### How Does the Program Work?
1. If the number is \`<= 1\`, it cannot be a perfect number.
2. Iterate from \`1\` to \`sqrt(n)\` and find divisors.
3. Add the divisor and its pair to the sum.
4. Exclude the number itself from the sum.
5. Check if the sum of divisors equals the number.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use modulo (\`%\`) to find divisors.
2. **Hint 2**: Only iterate up to \`sqrt(n)\` to optimize calculations.
3. **Hint 3**: Include both \`i\` and \`n / i\` as divisors unless they are equal or \`n\` itself.
4. **Hint 4**: For edge cases like \`1\`, the number is not a perfect number.

#### Why Do We Do This?
This problem enhances understanding of divisor properties and optimization techniques in algorithms. It also introduces basic number theory concepts used in programming.
`;

export const initialAlgorithm = `1. If the number is \`<= 1\`, return \`false\`.
2. Initialize \`sum = 0\`.
3. Loop from \`i = 1\` to \`sqrt(n)\`:
   - a. If \`n % i == 0\`, add \`i\` to \`sum\`.
   - b. If \`i != n / i\` and \`i != n\`, add \`n / i\` to \`sum\`.
4. Return \`true\` if \`sum == n\`, otherwise return \`false\`.`;

export const initialProblemCode = `1  function isPerfectNumber(n) {
2    if (n <= 1) return false;
3    let sum = 0;
4    for (let i = 1; i * i <= n; i++) {
5      if (n % i === 0) {
6        sum += i;
7        if (i !== n / i && i !== n) {
8          sum += n / i;
9        }
10     }
11   }
12   return sum === n;
13 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isPerfectNumber is called with n = ${n}`,
    {
      n,
    }
  );

  // Line 2: Check if n <= 1
  addTrace(executionTrace, 2, `Check if n <= 1 (${n} <= 1) = ${n <= 1}`, { n });
  if (n <= 1) {
    addTrace(executionTrace, 2, `n is <= 1, return false`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${n} is not a perfect number.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let sum = 0;
  addTrace(executionTrace, 3, `Initialize sum = ${sum}`, { sum });

  // Line 4: Start for loop
  for (let i = 1; i * i <= n; i++) {
    addTrace(
      executionTrace,
      4,
      `Check loop condition: i (${i}) * i <= n (${n}) = ${i * i <= n}`,
      { i }
    );

    // Line 5: Check if n % i === 0
    if (n % i === 0) {
      addTrace(
        executionTrace,
        5,
        `Check if n % i === 0 (${n} % ${i} = ${n % i}) = ${n % i === 0}`,
        { i }
      );

      if (n % i === 0) {
        // Line 6: Add i to sum
        let previousSum = sum;
        sum += i;
        addTrace(
          executionTrace,
          6,
          `Update sum: sum = ${previousSum} + ${i} = ${sum}`,
          { sum }
        );

        // Line 7: Check if i != n / i and i != n
        if (i !== n / i && i !== n) {
          let divisor = Math.floor(n / i);
          addTrace(
            executionTrace,
            7,
            `Check if i != n / i (${i} != ${n} / ${i} = ${divisor}) and i != n (${i} != ${n}) = ${i !== divisor && i !== n}`,
            { divisor }
          );

          if (i !== divisor && i !== n) {
            // Line 8: Add n / i to sum
            previousSum = sum;
            sum += divisor;
            addTrace(
              executionTrace,
              8,
              `Update sum: sum = ${previousSum} + ${divisor} = ${sum}`,
              { sum }
            );
          }
        }
      }
    }
  }

  // Line 12: Check if sum === n
  addTrace(
    executionTrace,
    12,
    `Compare sum (${sum}) == n (${n}) = ${sum === n}`,
    { sum, n }
  );

  let isPerfect = sum === n;
  let resultMessage = isPerfect
    ? `${n} is a perfect number.`
    : `${n} is not a perfect number.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
