import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from '../../components/Navbar.js'; // Import the Navbar component
import Footer from '../../components/Footer.js'; // Import the Footer component
import './Events.css'; // Import the Events-specific CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const Events = ({ hideHeaderFooter }) => {
  return (
    <div className="d-flex flex-column min-vh-100 events-container">
      {!hideHeaderFooter && <Navbar />} {/* Conditionally render Navbar */}
      <Container className="flex-grow-1 mt-5">
        <Row>
          <Col>
            <h3 className="events-title">Upcoming Events</h3>
            <p className="events-subtitle">
              Stay tuned! Exciting events are coming soon.
            </p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={6} lg={4}>
            <Card className="mb-3 events-card">
              <Card.Body>
                <Card.Title className="events-card-title">Event 1</Card.Title>
                <Card.Text className="events-card-text">
                  Details about event 1 (Coming Soon)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mb-3 events-card">
              <Card.Body>
                <Card.Title className="events-card-title">Event 2</Card.Title>
                <Card.Text className="events-card-text">
                  Details about event 2 (Coming Soon)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {!hideHeaderFooter && <Footer />} {/* Conditionally render Footer */}
    </div>
  );
};

// Set default prop for hideHeaderFooter
Events.defaultProps = {
  hideHeaderFooter: false,
};

export default Events;
