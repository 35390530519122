import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllEnrollments } from '../../services/api.js';
import { handleCourseResume } from '../../helpers/componentsHelper.js';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './MyLearningPage.css'; // Import the component-specific CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const MyLearningPage = () => {
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [completedCourses, setCompletedCourses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const ongoingResponse = await getAllEnrollments(token);

        // Filter ongoing and completed courses based on progress
        const ongoing = ongoingResponse.data.filter(
          (course) => parseFloat(course.progress) < 100
        );
        const completed = ongoingResponse.data.filter(
          (course) => parseFloat(course.progress) === 100
        );

        setOngoingCourses(ongoing);
        setCompletedCourses(completed);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  return (
    <Container fluid className="my-learning-page p-3">
      <Row>
        <Col>
          <h3 className="mb-4">Ongoing Courses</h3>
          {ongoingCourses.length === 0 ? (
            <p>No ongoing courses</p>
          ) : (
            ongoingCourses.map((enrollment) => (
              <Card key={enrollment.course_id} className="mb-3 learning-card">
                <Card.Body>
                  <Card.Title>{enrollment.Course.title}</Card.Title>
                  <Card.Text>{enrollment.Course.description}</Card.Text>
                  <Card.Text>Progress: {enrollment.progress}%</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() =>
                      handleCourseResume(navigate, enrollment.course_id)
                    }
                  >
                    Resume
                  </Button>
                </Card.Body>
              </Card>
            ))
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mb-4">Completed Courses</h3>
          {completedCourses.length === 0 ? (
            <p>No completed courses</p>
          ) : (
            completedCourses.map((enrollment) => (
              <Card key={enrollment.course_id} className="mb-3 learning-card">
                <Card.Body>
                  <Card.Title>{enrollment.Course.title}</Card.Title>
                  <Card.Text>{enrollment.Course.description}</Card.Text>
                  <Button variant="secondary">Review</Button>
                </Card.Body>
              </Card>
            ))
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MyLearningPage;
