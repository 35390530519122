export const problemTitle = `### Understanding and Solving **Base 7** Conversion Problem`;
export const initialInputValue = '100';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to convert a number to **Base 7**:`,
  `###### **Step 2:** Program to Convert a Number to **Base 7**:`,
  `###### **Step 3:** Input a number to convert to **Base 7**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`x\`, convert it to a string representation in **Base 7**. For example, the decimal number \`100\` in Base 7 is \`202\`.

#### Problem Statement (Elaborated):
Write a program that **converts an integer** to its equivalent in **Base 7**. The program should **take an integer as input** and return its **Base 7 string representation**.

#### Testing Examples
1. **Example 1: Input = 100**
   - **Step-by-step**: Convert 100 to Base 7: 
     - Divide 100 by 7: quotient = 14, remainder = 2
     - Divide 14 by 7: quotient = 2, remainder = 0
     - Divide 2 by 7: quotient = 0, remainder = 2
     - The remainders from last to first are: \`202\`.
2. **Example 2: Input = -7**
   - **Step-by-step**: The equivalent of -7 in Base 7 is \`-10\`.
3. **Example 3: Input = 0**
   - **Step-by-step**: The Base 7 equivalent of \`0\` is \`0\`.

#### What is Base 7?
Base 7 is a numeral system that uses \`7\` as its base. In this system:
- Digits range from \`0\` to \`6\`.
- Each digit's value depends on its position, like decimal or binary systems.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To compute remainders and construct the Base 7 representation.
2. **Mathematical Operations**: Using division and modulo to extract remainders.
3. **Conditional Statements**: To handle edge cases like \`x = 0\` or negative numbers.
4. **String Construction**: Building a string representation from computed digits.

#### Real-World Concepts Covered
1. **Number Systems**: Understanding conversions between different numeral systems.
2. **Modular Arithmetic**: Using remainders for digit extraction.
3. **Problem-Solving**: Step-by-step approach to breaking down conversions.

#### How Does the Program Work?
1. If the number is \`0\`, return "0".
2. If the number is negative, work with its absolute value and add a "-" sign at the end.
3. Repeatedly divide the number by 7 to get the quotient and remainder.
4. The remainders, collected in reverse order, form the Base 7 representation.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the modulo operator (\`%\`) to get the remainder when dividing by 7.
2. **Hint 2**: Use integer division (\`Math.floor()\`) to update the number after each step.
3. **Hint 3**: Reverse the remainders collected to get the correct order of digits.
4. **Hint 4**: Handle negative numbers by converting them to positive, then adding the "-" sign later.

#### Why Do We Do This?
This problem demonstrates how numeral systems work and the logic behind conversions. Understanding Base 7 is a stepping stone to learning about various numeral systems used in computer science and mathematics.
`;

export const initialAlgorithm = `1. If the number is \`0\`, return "0".
2. Initialize a variable \`isNegative\` to \`true\` if the number is negative, otherwise \`false\`.
3. Work with the absolute value of the number.
4. Initialize an empty string \`base7\` to store the result.
5. While the number is greater than \`0\`:
   - a. Find the remainder when the number is divided by \`7\`.
   - b. Append the remainder to \`base7\`.
   - c. Update the number by dividing it by \`7\` (integer division).
6. If \`isNegative\` is \`true\`, prepend "-" to \`base7\`.
7. Return the reversed \`base7\` string.`;

export const initialProblemCode = `1  function convertToBase7(num) {
2    if (num === 0) return "0";
3    let isNegative = num < 0;
4    num = Math.abs(num);
5    let base7 = "";
6    while (num > 0) {
7      let remainder = num % 7;
8      base7 = remainder + base7;
9      num = Math.floor(num / 7);
10   }
11   return isNegative ? "-" + base7 : base7;
12 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num;
  try {
    num = parseInt(userInput, 10);
    if (isNaN(num)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function convertToBase7 is called with num = ${num}`,
    { num }
  );

  // Line 2: Check if num is 0
  if (num === 0) {
    addTrace(executionTrace, 2, `num is 0, return "0"`, { num });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The Base 7 representation of 0 is "0".`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Check if num is negative
  let isNegative = num < 0;
  addTrace(
    executionTrace,
    3,
    `Check if num is negative: ${num} < 0 = ${isNegative}`,
    { isNegative }
  );

  // Line 4: Take absolute value of num
  num = Math.abs(num);
  addTrace(executionTrace, 4, `Take absolute value of num: num = ${num}`, {
    num,
  });

  let base7 = '';
  addTrace(executionTrace, 5, `Initialize base7 = "${base7}"`, { base7 });

  // Line 6: Start while loop
  while (num > 0) {
    addTrace(
      executionTrace,
      6,
      `Check while condition: num (${num}) > 0 = ${num > 0}`,
      { num }
    );

    // Line 7: Calculate remainder
    let remainder = num % 7;
    addTrace(
      executionTrace,
      7,
      `Calculate remainder: remainder = num % 7 = ${num} % 7 = ${remainder}`,
      { remainder }
    );

    // Line 8: Update base7
    let previousBase7 = base7;
    base7 = remainder + base7;
    addTrace(
      executionTrace,
      8,
      `Update base7: base7 = "${remainder}" + "${previousBase7}" = "${base7}"`,
      { base7 }
    );

    // Line 9: Update num
    let previousNum = num;
    num = Math.floor(num / 7);
    addTrace(
      executionTrace,
      9,
      `Update num: num = Math.floor(${previousNum} / 7) = ${num}`,
      { num }
    );
  }

  // Line 11: Return result
  let result = isNegative ? `-${base7}` : base7;
  addTrace(
    executionTrace,
    11,
    `Return result: isNegative = ${isNegative}, result = "${result}"`,
    { result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The Base 7 representation of the number is "${result}".`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
