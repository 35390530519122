export const problemTitle = `### Understanding and Solving **DI String Match** Problem`;
export const initialInputValue = '"IDID"';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve the **DI String Match** problem using Greedy approach:`,
  `###### **Step 2:** Program to Solve **DI String Match** Problem:`,
  `###### **Step 3:** Input a DI string to generate the matching permutation:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string \`s\` consisting of only characters \`I\` (for increasing) and \`D\` (for decreasing), write a program to return an array of integers \`perm\` such that:
1. \`perm\` is a permutation of integers from \`0\` to \`n\`, where \`n = s.length\`.
2. For each index \`i\`:
   - If \`s[i] == 'I'\`, then \`perm[i] < perm[i + 1]\`.
   - If \`s[i] == 'D'\`, then \`perm[i] > perm[i + 1]\`.

#### Testing Examples
1. **Example 1**: Input = "IDID"
   - **Step-by-step**:
     - Start with \`low = 0\` and \`high = 4\`.
     - For each 'I', take the current \`low\`.
     - For each 'D', take the current \`high\`.
     - Result: [0, 4, 1, 3, 2].
2. **Example 2**: Input = "III"
   - **Step-by-step**:
     - Result: [0, 1, 2, 3].
3. **Example 3**: Input = "DDI"
   - **Step-by-step**:
     - Result: [3, 2, 0, 1].

#### What is the DI String Match Problem?
The **DI String Match** problem is about generating a permutation of numbers that satisfy the increasing ('I') and decreasing ('D') conditions in the given string.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through the string to generate the permutation.
2. **Conditional Statements**: Handling 'I' and 'D' cases to adjust values.
3. **Greedy Strategy**: Making immediate decisions based on the character at the current position.

#### Real-World Concepts Covered
1. **Order and Symmetry**: Understanding how specific conditions determine ordering.
2. **Logical Thinking**: Translating conditions into a step-by-step greedy algorithm.

#### How Does the Program Work?
1. Use two pointers: \`low\` starting at 0 and \`high\` starting at \`n\` (length of the string).
2. For each character in the string:
   - If the character is 'I', take the current value of \`low\` and increment \`low\`.
   - If the character is 'D', take the current value of \`high\` and decrement \`high\`.
3. Append the final remaining value of \`low\` (or \`high\`) to complete the permutation.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use two pointers to efficiently assign numbers based on 'I' and 'D'.
2. **Hint 2**: Append the final pointer value at the end to ensure all numbers are used.
3. **Hint 3**: Ensure the length of the result matches \`n + 1\`.

#### Why Do We Do This?
The **DI String Match** problem teaches how to convert character-based conditions into a greedy numerical assignment. It enhances problem-solving skills and logical reasoning.
`;

export const initialAlgorithm = `1. Initialize two pointers: \`low = 0\` and \`high = n\` (where \`n\` is the length of the string).
2. Initialize an empty array \`result\`.
3. Loop through each character in the string:
   - a. If the character is 'I':
     - Append \`low\` to \`result\` and increment \`low\`.
   - b. If the character is 'D':
     - Append \`high\` to \`result\` and decrement \`high\`.
4. Append the final value of \`low\` (or \`high\`) to \`result\`.
5. Return \`result\`.`;

export const initialProblemCode = `1  function diStringMatch(s) {
2    let low = 0;
3    let high = s.length;
4    let result = [];
5    for (let i = 0; i < s.length; i++) {
6      if (s[i] === 'I') {
7        result.push(low);
8        low++;
9      } else if (s[i] === 'D') {
10       result.push(high);
11       high--;
12     }
13   }
14   result.push(low); // Append the last remaining number
15   return result;
16 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let s;
  try {
    s = JSON.parse(userInput);
    if (typeof s !== 'string' || !/^[ID]*$/.test(s)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid string of "I" and "D".',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function diStringMatch is called with s = "${s}"`,
    { s }
  );

  let low = 0;
  let high = s.length;
  let result = [];
  addTrace(
    executionTrace,
    2,
    `Initialize low = ${low}, high = ${high}, result = []`,
    { low, high, result }
  );

  for (let i = 0; i < s.length; i++) {
    addTrace(executionTrace, 5, `Processing character s[${i}] = "${s[i]}"`, {
      i,
      char: s[i],
    });

    if (s[i] === 'I') {
      result.push(low);
      addTrace(
        executionTrace,
        7,
        `Append low = ${low} to result, increment low`,
        { low, result }
      );
      low++;
    } else if (s[i] === 'D') {
      result.push(high);
      addTrace(
        executionTrace,
        10,
        `Append high = ${high} to result, decrement high`,
        { high, result }
      );
      high--;
    }
  }

  result.push(low); // Append the final value
  addTrace(
    executionTrace,
    14,
    `Append the final value low = ${low} to result`,
    { result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The generated permutation is: [${result.join(', ')}].`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
