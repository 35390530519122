import {
  getCareerPathEnrollmentById,
  getEnrollmentById,
} from '../services/api.js';

// Function to fetch the latest progress data
export const fetchProgressDataOld = async (courseId) => {
  try {
    const token = localStorage.getItem('token');
    const progressResponse = await getEnrollmentById(token, courseId);

    const groupedData = progressResponse.data.reduce((acc, item) => {
      if (!acc[item.activity_id]) {
        acc[item.activity_id] = {
          totalProgress: 0,
          count: 0,
          correctCount: 0,
        };
      }
      acc[item.activity_id].totalProgress += item.progress_percentage;
      acc[item.activity_id].count += 1;

      if (item.progress_percentage !== 0) {
        acc[item.activity_id].correctCount += 1;
      }
      return acc;
    }, {});

    const averageProgressData = Object.entries(groupedData).reduce(
      (acc, [activity_id, data]) => {
        // Initialize the object for the activity_id if it doesn't exist
        if (!acc[activity_id]) {
          acc[activity_id] = {};
        }

        // Assign the properties
        acc[activity_id]['final_progress_score'] =
          data.totalProgress / data.count;
        acc[activity_id]['all_tests_count'] = data.count;
        acc[activity_id]['correct_tests_count'] = data.correctCount;
        return acc;
      },
      {}
    );
    console.log(
      `averageProgressData: ${JSON.stringify(averageProgressData, null, 4)}`
    );
    return averageProgressData;
  } catch (error) {
    console.error(
      'Error fetching progress data:',
      error.response?.data || error.message
    );
  }
};

// Function to fetch the latest progress data
export const fetchProgressData = async (courseId) => {
  try {
    const token = localStorage.getItem('token');
    const progressResponse = await getEnrollmentById(token, courseId);

    return await processProgressData(progressResponse);
  } catch (error) {
    console.error(
      'Error fetching progress data:',
      error.response?.data || error.message
    );
  }
};

export const processProgressData = async (progressResponse) => {
  try {
    const groupedData = progressResponse.data.syllabus.sections.reduce(
      (acc, section) => {
        section.activities.forEach((activity) => {
          // Initialize activity data if not already present
          if (!acc[activity.activity_id]) {
            acc[activity.activity_id] = {
              totalProgress: 0,
              stepsCompleted: 0,
              totalSteps: 0,
            };
          }

          // Count total instructions (steps) for this activity
          const totalSteps = activity.instructions.length;
          acc[activity.activity_id].totalSteps += totalSteps;

          // Count completed steps
          const stepsCompleted = activity.instructions.filter(
            (step) => step.step_completed
          ).length;
          acc[activity.activity_id].stepsCompleted += stepsCompleted;

          // If activity is completed, mark it as 100% progress
          if (activity.activity_completed) {
            acc[activity.activity_id].totalProgress += 100;
          } else if (totalSteps > 0) {
            // Otherwise, calculate the progress percentage based on steps completed
            const progressPercentage = (stepsCompleted / totalSteps) * 100;
            acc[activity.activity_id].totalProgress += progressPercentage;
          }
        });
        return acc;
      },
      {}
    );

    console.log(`REACHED DATA`);

    // Calculate average progress data for each activity
    const averageProgressData = Object.entries(groupedData).reduce(
      (acc, [activity_id, data]) => {
        acc[activity_id] = {
          final_progress_score: data.totalProgress / (data.totalSteps || 1), // Avoid division by zero
          total_steps_count: data.totalSteps,
          completed_steps_count: data.stepsCompleted,
        };
        return acc;
      },
      {}
    );

    console.log(
      `averageProgressData: ${JSON.stringify(averageProgressData, null, 4)}`
    );
    return averageProgressData;
  } catch (error) {
    console.error(
      'Error fetching progress data:',
      error.response?.data || error.message
    );
  }
};

export const fetchCareerPathProgressData = async (careerPathId) => {
  try {
    const token = localStorage.getItem('token');
    const progressResponse = await getCareerPathEnrollmentById(
      token,
      careerPathId
    );

    return await processCareerPathProgressData(progressResponse);
  } catch (error) {
    console.error(
      'Error fetching progress data:',
      error.response?.data || error.message
    );
  }
};

export const processCareerPathProgressData = async (progressResponse) => {
  try {
    console.log(`REACHED processCareerPathProgressData`);
    const groupedData = progressResponse.data.syllabus.sections.reduce(
      (acc, courseItem) => {
        courseItem.syllabus.sections.forEach((section) => {
          section.activities.forEach((activity) => {
            // Initialize activity data if not already present
            if (!acc[activity.activity_id]) {
              acc[activity.activity_id] = {
                totalProgress: 0,
                stepsCompleted: 0,
                totalSteps: 0,
              };
            }

            // Count total instructions (steps) for this activity
            const totalSteps = activity.instructions.length;
            acc[activity.activity_id].totalSteps += totalSteps;

            // Count completed steps
            const stepsCompleted = activity.instructions.filter(
              (step) => step.step_completed
            ).length;
            acc[activity.activity_id].stepsCompleted += stepsCompleted;

            // If activity is completed, mark it as 100% progress
            if (activity.activity_completed) {
              acc[activity.activity_id].totalProgress += 100;
            } else if (totalSteps > 0) {
              // Otherwise, calculate the progress percentage based on steps completed
              const progressPercentage = (stepsCompleted / totalSteps) * 100;
              acc[activity.activity_id].totalProgress += progressPercentage;
            }
          });
        });
        return acc;
      },
      {}
    );

    console.log(`REACHED DATA`);

    // Calculate average progress data for each activity
    const averageProgressData = Object.entries(groupedData).reduce(
      (acc, [activity_id, data]) => {
        acc[activity_id] = {
          final_progress_score: data.totalProgress / (data.totalSteps || 1), // Avoid division by zero
          total_steps_count: data.totalSteps,
          completed_steps_count: data.stepsCompleted,
        };
        return acc;
      },
      {}
    );

    console.log(
      `averageProgressData: ${JSON.stringify(averageProgressData, null, 4)}`
    );
    return averageProgressData;
  } catch (error) {
    console.error(
      'Error fetching progress data:',
      error.response?.data || error.message
    );
  }
};
