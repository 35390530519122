export const problemTitle = `### Understanding and Solving **Range Addition II** Problem`;
export const initialInputValue = '3 3 [[2,2],[3,3]]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the maximum count after operations:`,
  `###### **Step 2:** Program to Solve **Range Addition II**:`,
  `###### **Step 3:** Input the grid dimensions and operations to find the maximum count:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You are given a matrix of dimensions \`m x n\`, initialized with all 0s, and a list of operations \`ops\`. Each operation adds 1 to all the elements in a subrectangle defined by the top-left corner \`(0, 0)\` and the bottom-right corner \`(a - 1, b - 1)\`. Return the number of elements in the matrix with the maximum integer value after performing all operations.

#### Problem Statement (Elaborated):
Write a program that calculates the size of the area in the matrix that has the maximum value after performing all operations. The dimensions of the affected subrectangle shrink with each operation, determined by the minimum values of \`a\` and \`b\`.

#### Testing Examples
1. **Example 1: Input = "3 3 [[2,2],[3,3]]"**
   - **Step-by-step**: The grid size is \`3x3\`, and two operations affect subrectangles:
     - \`[2,2]\`: Affects a \`2x2\` subrectangle.
     - \`[3,3]\`: Affects the entire \`3x3\` matrix.
   - The minimum affected dimensions are \`2x2\`.
   - Result: The maximum value appears in a \`2x2\` subrectangle.
2. **Example 2: Input = "4 4 [[3,3],[2,2],[4,4]]"**
   - **Step-by-step**: The grid size is \`4x4\`, and operations affect:
     - \`[3,3]\`, \`[2,2]\`, \`[4,4]\`.
   - The minimum dimensions of overlap are \`2x2\`.
   - Result: The maximum value appears in a \`2x2\` subrectangle.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through operations.
2. **Mathematical Operations**: Finding the minimum overlap of dimensions.
3. **Conditional Statements**: To determine the smallest affected area.

#### Real-World Concepts Covered
1. **Optimization**: Minimizing the affected area.
2. **Mathematical Properties**: Understanding intersections of multiple ranges.
3. **Problem-Solving**: Simplifying iterative changes into a mathematical representation.

#### How Does the Program Work?
1. Initialize \`minRow = m\` and \`minCol = n\` for the smallest overlap dimensions.
2. For each operation \`[a, b]\`, update \`minRow = min(minRow, a)\` and \`minCol = min(minCol, b)\`.
3. Return the area of the overlap: \`minRow * minCol\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: The final result is determined by the smallest \`minRow\` and \`minCol\`.
2. **Hint 2**: Use nested loops sparingly; avoid iterating through the entire matrix.
3. **Hint 3**: Handle edge cases like no operations (\`ops.length = 0\`).

#### Why Do We Do This?
This problem demonstrates optimization and understanding range constraints, which are important in many real-world scenarios like scheduling, resource allocation, and computational geometry.
`;

export const initialAlgorithm = `1. Initialize \`minRow = m\` and \`minCol = n\`.
2. For each operation \`[a, b]\` in \`ops\`:
   - a. Update \`minRow = min(minRow, a)\`.
   - b. Update \`minCol = min(minCol, b)\`.
3. Return the product of \`minRow * minCol\` as the result.`;

export const initialProblemCode = `1  function maxCount(m, n, ops) {
2    let minRow = m;
3    let minCol = n;
4    for (let i = 0; i < ops.length; i++) {
5      let [a, b] = ops[i];
6      if (a < minRow) minRow = a;
7      if (b < minCol) minCol = b;
8    }
9    return minRow * minCol;
10 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let inputParts;
  try {
    inputParts = userInput.split(' ');
    if (inputParts.length < 3) {
      throw new Error();
    }
    let m = parseInt(inputParts[0], 10);
    let n = parseInt(inputParts[1], 10);
    let ops = JSON.parse(inputParts.slice(2).join(' '));

    if (isNaN(m) || isNaN(n) || !Array.isArray(ops)) {
      throw new Error();
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function maxCount is called with m = ${m}, n = ${n}, ops = ${JSON.stringify(
        ops
      )}`,
      { m, n, ops }
    );

    // Line 2: Initialize minRow
    let minRow = m;
    addTrace(executionTrace, 2, `Initialize minRow = ${minRow}`, { minRow });

    // Line 3: Initialize minCol
    let minCol = n;
    addTrace(executionTrace, 3, `Initialize minCol = ${minCol}`, { minCol });

    // Line 4: Start loop over ops
    for (let i = 0; i < ops.length; i++) {
      addTrace(
        executionTrace,
        4,
        `Start iteration ${i + 1}: ops[${i}] = ${JSON.stringify(ops[i])}`,
        { currentOp: ops[i] }
      );

      let [a, b] = ops[i];
      addTrace(executionTrace, 5, `Extract dimensions: a = ${a}, b = ${b}`, {
        a,
        b,
      });

      // Line 6: Update minRow
      if (a < minRow) {
        let previousMinRow = minRow;
        minRow = a;
        addTrace(
          executionTrace,
          6,
          `Update minRow: minRow = min(${previousMinRow}, ${a}) = ${minRow}`,
          { minRow }
        );
      }

      // Line 7: Update minCol
      if (b < minCol) {
        let previousMinCol = minCol;
        minCol = b;
        addTrace(
          executionTrace,
          7,
          `Update minCol: minCol = min(${previousMinCol}, ${b}) = ${minCol}`,
          { minCol }
        );
      }
    }

    // Line 9: Calculate and return result
    let result = minRow * minCol;
    addTrace(
      executionTrace,
      9,
      `Calculate result: minRow * minCol = ${minRow} * ${minCol} = ${result}`,
      { result }
    );

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The maximum count of elements with the highest value is ${result}.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter valid grid dimensions and operations!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }
};
