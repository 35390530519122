export const problemTitle = `### Understanding and Solving **Smallest Range I** Problem`;
export const initialInputValue = '1,3,6;2';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the **Smallest Range I**:`,
  `###### **Step 2:** Program to Calculate **Smallest Range I**:`,
  `###### **Step 3:** Input the array and value k to calculate the **Smallest Range I**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array of integers \`nums\` and an integer \`k\`, return the **smallest possible range** between the maximum and minimum values of the array after modifying each element by at most \`k\`.

#### Problem Statement(Elaborated):
The **range** of an array is defined as the difference between the largest and smallest elements. You can increase or decrease each element by at most \`k\` once. Find the smallest possible range of the modified array.

#### Testing Examples
1. **Example 1: Input = [1, 3, 6], k = 2**
   - **Step-by-step**:
     - Original range: 6 - 1 = 5.
     - After modification, max = 6 - 2 = 4 and min = 1 + 2 = 3.
     - New range = 4 - 3 = 1.
2. **Example 2: Input = [0, 10], k = 2**
   - **Step-by-step**:
     - Original range: 10 - 0 = 10.
     - After modification, max = 10 - 2 = 8 and min = 0 + 2 = 2.
     - New range = 8 - 2 = 6.

#### What is the Smallest Range?
The **smallest range** is the minimum difference between the maximum and minimum values of the array after applying the allowed modifications.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through the array to find max and min.
2. **Arithmetic Operations**: Calculating differences between max and min values.
3. **Conditional Logic**: Determining if the range can be reduced to 0.

#### Real-World Concepts Covered
1. **Optimization**: Minimizing the difference between elements in a range.
2. **Logical Thinking**: Adjusting elements within constraints to achieve a goal.

#### How Does the Program Work?
1. Parse the input to extract the array and the value of \`k\`.
2. Calculate the maximum and minimum values of the array.
3. Adjust the maximum by subtracting \`k\` and the minimum by adding \`k\`.
4. Calculate the new range, ensuring it’s non-negative.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Only the maximum and minimum values affect the range calculation.
2. **Hint 2**: If \`max - min <= 2k\`, the range can be reduced to 0.
3. **Hint 3**: Use simple arithmetic to adjust max and min values.

#### Why Do We Do This?
This problem highlights optimization techniques under constraints, which are common in computational problems and real-life scenarios.
`;

export const initialAlgorithm = `1. Parse the input to extract the array and the value of \`k\`.
2. Initialize variables to find the maximum and minimum values of the array.
3. Calculate the modified maximum value as \`maxValue - k\`.
4. Calculate the modified minimum value as \`minValue + k\`.
5. Calculate the new range as \`max(modifiedMax - modifiedMin, 0)\`.
6. Return the new range.`;

export const initialProblemCode = `1  function smallestRangeI(nums, k) {
2    let maxValue = -Infinity;
3    let minValue = Infinity;
4    
5    for (let i = 0; i < nums.length; i++) {
6      if (nums[i] > maxValue) {
7        maxValue = nums[i];
8      }
9      if (nums[i] < minValue) {
10       minValue = nums[i];
11     }
12   }
13   
14   let modifiedMax = maxValue - k;
15   let modifiedMin = minValue + k;
16   
17   return Math.max(modifiedMax - modifiedMin, 0);
18 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let nums, k;
  try {
    let input = userInput.split(';');
    nums = input[0].split(',').map(Number);
    k = parseInt(input[1], 10);
    if (nums.some(isNaN) || isNaN(k)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter valid input in the format "a,b,c;k"',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    2,
    `Function smallestRangeI is called with nums = [${nums}] and k = ${k}`,
    { nums, k }
  );

  let maxValue = -Infinity;
  let minValue = Infinity;

  for (let i = 0; i < nums.length; i++) {
    if (nums[i] > maxValue) {
      maxValue = nums[i];
    }
    if (nums[i] < minValue) {
      minValue = nums[i];
    }
    addTrace(
      executionTrace,
      6,
      `Iterating nums[${i}] = ${nums[i]}; maxValue = ${maxValue}; minValue = ${minValue}`,
      { nums, maxValue, minValue }
    );
  }

  let modifiedMax = maxValue - k;
  let modifiedMin = minValue + k;

  addTrace(
    executionTrace,
    14,
    `Calculate modifiedMax = ${maxValue} - ${k} = ${modifiedMax}`,
    { modifiedMax }
  );
  addTrace(
    executionTrace,
    15,
    `Calculate modifiedMin = ${minValue} + ${k} = ${modifiedMin}`,
    { modifiedMin }
  );

  let newRange = Math.max(modifiedMax - modifiedMin, 0);
  addTrace(
    executionTrace,
    17,
    `Calculate newRange = max(${modifiedMax} - ${modifiedMin}, 0) = ${newRange}`,
    { newRange }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The smallest possible range is ${newRange}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
