export const problemTitle = `### Understanding and Solving **Valid Perfect Square** Problem`;
export const initialInputValue = '16';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a number is a **valid perfect square**:`,
  `###### **Step 2:** Program to Check **Valid Perfect Square**:`,
  `###### **Step 3:** Input a number to check if it is a **valid perfect square**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a positive integer \`num\`, return \`true\` if \`num\` is a **perfect square**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
Write a program that determines whether a given integer is a perfect square. A number is a perfect square if it can be expressed as \`x * x\`, where \`x\` is an integer.

#### Testing Examples
1. **Example 1: Input = 16**
   - **Step-by-step**: 
     - 16 can be written as \`4 * 4\`. 
     - Therefore, the output is **true**.
2. **Example 2: Input = 14**
   - **Step-by-step**: 
     - 14 cannot be written as \`x * x\` for any integer \`x\`. 
     - Therefore, the output is **false**.

#### What is a Perfect Square?
A **perfect square** is any number that can be expressed as the square of an integer. For example, \`1\`, \`4\`, \`9\`, \`16\`, \`25\`, etc., are all perfect squares.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iteratively find if a number is a perfect square.
2. **Mathematical Operations**: Using multiplication to determine the square of a number.
3. **Conditional Statements**: Using \`if-else\` to check conditions.

#### Real-World Concepts Covered
1. **Geometric Interpretation**: Understanding perfect squares as areas of squares with integer side lengths.
2. **Number Properties**: Exploring properties of integers and their relationships.

#### How Does the Program Work?
1. If \`num <= 0\`, return \`false\`.
2. Start with \`i = 1\` and compute \`i * i\` iteratively.
3. Stop if \`i * i\` exceeds \`num\`. 
4. If \`i * i == num\` at any point, \`num\` is a perfect square.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Start from \`1\` and increment \`i\` while \`i * i\` is less than or equal to \`num\`.
2. **Hint 2**: If at any point \`i * i == num\`, \`num\` is a perfect square.
3. **Hint 3**: If the loop ends and no such \`i\` is found, \`num\` is not a perfect square.
`;

export const initialAlgorithm = `1. If the number (\`num\`) is less than or equal to 0, return \`false\`.
2. Initialize a variable \`i = 1\`.
3. While \`i * i <= num\`:
   - a. If \`i * i == num\`, return \`true\`.
   - b. Increment \`i\` by 1.
4. If the loop ends, return \`false\`.`;

export const initialProblemCode = `1  function isPerfectSquare(num) {
2    if (num <= 0) return false;
3    let i = 1;
4    while (i * i <= num) {
5      if (i * i === num) {
6        return true;
7      }
8      i++;
9    }
10   return false;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num;
  try {
    num = parseInt(userInput, 10);
    if (isNaN(num)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isPerfectSquare is called with num = ${num}`,
    {
      num,
    }
  );

  // Line 2: Check if num <= 0
  addTrace(
    executionTrace,
    2,
    `Check if num is less than or equal to 0: num <= 0 = ${num <= 0}`,
    {
      num,
    }
  );
  if (num <= 0) {
    addTrace(executionTrace, 2, `Since num <= 0, return false`, { num });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${num} is not a perfect square.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let i = 1;
  addTrace(executionTrace, 3, `Initialize i = ${i}`, { i });

  // Line 4: Start of while loop
  while (i * i <= num) {
    addTrace(
      executionTrace,
      4,
      `Check while condition: i * i <= num = ${i} * ${i} <= ${num} = ${i * i <= num}`,
      { i }
    );

    // Line 5: Check if i * i === num
    if (i * i === num) {
      addTrace(
        executionTrace,
        5,
        `Check if i * i == num: ${i} * ${i} == ${num} = ${i * i === num}`,
        { i }
      );
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: `${num} is a perfect square.`,
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let previousI = i;
    i++;
    addTrace(executionTrace, 8, `Increment i: i = ${previousI} + 1 = ${i}`, {
      i,
    });
  }

  // Line 10: Return false
  addTrace(
    executionTrace,
    10,
    `Loop ended without finding i: i * i == num. Return false`,
    {
      num,
    }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `${num} is not a perfect square.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
