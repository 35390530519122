export const problemTitle = `### Understanding and Solving **Excel Sheet Column Title Using Mathematics** Problem`;
export const initialInputValue = '28';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to convert a number to Excel sheet column title using mathematics:`,
  `###### **Step 2:** Program to Convert **Number to Excel Column Title** Using Mathematics:`,
  `###### **Step 3:** Input a number to find its **Excel column title**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a positive integer \`columnNumber\`, return its corresponding column title as it appears in an Excel sheet.

#### Problem Statement(Elaborated):
Write a program that converts a **positive integer** to its **Excel sheet column title**. The column titles in Excel are represented as follows:

| Column Number | Column Title |
|---------------|--------------|
| 1             | A            |
| 2             | B            |
| ...           | ...          |
| 26            | Z            |
| 27            | AA           |
| 28            | AB           |

#### Testing Examples
1. **Example 1: Input = 1**
   - **Step-by-step**: The column number \`1\` corresponds to the title \`A\`. Therefore, the **output** is **A**.
2. **Example 2: Input = 28**
   - **Step-by-step**: The column number \`28\` corresponds to the title \`AB\`. Therefore, the **output** is **AB**.
3. **Example 3: Input = 701**
   - **Step-by-step**: The column number \`701\` corresponds to the title \`ZY\`. Therefore, the **output** is **ZY**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate the characters in the column title iteratively.
2. **Mathematical Operations**: Using modulo (\`%\`) and division (\`/\`) to extract and process each character.
3. **Conditional Statements**: To handle the mapping of numbers to letters.

#### Real-World Concepts Covered
1. **Base Conversion**: Understanding how Excel column titles map numbers in a base-26 system.
2. **Character Arithmetic**: Using ASCII values to generate letters from numbers.

#### How Does the Program Work?
1. Use a loop to process the number:
   - a. Find the current letter by taking modulo 26.
   - b. Append the letter to the result.
   - c. Update the number by dividing it by 26 and adjusting for 1-based indexing.
2. Reverse the result to get the correct column title.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use \`String.fromCharCode()\` with the ASCII value of 'A' to map numbers to letters.
2. **Hint 2**: Remember to adjust for 1-based indexing by subtracting 1 before taking modulo.
3. **Hint 3**: Reverse the generated string before returning it.

#### Why Do We Do This?
This problem demonstrates how to translate numbers to a custom base-26 system, a concept frequently used in computer science and real-world applications like spreadsheets.
`;

export const initialAlgorithm = `1. Initialize an empty string \`result\` to store the column title.
2. While \`columnNumber > 0\`:
   - a. Decrement \`columnNumber\` by 1 to handle 1-based indexing.
   - b. Compute the remainder: \`remainder = columnNumber % 26\`.
   - c. Find the corresponding character: \`char = String.fromCharCode(remainder + 'A'.charCodeAt(0))\`.
   - d. Append \`char\` to the front of \`result\`.
   - e. Update \`columnNumber = Math.floor(columnNumber / 26)\`.
3. Return the \`result\`.`;

export const initialProblemCode = `1  function numberToExcelColumn(columnNumber) {
2    let result = '';
3    while (columnNumber > 0) {
4      columnNumber--; // Adjust for 1-based indexing
5      let remainder = columnNumber % 26;
6      let char = String.fromCharCode(remainder + 'A'.charCodeAt(0));
7      result = char + result;
8      columnNumber = Math.floor(columnNumber / 26);
9    }
10   return result;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let columnNumber;
  try {
    columnNumber = parseInt(userInput, 10);
    if (isNaN(columnNumber) || columnNumber <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function numberToExcelColumn is called with columnNumber = ${columnNumber}`,
    { columnNumber }
  );

  let result = '';
  addTrace(executionTrace, 2, `Initialize result = ''`, { result });

  while (columnNumber > 0) {
    addTrace(
      executionTrace,
      3,
      `Check while condition: columnNumber (${columnNumber}) > 0 = ${
        columnNumber > 0
      }`,
      { columnNumber }
    );

    columnNumber--; // Adjust for 1-based indexing
    addTrace(
      executionTrace,
      4,
      `Decrement columnNumber: columnNumber = ${columnNumber}`,
      { columnNumber }
    );

    let remainder = columnNumber % 26;
    addTrace(
      executionTrace,
      5,
      `Calculate remainder: remainder = columnNumber % 26 = ${remainder}`,
      { remainder }
    );

    let char = String.fromCharCode(remainder + 'A'.charCodeAt(0));
    addTrace(
      executionTrace,
      6,
      `Find corresponding character: char = String.fromCharCode(${remainder} + 'A'.charCodeAt(0)) = ${char}`,
      { char }
    );

    result = char + result;
    addTrace(executionTrace, 7, `Append char to result: result = ${result}`, {
      result,
    });

    columnNumber = Math.floor(columnNumber / 26);
    addTrace(
      executionTrace,
      8,
      `Update columnNumber: columnNumber = Math.floor(columnNumber / 26) = ${columnNumber}`,
      { columnNumber }
    );
  }

  addTrace(executionTrace, 10, `Return result: ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The Excel column title for ${userInput} is '${result}'.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
