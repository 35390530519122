export const problemTitle = `### Understanding and Solving **Surface Area of 3D Shapes** Problem`;
export const initialInputValue = '[[1,2],[3,4]]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the surface area of 3D shapes:`,
  `###### **Step 2:** Program to Compute Surface Area:`,
  `###### **Step 3:** Input a matrix representing the 3D shapes:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D grid \`grid\` of integers where \`grid[i][j]\` represents the height of a 3D shape stacked on a grid cell, calculate the **total surface area** of the shapes. The surface area is the sum of:
1. The **top and bottom faces** (equal to the number of non-zero cells multiplied by 2),
2. The **side faces** for adjacent cells (consider differences in height between neighboring cells),
3. The **outer side faces** (consider edges of the grid).

#### Problem Statement(Elaborated):
Write a program that takes a 2D grid representing heights of shapes in a grid layout and computes the total **surface area** visible.

#### Testing Examples
1. **Example 1: Input = [[1,2],[3,4]]**
   - **Step-by-step**:
     - Top and bottom faces: Non-zero cells = 4 → Area = \`4 * 2 = 8\`.
     - Side faces:
       - Left/right: \`1 + 3 + 2 + 4 = 10\`.
       - Front/back: \`1 + 2 + 3 + 4 = 10\`.
       - Between cells: \`|1-2| + |2-0| + |3-4| + |4-0| = 7\`.
     - Total Surface Area = \`8 + 10 + 10 + 7 = 35\`.
2. **Example 2: Input = [[2]]**
   - **Step-by-step**:
     - Top and bottom faces: Non-zero cells = 1 → Area = \`1 * 2 = 2\`.
     - Side faces:
       - Left/right: \`2 + 0 = 2\`.
       - Front/back: \`2 + 0 = 2\`.
       - Between cells: None.
     - Total Surface Area = \`2 + 2 + 2 = 6\`.

#### What is the Surface Area of 3D Shapes?
The **surface area** of 3D shapes is the sum of all visible faces of the shapes, including their top, bottom, and sides.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Using **nested loops** to iterate over the grid rows and columns.
2. **Conditional Statements**: To check non-zero values and edge cases.
3. **Indexing**: Understanding and working with matrix indices for rows and columns.
4. **Arithmetic Operations**: Calculating absolute differences for adjacent heights.

#### Real-World Concepts Covered
1. **3D Modeling**: Understanding how to calculate visible surface areas.
2. **Grid Manipulation**: Working with grids for mathematical computations.
3. **Logical Thinking**: Breaking down the problem into smaller parts (top, side, and outer faces).

#### How Does the Program Work?
1. Compute the **top and bottom surface area** by counting non-zero cells.
2. Compute the **side surface areas** for adjacent cells and edges.
3. Sum all the contributions to get the total surface area.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse the grid row-wise and column-wise.
2. **Hint 2**: Calculate differences in height for neighboring cells and edges separately.
3. **Hint 3**: Add a base case for zero heights where there are no visible faces.
4. **Hint 4**: Carefully handle grid edges where neighboring cells do not exist.
5. **Hint 5**: Avoid using built-in methods for simplicity.

#### Why Do We Do This?
This problem simulates how surface area calculations work in 3D modeling and engineering. Understanding these basics is essential for more advanced problems in 3D geometry.`;

export const initialAlgorithm = `1. Initialize **surfaceArea** as 0.
2. Iterate through each cell of the grid:
   - a. If the height is greater than 0, add **2** to surfaceArea for the top and bottom faces.
   - b. Add the height difference with neighboring cells (left, right, up, down).
   - c. For edges of the grid, add the entire height to the surfaceArea.
3. Return **surfaceArea**.`;

export const initialProblemCode = `1  function surfaceArea(grid) {
2    let surfaceArea = 0;
3    let rows = grid.length;
4    let cols = grid[0].length;
5
6    for (let i = 0; i < rows; i++) {
7      for (let j = 0; j < cols; j++) {
8        if (grid[i][j] > 0) {
9          surfaceArea += 2; // Top and bottom faces
10         // Left
11         if (j === 0) surfaceArea += grid[i][j];
12         else surfaceArea += Math.max(grid[i][j] - grid[i][j - 1], 0);
13         // Right
14         if (j === cols - 1) surfaceArea += grid[i][j];
15         else surfaceArea += Math.max(grid[i][j] - grid[i][j + 1], 0);
16         // Front
17         if (i === 0) surfaceArea += grid[i][j];
18         else surfaceArea += Math.max(grid[i][j] - grid[i - 1][j], 0);
19         // Back
20         if (i === rows - 1) surfaceArea += grid[i][j];
21         else surfaceArea += Math.max(grid[i][j] - grid[i + 1][j], 0);
22       }
23     }
24   }
25   return surfaceArea;
26 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let grid;
  try {
    grid = JSON.parse(userInput);
    if (!Array.isArray(grid) || !grid.every(Array.isArray)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid 2D array (grid)!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function surfaceArea is called with grid = ${JSON.stringify(grid)}`,
    { grid }
  );

  let surfaceArea = 0;
  let rows = grid.length;
  let cols = grid[0].length;
  addTrace(
    executionTrace,
    2,
    `Initialize surfaceArea = ${surfaceArea}, rows = ${rows}, cols = ${cols}`,
    { surfaceArea, rows, cols }
  );

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      if (grid[i][j] > 0) {
        surfaceArea += 2; // Top and bottom faces
        addTrace(
          executionTrace,
          9,
          `grid[${i}][${j}] > 0, add 2 for top and bottom faces. Updated surfaceArea = ${surfaceArea}`,
          { surfaceArea }
        );

        // Left
        if (j === 0) {
          surfaceArea += grid[i][j];
          addTrace(
            executionTrace,
            11,
            `Left edge: add grid[${i}][${j}] = ${grid[i][j]}. Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        } else {
          surfaceArea += Math.max(grid[i][j] - grid[i][j - 1], 0);
          addTrace(
            executionTrace,
            12,
            `Add max(grid[${i}][${j}] - grid[${i}][${j - 1}], 0). Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        }

        // Right
        if (j === cols - 1) {
          surfaceArea += grid[i][j];
          addTrace(
            executionTrace,
            14,
            `Right edge: add grid[${i}][${j}] = ${grid[i][j]}. Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        } else {
          surfaceArea += Math.max(grid[i][j] - grid[i][j + 1], 0);
          addTrace(
            executionTrace,
            15,
            `Add max(grid[${i}][${j}] - grid[${i}][${j + 1}], 0). Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        }

        // Front
        if (i === 0) {
          surfaceArea += grid[i][j];
          addTrace(
            executionTrace,
            17,
            `Front edge: add grid[${i}][${j}] = ${grid[i][j]}. Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        } else {
          surfaceArea += Math.max(grid[i][j] - grid[i - 1][j], 0);
          addTrace(
            executionTrace,
            18,
            `Add max(grid[${i}][${j}] - grid[${i - 1}][${j}], 0). Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        }

        // Back
        if (i === rows - 1) {
          surfaceArea += grid[i][j];
          addTrace(
            executionTrace,
            20,
            `Back edge: add grid[${i}][${j}] = ${grid[i][j]}. Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        } else {
          surfaceArea += Math.max(grid[i][j] - grid[i + 1][j], 0);
          addTrace(
            executionTrace,
            21,
            `Add max(grid[${i}][${j}] - grid[${i + 1}][${j}], 0). Updated surfaceArea = ${surfaceArea}`,
            { surfaceArea }
          );
        }
      }
    }
  }

  addTrace(executionTrace, 25, `Return total surfaceArea = ${surfaceArea}`, {
    surfaceArea,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Result: ${surfaceArea}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
