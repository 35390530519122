import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import CenteredText from './CenteredText.js';
import PERN_MERN from '../assets/images/javascript/PERN, MERN.png';
import BACK_END from '../assets/images/javascript/HLS Course Back-End.png';
import FRONT_END from '../assets/images/javascript/HLS Course Front-End.png';
import './CareerPaths.css'; // Import a CSS file for component-specific styles

// Sample career paths data
const careerPathsData = [
  {
    id: 1,
    title: 'Full-Stack Engineer',
    description:
      'Learn to build comprehensive web applications from start to finish. This path begins with front-end development, covering HTML, CSS, JavaScript, and frameworks like React to create stunning web interfaces. Then, transition to back-end development with Node.js, Express.js, PostgreSQL, SQL, and security to build robust and secure databases and APIs. Master the full stack and connect the front-end and back-end seamlessly to develop complete web applications.',
    image: PERN_MERN,
    link: '/career-paths/pern-mern',
  },
  {
    id: 2,
    title: 'Back End Engineer',
    description:
      'Back-end developers manage the behind-the-scenes processes, building APIs and databases that power the front-end. Their expertise includes Node.js, Express.js, PostgreSQL, back-end development, SQL, security, and more. Discover how to become a back-end developer and master the essential technologies and skills to build robust and secure web applications.',
    image: BACK_END,
    link: '/career-paths/back-end',
  },
  {
    id: 3,
    title: 'Front End Engineer',
    description:
      'Front-end engineers collaborate closely with designers to create beautiful, functional, and fast websites. Their expertise includes HTML, CSS, JavaScript, front-end development, web applications, and popular frameworks like React. Learn how to become a front-end engineer and build stunning web interfaces with these essential technologies and skills.',
    image: FRONT_END,
    link: '/career-paths/front-end',
  },
];

const CareerPaths = () => {
  const [careerPaths, setCareerPaths] = useState([]);

  useEffect(() => {
    // Simulate fetching career paths data (replace this with a real API call)
    setCareerPaths(careerPathsData);
  }, []);

  return (
    <section className="career-paths mt-5">
      <Container>
        <CenteredText as="h3" centered className="text-center display-4">
          Explore Career Paths
        </CenteredText>
        <CenteredText as="p" centered className="text-center lead">
          Choose a path that aligns with your goals and get started with a
          structured learning plan.
        </CenteredText>
        <Row className="mt-4">
          {careerPaths.map((path) => (
            <Col md={4} key={path.id} className="mb-4">
              <Card className="career-card">
                <Card.Img variant="top" src={path.image} alt={path.title} />
                <Card.Body>
                  <Card.Title>{path.title}</Card.Title>
                  <Card.Text>{path.description}</Card.Text>
                  <Button as={Link} to={path.link} variant="primary">
                    Learn More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default CareerPaths;
