import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api`;

const authHeader = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// CodingSubmission APIs
export const createCodingSubmission = (token, data) =>
  axios.post(`${API_URL}/coding-submissions`, data, authHeader(token));
export const updateCodingSubmission = (token, id, data) =>
  axios.put(`${API_URL}/coding-submissions/${id}`, data, authHeader(token));

// Career Path APIs
export const getAllCareerPaths = (token) =>
  axios.get(`${API_URL}/career-paths`, authHeader(token));
export const getCareerPathById = (token, id) =>
  axios.get(`${API_URL}/career-path/${id}`, authHeader(token));
export const getActivityJSONByCareerPathIdActivityID = (
  token,
  careerPathId,
  courseId,
  sectionId,
  activityType,
  activityId
) =>
  axios.get(
    `${API_URL}/career-path/${careerPathId}/${courseId}/${sectionId}/activity/${activityType}/${activityId}`,
    authHeader(token)
  );

export const getCareerPathNextActivity = (
  token,
  careerPathId,
  courseId,
  sectionId,
  activityType,
  activityId
) =>
  axios.get(
    `${API_URL}/career-path/${careerPathId}/${courseId}/${sectionId}/nextActivity/${activityType}/${activityId}`,
    authHeader(token)
  );

// Course APIs
export const getAllCoursesPublic = () => axios.get(`${API_URL}/public/courses`);
export const getCourseByIdPublic = (id) =>
  axios.get(`${API_URL}/public/courses/${id}`);

export const getAllCourses = (token) =>
  axios.get(`${API_URL}/courses`, authHeader(token));
export const getCourseById = (token, id) =>
  axios.get(`${API_URL}/courses/${id}`, authHeader(token));

export const getActivityJSONByCourseIdActivityID = (
  token,
  courseId,
  sectionId,
  activityType,
  activityId
) =>
  axios.get(
    `${API_URL}/courses/${courseId}/${sectionId}/activity/${activityType}/${activityId}`,
    authHeader(token)
  );

export const getCourseResumeActivity = (token, id) =>
  axios.get(`${API_URL}/courses/${id}/resume`, authHeader(token));

export const getCourseNextActivity = (
  token,
  courseId,
  sectionId,
  activityType,
  activityId
) =>
  axios.get(
    `${API_URL}/courses/${courseId}/${sectionId}/nextActivity/${activityType}/${activityId}`,
    authHeader(token)
  );
export const createCourse = (token, data) =>
  axios.post(`${API_URL}/courses`, data, authHeader(token));
export const updateCourse = (token, id, data) =>
  axios.put(`${API_URL}/courses/${id}`, data, authHeader(token));
export const deleteCourse = (token, id) =>
  axios.delete(`${API_URL}/courses/${id}`, authHeader(token));

// Get all programming languages
export const getAllProgrammingLanguages = (token) =>
  axios.get(`${API_URL}/programming-languages`, authHeader(token));

// Enrollment APIs
export const getAllEnrollments = (token) =>
  axios.get(`${API_URL}/enrollments`, authHeader(token));
export const getEnrollmentById = (token, course_id) =>
  axios.get(`${API_URL}/enrollments/${course_id}`, authHeader(token));

export const getCareerPathEnrollmentById = (token, careerPath_id) =>
  axios.get(
    `${API_URL}/enrollments/career-path/${careerPath_id}`,
    authHeader(token)
  );

export const createCareerPathEnrollment = (token, data) =>
  axios.post(`${API_URL}/enrollment/career-path`, data, authHeader(token));

export const createEnrollment = (token, data) =>
  axios.post(`${API_URL}/enrollments`, data, authHeader(token));
export const updateEnrollment = (token, user_id, course_id, data) =>
  axios.put(
    `${API_URL}/enrollments/${user_id}/${course_id}`,
    data,
    authHeader(token)
  );
export const deleteEnrollment = (token, course_id) =>
  axios.delete(`${API_URL}/enrollments/${course_id}`, authHeader(token));

export const deleteCareerPathEnrollment = (token, careerPathId) =>
  axios.delete(
    `${API_URL}/enrollment/career-path/${careerPathId}`,
    authHeader(token)
  );

// Lesson APIs
export const getAllLessons = (token) =>
  axios.get(`${API_URL}/lessons`, authHeader(token));
export const getLessonById = (token, id) =>
  axios.get(`${API_URL}/lessons/${id}`, authHeader(token));
export const createLesson = (token, data) =>
  axios.post(`${API_URL}/lessons`, data, authHeader(token));
export const updateLesson = (token, id, data) =>
  axios.put(`${API_URL}/lessons/${id}`, data, authHeader(token));
export const deleteLesson = (token, id) =>
  axios.delete(`${API_URL}/lessons/${id}`, authHeader(token));

// ProjectActivity APIs
export const getAllProjectActivities = (token) =>
  axios.get(`${API_URL}/project-activities`, authHeader(token));
export const getProjectActivityById = (token, id) =>
  axios.get(`${API_URL}/project-activities/${id}`, authHeader(token));
export const createProjectActivity = (token, data) =>
  axios.post(`${API_URL}/project-activities`, data, authHeader(token));
export const updateProjectActivity = (token, id, data) =>
  axios.put(`${API_URL}/project-activities/${id}`, data, authHeader(token));
export const deleteProjectActivity = (token, id) =>
  axios.delete(`${API_URL}/project-activities/${id}`, authHeader(token));

// ProjectSubmission APIs
export const getAllProjectSubmissions = (token) =>
  axios.get(`${API_URL}/project-submissions`, authHeader(token));
export const getProjectSubmissionById = (token, id) =>
  axios.get(`${API_URL}/project-submissions/${id}`, authHeader(token));
export const createProjectSubmission = (token, data) =>
  axios.post(`${API_URL}/project-submissions`, data, authHeader(token));
export const updateProjectSubmission = (token, id, data) =>
  axios.put(`${API_URL}/project-submissions/${id}`, data, authHeader(token));
export const deleteProjectSubmission = (token, id) =>
  axios.delete(`${API_URL}/project-submissions/${id}`, authHeader(token));

// Quiz APIs
export const getAllQuizzes = (token) =>
  axios.get(`${API_URL}/quizzes`, authHeader(token));
export const getQuizById = (token, id) =>
  axios.get(`${API_URL}/quizzes/${id}`, authHeader(token));
export const createQuiz = (token, data) =>
  axios.post(`${API_URL}/quizzes`, data, authHeader(token));
export const updateQuiz = (token, id, data) =>
  axios.put(`${API_URL}/quizzes/${id}`, data, authHeader(token));
export const deleteQuiz = (token, id) =>
  axios.delete(`${API_URL}/quizzes/${id}`, authHeader(token));

// Submission APIs
export const getAllSubmissions = (token) =>
  axios.get(`${API_URL}/submissions`, authHeader(token));
export const getSubmissionById = (token, id) =>
  axios.get(`${API_URL}/submissions/${id}`, authHeader(token));
export const createSubmission = (token, data) =>
  axios.post(`${API_URL}/submissions`, data, authHeader(token));
export const updateSubmission = (token, id, data) =>
  axios.put(`${API_URL}/submissions/${id}`, data, authHeader(token));
export const deleteSubmission = (token, id) =>
  axios.delete(`${API_URL}/submissions/${id}`, authHeader(token));

// UserProgress APIs
// export const getAllUserProgress = (token) =>
//   axios.get(`${API_URL}/user-progress`, authHeader(token));
// export const getUserProgressById = (token, id) =>
//   axios.get(`${API_URL}/user-progress/${id}`, authHeader(token));
// export const getUserProgressByCourseId = (token, id) =>
//   axios.get(`${API_URL}/user-progress/${id}/course`, authHeader(token));

export const createUserProgress = (token, data) =>
  axios.post(`${API_URL}/user-progress`, data, authHeader(token));

export const updateUserProgress = (token, data) =>
  axios.post(`${API_URL}/progress/activity`, data, authHeader(token));

export const updateUserCareerPathProgress = (token, data) =>
  axios.post(
    `${API_URL}/progress/career-path/activity`,
    data,
    authHeader(token)
  );

// export const deleteUserProgress = (token, id) =>
//   axios.delete(`${API_URL}/user-progress/${id}`, authHeader(token));

// User APIs
export const getAllUsers = (token) =>
  axios.get(`${API_URL}/users`, authHeader(token));
export const getUserById = (token, id) =>
  axios.get(`${API_URL}/users/${id}`, authHeader(token));
export const createUser = (data) => axios.post(`${API_URL}/users`, data);
export const updateUser = (token, id, data) =>
  axios.put(`${API_URL}/users/${id}`, data, authHeader(token));
export const deleteUser = (token, id) =>
  axios.delete(`${API_URL}/users/${id}`, authHeader(token));

// Authentication APIs
export const loginUser = (email, password) =>
  axios.post(`${API_URL}/auth/login`, { email, password });
export const validateToken = (token) =>
  axios.get(`${API_URL}/auth/validate-token`, authHeader(token));

// Execute code API call
export const executeCodeOnline = (token, codePayload) =>
  axios.post(`${API_URL}/compiler/execute`, codePayload, authHeader(token));
