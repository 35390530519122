import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the ValidParenthesesDemo component
const problemTitle = `### Understanding and Solving **Valid Parentheses** Problem`;
const initialInputValue = '()';
const stepsTitles = [
  `###### **Step 1:** Algorithm to check **Valid Parentheses**:`,
  `###### **Step 2:** Program to Check **Valid Parentheses**:`,
  `###### **Step 3:** Input a string containing **parentheses** to check if it is **valid**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string containing just the characters \`'('\`, \`')'\`, \`'{'\`, \`'}'\`, \`'['\`, and \`']'\`, determine if the input string is **valid**.

#### Problem Statement (Elaborated):
A **string** containing **parentheses** is considered **valid** if:
1. **Open brackets** are **closed** by the same type of **brackets**.
2. **Open brackets** are **closed** in the **correct order**.

#### Testing Examples
1. **Example 1: Input = "()"**
   - **Step-by-step**: \`"()"\` is **valid** because the **open parenthesis** is **closed** correctly.
2. **Example 2: Input = "([)]"**
   - **Step-by-step**: \`"([)]"\` is **not valid** because the **parentheses are not closed** in the **correct order**.
3. **Example 3: Input = "{[]}"**
   - **Step-by-step**: \`"{[]}"\` is **valid** because **each open bracket** has a **corresponding closing bracket**.

#### What is a Valid Parentheses String?
A **valid parentheses** string is a **balanced** string where **every open parenthesis** has a **corresponding closing parenthesis** of the **same type**, and they are **properly nested**.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to **match opening and closing brackets**.
2. **Loops**: To **iterate through the characters** of the string.
3. **Stack-like Behavior**: Using **variables** to **track matches** without using complex **data structures**.

#### Real-World Concepts Covered
1. **Balancing Problems**: Understanding how to **balance open and closed elements**.
2. **Pattern Recognition**: Recognizing **valid sequences** of symbols.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **conditional comparisons**.

#### How Does the Program Work?
1. Iterate through **each character** of the input **string**.
2. Use a **variable** to **simulate** a **stack behavior** for tracking **open brackets**.
3. **Check** if each **closing bracket** matches the **last opened bracket**.
4. If **all brackets are matched**, the string is **valid**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a **variable** to **track the last unmatched open bracket**.
2. **Hint 2**: Think about how to **remove an open bracket** when a **match is found**.
3. **Hint 3**: Handle **edge cases** like **empty strings** which are technically **valid**.

#### Why Do We Do This?
We **perform this check** to ensure that **every opening bracket** has a **corresponding closing bracket**, and it helps in understanding how to **manage nested structures** in programming.
`;

const initialAlgorithm = `1. Initialize a **counter variable** for each **type of open bracket**.
2. Iterate through **each character** in the input string:
   - a. If the **character is an open bracket**, **increase** the corresponding **counter**.
   - b. If the **character is a closing bracket**, **check** if there is a **matching open bracket** available.
   - c. If a **match is found**, **decrease** the **counter**.
   - d. If **no matching open bracket** is found, return **false**.
3. After the loop, **check** if all **counters are zero**.
4. **Return true** if all **counters are zero**, indicating that the string is **valid**.
`;

const initialProblemCode = `1  function isValidParentheses(s) {
2    let openParentheses = 0, openBrackets = 0, openBraces = 0;
3    for (let i = 0; i < s.length; i++) {
4      const char = s[i];
5      if (char === '(') openParentheses++;
6      else if (char === ')') {
7        if (openParentheses === 0) return false;
8        openParentheses--;
9      }
10     else if (char === '[') openBrackets++;
11     else if (char === ']') {
12       if (openBrackets === 0) return false;
13       openBrackets--;
14     }
15     else if (char === '{') openBraces++;
16     else if (char === '}') {
17       if (openBraces === 0) return false;
18       openBraces--;
19     }
20   }
21   return openParentheses === 0 && openBrackets === 0 && openBraces === 0;
22 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component (same as PalindromeNumberDemo)
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

const ValidParenthesesDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);

  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };
      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let s = numberInput;
    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function isValidParentheses is called with s = "${s}"`,
      { s }
    );

    let openParentheses = 0,
      openBrackets = 0,
      openBraces = 0;
    addTrace(
      executionTrace,
      2,
      `Initialize counters: openParentheses = ${openParentheses}, openBrackets = ${openBrackets}, openBraces = ${openBraces}`,
      { openParentheses, openBrackets, openBraces }
    );

    for (let i = 0; i < s.length; i++) {
      const char = s[i];
      addTrace(executionTrace, 4, `Character at index ${i}: "${char}"`, {
        i,
        char,
      });

      if (char === '(') {
        openParentheses++;
        addTrace(
          executionTrace,
          5,
          `Increment openParentheses: ${openParentheses}`,
          { openParentheses }
        );
      } else if (char === ')') {
        if (openParentheses === 0) {
          addTrace(
            executionTrace,
            7,
            `No matching open parenthesis, return false`,
            {}
          );
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `The parentheses sequence "${s}" is not valid.`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
        openParentheses--;
        addTrace(
          executionTrace,
          8,
          `Decrement openParentheses: ${openParentheses}`,
          { openParentheses }
        );
      } else if (char === '[') {
        openBrackets++;
        addTrace(
          executionTrace,
          10,
          `Increment openBrackets: ${openBrackets}`,
          { openBrackets }
        );
      } else if (char === ']') {
        if (openBrackets === 0) {
          addTrace(
            executionTrace,
            12,
            `No matching open bracket, return false`,
            {}
          );
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `The parentheses sequence "${s}" is not valid.`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
        openBrackets--;
        addTrace(
          executionTrace,
          13,
          `Decrement openBrackets: ${openBrackets}`,
          { openBrackets }
        );
      } else if (char === '{') {
        openBraces++;
        addTrace(executionTrace, 15, `Increment openBraces: ${openBraces}`, {
          openBraces,
        });
      } else if (char === '}') {
        if (openBraces === 0) {
          addTrace(
            executionTrace,
            17,
            `No matching open brace, return false`,
            {}
          );
          dispatch({
            type: ACTION_TYPES.SET_MESSAGE,
            payload: `The parentheses sequence "${s}" is not valid.`,
          });
          dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
          dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
          return;
        }
        openBraces--;
        addTrace(executionTrace, 18, `Decrement openBraces: ${openBraces}`, {
          openBraces,
        });
      }
    }

    const isValid =
      openParentheses === 0 && openBrackets === 0 && openBraces === 0;
    addTrace(executionTrace, 21, `Check if all counters are zero: ${isValid}`, {
      openParentheses,
      openBrackets,
      openBraces,
      isValid,
    });

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The parentheses sequence "${s}" is ${
        isValid ? 'valid' : 'not valid'
      }.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default ValidParenthesesDemo;
