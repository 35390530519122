export const problemTitle = `### Understanding and Solving **Array Partition** Problem`;
export const initialInputValue = '[1,4,3,2]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve the **Array Partition** problem using Greedy approach:`,
  `###### **Step 2:** Program to Solve **Array Partition** Problem:`,
  `###### **Step 3:** Input an array to maximize the sum of pairs:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array of \`2n\` integers, your task is to **maximize the sum** of \`min(a, b)\` for all pairs \`(a, b)\`.

#### Testing Examples
1. **Example 1: Input = [1,4,3,2]**
   - **Step-by-step**: 
     - Pair \`(1, 2)\` and \`(3, 4)\`.
     - The sum of the minimums is \`1 + 3 = 4\`.
     - **Result**: 4.
2. **Example 2: Input = [6,2,6,5,1,2]**
   - **Step-by-step**: 
     - Pair \`(1, 2)\`, \`(2, 5)\`, \`(6, 6)\`.
     - The sum of the minimums is \`1 + 2 + 6 = 9\`.
     - **Result**: 9.

#### What is the Array Partition Problem?
The **Array Partition** problem is about dividing an array of integers into pairs such that the **sum of the smaller elements** in each pair is maximized.

#### What Topics of JavaScript Will Be Covered?
1. **Sorting**: Ordering the array in ascending order.
2. **Loops**: Iterating through the array to select elements.
3. **Mathematical Operations**: Calculating the sum of selected elements.
4. **Edge Case Handling**: Understanding cases like an array with only two elements.

#### Real-World Concepts Covered
1. **Optimization**: Maximizing the sum of minimums.
2. **Sorting and Selection**: Using sorting to guide optimal choices.
3. **Logical Thinking**: Problem-solving through greedy strategies.

#### How Does the Program Work?
1. **Sort** the array in ascending order.
2. To maximize the sum of the minimums, pair consecutive elements after sorting.
3. Add the first element of each pair to the total sum.
4. Return the total sum.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Sorting the array ensures that the smallest numbers are paired together.
2. **Hint 2**: Only the first element of each pair contributes to the sum.
3. **Hint 3**: Use a loop to iterate over the sorted array, skipping every second element.
4. **Hint 4**: Handle edge cases like arrays with only two elements directly.

#### Why Do We Do This?
The **Array Partition** problem illustrates how a **greedy strategy** can be used to solve optimization problems efficiently. It builds understanding of sorting and systematic selection.
`;

export const initialAlgorithm = `1. **Sort** the array in ascending order.
2. Initialize \`sum = 0\`.
3. Loop through the array, skipping every second element:
   - a. Add the current element to \`sum\`.
4. Return \`sum\`.`;

export const initialProblemCode = `1  function arrayPartition(arr) {
2    arr = bubbleSort(arr); // Sort the array
3    let sum = 0;
4    for (let i = 0; i < arr.length; i += 2) {
5      sum += arr[i];
6    }
7    return sum;
8  }
9  
10 function bubbleSort(arr) {
11   for (let i = 0; i < arr.length; i++) {
12     for (let j = 0; j < arr.length - i - 1; j++) {
13       if (arr[j] > arr[j + 1]) {
14         let temp = arr[j];
15         arr[j] = arr[j + 1];
16         arr[j + 1] = temp;
17       }
18     }
19   }
20   return arr;
21 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let arr;
  try {
    arr = JSON.parse(userInput);
    if (!Array.isArray(arr) || arr.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid array of integers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function arrayPartition is called with arr = [${arr}]`,
    { arr }
  );

  arr = bubbleSort(arr, executionTrace);

  let sum = 0;
  addTrace(executionTrace, 3, `Initialize sum = ${sum}`, { sum });

  for (let i = 0; i < arr.length; i += 2) {
    sum += arr[i];
    addTrace(
      executionTrace,
      5,
      `Add arr[${i}] (${arr[i]}) to sum. New sum = ${sum}`,
      { sum }
    );
  }

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The maximum sum of pairs is: ${sum}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });

  function bubbleSort(arr, executionTrace) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length - i - 1; j++) {
        if (arr[j] > arr[j + 1]) {
          let temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
          addTrace(
            executionTrace,
            14,
            `BubbleSort: Swap arr[${j}] (${arr[j + 1]}) with arr[${j + 1}] (${arr[j]})`,
            {
              arr,
            }
          );
        }
      }
    }
    return arr;
  }
};
