import LemonadeChangeGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/860LemonadeChangeGreedyEasyDemo.js';
import DIStringMatchGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/942DIStringMatchGreedyEasyDemo.js';
import ValidPalindromeIIGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/680ValidPalindromeIIGreedyEasyDemo.js';
import LargestPerimeterTriangleGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/976LargestPerimeterTriangleGreedyEasyDemo.js';
import ArrayPartitionGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/561ArrayPartitionGreedyEasyDemo.js';
import AssignCookiesGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/455AssignCookiesGreedyEasyDemo.js';
import CanPlaceFlowersGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/605CanPlaceFlowersGreedyEasyDemo.js';
import LongestPalindromeGreedyEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/Greedy/409LongestPalindromeGreedyEasyDemo.js';

export const greedyEasyProblemStatements = (title, setComponent) => {
  if (title === 'Lemonade Change') {
    setComponent(() => LemonadeChangeGreedyEasyDemo);
  } else if (title === 'DI String Match') {
    setComponent(() => DIStringMatchGreedyEasyDemo);
  } else if (title === 'Valid Palindrome II') {
    setComponent(() => ValidPalindromeIIGreedyEasyDemo);
  } else if (title === 'Largest Perimeter Triangle') {
    setComponent(() => LargestPerimeterTriangleGreedyEasyDemo);
  } else if (title === 'Array Partition') {
    setComponent(() => ArrayPartitionGreedyEasyDemo);
  } else if (title === 'Assign Cookies') {
    setComponent(() => AssignCookiesGreedyEasyDemo);
  } else if (title === 'Can Place Flowers') {
    setComponent(() => CanPlaceFlowersGreedyEasyDemo);
  } else if (title === 'Longest Palindrome') {
    setComponent(() => LongestPalindromeGreedyEasyDemo);
  }
};
