export const problemTitle = `### Understanding and Solving **Projection Area of 3D Shapes** Problem`;
export const initialInputValue = '[[1,2],[3,4]]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the projection area of 3D shapes:`,
  `###### **Step 2:** Program to Compute Projection Area:`,
  `###### **Step 3:** Input a matrix representing the 3D shapes:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D grid \`grid\` of integers where \`grid[i][j]\` represents the height of a 3D shape stacked on a grid cell, calculate the **total projection area** of the shapes. The total projection area is the sum of:
1. The **top view** (number of non-zero cells in the grid),
2. The **front view** (maximum height of each row),
3. The **side view** (maximum height of each column).

#### Problem Statement(Elaborated):
Write a program that takes a 2D grid representing heights of shapes in a grid layout and computes the **sum of areas** of three projections (top, front, and side).

#### Testing Examples
1. **Example 1: Input = [[1,2],[3,4]]**
   - **Step-by-step**:
     - Top View: Count of non-zero values = 4.
     - Front View: Maximum in rows = \`max([1,2]) = 2\`, \`max([3,4]) = 4\` → Sum = 6.
     - Side View: Maximum in columns = \`max([1,3]) = 3\`, \`max([2,4]) = 4\` → Sum = 7.
     - Total Projection Area = \`4 + 6 + 7 = 17\`.
2. **Example 2: Input = [[2]]**
   - **Step-by-step**:
     - Top View: Count of non-zero values = 1.
     - Front View: Maximum in rows = \`max([2]) = 2\`.
     - Side View: Maximum in columns = \`max([2]) = 2\`.
     - Total Projection Area = \`1 + 2 + 2 = 5\`.

#### What is the Projection Area?
The **projection area** of 3D shapes is the **total visible area** of the shapes when viewed from three different perspectives: **top**, **front**, and **side**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Using **nested loops** to iterate over the grid rows and columns.
2. **Conditional Statements**: To check non-zero values for the top view.
3. **Indexing**: Understanding and working with matrix indices for rows and columns.
4. **Maximum Calculation**: Finding the maximum values row-wise and column-wise.

#### Real-World Concepts Covered
1. **Spatial Visualization**: Understanding how objects appear in 2D projections.
2. **Grid Manipulation**: Working with data structures like grids for computation.
3. **Mathematical Thinking**: Calculating areas from numerical data.

#### How Does the Program Work?
1. Calculate the **top view area** by counting non-zero grid values.
2. Calculate the **front view area** by finding the maximum value in each row.
3. Calculate the **side view area** by finding the maximum value in each column.
4. Sum these three areas to get the total projection area.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse rows and columns of the grid.
2. **Hint 2**: Count non-zero values for the top view projection.
3. **Hint 3**: Track maximum values for rows and columns for the front and side projections.
4. **Hint 4**: Carefully handle edge cases like empty grids or grids with all zeros.
5. **Hint 5**: Avoid using built-in methods to simplify the solution for beginners.

#### Why Do We Do This?
This problem helps visualize how 3D objects project onto 2D planes, a fundamental concept in geometry, computer graphics, and 3D modeling.`;

export const initialAlgorithm = `1. Initialize **topArea**, **frontArea**, and **sideArea** as 0.
2. Iterate over each row in the grid:
   - a. Count non-zero elements in the row and add to **topArea**.
   - b. Find the maximum height in the row and add to **frontArea**.
3. Iterate over each column in the grid:
   - a. Find the maximum height in the column and add to **sideArea**.
4. Return the sum of **topArea**, **frontArea**, and **sideArea**.`;

export const initialProblemCode = `1  function projectionArea(grid) {
2    let topArea = 0;
3    let frontArea = 0;
4    let sideArea = 0;
5    let rows = grid.length;
6    let cols = grid[0].length;
7    
8    // Calculate top and front areas
9    for (let i = 0; i < rows; i++) {
10      let rowMax = 0;
11      for (let j = 0; j < cols; j++) {
12        if (grid[i][j] > 0) topArea++;
13        if (grid[i][j] > rowMax) rowMax = grid[i][j];
14      }
15      frontArea += rowMax;
16    }
17    
18    // Calculate side area
19    for (let j = 0; j < cols; j++) {
20      let colMax = 0;
21      for (let i = 0; i < rows; i++) {
22        if (grid[i][j] > colMax) colMax = grid[i][j];
23      }
24      sideArea += colMax;
25    }
26    
27    return topArea + frontArea + sideArea;
28 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let grid;
  try {
    grid = JSON.parse(userInput);
    if (!Array.isArray(grid) || !grid.every(Array.isArray)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid 2D array (grid)!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function projectionArea is called with grid = ${JSON.stringify(grid)}`,
    { grid }
  );

  let topArea = 0;
  let frontArea = 0;
  let sideArea = 0;
  let rows = grid.length;
  let cols = grid[0].length;
  addTrace(
    executionTrace,
    2,
    `Initialize topArea = ${topArea}, frontArea = ${frontArea}, sideArea = ${sideArea}, rows = ${rows}, cols = ${cols}`,
    { topArea, frontArea, sideArea, rows, cols }
  );

  for (let i = 0; i < rows; i++) {
    let rowMax = 0;
    addTrace(executionTrace, 10, `Start processing row ${i + 1}`, {
      row: grid[i],
    });
    for (let j = 0; j < cols; j++) {
      if (grid[i][j] > 0) {
        topArea++;
        addTrace(
          executionTrace,
          12,
          `grid[${i}][${j}] = ${grid[i][j]} > 0, increment topArea to ${topArea}`,
          { topArea }
        );
      }
      if (grid[i][j] > rowMax) {
        rowMax = grid[i][j];
        addTrace(
          executionTrace,
          13,
          `grid[${i}][${j}] = ${grid[i][j]} > rowMax, update rowMax to ${rowMax}`,
          { rowMax }
        );
      }
    }
    frontArea += rowMax;
    addTrace(
      executionTrace,
      15,
      `Add rowMax ${rowMax} to frontArea, updated frontArea = ${frontArea}`,
      { frontArea }
    );
  }

  for (let j = 0; j < cols; j++) {
    let colMax = 0;
    addTrace(executionTrace, 20, `Start processing column ${j + 1}`, {
      column: j,
    });
    for (let i = 0; i < rows; i++) {
      if (grid[i][j] > colMax) {
        colMax = grid[i][j];
        addTrace(
          executionTrace,
          22,
          `grid[${i}][${j}] = ${grid[i][j]} > colMax, update colMax to ${colMax}`,
          { colMax }
        );
      }
    }
    sideArea += colMax;
    addTrace(
      executionTrace,
      24,
      `Add colMax ${colMax} to sideArea, updated sideArea = ${sideArea}`,
      { sideArea }
    );
  }

  addTrace(
    executionTrace,
    27,
    `Return the sum of areas: topArea + frontArea + sideArea = ${topArea} + ${frontArea} + ${sideArea} = ${topArea + frontArea + sideArea}`,
    { result: topArea + frontArea + sideArea }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Result: ${topArea + frontArea + sideArea}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
