export const problemTitle = `### Understanding and Solving **Arranging Coins** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate how many complete rows of coins can be formed:`,
  `###### **Step 2:** Program to Calculate Complete Rows:`,
  `###### **Step 3:** Input the number of coins to determine the complete rows:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a number \`n\` representing the total number of coins, return the number of **complete rows** of a staircase that can be formed. A staircase consists of \`k\` rows, where the \`i-th\` row contains \`i\` coins.

#### Problem Statement (Elaborated):
Write a program to calculate how many **complete rows** of coins can be arranged in a staircase pattern. For example, given \`n = 5\`, the arrangement would form two complete rows: the first row with 1 coin, and the second row with 2 coins, with 2 coins left over.

#### Testing Examples
1. **Example 1: Input = 5**
   - **Step-by-step**: The first row uses 1 coin, the second row uses 2 coins, and the third row would require 3 coins. Since only 2 coins remain, we stop at 2 complete rows.
2. **Example 2: Input = 8**
   - **Step-by-step**: Rows: 1, 2, 3. The fourth row requires 4 coins, but only 2 are left, so the result is 3 complete rows.
3. **Example 3: Input = 1**
   - **Step-by-step**: Only 1 coin is available, forming 1 complete row.

#### What Does This Problem Involve?
A staircase pattern requires the number of coins in each row to increment by 1. For instance:
- Row 1: 1 coin
- Row 2: 2 coins
- Row 3: 3 coins
- Row \`k\`: \`k\` coins

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate the coins required for each row and stop when they exceed \`n\`.
2. **Mathematical Operations**: Using simple addition and subtraction to count coins.
3. **Conditional Statements**: To handle edge cases like \`n = 0\`.

#### Real-World Concepts Covered
1. **Summation**: Calculating the sum of the first \`k\` numbers.
2. **Iterative Patterns**: Understanding how to iteratively subtract from a total.
3. **Problem-Solving**: Breaking down a mathematical problem into logical steps.

#### How Does the Program Work?
1. Initialize a counter for rows (\`rows\`) and a variable to track remaining coins (\`remainingCoins\`).
2. Deduct coins row by row, starting with 1, then 2, then 3, and so on.
3. Stop when the coins required for the next row exceed the number of remaining coins.
4. Return the count of complete rows.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a **loop** to simulate the subtraction of coins row by row.
2. **Hint 2**: Keep a counter to track the number of complete rows.
3. **Hint 3**: Stop when the coins needed for the next row are greater than the remaining coins.
4. **Hint 4**: Avoid using prebuilt summation formulas to make the problem accessible for beginners.

#### Why Do We Do This?
This problem helps understand **iteration** and **basic mathematical patterns**, enhancing logical reasoning and the ability to translate real-world problems into code.
`;

export const initialAlgorithm = `1. Initialize **rows** = 0 and **remainingCoins** = n.
2. While **remainingCoins** is greater than or equal to **rows + 1**:
   - a. Increment **rows** by 1.
   - b. Deduct **rows** from **remainingCoins**.
3. Return **rows** as the number of complete rows.`;

export const initialProblemCode = `1  function arrangeCoins(n) {
2    let rows = 0;
3    let remainingCoins = n;
4    while (remainingCoins >= rows + 1) {
5      rows += 1;
6      remainingCoins -= rows;
7    }
8    return rows;
9  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function arrangeCoins is called with n = ${n}`, {
    n,
  });

  // Line 2: Initialize rows
  let rows = 0;
  addTrace(executionTrace, 2, `Initialize rows = ${rows}`, { rows });

  // Line 3: Initialize remainingCoins
  let remainingCoins = n;
  addTrace(executionTrace, 3, `Initialize remainingCoins = ${remainingCoins}`, {
    remainingCoins,
  });

  // Line 4: Start while loop
  while (remainingCoins >= rows + 1) {
    addTrace(
      executionTrace,
      4,
      `Check while condition: remainingCoins (${remainingCoins}) >= rows + 1 (${rows + 1}) = ${remainingCoins >= rows + 1}`,
      { remainingCoins, rows }
    );

    // Line 5: Increment rows
    rows += 1;
    addTrace(executionTrace, 5, `Increment rows: rows = ${rows}`, { rows });

    // Line 6: Deduct coins
    let previousRemainingCoins = remainingCoins;
    remainingCoins -= rows;
    addTrace(
      executionTrace,
      6,
      `Deduct rows from remainingCoins: remainingCoins = ${previousRemainingCoins} - ${rows} = ${remainingCoins}`,
      { remainingCoins }
    );
  }

  // Line 8: Return rows
  addTrace(
    executionTrace,
    8,
    `Return rows = ${rows} as the number of complete rows`,
    { rows }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `${n} coins can form ${rows} complete rows.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
