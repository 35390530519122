import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import the Navbar component
import Footer from './Footer.js'; // Import the Footer component
import './Forums.css'; // Import the Forums-specific CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const Forums = ({ hideHeaderFooter }) => {
  // Sample forum topics data with "Coming Soon" status
  const forumTopics = [
    {
      id: 1,
      title: 'Introduction to Full-Stack Development',
      description:
        'Discuss all things related to full-stack development (Coming Soon).',
      posts: 0,
      lastPost: 'N/A',
    },
    {
      id: 2,
      title: 'JavaScript Best Practices',
      description:
        'Share tips and ask questions about JavaScript (Coming Soon).',
      posts: 0,
      lastPost: 'N/A',
    },
    {
      id: 3,
      title: 'React vs. Angular vs. Vue',
      description:
        'Debate the pros and cons of popular front-end frameworks (Coming Soon).',
      posts: 0,
      lastPost: 'N/A',
    },
  ];

  return (
    <div className="d-flex flex-column min-vh-100">
      {!hideHeaderFooter && <Navbar />} {/* Conditionally render Navbar */}
      <Container className="flex-grow-1 mt-5 forums-container">
        <h1 className="text-center mb-4 forums-title">Community Forums</h1>
        <p className="text-center forums-subtitle">
          Our forums are coming soon! Stay tuned for the latest discussions,
          questions, and knowledge sharing.
        </p>
        <Row>
          {forumTopics.map((topic) => (
            <Col md={4} key={topic.id} className="mb-4">
              <Card className="h-100 shadow-sm forum-card">
                <Card.Body>
                  <Card.Title>{topic.title}</Card.Title>
                  <Card.Text>{topic.description}</Card.Text>
                  <p>
                    <strong>Posts:</strong> {topic.posts}
                    <br />
                    <strong>Last Post:</strong> {topic.lastPost}
                  </p>
                  <Button variant="primary" disabled>
                    Coming Soon
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {!hideHeaderFooter && <Footer />} {/* Conditionally render Footer */}
    </div>
  );
};

// Set default prop for hideHeaderFooter
Forums.defaultProps = {
  hideHeaderFooter: false,
};

export default Forums;
