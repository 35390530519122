import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllCoursesPublic } from '../services/api.js';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import CenteredText from './CenteredText.js';
import './FeaturedCourses.css'; // Import the component-specific CSS file

const FeaturedCourses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await getAllCoursesPublic();
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  return (
    <section className="featured-courses mt-5">
      <Container>
        <CenteredText as="h3" centered className="text-center display-4">
          Popular Courses
        </CenteredText>

        <Row className="mt-4">
          {courses.slice(0, 3).map((course) => (
            <Col md={4} key={course.course_id} className="mb-4">
              <Card className="course-card">
                <Card.Img
                  variant="top"
                  src="course-thumbnail.jpg" // Replace with dynamic image if available
                  alt={course.title}
                />
                <Card.Body>
                  <Card.Title>{course.title}</Card.Title>
                  <Card.Text>{course.description}</Card.Text>
                  <Button
                    as={Link}
                    to={`/public/course/${course.course_id}`}
                    variant="primary"
                  >
                    Start Course
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default FeaturedCourses;
