import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import the Navbar component
import Footer from './Footer.js'; // Import the Footer component
import './SuccessStories.css'; // Import the CSS for this component
import 'bootstrap/dist/css/bootstrap.min.css';

const SuccessStories = () => {
  const stories = [
    {
      name: 'Rajesh Kumar K',
      role: 'Back-End Developer at XYZ Corp',
      hike: '100% hike',
      story:
        'The backend development program at HLS was instrumental in doubling my salary. The course was comprehensive, and the real-world projects boosted my confidence to handle complex challenges.',
    },
    // Add more stories as needed
  ];

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar /> {/* Header/Navbar Component */}
      <Container className="flex-grow-1 mt-5 success-stories-container">
        <h1 className="text-center mb-4">Success Stories</h1>
        <p className="text-center mb-5">
          Hear from our learners who transformed their careers with the help of
          our programs.
        </p>

        <Row>
          {stories.map((story, index) => (
            <Col md={6} lg={4} key={index} className="mb-4">
              <Card className="shadow-sm success-story-card">
                <Card.Body>
                  <Card.Title>{story.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {story.role}
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>{story.hike}</strong>
                  </Card.Text>
                  <Card.Text>{story.story}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Placeholder for more stories */}
        <p className="text-center mt-5">
          More success stories will be added as they become available.
        </p>
      </Container>
      <Footer /> {/* Footer Component */}
    </div>
  );
};

export default SuccessStories;
