export const problemTitle = `### Understanding and Solving **Rectangle Overlap** Problem`;
export const initialInputValue = '0,0,2,2 1,1,3,3';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if rectangles overlap:`,
  `###### **Step 2:** Program to Check **Rectangle Overlap**:`,
  `###### **Step 3:** Input rectangles to check if they overlap:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two rectangles represented by their bottom-left and top-right corners, determine if the two rectangles overlap.

#### Problem Statement(Elaborated):
Write a program that determines whether two rectangles overlap. A rectangle is defined by two points:
- Bottom-left corner: \`(x1, y1)\`
- Top-right corner: \`(x2, y2)\`

#### Testing Examples
1. **Example 1: Rectangles [(0,0,2,2), (1,1,3,3)]**
   - **Step-by-step**:
     - Rectangle 1 overlaps Rectangle 2. Return **true**.
2. **Example 2: Rectangles [(0,0,1,1), (1,1,2,2)]**
   - **Step-by-step**:
     - Rectangles only touch at the corner. Return **false**.

#### When Do Two Rectangles Overlap?
Two rectangles overlap if their projections on both the x-axis and y-axis overlap.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: To determine if the projections on x and y overlap.
2. **Mathematical Comparisons**: To evaluate boundaries of the rectangles.

#### Real-World Concepts Covered
1. **Geometry**: Understanding 2D Cartesian coordinates.
2. **Logical Thinking**: Applying logical comparisons to solve geometry problems.

#### How Does the Program Work?
1. Parse the input to extract rectangle coordinates.
2. Compare the boundaries of the rectangles:
   - Check if the rectangles are separated on the x-axis.
   - Check if the rectangles are separated on the y-axis.
3. If they are not separated in either dimension, return **true** for overlap.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use comparisons for the x-axis and y-axis separately.
2. **Hint 2**: Rectangles that only touch but don’t overlap should return **false**.
3. **Hint 3**: Ensure that the input format for rectangles is valid.

#### Why Do We Do This?
This problem demonstrates the application of geometry in computational scenarios, showcasing problem-solving techniques in a spatial context.
`;

export const initialAlgorithm = `1. Parse the input for the coordinates of the two rectangles.
2. For the first rectangle, extract:
   - Bottom-left corner: \`(x1, y1)\`
   - Top-right corner: \`(x2, y2)\`
3. For the second rectangle, extract:
   - Bottom-left corner: \`(x3, y3)\`
   - Top-right corner: \`(x4, y4)\`
4. Check if the rectangles do **not overlap**:
   - If one rectangle is to the left of the other: \`x2 <= x3 || x4 <= x1\`
   - If one rectangle is above the other: \`y2 <= y3 || y4 <= y1\`
5. If they do not overlap, return **false**.
6. Otherwise, return **true**.`;

export const initialProblemCode = `1  function isRectangleOverlap(rect1, rect2) {
2    let [x1, y1, x2, y2] = rect1;
3    let [x3, y3, x4, y4] = rect2;
4    if (x2 <= x3 || x4 <= x1 || y2 <= y3 || y4 <= y1) {
5      return false;
6    }
7    return true;
8  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let rect1, rect2;
  try {
    const rectangles = userInput
      .split(' ')
      .map((r) => r.split(',').map(Number));
    if (rectangles.length !== 2 || rectangles.some((r) => r.length !== 4)) {
      throw new Error();
    }
    [rect1, rect2] = rectangles;
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter two rectangles in the format "x1,y1,x2,y2 x3,y3,x4,y4"!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    2,
    `Function isRectangleOverlap is called with rect1 = ${JSON.stringify(
      rect1
    )} and rect2 = ${JSON.stringify(rect2)}`,
    { rect1, rect2 }
  );

  let [x1, y1, x2, y2] = rect1;
  let [x3, y3, x4, y4] = rect2;
  addTrace(
    executionTrace,
    3,
    `Extracted rectangle 1: (${x1}, ${y1}, ${x2}, ${y2})`,
    { x1, y1, x2, y2 }
  );
  addTrace(
    executionTrace,
    3,
    `Extracted rectangle 2: (${x3}, ${y3}, ${x4}, ${y4})`,
    { x3, y3, x4, y4 }
  );

  if (x2 <= x3 || x4 <= x1) {
    addTrace(
      executionTrace,
      4,
      `Rectangles do not overlap horizontally (x2 <= x3 || x4 <= x1)`,
      { x2, x3, x4, x1 }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Rectangles do not overlap.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  if (y2 <= y3 || y4 <= y1) {
    addTrace(
      executionTrace,
      4,
      `Rectangles do not overlap vertically (y2 <= y3 || y4 <= y1)`,
      { y2, y3, y4, y1 }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Rectangles do not overlap.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  addTrace(executionTrace, 7, `Rectangles overlap. Returning true.`, {
    overlap: true,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: 'Rectangles overlap.',
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
