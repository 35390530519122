import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the MergeSortedArrayDemo component
const problemTitle = `### Understanding and Solving **Merge Sorted Array** Problem`;
const initialInputValue = '1,2,3,0,0,0|3|2,5,6|3'; // Format: nums1 separated by commas | m | nums2 separated by commas | n
const stepsTitles = [
  `###### **Step 1:** Algorithm to **merge two sorted arrays** into **one sorted array**:`,
  `###### **Step 2:** Program to **Merge Sorted Array**:`,
  `###### **Step 3:** Input the **arrays** and their **sizes**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
You are given two **sorted arrays** \`nums1\` and \`nums2\`, along with two integers \`m\` and \`n\` representing the **number of elements** in each array respectively. **Merge nums2 into nums1** such that \`nums1\` becomes a **single sorted array**.

#### Problem Statement (Elaborated):
Write a program that **merges two sorted arrays** into a **single sorted array**. The **first array** \`nums1\` has a size that is **large enough** to hold both \`nums1\` and \`nums2\` (it has **extra space** at the end to accommodate the elements of \`nums2\`). Use this **extra space** to **merge**.

#### Testing Examples
1. **Example 1: Input = nums1 = [1,2,3,0,0,0], m = 3, nums2 = [2,5,6], n = 3**
   - **Step-by-step**: After **merging** both arrays, \`nums1\` becomes **[1,2,2,3,5,6]**.
2. **Example 2: Input = nums1 = [1], m = 1, nums2 = [], n = 0**
   - **Step-by-step**: \`nums2\` is **empty**, so \`nums1\` remains **[1]**.
3. **Example 3: Input = nums1 = [0], m = 0, nums2 = [1], n = 1**
   - **Step-by-step**: After **merging**, \`nums1\` becomes **[1]**.

#### What is the Merge Sorted Array Problem?
The **merge sorted array** problem requires **combining two sorted arrays** such that the **resulting array** is also **sorted**. It tests **logical thinking** and **array manipulation**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate through the arrays**.
2. **Conditional Statements**: Using \`if-else\` to **compare elements** and **determine their order**.
3. **Basic Index Handling**: Understanding how to **place elements** in the **correct positions**.
4. **In-place Modifications**: Modifying an **array** without using **additional space**.

#### Real-World Concepts Covered
1. **Data Combination**: Understanding how to **combine sorted lists**.
2. **Logical Thinking**: Enhancing **problem-solving skills** through **comparison logic**.
3. **Array Manipulation**: Understanding how to **merge and order elements**.

#### How Does the Program Work?
1. **Start from the end** of both arrays.
2. **Compare** the elements from the **back** of \`nums1\` and \`nums2\`.
3. **Place** the **larger element** in the **last available position** of \`nums1\`.
4. **Move the pointers** accordingly until **all elements** are placed.
5. **Fill remaining elements** from \`nums2\` if any.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Start merging **from the end** to avoid **overwriting elements** in \`nums1\`.
2. **Hint 2**: Use **pointers** to **track the positions** in both arrays.
3. **Hint 3**: Handle cases where **one array is empty**.
4. **Hint 4**: Remember to **move elements** into \`nums1\`'s extra space.
5. **Hint 5**: Consider the **order of elements** when placing them.

#### Why Do We Do This?
We **perform this task** to understand how to **merge data** while maintaining **order**. This is useful in scenarios like **merging sorted datasets**.
`;

const initialAlgorithm = `1. Initialize **pointers**: 
   - \`p1\` to \`m - 1\` (last element of the filled part of \`nums1\`)
   - \`p2\` to \`n - 1\` (last element of \`nums2\`)
   - \`p\` to \`m + n - 1\` (last position in \`nums1\`).
2. While \`p1 >= 0\` and \`p2 >= 0\`:
   - a. If \`nums1[p1] > nums2[p2]\`, set \`nums1[p] = nums1[p1]\` and **decrement** \`p1\`.
   - b. Else, set \`nums1[p] = nums2[p2]\` and **decrement** \`p2\`.
   - c. **Decrement** \`p\` after each step.
3. If there are any remaining elements in \`nums2\`, **copy them** to \`nums1\`.
4. The array \`nums1\` is now **merged**.`;

const initialProblemCode = `1  function merge(nums1, m, nums2, n) {
2    let p1 = m - 1;
3    let p2 = n - 1;
4    let p = m + n - 1;
5    while (p1 >= 0 && p2 >= 0) {
6      if (nums1[p1] > nums2[p2]) {
7        nums1[p] = nums1[p1];
8        p1--;
9      } else {
10       nums1[p] = nums2[p2];
11       p2--;
12     }
13     p--;
14   }
15   while (p2 >= 0) {
16     nums1[p] = nums2[p2];
17     p2--;
18     p--;
19   }
20 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the MergeSortedArrayDemo
const MergeSortedArrayDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let nums1, nums2, m, n;
    try {
      const [nums1Str, mStr, nums2Str, nStr] = numberInput.split('|');
      nums1 = nums1Str.split(',').map((str) => parseInt(str.trim(), 10));
      m = parseInt(mStr.trim(), 10);
      nums2 = nums2Str.split(',').map((str) => parseInt(str.trim(), 10));
      n = parseInt(nStr.trim(), 10);

      if (
        nums1.some(isNaN) ||
        nums2.some(isNaN) ||
        isNaN(m) ||
        isNaN(n) ||
        nums1.length < m + n
      ) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter numbers and sizes in the format "1,2,3,0,0,0|3|2,5,6|3".',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function merge is called with nums1 = [${nums1}], m = ${m}, nums2 = [${nums2}], n = ${n}`,
      { nums1, m, nums2, n }
    );

    let p1 = m - 1;
    let p2 = n - 1;
    let p = m + n - 1;

    addTrace(
      executionTrace,
      2,
      `Initialize pointers: p1 = ${p1}, p2 = ${p2}, p = ${p}`,
      { p1, p2, p }
    );

    while (p1 >= 0 && p2 >= 0) {
      addTrace(
        executionTrace,
        5,
        `Comparing nums1[p1] (${nums1[p1]}) and nums2[p2] (${nums2[p2]})`,
        { p1, p2, nums1, nums2 }
      );

      if (nums1[p1] > nums2[p2]) {
        nums1[p] = nums1[p1];
        p1--;
        addTrace(
          executionTrace,
          7,
          `nums1[p1] is larger. Place nums1[p] = ${nums1[p]}. Decrement p1 to ${p1}.`,
          { nums1, p1, p }
        );
      } else {
        nums1[p] = nums2[p2];
        p2--;
        addTrace(
          executionTrace,
          10,
          `nums2[p2] is larger or equal. Place nums1[p] = ${nums1[p]}. Decrement p2 to ${p2}.`,
          { nums1, p2, p }
        );
      }
      p--;
      addTrace(executionTrace, 13, `Decrement p to ${p}.`, { p });
    }

    while (p2 >= 0) {
      nums1[p] = nums2[p2];
      p2--;
      p--;
      addTrace(
        executionTrace,
        15,
        `Copy remaining nums2 elements to nums1. nums1[p] = ${nums1[p + 1]}`,
        { nums1, p2, p }
      );
    }

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Merged array: [${nums1}].`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default MergeSortedArrayDemo;
