import React from 'react';
import Navbar from '../../components/Navbar.js';
import HeroSection from '../../components/HeroSection.js';
import FeaturedCourses from '../../components/FeaturedCourses.js';
import Benefits from '../../components/Benefits.js';
import Testimonials from '../../components/Testimonials.js';
import Pricing from '../../components/Pricing.js';
import Footer from '../../components/Footer.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import CareerPaths from '../../components/CareerPaths.js';

const Homepage = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <HeroSection />
      <CareerPaths />
      <FeaturedCourses />
      <Benefits />
      <Testimonials />
      <Pricing hideHeaderFooter={true} />
      <Footer />
    </div>
  );
};

export default Homepage;
