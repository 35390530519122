export const problemTitle = `### Understanding and Solving **Palindrome Number Using Mathematics** Problem`;
export const initialInputValue = '121';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a number is a **palindrome using mathematics**:`,
  `###### **Step 2:** Program to Check **Palindrome Number** Using Mathematics:`,
  `###### **Step 3:** Input a number to check if it is a **palindrome**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`x\`, return \`true\` if \`x\` is a **palindrome**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
Write a program that **checks** whether a given **integer** is a **palindrome** using purely **mathematical operations**. The program should **take an integer as input** and **determine** if it **reads the same** from **front to back**. Note that **negative numbers** are **not considered palindromes** because the **negative sign** will **not match** when **read backward**.

#### Testing Examples
1. **Example 1: Input = 121**
   - **Step-by-step**: The **reversed version** of **121** is also **121**. Therefore, the **output** is **true**, indicating that **121** is a **palindrome**.
2. **Example 2: Input = -121**
   - **Step-by-step**: Since **-121** is a **negative number**, it **cannot be a palindrome**. Therefore, the **output** is **false**.
3. **Example 3: Input = 123**
   - **Step-by-step**: The **reversed version** of **123** is **321**, which is **different** from the **original number**. Therefore, the **output** is **false**.

#### What is a Palindrome Number?
A **palindrome number** is a **number** that **reads the same** **backward** as **forward**. In other words, when you **reverse the digits** of the number, it **remains unchanged**. For example, **121** is a **palindrome** because when you look at it **backward**, it is still **121**. However, **123** is **not a palindrome** because, when **reversed**, it becomes **321**, which is **different**.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to **handle conditions** like **negative numbers** and **comparing values**.
2. **Loops**: To **reverse the number**, we may need to **extract digits** using a **loop**.
3. **Mathematical Operations**: Using the **modulo** (\`%\`) operator to **extract digits** and **division** (\`/\`) to **manipulate numbers**.
4. **Edge Case Handling**: Understanding how to **handle input** like \`0\` and **negative numbers**.

#### How Does the Program Work?
1. If the **number is negative**, it is **not a palindrome** (e.g., -121).
2. Make a **copy** of the number and **reverse** it mathematically.
3. Keep **extracting the last digit** of the **original number** and **add** it to the **reversed number** in reverse order.
4. When done, **compare** the **original number** to the **reversed number**. If they are **identical**, the number is a **palindrome**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the **modulo operator** (\`%\`) to **get the last digit** of a number. For example, \`123 % 10\` gives \`3\`.
2. **Hint 2**: Use **integer division** (\`Math.floor()\`) to **remove the last digit** from a number. For example, \`Math.floor(123 / 10)\` gives \`12\`.
3. **Hint 3**: Remember that **reversing the number** involves **constructing a new number** **digit by digit**, not simply **reversing** the digits in place.
4. **Hint 4**: Don’t forget to **handle edge cases** like \`0\`, which is technically a **palindrome** since it **reads the same backward and forward**.
`;

export const initialAlgorithm = `1. If the **number** is **negative**, it is **not a palindrome**.
2. Initialize **variables**:
   - **originalNumber** = x
   - **reversedNumber** = 0
3. While **x > 0**:
   - a. **Extract** the **last digit**: **digit** = x % 10
   - b. **Append digit** to **reversedNumber**: **reversedNumber** = **reversedNumber** * 10 + **digit**
   - c. **Remove last digit** from x: **x** = **floor(x / 10)**
4. **Compare** **originalNumber** and **reversedNumber**:
   - If they are **equal**, the number is a **palindrome**.
   - **Else**, it is **not a palindrome**.`;

export const initialProblemCode = `1  function isPalindromeUsingMath(x) {
2    if (x < 0) return false;
3    let originalNumber = x;
4    let reversedNumber = 0;
5    while (x > 0) {
6      let digit = x % 10;
7      reversedNumber = reversedNumber * 10 + digit;
8      x = Math.floor(x / 10);
9    }
10   return originalNumber === reversedNumber;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let x;
  try {
    x = parseInt(userInput, 10);
    if (isNaN(x)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isPalindromeUsingMath is called with x = ${x}`,
    {
      x,
    }
  );

  // Line 2: Check if x is negative
  addTrace(executionTrace, 2, `Check if x is negative (${x} < 0) = ${x < 0}`, {
    x,
  });
  if (x < 0) {
    addTrace(executionTrace, 2, `x is negative (${x} < 0), return false`, {
      x,
    });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${x} is not a palindrome.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let originalNumber = x;
  let reversedNumber = 0;
  // Line 3: Initialize originalNumber
  addTrace(executionTrace, 3, `Initialize originalNumber = ${originalNumber}`, {
    originalNumber,
  });

  // Line 4: Initialize reversedNumber
  addTrace(executionTrace, 4, `Initialize reversedNumber = ${reversedNumber}`, {
    reversedNumber,
  });

  // Line 5: Start of while loop
  while (x > 0) {
    addTrace(
      executionTrace,
      5,
      `Check while condition: x (${x}) > 0 = ${x > 0}`,
      { x }
    );

    // Line 6: Calculate digit
    let digit = x % 10;
    addTrace(
      executionTrace,
      6,
      `Calculate digit = x % 10 = ${x} % 10 = ${digit}`,
      { x, digit }
    );

    // Line 7: Update reversedNumber
    let previousReversedNumber = reversedNumber;
    reversedNumber = reversedNumber * 10 + digit;
    addTrace(
      executionTrace,
      7,
      `Update reversedNumber: reversedNumber = ${previousReversedNumber} * 10 + ${digit} = ${reversedNumber}`,
      { reversedNumber }
    );

    // Line 8: Update x
    let previousX = x;
    x = Math.floor(x / 10);
    addTrace(
      executionTrace,
      8,
      `Update x = floor(x / 10) = floor(${previousX} / 10) = ${x}`,
      { x }
    );
  }

  // Line 10: Compare originalNumber and reversedNumber
  addTrace(
    executionTrace,
    10,
    `Compare originalNumber (${originalNumber}) and reversedNumber (${reversedNumber}). Return true if they are equal, otherwise return false: ${originalNumber === reversedNumber}`,
    { originalNumber, reversedNumber }
  );

  let isPalin = originalNumber === reversedNumber;
  let resultMessage = isPalin
    ? `${originalNumber} is a palindrome.`
    : `${originalNumber} is not a palindrome.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
