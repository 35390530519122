export const problemTitle = `### Understanding and Solving **Available Captures for Rook** Problem`;
export const initialInputValue = `[
  [".",".",".",".",".",".",".","."],
  [".",".",".","p",".",".",".","."],
  [".",".",".","R",".",".",".","p"],
  [".",".",".",".",".",".",".","."],
  [".",".",".",".",".",".",".","."],
  [".",".",".","p",".",".",".","."],
  [".",".",".",".",".",".",".","."],
  [".",".",".",".",".",".",".","."]
]`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find available captures for the rook:`,
  `###### **Step 2:** Program to Calculate Available Captures:`,
  `###### **Step 3:** Input a chessboard matrix:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an 8x8 chessboard grid \`board\`, determine the number of **available captures** for a rook ('R'). A capture is when the rook can move horizontally or vertically to a position occupied by a pawn ('p') without being blocked by any bishops ('B') or going out of bounds.

#### Problem Statement(Elaborated):
Write a program that takes an 8x8 chessboard grid and calculates how many pawns ('p') can be captured by the rook ('R'). The rook can only move in straight lines (horizontally or vertically), and its movement stops if it encounters a bishop ('B') or the edge of the board.

#### Testing Examples
1. **Example 1: Input =** 
\`\`\`
[
  [".",".",".",".",".",".",".","."],
  [".",".",".","p",".",".",".","."],
  [".",".",".","R",".",".",".","p"],
  [".",".",".",".",".",".",".","."],
  [".",".",".",".",".",".",".","."],
  [".",".",".","p",".",".",".","."],
  [".",".",".",".",".",".",".","."],
  [".",".",".",".",".",".",".","."]
]
\`\`\`
   - **Step-by-step**:
     - The rook can move up, down, left, and right from position \`(2,3)\`.
     - Captures pawns at \`(1,3)\` and \`(5,3)\`.
     - Total captures = 2.

2. **Example 2: Input =**
\`\`\`
[
  [".",".",".",".",".",".",".","."],
  [".","p","p","p","p","p",".","."],
  [".","p","p","B","p","p",".","."],
  [".","p","B","R","B","p",".","."],
  [".","p","p","B","p","p",".","."],
  [".","p","p","p","p","p",".","."],
  [".",".",".",".",".",".",".","."],
  [".",".",".",".",".",".",".","."]
]
\`\`\`
   - **Step-by-step**:
     - The rook at \`(3,3)\` is blocked by bishops ('B') in all directions.
     - Total captures = 0.

#### What is the Available Captures for a Rook?
The **available captures** for a rook are the number of pawns ('p') it can capture based on its movement rules.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Using loops to traverse the chessboard matrix.
2. **Conditional Statements**: To handle movement, capturing, and blocking logic.
3. **Indexing**: Understanding and working with 2D matrix indices.
4. **Edge Case Handling**: Ensuring correct results for blocked paths and edge-of-board scenarios.

#### Real-World Concepts Covered
1. **Game Simulations**: Simulating game rules on a chessboard grid.
2. **Logical Thinking**: Translating movement rules into code logic.
3. **Matrix Operations**: Traversing and analyzing 2D grids for specific patterns.

#### How Does the Program Work?
1. Find the position of the rook ('R') on the chessboard.
2. Traverse horizontally and vertically from the rook's position.
3. Count pawns ('p') that can be captured, stopping at bishops ('B') or board edges.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to locate the rook's position.
2. **Hint 2**: Traverse in all four directions (up, down, left, right) to calculate captures.
3. **Hint 3**: Stop traversing a direction when you encounter a bishop ('B') or go out of bounds.
4. **Hint 4**: Ensure you do not double-count or go beyond the board's edges.
5. **Hint 5**: Carefully handle cases with no rook or pawns on the board.

#### Why Do We Do This?
This problem helps in understanding grid-based simulations and how rules can be implemented in programming. It is fundamental to solving chess-related problems or board-game logic.`;

export const initialAlgorithm = `1. Find the position of the rook ('R') on the board:
   - Use nested loops to iterate through the grid.
2. Traverse the grid in four directions (up, down, left, right):
   - For each direction, move step by step until you encounter:
     a. A pawn ('p'): Increment the capture count and stop moving in that direction.
     b. A bishop ('B'): Stop moving in that direction.
     c. The edge of the board: Stop moving in that direction.
3. Return the total capture count.`;

export const initialProblemCode = `1  function numRookCaptures(board) {
2    let rookRow = -1, rookCol = -1;
3    let captures = 0;
4
5    // Locate the rook ('R')
6    for (let i = 0; i < 8; i++) {
7      for (let j = 0; j < 8; j++) {
8        if (board[i][j] === 'R') {
9          rookRow = i;
10         rookCol = j;
11         break;
12       }
13     }
14     if (rookRow !== -1) break;
15   }
16
17   // Define directions (up, down, left, right)
18   const directions = [
19     [-1, 0], // up
20     [1, 0],  // down
21     [0, -1], // left
22     [0, 1]   // right
23   ];
24
25   // Traverse in all directions
26   for (let [dx, dy] of directions) {
27     let x = rookRow;
28     let y = rookCol;
29     while (x >= 0 && x < 8 && y >= 0 && y < 8) {
30       x += dx;
31       y += dy;
32       if (x < 0 || x >= 8 || y < 0 || y >= 8) break;
33       if (board[x][y] === 'B') break; // Stop at bishop
34       if (board[x][y] === 'p') {
35         captures++;
36         break; // Stop after capturing a pawn
37       }
38     }
39   }
40
41   return captures;
42 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let board;
  try {
    board = JSON.parse(userInput);
    if (
      !Array.isArray(board) ||
      board.length !== 8 ||
      !board.every((row) => Array.isArray(row) && row.length === 8)
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid 8x8 chessboard matrix!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function numRookCaptures is called with board = ${JSON.stringify(board)}`,
    { board }
  );

  let rookRow = -1,
    rookCol = -1;
  let captures = 0;
  addTrace(
    executionTrace,
    2,
    `Initialize rookRow = ${rookRow}, rookCol = ${rookCol}, captures = ${captures}`,
    { rookRow, rookCol, captures }
  );

  for (let i = 0; i < 8; i++) {
    for (let j = 0; j < 8; j++) {
      if (board[i][j] === 'R') {
        rookRow = i;
        rookCol = j;
        addTrace(
          executionTrace,
          9,
          `Found rook at position (${rookRow}, ${rookCol})`,
          { rookRow, rookCol }
        );
        break;
      }
    }
    if (rookRow !== -1) break;
  }

  const directions = [
    [-1, 0], // up
    [1, 0], // down
    [0, -1], // left
    [0, 1], // right
  ];
  addTrace(executionTrace, 17, `Define directions for traversal`, {
    directions,
  });

  for (let [dx, dy] of directions) {
    let x = rookRow;
    let y = rookCol;
    addTrace(
      executionTrace,
      26,
      `Start traversing direction dx = ${dx}, dy = ${dy}`,
      { dx, dy }
    );
    while (x >= 0 && x < 8 && y >= 0 && y < 8) {
      x += dx;
      y += dy;
      if (x < 0 || x >= 8 || y < 0 || y >= 8) break;
      if (board[x][y] === 'B') {
        addTrace(
          executionTrace,
          33,
          `Encountered bishop at position (${x}, ${y}), stop traversal`,
          { x, y }
        );
        break;
      }
      if (board[x][y] === 'p') {
        captures++;
        addTrace(
          executionTrace,
          35,
          `Captured pawn at position (${x}, ${y}), increment captures to ${captures}`,
          { captures }
        );
        break;
      }
    }
  }

  addTrace(executionTrace, 41, `Return total captures = ${captures}`, {
    captures,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Result: ${captures}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
