import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the ValidPalindromeDemo component
const problemTitle = `### Understanding and Solving **Valid Palindrome** Problem`;
const initialInputValue = '"A man, a plan, a canal: Panama"';
const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a **string** is a **valid palindrome**:`,
  `###### **Step 2:** Program to Check **Valid Palindrome**:`,
  `###### **Step 3:** Input a string to check if it is a **valid palindrome**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string \`s\`, determine if it is a **palindrome**, considering only **alphanumeric characters** and ignoring **cases**.

#### Problem Statement (Elaborated):
Write a program that **checks** whether a given **string** is a **palindrome** after **removing non-alphanumeric characters** and **ignoring cases**. A **palindrome** is a string that **reads the same** forward and backward.

#### Testing Examples
1. **Example 1: Input = "A man, a plan, a canal: Panama"**
   - **Step-by-step**: After removing non-alphanumeric characters and converting to lowercase, the string becomes \`"amanaplanacanalpanama"\`, which reads the same forward and backward.
2. **Example 2: Input = "race a car"**
   - **Step-by-step**: After removing non-alphanumeric characters and converting to lowercase, the string becomes \`"raceacar"\`, which does not read the same forward and backward.

#### What is a Valid Palindrome?
A **valid palindrome** is a **string** that **remains the same** when **read backward** after **ignoring non-alphanumeric characters** and **case differences**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate** through the characters of the string.
2. **Conditional Statements**: Using \`if-else\` to **filter out non-alphanumeric characters**.
3. **Two-Pointer Technique**: To **compare characters** from both ends.

#### Real-World Concepts Covered
1. **Text Processing**: Understanding how to **clean up** and **analyze text**.
2. **String Manipulation**: Learning to **modify** and **compare strings**.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **character checks**.

#### How Does the Program Work?
1. Use two **pointers**, one starting at the **beginning** and the other at the **end** of the string.
2. **Skip non-alphanumeric characters** using **conditional checks**.
3. **Compare characters** at both pointers while **ignoring case**.
4. If **all characters match**, the string is a **valid palindrome**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the **two-pointer technique** to compare characters from both ends.
2. **Hint 2**: Use conditions to **skip non-alphanumeric characters**.
3. **Hint 3**: Convert characters to **lowercase** before comparing.

#### Why Do We Do This?
We **perform this check** to determine if a **string reads the same** when cleaned of **non-essential characters**, similar to how some **words or phrases** can be **read backward and forward**.
`;

const initialAlgorithm = `1. Initialize two **pointers**, **left** at **0** and **right** at **s.length - 1**.
2. Use a loop to **iterate** while **left < right**:
   - a. **Skip non-alphanumeric** characters at **left**.
   - b. **Skip non-alphanumeric** characters at **right**.
   - c. **Compare** the characters at **left** and **right** after **converting them to lowercase**.
   - d. If they **do not match**, return **false**.
   - e. If they **match**, move **left** pointer **right** and **right** pointer **left**.
3. If the loop completes, return **true** as all characters matched.
`;

const initialProblemCode = `1  function isValidPalindrome(s) {
2    let left = 0;
3    let right = s.length - 1;
4    while (left < right) {
5      while (left < right && !isAlphanumeric(s[left])) {
6        left++;
7      }
8      while (left < right && !isAlphanumeric(s[right])) {
9        right--;
10     }
11     if (toLowerCase(s[left]) !== toLowerCase(s[right])) {
12       return false;
13     }
14     left++;
15     right--;
16   }
17   return true;
18 }
19 
20 function isAlphanumeric(char) {
21   return (char >= 'A' && char <= 'Z') ||
22          (char >= 'a' && char <= 'z') ||
23          (char >= '0' && char <= '9');
24 }
25 
26 function toLowerCase(char) {
27   if (char >= 'A' && char <= 'Z') {
28     return String.fromCharCode(char.charCodeAt(0) + 32);
29   }
30   return char;
31 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component (same as PalindromeNumberDemo)
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

const ValidPalindromeDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);

  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };
      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const isAlphanumeric = (char) => {
    return (
      (char >= 'A' && char <= 'Z') ||
      (char >= 'a' && char <= 'z') ||
      (char >= '0' && char <= '9')
    );
  };

  const toLowerCase = (char) => {
    if (char >= 'A' && char <= 'Z') {
      return String.fromCharCode(char.charCodeAt(0) + 32);
    }
    return char;
  };

  const handleRunClick = useCallback(() => {
    let s;
    try {
      s = JSON.parse(numberInput);
      if (typeof s !== 'string') {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: 'Invalid input: Please enter a valid string!',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function isValidPalindrome is called with s = "${s}"`,
      { s }
    );

    let left = 0;
    let right = s.length - 1;

    addTrace(
      executionTrace,
      2,
      `Initialize pointers: left = ${left}, right = ${right}`,
      { left, right }
    );

    while (left < right) {
      while (left < right && !isAlphanumeric(s[left])) {
        left++;
        addTrace(
          executionTrace,
          5,
          `Skipping non-alphanumeric character at left: left = ${left}`,
          { left, char: s[left] }
        );
      }

      while (left < right && !isAlphanumeric(s[right])) {
        right--;
        addTrace(
          executionTrace,
          8,
          `Skipping non-alphanumeric character at right: right = ${right}`,
          { right, char: s[right] }
        );
      }

      const leftChar = toLowerCase(s[left]);
      const rightChar = toLowerCase(s[right]);

      addTrace(
        executionTrace,
        11,
        `Comparing leftChar = "${leftChar}" with rightChar = "${rightChar}"`,
        { leftChar, rightChar, left, right }
      );

      if (leftChar !== rightChar) {
        addTrace(
          executionTrace,
          12,
          `Characters do not match, returning false`,
          {}
        );
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `The string is not a valid palindrome.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }

      left++;
      right--;

      addTrace(
        executionTrace,
        14,
        `Characters match, moving to next: left = ${left}, right = ${right}`,
        { left, right }
      );
    }

    addTrace(executionTrace, 17, `All characters matched, returning true`, {});

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The string is a valid palindrome.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default ValidPalindromeDemo;
