export const problemTitle = `### Understanding and Solving **Plus One Using Mathematics** Problem`;
export const initialInputValue = '123';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to implement the **plus one** functionality using mathematics:`,
  `###### **Step 2:** Program to Implement **Plus One** Using Mathematics:`,
  `###### **Step 3:** Input a number to add **one** to it:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer represented as a sequence of digits, add one to the integer without using arrays or other data structures to store digits.

#### Problem Statement(Elaborated):
Write a program that **takes an integer** and **adds one to it** using only **mathematical operations**. The solution should not rely on converting the integer into a string or using any data structures like arrays.

#### Testing Examples
1. **Example 1: Input = 123**
   - **Step-by-step**: Adding **1** to **123** results in **124**. Therefore, the **output** is **124**.
2. **Example 2: Input = 999**
   - **Step-by-step**: Adding **1** to **999** causes a carry, resulting in **1000**. Therefore, the **output** is **1000**.
3. **Example 3: Input = 0**
   - **Step-by-step**: Adding **1** to **0** results in **1**. Therefore, the **output** is **1**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **process each digit** and handle cases like **carryovers**.
2. **Mathematical Operations**: Using modulo (\`%\`) and division (\`/\`) to extract and process digits.
3. **Conditional Statements**: To **handle carryovers** when a digit exceeds 9.
4. **Edge Case Handling**: Managing scenarios where the number results in additional digits, like going from **999** to **1000**.

#### Real-World Concepts Covered
1. **Incrementing Numbers**: Simulating the operation of adding one to a sequence of digits, as seen in devices like odometers.
2. **Carryover Logic**: Understanding how carry propagates in addition.
3. **Digit Manipulation**: Using arithmetic to process numbers digit by digit.

#### How Does the Program Work?
1. Initialize variables to **track the result** and **carry**.
2. Extract digits from the **rightmost side** of the number.
3. Add **one to the last digit** and handle the **carry** if the result exceeds 9.
4. Continue processing digits until there are no more digits left.
5. If there’s a carry at the end, **prepend** it to the final result.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use modulo (\`%\`) to extract the last digit of the number.
2. **Hint 2**: Use integer division (\`Math.floor()\`) to remove the last digit.
3. **Hint 3**: Handle carryover logic carefully when adding one.
4. **Hint 4**: Consider the edge case when the input consists of only 9s (e.g., \`999\` becomes \`1000\`).
`;

export const initialAlgorithm = `1. Initialize variables:
   - **number** = input
   - **result** = 0
   - **place** = 1 (to keep track of the place value)
   - **carry** = 1 (to add one to the number)
2. While **number > 0** or **carry > 0**:
   - a. Extract the last digit: **digit** = number % 10
   - b. Add the carry: **sum** = digit + carry
   - c. Update the carry: **carry** = sum > 9 ? 1 : 0
   - d. Append the resulting digit to the result: **result** += (sum % 10) * place
   - e. Update the place value: **place** *= 10
   - f. Remove the last digit from the number: **number** = Math.floor(number / 10)
3. Return the **result**.`;

export const initialProblemCode = `1  function plusOne(number) {
2    let result = 0;
3    let place = 1;
4    let carry = 1; // Start with carry to add 1
5    while (number > 0 || carry > 0) {
6      let digit = number % 10;
7      let sum = digit + carry;
8      carry = sum > 9 ? 1 : 0;
9      result += (sum % 10) * place;
10     place *= 10;
11     number = Math.floor(number / 10);
12   }
13   return result;
14 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let number;
  try {
    number = parseInt(userInput, 10);
    if (isNaN(number)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function plusOne is called with number = ${number}`,
    { number }
  );

  let result = 0;
  let place = 1;
  let carry = 1; // Initialize carry to add one

  addTrace(executionTrace, 2, `Initialize result = 0, place = 1, carry = 1`, {
    result,
    place,
    carry,
  });

  while (number > 0 || carry > 0) {
    addTrace(
      executionTrace,
      5,
      `Check while condition: number (${number}) > 0 || carry (${carry}) > 0 = ${number > 0 || carry > 0}`,
      { number, carry }
    );

    let digit = number % 10;
    addTrace(
      executionTrace,
      6,
      `Extract last digit: digit = number % 10 = ${number} % 10 = ${digit}`,
      { digit }
    );

    let sum = digit + carry;
    addTrace(
      executionTrace,
      7,
      `Add carry to digit: sum = digit + carry = ${digit} + ${carry} = ${sum}`,
      { sum }
    );

    carry = sum > 9 ? 1 : 0;
    addTrace(
      executionTrace,
      8,
      `Update carry: carry = sum > 9 ? 1 : 0 = ${carry}`,
      { carry }
    );

    result += (sum % 10) * place;
    addTrace(
      executionTrace,
      9,
      `Update result: result += (sum % 10) * place = ${result}`,
      { result }
    );

    place *= 10;
    addTrace(executionTrace, 10, `Update place: place *= 10 = ${place}`, {
      place,
    });

    number = Math.floor(number / 10);
    addTrace(
      executionTrace,
      11,
      `Remove last digit: number = Math.floor(number / 10) = ${number}`,
      { number }
    );
  }

  addTrace(executionTrace, 13, `Return result: ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The result of adding one to ${userInput} is ${result}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
