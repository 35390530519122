export const problemTitle = `### Understanding and Solving **Pascal's Triangle** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to generate Pascal's Triangle:`,
  `###### **Step 2:** Program to Generate Pascal's Triangle:`,
  `###### **Step 3:** Input the number of rows:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, return the first \`n\` rows of Pascal's Triangle. Each row of Pascal's Triangle is generated based on the sum of the two numbers directly above it in the previous row.

#### Problem Statement(Elaborated):
Write a program that **takes an integer as input** and generates the first \`n\` rows of Pascal's Triangle. Each row starts and ends with \`1\`, and each number in between is the sum of the two numbers directly above it in the previous row.

#### Testing Examples
1. **Example 1: Input = 3**
   - **Output**: 
     - Row 1: [1]
     - Row 2: [1, 1]
     - Row 3: [1, 2, 1]
2. **Example 2: Input = 5**
   - **Output**: 
     - Row 1: [1]
     - Row 2: [1, 1]
     - Row 3: [1, 2, 1]
     - Row 4: [1, 3, 3, 1]
     - Row 5: [1, 4, 6, 4, 1]

#### What is Pascal's Triangle?
Pascal's Triangle is a triangular arrangement of numbers where:
1. The first and last numbers in each row are \`1\`.
2. Each number in between is the sum of the two numbers directly above it.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Generating each row of the triangle iteratively.
2. **Mathematical Operations**: Adding numbers to generate rows.
3. **Dynamic Programming Basics**: Using the previous row to compute the current row.

#### Real-World Concepts Covered:
1. **Binomial Coefficients**: Pascal's Triangle is closely related to combinations in mathematics.
2. **Iterative Patterns**: Learning to generate patterns based on iterative logic.
3. **Logical Thinking**: Enhancing problem-solving skills through structured loops.

#### How Does the Program Work?
1. Start with the first row as \`[1]\`.
2. For each subsequent row, use the previous row to generate the next one by summing adjacent numbers.
3. Continue until the desired number of rows is generated.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Each row starts and ends with \`1\`.
2. **Hint 2**: For elements in between, sum adjacent numbers from the previous row.
3. **Hint 3**: Use a nested loop to iterate over rows and elements within rows.

#### Why Do We Do This?
Pascal's Triangle helps understand patterns in binomial coefficients and combinations, which are fundamental concepts in mathematics and computer science.
`;

export const initialAlgorithm = `1. Initialize the first row of Pascal's Triangle as \`[1]\`.
2. Loop from 2 to \`n\` (the number of rows to generate):
   - a. Start a new row with \`1\` (the first element).
   - b. For each element in the row (except the last one), calculate the sum of two adjacent elements from the previous row.
   - c. End the row with \`1\` (the last element).
3. Store the generated row in the triangle.
4. Repeat until all rows are generated.`;

export const initialProblemCode = `1  function generatePascalsTriangle(n) {
2    if (n <= 0) return []; // No rows to generate
3    let triangle = [[1]]; // Start with the first row
4    for (let i = 1; i < n; i++) {
5      let previousRow = triangle[i - 1]; // Get the previous row
6      let newRow = [1]; // Start the new row with 1
7      for (let j = 1; j < previousRow.length; j++) {
8        let value = previousRow[j - 1] + previousRow[j]; // Sum two adjacent elements
9        newRow.push(value); // Add the calculated value to the new row
10      }
11      newRow.push(1); // End the row with 1
12      triangle.push(newRow); // Add the new row to the triangle
13    }
14    return triangle; // Return the complete triangle
15 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer greater than 0!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function generatePascalsTriangle is called with n = ${n}`,
    { n }
  );

  if (n === 1) {
    addTrace(executionTrace, 2, `Base case: Only one row, return [[1]]`, {
      triangle: [[1]],
    });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Pascal's Triangle with 1 row: [[1]]`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let triangle = [[1]];
  addTrace(executionTrace, 3, `Initialize triangle with first row: [[1]]`, {
    triangle,
  });

  for (let i = 1; i < n; i++) {
    let previousRow = triangle[i - 1];
    let newRow = [1];
    addTrace(
      executionTrace,
      5,
      `Generate row ${i + 1}: Start new row with [1]`,
      { newRow }
    );

    for (let j = 1; j < previousRow.length; j++) {
      let value = previousRow[j - 1] + previousRow[j];
      newRow.push(value);
      addTrace(
        executionTrace,
        8,
        `Calculate value for row ${i + 1}, position ${j}: ${previousRow[j - 1]} + ${previousRow[j]} = ${value}`,
        { value, newRow }
      );
    }

    newRow.push(1);
    addTrace(
      executionTrace,
      11,
      `Complete row ${i + 1} by adding 1 at the end: ${newRow}`,
      { newRow }
    );

    triangle.push(newRow);
    addTrace(executionTrace, 12, `Add completed row ${i + 1} to triangle`, {
      triangle,
    });
  }

  addTrace(
    executionTrace,
    14,
    `Return the complete Pascal's Triangle with ${n} rows`,
    { triangle }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Pascal's Triangle with ${n} rows generated successfully.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
