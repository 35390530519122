export const problemTitle = `### Understanding and Solving **X of a Kind in a Deck of Cards** Problem`;
export const initialInputValue = '1,2,3,4,4,3,2,1';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine if all cards can be grouped in **X of a Kind**:`,
  `###### **Step 2:** Program to Check **X of a Kind in a Deck of Cards**:`,
  `###### **Step 3:** Input the card deck to check if it can be grouped:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a deck of cards represented as an array \`deck\`, determine if it is possible to group the cards such that each group has exactly \`X\` cards of the same rank, where \`X >= 2\`.

#### Problem Statement(Elaborated):
You need to check if there exists a positive integer \`X\` such that the deck can be divided into groups where:
- Each group contains \`X\` cards.
- All cards in each group have the same value.

#### Testing Examples
1. **Example 1: Input = [1,2,3,4,4,3,2,1]**
   - **Step-by-step**:
     - Frequencies of cards: {1:2, 2:2, 3:2, 4:2}.
     - Greatest Common Divisor (GCD) of all frequencies = 2.
     - \`X = 2\` is valid.
   - **Output**: true.
2. **Example 2: Input = [1,1,1,2,2,2,3,3]**
   - **Step-by-step**:
     - Frequencies of cards: {1:3, 2:3, 3:2}.
     - GCD of all frequencies = 1.
     - No valid \`X >= 2\`.
   - **Output**: false.

#### What Does "X of a Kind" Mean?
\`X of a Kind\` means that all cards in a group are of the same rank, and the size of the group is \`X\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Counting card frequencies.
2. **Mathematical Operations**: Calculating the Greatest Common Divisor (GCD).
3. **Conditionals**: Verifying if the GCD satisfies the condition.

#### Real-World Concepts Covered
1. **Optimization**: Analyzing patterns using GCD to identify valid groupings.
2. **Logical Thinking**: Dividing sets into equal partitions based on rules.

#### How Does the Program Work?
1. Parse the input to extract the deck of cards.
2. Count the frequency of each card.
3. Compute the GCD of all frequencies.
4. Check if the GCD is greater than or equal to 2.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a loop to count frequencies manually instead of built-in functions.
2. **Hint 2**: GCD calculation can be done iteratively without recursion.
3. **Hint 3**: If the GCD of frequencies is 1, no grouping is possible.

#### Why Do We Do This?
This problem teaches optimization using mathematical techniques like GCD to solve grouping problems efficiently.
`;

export const initialAlgorithm = `1. Parse the input to extract the deck of cards.
2. Initialize an array to count the frequency of each card.
3. Loop through the deck and count frequencies manually.
4. Calculate the GCD of all frequencies:
   - Start with the first frequency.
   - For each subsequent frequency, calculate the GCD iteratively.
5. If the GCD is greater than or equal to 2, return true.
6. Otherwise, return false.`;

export const initialProblemCode = `1  function hasGroupsSizeX(deck) {
2    let freq = {};
3    
4    for (let i = 0; i < deck.length; i++) {
5      let card = deck[i];
6      if (freq[card] === undefined) {
7        freq[card] = 1;
8      } else {
9        freq[card]++;
10      }
11    }
12    
13    let gcd = -1;
14    for (let card in freq) {
15      let count = freq[card];
16      if (gcd === -1) {
17        gcd = count;
18      } else {
19        while (count !== 0) {
20          let temp = gcd % count;
21          gcd = count;
22          count = temp;
23        }
24      }
25    }
26    
27    return gcd >= 2;
28 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let deck;
  try {
    deck = userInput.split(',').map(Number);
    if (deck.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid deck of integers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function hasGroupsSizeX is called with deck = [${deck}]`,
    {
      deck,
    }
  );

  let freq = {};
  for (let i = 0; i < deck.length; i++) {
    let card = deck[i];
    if (freq[card] === undefined) {
      freq[card] = 1;
    } else {
      freq[card]++;
    }
    addTrace(
      executionTrace,
      4,
      `Processing card ${card}: freq = ${JSON.stringify(freq)}`,
      { freq }
    );
  }

  let gcd = -1;
  for (let card in freq) {
    let count = freq[card];
    if (gcd === -1) {
      gcd = count;
    } else {
      while (count !== 0) {
        let temp = gcd % count;
        gcd = count;
        count = temp;
      }
    }
    addTrace(executionTrace, 14, `Card ${card}: Current GCD = ${gcd}`, { gcd });
  }

  let result = gcd >= 2;
  addTrace(
    executionTrace,
    27,
    `Final check: GCD = ${gcd}. Can group? ${result}`,
    { gcd, result }
  );

  let resultMessage = result
    ? `The deck can be grouped into X of a kind.`
    : `The deck cannot be grouped into X of a kind.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
