import React from 'react';
import { Row } from 'react-bootstrap';
import CenteredText from './CenteredText.js';
import './LoadPDF.css'; // Import the component-specific CSS

const LoadPDF = ({ title, fileName }) => (
  <Row className="mt-5">
    <CenteredText as="h3" centered className="pdf-title">
      {title}
    </CenteredText>
    <div className="pdf-container">
      <iframe
        src={fileName}
        width="100%"
        height="100%"
        allow="autoplay"
        title={title}
        className="pdf-iframe"
      ></iframe>
    </div>
  </Row>
);

export default LoadPDF;
