import React, { useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  ListGroup,
  Card,
  Accordion,
  Modal,
  Button,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { filesParentPath, jsConcepts, ppsConcepts } from './docsData.js';
import LoopsQuiz from '../feedComponents/Docs/JavaScript/Quizzes/LoopsQuiz.js';

const extractLanguage = (node) => {
  const className = node?.properties?.className || [];
  const languageClass = className.find((cls) => cls.startsWith('language-'));
  return languageClass ? languageClass.replace('language-', '') : null;
};

const CodeRenderer = ({ node, disablePreview, children }) => {
  const [executionResult, setExecutionResult] = useState(null);
  const [copied, setCopied] = useState(false);

  const codeText = children || ''; // Extract code text from children
  const language = extractLanguage(node); // Extract language from node properties
  const isBlockCode = language != null; // Block code check

  const executeJavaScript = (code) => {
    try {
      const func = new Function(code); // Create a new function from the code
      const result = func();
      console.log(`result function: ${result}`);
      return result === undefined ? 'No output' : String(result);
    } catch (error) {
      return `Error: ${error.message}`;
    }
  };

  const handleRunCode = (code) => {
    try {
      const result = executeJavaScript(code); // Use eval to run the code (Only for trusted inputs)
      console.log(`result: ${result}`);
      return result === undefined ? 'No output' : String(result);
    } catch (error) {
      return `Error: ${error.message}`;
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
  };

  const handleExecute = () => {
    const result = handleRunCode(codeText);
    setExecutionResult(result);
  };

  // For inline code, render without the "Copy Code" and "Run" buttons
  if (!isBlockCode) {
    return <code>{codeText}</code>;
  }

  return (
    <div style={{ position: 'relative', marginBottom: '1em' }}>
      <CopyToClipboard text={codeText} onCopy={handleCopy}>
        <button
          style={{
            position: 'absolute',
            top: '5px',
            right: '80px',
            padding: '5px',
            backgroundColor: copied ? 'green' : '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '0.9em',
            zIndex: 1,
            borderRadius: '5px',
          }}
        >
          {copied ? 'Copied!' : 'Copy Code'}
        </button>
      </CopyToClipboard>
      <button
        onClick={handleExecute}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          padding: '5px',
          backgroundColor: '#28a745',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '0.9em',
          zIndex: 1,
          borderRadius: '5px',
        }}
      >
        Run
      </button>
      <pre>
        <code className={`language-${language}`}>{codeText}</code>
      </pre>
      {executionResult && !disablePreview && (
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#f8f9fa',
            border: '1px solid #ddd',
            borderRadius: '5px',
          }}
        >
          <strong>Output:</strong> {executionResult}
        </div>
      )}
    </div>
  );
};

const ConceptsList = ({ topicName }) => {
  const [concepts, setConcepts] = useState([]);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [markdownContent, setMarkdownContent] = useState('');
  const [disablePreview, setDisablePreview] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [ModalComponent, setModalComponent] = useState(null); // Component to load in the modal

  const loadMarkdownFile = async (conceptName) => {
    try {
      console.log(`conceptName: ${conceptName}`);
      let modifiedConceptName = conceptName.replace(/[^a-zA-Z0-9]/g, '_');
      console.log(`modifiedConceptName: ${modifiedConceptName}`);

      console.log(
        `Loading markdown for concept File PATH: /hls/docs/${filesParentPath[topicName]}/${modifiedConceptName}.md`
      );
      const response = await fetch(
        `/hls/docs/${filesParentPath[topicName]}/${modifiedConceptName}.md`
      );
      if (!response.ok) {
        throw new Error(`Failed to load ${conceptName}.md`);
      }
      const content = await response.text();
      setMarkdownContent(content);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConceptClick = (concept) => {
    console.log(`Clicked on concept: ${JSON.stringify(concept, null, 4)}`);
    loadMarkdownFile(concept.name);
    setSelectedConcept(concept);
  };

  useEffect(() => {
    console.log(`topicName: ${topicName}`);
    if (topicName === 'JavaScript') {
      setConcepts(jsConcepts);
      // Automatically select the first topic
      if (jsConcepts.length > 0) {
        const firstChapter = jsConcepts[0];
        if (firstChapter.sections.length > 0) {
          const firstSection = firstChapter.sections[0];
          if (firstSection.topics && firstSection.topics.length > 0) {
            const firstTopic = firstSection.topics[0];
            setSelectedConcept(firstTopic);
            loadMarkdownFile(firstTopic.name);
          }
        }
      }
    } else if (topicName === 'PROGRAMMING FOR PROBLEM SOLVING') {
      setConcepts(ppsConcepts);
      // Automatically select the first topic
      if (ppsConcepts.length > 0) {
        const firstChapter = ppsConcepts[0];
        if (firstChapter.sections.length > 0) {
          const firstSection = firstChapter.sections[0];
          if (firstSection.topics && firstSection.topics.length > 0) {
            const firstTopic = firstSection.topics[0];
            setSelectedConcept(firstTopic);
            loadMarkdownFile(firstTopic.name);
          }
        }
      }
    }
  }, [topicName]);

  const handleOpenMemorizingModal = () => {
    console.log(`selectedConcept: ${JSON.stringify(selectedConcept, null, 4)}`);
    // Dynamically load the component you want to display in the modal
    if (selectedConcept.name === 'Loops') {
      setModalComponent(() => LoopsQuiz);
      setShowModal(true);
    } else {
      alert(`Quiz Loading for **${selectedConcept.name}**...`);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalComponent(null); // Clear the modal component
  };

  // Memoize renderChaptersAndSections to avoid unnecessary re-renders
  const renderChaptersAndSections = useCallback(() => {
    console.log('Rendering Chapters and Sections:', concepts); // Log the entire concepts array

    return concepts.map((chapter, chapterIndex) => {
      console.log(`Rendering Chapter ${chapterIndex + 1}:`, chapter);

      return (
        <Accordion key={chapterIndex} defaultActiveKey={0}>
          <Accordion.Item eventKey={chapterIndex.toString()}>
            <Accordion.Header>{chapter.name}</Accordion.Header>
            <Accordion.Body>
              {chapter.sections.map((section, sectionIndex) => {
                console.log(
                  `Rendering Section ${sectionIndex + 1} of Chapter ${
                    chapterIndex + 1
                  }:`,
                  section
                );

                // Check if section.topics is defined and is an array
                if (!section.topics || !Array.isArray(section.topics)) {
                  console.warn(
                    `Section ${
                      sectionIndex + 1
                    } of Chapter ${chapterIndex + 1} has no valid topics:`,
                    section
                  );
                  return (
                    <div
                      key={sectionIndex}
                      style={{ color: 'red', fontStyle: 'italic' }}
                    >
                      No topics available.
                    </div>
                  );
                }

                return (
                  <Accordion key={sectionIndex}>
                    <Accordion.Item
                      eventKey={`${chapterIndex}-${sectionIndex}`}
                    >
                      <Accordion.Header>{section.name}</Accordion.Header>
                      <Accordion.Body>
                        <ListGroup>
                          {section.topics.map((topic, topicIndex) => {
                            console.log(
                              `Rendering Topic ${topicIndex + 1} of Section ${
                                sectionIndex + 1
                              }, Chapter ${chapterIndex + 1}:`,
                              topic
                            );

                            return (
                              <ListGroup.Item
                                key={topic.topic_id}
                                action
                                onClick={() => handleConceptClick(topic)}
                                active={
                                  selectedConcept?.topic_id === topic.topic_id
                                }
                                className="text-dark"
                              >
                                {topic.topic_id ? topic.topic_id + ': ' : ''}
                                {topic.name}
                              </ListGroup.Item>
                            );
                          })}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    });
  }, [concepts, selectedConcept]);

  const renderers = {
    code: ({ node, children }) => (
      <CodeRenderer node={node} disablePreview={disablePreview}>
        {children}
      </CodeRenderer>
    ),
  };

  return (
    <>
      <Row style={{ height: '85vh' }}>
        {/* Sidebar: List of Concepts */}
        <Col
          md={3}
          className="border-end"
          style={{
            height: '100%',
            overflowY: 'auto',
            backgroundColor: '#f8f9fa',
          }}
        >
          <h3 className="mb-3 text-primary">Concepts</h3>
          {renderChaptersAndSections()}
          <div className="mt-3">
            <button
              onClick={() => setDisablePreview(!disablePreview)}
              className="btn btn-outline-secondary btn-sm w-100"
            >
              {disablePreview
                ? 'Enable Output Preview'
                : 'Disable Output Preview'}
            </button>
          </div>
        </Col>

        {/* Details Section */}
        <Col
          md={9}
          style={{
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {markdownContent ? (
            <Card>
              <Card.Body>
                <Card.Title className="text-primary d-flex justify-content-between align-items-center">
                  {selectedConcept?.name}
                  <Button
                    variant="info"
                    size="sm"
                    onClick={handleOpenMemorizingModal} // Open modal on click
                  >
                    Quiz
                  </Button>
                </Card.Title>
                <ReactMarkdown components={renderers}>
                  {markdownContent || 'No description available'}
                </ReactMarkdown>
              </Card.Body>
            </Card>
          ) : (
            <div className="text-center mt-5">
              <h4 className="text-secondary">
                Select a concept to view its details
              </h4>
            </div>
          )}
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedConcept?.name} - Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {ModalComponent ? (
            <ModalComponent /> // Dynamically loaded component
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConceptsList;
