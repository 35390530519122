import { ProgressBar, Row, Col } from 'react-bootstrap';
import {
  getCareerPathNextActivity,
  getCourseNextActivity,
  getCourseResumeActivity,
} from '../services/api.js';

export const DisplayActivityProgress = ({ progress, activity }) => {
  console.log(`activity: ${JSON.stringify(activity, null, 4)}`);
  // Retrieve progress data based on activity_id
  let progressData;
  if (activity.data) {
    progressData = activity?.data?.title ? progress[activity.title] || {} : {};
  } else {
    progressData = activity?.title ? progress[activity.title] || {} : {};
  }

  // console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);

  const totalSteps = progressData.total_steps_count || 0;
  const completedSteps = progressData.completed_steps_count || 0;
  const activityProgressScore = (completedSteps / totalSteps) * 100 || 0;

  // const courseProgressScore = progressData.final_progress_score || 0;

  if (completedSteps > 0) {
    console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);
  }

  return (
    <Row className="align-items-center">
      <Col>
        <ProgressBar
          now={activityProgressScore}
          label={`${activityProgressScore.toFixed(0)}%`} // Display whole number
          className="mt-2"
        />
      </Col>
      {totalSteps > 0 && (
        <Col xs="auto">
          <p className="mb-0">
            Steps: {completedSteps}/{totalSteps} completed
          </p>
        </Col>
      )}
    </Row>
  );
};

export const DisplayCareerPathActivityProgress = ({ progress, activity }) => {
  console.log(`activity: ${JSON.stringify(activity, null, 4)}`);
  console.log(`progress: ${JSON.stringify(progress, null, 4)}`);
  // Retrieve progress data based on activity_id
  let progressData;
  if (activity.data) {
    progressData = activity?.data?.title ? progress[activity.title] || {} : {};
  } else {
    progressData = activity?.title ? progress[activity.title] || {} : {};
  }

  // console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);

  const totalSteps = progressData.total_steps_count || 0;
  const completedSteps = progressData.completed_steps_count || 0;
  const activityProgressScore = (completedSteps / totalSteps) * 100 || 0;

  // const courseProgressScore = progressData.final_progress_score || 0;

  if (completedSteps > 0) {
    console.log(`progressData: ${JSON.stringify(progressData, null, 4)}`);
  }

  return (
    <Row className="align-items-center">
      <Col>
        <ProgressBar
          now={activityProgressScore}
          label={`${activityProgressScore.toFixed(0)}%`} // Display whole number
          className="mt-2"
        />
      </Col>
      {totalSteps > 0 && (
        <Col xs="auto">
          <p className="mb-0">
            Steps: {completedSteps}/{totalSteps} completed
          </p>
        </Col>
      )}
    </Row>
  );
};

export const handleCourseResume = async (navigate, courseId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await getCourseResumeActivity(token, courseId);

    const activityId = response.data?.activity?.activity_id || null;

    navigate(`/course/${courseId}/activity/${activityId}`);
  } catch (error) {
    console.error('Error fetching activity to resume:', error);
    navigate(`/course/${courseId}/activity/null`);
  }
};

export const handleCourseNextActivity = async (
  navigate,
  careerPathId,
  courseId,
  sectionId,
  activityType,
  activityId
) => {
  try {
    console.log(`Next: ${activityId}, careerPathId: ${careerPathId}`);
    // debugger;
    const token = localStorage.getItem('token');
    if (careerPathId) {
      const response = await getCareerPathNextActivity(
        token,
        careerPathId,
        courseId,
        sectionId,
        activityType,
        activityId
      );

      console.log(`response: ${JSON.stringify(response, null, 4)}`);

      if (response.data?.nextActivity === null) {
        navigate(`/career-path/${careerPathId}`);
      } else {
        const redirectActivityType =
          response.data?.nextActivity?.activity_type || null;
        const redirectSectionId =
          response.data?.nextActivity?.sectionId || null;
        const redirectActivityId = response.data?.nextActivity?.order || null;
        const redirectCourseId = response.data?.nextActivity?.courseId || null;

        navigate(
          `/career-path/${careerPathId}/${redirectCourseId}/${redirectSectionId}/activity/${redirectActivityType}/${redirectActivityId}`
        );
      }
    } else {
      const response = await getCourseNextActivity(
        token,
        courseId,
        sectionId,
        activityType,
        activityId
      );

      console.log(`response: ${JSON.stringify(response, null, 4)}`);

      if (response.data?.nextActivity === null) {
        navigate(`/course/${courseId}`);
      } else {
        const redirectActivityType =
          response.data?.nextActivity?.activity_type || null;
        const redirectSectionId =
          response.data?.nextActivity?.sectionId || null;
        const redirectActivityId = response.data?.nextActivity?.order || null;

        navigate(
          `/course/${courseId}/${redirectSectionId}/activity/${redirectActivityType}/${redirectActivityId}`
        );
      }
    }
  } catch (error) {
    console.error('Error fetching activity to resume:', error);
    navigate(`/course/${courseId}/activity/null`);
  }
};

export const handleCoursePreviousActivity = async (
  navigate,
  careerPathId,
  courseId,
  sectionId,
  activityType,
  activityId
) => {
  try {
    const token = localStorage.getItem('token');
    if (careerPathId) {
      const response = await getCareerPathNextActivity(
        token,
        careerPathId,
        courseId,
        sectionId,
        activityType,
        activityId
      );

      console.log(`response: ${JSON.stringify(response, null, 4)}`);

      if (response.data?.nextActivity === null) {
        navigate(`/career-path/${careerPathId}`);
      } else {
        const redirectActivityType =
          response.data?.previousActivity?.activity_type || null;
        const redirectSectionId =
          response.data?.previousActivity?.sectionId || null;
        const redirectActivityId =
          response.data?.previousActivity?.order || null;
        const redirectCourseId =
          response.data?.previousActivity?.courseId || null;

        navigate(
          `/career-path/${careerPathId}/${redirectCourseId}/${redirectSectionId}/activity/${redirectActivityType}/${redirectActivityId}`
        );
      }
    } else {
      const response = await getCourseNextActivity(
        token,
        courseId,
        sectionId,
        activityType,
        activityId
      );

      console.log(`response: ${JSON.stringify(response, null, 4)}`);

      if (response.data?.previousActivity === null) {
        alert('This is the Courses first activity.');
      } else {
        const redirectActivityType =
          response.data?.previousActivity?.activity_type || null;
        const redirectSectionId =
          response.data?.previousActivity?.sectionId || null;
        const redirectActivityId =
          response.data?.previousActivity?.order || null;

        navigate(
          `/course/${courseId}/${redirectSectionId}/activity/${redirectActivityType}/${redirectActivityId}`
        );
      }
    }
  } catch (error) {
    console.error('Error fetching activity to resume:', error);
    navigate(`/course/${courseId}/activity/null`);
  }
};
