import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './HeroSection.css'; // Import the component-specific CSS file

const HeroSection = () => {
  return (
    <Container className="hero-section">
      <Card className="p-5 mb-5 shadow-lg">
        <h1 className="display-3">Learn to Code with HLS</h1>
        <p className="lead mb-4">
          Accelerate your learning with interactive, AI-powered experiences and
          real-world projects through our hands-on Learn by Doing methodology.
        </p>
        <Button
          as={Link}
          to="/register"
          variant="primary"
          size="lg"
          className="mb-4"
        >
          Get Started
        </Button>
      </Card>

      <Row className="mt-5 text-left">
        <Col md={4}>
          <Card className="p-4 mb-4 shadow-sm">
            <Card.Body>
              <h3>Interactive Learning</h3>
              <p>
                Master coding skills through hands-on projects, exercises, and
                interactive lessons that adapt to your learning pace.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-4 mb-4 shadow-sm">
            <Card.Body>
              <h3>Guided Career Paths</h3>
              <p>
                Choose from structured career paths to help you become a
                Front-End Developer, Full-Stack Engineer, or Data Scientist.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="p-4 mb-4 shadow-sm">
            <Card.Body>
              <h3>Learn by Doing</h3>
              <p>
                Apply what you learn immediately through real-world projects,
                coding challenges, and portfolio-building tasks.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="text-left">
        <Col md={6}>
          <Card className="p-4 mb-4 shadow-sm">
            <Card.Body>
              <h3>Community Support</h3>
              <p>
                Join a vibrant community of learners and mentors who can help
                you when you get stuck and motivate you to keep going.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="p-4 mb-4 shadow-sm">
            <Card.Body>
              <h3>Job-Ready Skills</h3>
              <p>
                Learn the skills needed to start your career in tech, including
                the latest tools and technologies demanded by employers.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Card className="p-5 mt-5 shadow-lg bg-primary text-white text-center">
        <h2>Be Part of Our Growing Community of Learners</h2>
        <p className="lead mb-4">
          Join a rapidly expanding network of learners and educators who are
          transforming their skills and careers with cutting-edge, personalized
          education.
        </p>

        <Button as={Link} to="/register" variant="light" size="lg">
          Start Your Learning Journey
        </Button>
      </Card>
    </Container>
  );
};

export default HeroSection;
