import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import './BadgeManagement.css'; // Import the CSS file

const BadgeManagement = () => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    fetchBadges();
  }, []);

  const fetchBadges = async () => {
    try {
      const response = await axios.get('/api/badges');
      setBadges(response.data);
    } catch (error) {
      console.error('Error fetching badges:', error);
    }
  };

  const handleDelete = async (badgeId) => {
    try {
      await axios.delete(`/api/badges/${badgeId}`);
      fetchBadges(); // Refresh the list
    } catch (error) {
      console.error('Error deleting badge:', error);
    }
  };

  return (
    <div className="badge-management-container">
      <h2 className="badge-management-title">Badge Management</h2>
      <Table striped bordered hover className="badge-management-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {badges.map((badge, index) => (
            <tr key={badge.id}>
              <td>{index + 1}</td>
              <td>{badge.title}</td>
              <td>{badge.description}</td>
              <td>
                <Button
                  variant="danger"
                  className="badge-management-delete-btn"
                  onClick={() => handleDelete(badge.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default BadgeManagement;
