import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import your Navbar component
import Footer from './Footer.js'; // Import your Footer component
import CenteredText from './CenteredText.js';
import './Contact.css'; // Import the component-specific CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
  return (
    <div className="contact-page d-flex flex-column min-vh-100">
      <Navbar /> {/* Add the Navbar at the top */}
      <Container className="mt-5 flex-grow-1">
        <Row className="mb-4 text-center">
          <Col>
            <CenteredText as="h1" centered className="display-4 contact-title">
              Contact Us
            </CenteredText>
            <CenteredText as="p" centered className="lead contact-subtitle">
              We&apos;d love to hear from you! Whether you have a question about
              our courses, pricing, or anything else, our team is ready to
              answer all your questions.
            </CenteredText>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-4">
            <CenteredText
              as="h3"
              centered={false}
              className="mb-4 contact-heading"
            >
              Get in Touch
            </CenteredText>
            <Card className="shadow-sm contact-info-card">
              <Card.Body>
                <p>
                  <strong>Email:</strong> happylearningsolutions8@gmail.com
                </p>
                <p>
                  <strong>Phone:</strong> +91 (991) 217-8555 / +91 (988)
                  879-9934
                </p>
                <p>
                  <strong>Address:</strong> Emjal, Abdullapurmet(M), Hyderabad
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <CenteredText
              as="h3"
              centered={false}
              className="mb-4 contact-heading"
            >
              Send Us a Message
            </CenteredText>
            <Card className="shadow-sm contact-form-card p-4">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScCpiXMGhPtCdfVYeVjVG-0Z9ZLnTfbI9KPynwTRdfg0ZLVoA/viewform?embedded=true"
                width="640"
                height="1049"
              >
                Loading…
              </iframe>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Add the Footer at the bottom */}
    </div>
  );
};

export default Contact;
