import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteCourse, getAllCourses } from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import './CourseManagement.css'; // Import the updated CSS file for dark mode

const CourseManagement = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalVariant, setModalVariant] = useState('success'); // For styling modal
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await getAllCourses(token);
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setModalMessage('Error fetching courses.');
      setModalVariant('danger');
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = (course) => {
    setCourseToDelete(course);
    setShowConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await deleteCourse(token, courseToDelete.course_id);
      setModalMessage(`Course "${courseToDelete.title}" deleted successfully!`);
      setModalVariant('success');
      setShowModal(true);
      setShowConfirmDelete(false);
      await fetchCourses(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting course:', error);
      setModalMessage('Failed to delete course.');
      setModalVariant('danger');
      setShowModal(true);
    }
  };

  return (
    <>
      <Navbar />
      <Container className="course-management-container">
        {/* Loading Indicator */}
        {loading && (
          <div className="text-center my-5">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {/* Success/Error Notification Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header
            closeButton
            className={`bg-${modalVariant} text-white`} // Dynamic background color
          >
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant={modalVariant === 'success' ? 'primary' : 'danger'}
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirm Delete Modal */}
        <Modal
          show={showConfirmDelete}
          onHide={() => setShowConfirmDelete(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the course &quot;
            {courseToDelete?.title}&quot;?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmDelete(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {!loading && (
          <>
            <h2>Course Management</h2>
            <Button
              variant="success"
              className="mb-3"
              onClick={() => navigate('/admin/courses/new')}
            >
              Create New Course
            </Button>
            <Table striped bordered hover className="course-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Difficulty</th>
                  <th>Instructor</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course, index) => (
                  <tr key={course.course_id}>
                    <td>{index + 1}</td>
                    <td>{course.title}</td>
                    <td>{course.category}</td>
                    <td>{course.difficulty}</td>
                    <td>{course.instructor?.name || 'Unknown'}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={() =>
                          navigate(`/admin/courses/edit/${course.course_id}`)
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => confirmDelete(course)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Container>
    </>
  );
};

export default CourseManagement;
