import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getCourseById, getLessonById } from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Breadcrumb,
  ListGroup,
  Form,
  Spinner,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Footer from '../../components/Footer.js';
import './CourseContentPage.css'; // Import the CSS file

const CourseContentPage = () => {
  const { courseId, lessonId } = useParams();
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    const fetchCourseAndLessonDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const courseResponse = await getCourseById(token, courseId);
        setCourse(courseResponse.data);

        const lessonResponse = await getLessonById(token, lessonId);
        setLesson(lessonResponse.data);
      } catch (error) {
        console.error(
          'Error fetching course or lesson details:',
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchCourseAndLessonDetails();
  }, [courseId, lessonId]);

  const handleNavigation = (direction) => {
    // Logic to navigate to the next or previous lesson
    if (direction === 'previous') {
      // Navigate to previous lesson
    } else if (direction === 'next') {
      // Navigate to next lesson
    }
  };

  if (!course || !lesson) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: '80vh' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container fluid className="d-flex flex-column course-content-page">
      <Navbar />

      <Container className="flex-grow-1 py-3">
        <Row className="mb-3">
          <Col>
            <h2>{course.title}</h2>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item as={Link} to="/dashboard">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item as={Link} to={`/course/${courseId}`}>
                {course.title}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{lesson.title}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h3>{lesson.title}</h3>
            <div>Progress</div>
          </Col>
        </Row>
        <Card className="mb-4 lesson-card">
          <Card.Body>
            <div className="video-content mb-3">
              <iframe
                width="100%"
                height="400"
                src={lesson.video_url}
                title="Lesson Video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <Card.Text>{lesson.description}</Card.Text>
          </Card.Body>
        </Card>
        <section className="interactive-exercises mb-4">
          <h3>Interactive Exercises/Quiz</h3>
          <Card className="exercise-card">
            <Card.Body>
              <Card.Title>Exercise/Question Title</Card.Title>
              <Form>
                <Form.Group controlId="interactive-area">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Type your answer here..."
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </section>
        <Row className="d-flex justify-content-between mb-4">
          <Col>
            <Button
              variant="secondary"
              onClick={() => handleNavigation('previous')}
            >
              Previous Lesson
            </Button>
          </Col>
          <Col className="text-end">
            <Button
              variant="secondary"
              onClick={() => handleNavigation('next')}
            >
              Next Lesson
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <h4>Course Syllabus</h4>
            <ListGroup>
              {course.syllabus.map((lesson) => (
                <ListGroup.Item key={lesson.id} className="syllabus-item">
                  {lesson.title}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4>Resources</h4>
            <ListGroup>
              {course.resources.map((resource, index) => (
                <ListGroup.Item key={index} className="resource-item">
                  <a
                    href={resource.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resource.title}
                  </a>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={4}>
            <h4>Notes</h4>
            <ListGroup>
              {course.notes.map((note, index) => (
                <ListGroup.Item key={index} className="note-item">
                  {note}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Container>
  );
};

export default CourseContentPage;
