import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Workspaces.css'; // Import the CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

const Workspaces = () => {
  return (
    <Container fluid className="mt-5 workspaces-container">
      <Row>
        <Col>
          <h3 className="workspace-title">Workspaces</h3>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={6} className="mb-3">
          <Card className="workspace-card">
            <Card.Body>
              <Card.Title as="h5" className="workspace-card-title">
                Workspace 1
              </Card.Title>
              <Card.Text className="workspace-card-text">
                Details about workspace 1
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-3">
          <Card className="workspace-card">
            <Card.Body>
              <Card.Title as="h5" className="workspace-card-title">
                Workspace 2
              </Card.Title>
              <Card.Text className="workspace-card-text">
                Details about workspace 2
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Workspaces;
