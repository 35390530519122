export const problemTitle = `### Understanding and Solving **Nim Game** Problem`;
export const initialInputValue = '4';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine the winner of the **Nim Game**:`,
  `###### **Step 2:** Program to Solve **Nim Game**:`,
  `###### **Step 3:** Input the number of stones to check the winner:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You are playing the Nim Game with your friend. There is a heap of stones, and you take turns removing 1 to 3 stones. The person who removes the last stone wins. Determine if you can win the game if you always play optimally, assuming you start first.

#### Problem Statement(Elaborated):
Write a program that takes an integer representing the number of stones and determines if the first player can guarantee a win.

#### Testing Examples
1. **Example 1: Input = 4**
   - **Step-by-step**: 
     - If the first player takes 1, 2, or 3 stones, the second player can always take the remaining stones and win.
     - The first player cannot guarantee a win.
     - Result: **false**.
2. **Example 2: Input = 1**
   - **Step-by-step**: 
     - The first player takes the only stone and wins.
     - Result: **true**.

#### What is the Nim Game?
The **Nim Game** is a mathematical strategy game where the players take turns removing stones from a heap. The player forced to take the last stone loses, unless they are playing optimally and can force a win.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to determine win/lose conditions.
2. **Modulus Operation**: Using the modulo operator (\`%\`) to check divisibility.

#### Real-World Concepts Covered
1. **Game Theory**: Understanding optimal moves and strategy.
2. **Logical Thinking**: Applying mathematical reasoning to make decisions.

#### How Does the Program Work?
1. If the number of stones is divisible by 4, the first player cannot win.
2. Otherwise, the first player can guarantee a win by making optimal moves.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: If \`n % 4 == 0\`, the second player can always win.
2. **Hint 2**: Otherwise, the first player can always leave a multiple of 4 stones for the second player to lose.
`;

export const initialAlgorithm = `1. Check if the number of stones (\`n\`) is divisible by 4.
2. If \`n % 4 == 0\`, return \`false\` (the first player cannot win).
3. Otherwise, return \`true\` (the first player can win).`;

export const initialProblemCode = `1  function canWinNim(n) {
2    if (n % 4 === 0) return false;
3    return true;
4  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 1) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function canWinNim is called with n = ${n}`, {
    n,
  });

  // Line 2: Check if n % 4 === 0
  let isDivisibleByFour = n % 4 === 0;
  addTrace(
    executionTrace,
    2,
    `Check if n is divisible by 4: n % 4 == 0 = ${n} % 4 == 0 = ${isDivisibleByFour}`,
    { n, isDivisibleByFour }
  );

  if (isDivisibleByFour) {
    addTrace(executionTrace, 2, `Since n % 4 == 0, return false`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `With ${n} stones, the first player cannot guarantee a win.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Return true
  addTrace(executionTrace, 3, `Since n % 4 != 0, return true`, { n });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `With ${n} stones, the first player can guarantee a win.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
