export const problemTitle = `### Understanding and Solving **Transpose Matrix** Problem`;
export const initialInputValue = '[[1,2,3],[4,5,6],[7,8,9]]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the transpose of a matrix:`,
  `###### **Step 2:** Program to Transpose a Matrix:`,
  `###### **Step 3:** Input a matrix to transpose:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D array \`matrix\`, return its **transpose**. The transpose of a matrix is produced by **swapping rows and columns**. 

#### Problem Statement(Elaborated):
Write a program that takes a **2D array (matrix)** and produces its **transpose**. For a matrix with dimensions **m x n**, the resulting matrix will have dimensions **n x m**. The element at \`matrix[i][j]\` will be placed at \`result[j][i]\`.

#### Testing Examples
1. **Example 1: Input = [[1,2,3],[4,5,6],[7,8,9]]**
   - **Step-by-step**:
     - Swap rows and columns: \`[[1,4,7],[2,5,8],[3,6,9]]\`.
     - Result: \`[[1,4,7],[2,5,8],[3,6,9]]\`.
2. **Example 2: Input = [[1,2],[3,4],[5,6]]**
   - **Step-by-step**:
     - Swap rows and columns: \`[[1,3,5],[2,4,6]]\`.
     - Result: \`[[1,3,5],[2,4,6]]\`.

#### What is the Transpose of a Matrix?
The transpose of a matrix is the matrix obtained by swapping its rows and columns.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Using **nested loops** to iterate over the matrix rows and columns.
2. **Indexing**: Understanding and working with **matrix indices**.
3. **Matrix Initialization**: Creating a new matrix dynamically using loops.
4. **Edge Case Handling**: What happens with **empty matrices** or **single-element matrices**.

#### Real-World Concepts Covered
1. **Matrix Operations**: Common in linear algebra and data transformations.
2. **Data Reorganization**: Transposing is similar to **pivoting** in data analysis.
3. **Logical Thinking**: Breaking the problem into simpler steps (row-to-column mapping).

#### How Does the Program Work?
1. Create a **result matrix** with dimensions **n x m** where \`n\` is the number of columns and \`m\` is the number of rows in the original matrix.
2. Iterate through each **element** of the original matrix.
3. Swap each element \`matrix[i][j]\` to the position \`result[j][i]\`.
4. Return the transposed matrix.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use two nested loops to traverse the original matrix.
2. **Hint 2**: Initialize a new matrix dynamically to store the transposed result.
3. **Hint 3**: The row index of the original matrix becomes the column index in the transposed matrix, and vice versa.
4. **Hint 4**: Carefully handle edge cases like empty matrices or single-row matrices.
5. **Hint 5**: Avoid using advanced JavaScript methods for simplicity.

#### Why Do We Do This?
This transformation helps in understanding **matrix operations**, which are fundamental in mathematics, computer graphics, and machine learning.`;

export const initialAlgorithm = `1. Create a **result matrix** with dimensions \`n x m\` where \`n\` is the number of columns in the original matrix and \`m\` is the number of rows.
2. Iterate through the **original matrix** using nested loops:
   - a. For each element at position \`matrix[i][j]\`, set \`result[j][i] = matrix[i][j]\`.
3. Return the **result matrix**.`;

export const initialProblemCode = `1  function transposeMatrix(matrix) {
2    let rows = matrix.length;
3    let cols = matrix[0].length;
4    let result = [];
5    for (let i = 0; i < cols; i++) {
6      result[i] = [];
7      for (let j = 0; j < rows; j++) {
8        result[i][j] = matrix[j][i];
9      }
10    }
11    return result;
12 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let matrix;
  try {
    matrix = JSON.parse(userInput);
    if (!Array.isArray(matrix) || !matrix.every(Array.isArray)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid 2D array (matrix)!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function transposeMatrix is called with matrix = ${JSON.stringify(matrix)}`,
    { matrix }
  );

  let rows = matrix.length;
  let cols = matrix[0].length;
  addTrace(executionTrace, 2, `Initialize rows = ${rows}, cols = ${cols}`, {
    rows,
    cols,
  });

  let result = [];
  addTrace(executionTrace, 4, `Initialize result matrix as an empty array`, {
    result,
  });

  for (let i = 0; i < cols; i++) {
    result[i] = [];
    addTrace(executionTrace, 6, `Initialize result[${i}] as an empty row`, {
      result,
    });
    for (let j = 0; j < rows; j++) {
      result[i][j] = matrix[j][i];
      addTrace(
        executionTrace,
        8,
        `Set result[${i}][${j}] = matrix[${j}][${i}] = ${matrix[j][i]}`,
        { currentValue: result[i][j] }
      );
    }
  }

  addTrace(
    executionTrace,
    11,
    `Return the transposed matrix: ${JSON.stringify(result)}`,
    { result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Result: ${JSON.stringify(result)}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
