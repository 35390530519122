import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the RomanToIntegerDemo component
const problemTitle = `### Understanding and Solving **Roman to Integer** Problem`;
const initialInputValue = 'III';
const stepsTitles = [
  `###### **Step 1:** Algorithm to convert **Roman Numerals** to **Integer**:`,
  `###### **Step 2:** Program to Convert **Roman to Integer**:`,
  `###### **Step 3:** Input a Roman numeral to convert it to an **integer**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a **Roman numeral**, convert it to an **integer**.

#### Problem Statement (Elaborated):
Write a program that **converts** a given **Roman numeral** into its corresponding **integer** value. The program should **take a Roman numeral as input** and **calculate** its **integer equivalent**. Roman numerals are represented by **seven different symbols**: \`I\` (1), \`V\` (5), \`X\` (10), \`L\` (50), \`C\` (100), \`D\` (500), and \`M\` (1000).

#### Testing Examples
1. **Example 1: Input = "III"**
   - **Step-by-step**: \`III\` is calculated as \`1 + 1 + 1 = 3\`. Therefore, the **output** is **3**.
2. **Example 2: Input = "IV"**
   - **Step-by-step**: \`IV\` is calculated as \`5 - 1 = 4\`. Therefore, the **output** is **4**.
3. **Example 3: Input = "IX"**
   - **Step-by-step**: \`IX\` is calculated as \`10 - 1 = 9\`. Therefore, the **output** is **9**.

#### What is a Roman Numeral?
A **Roman numeral** is a **symbolic representation** of numbers using combinations of **letters**. For example, \`III\` represents \`3\` while \`IV\` represents \`4\`.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to **handle cases** where **smaller values precede larger ones**.
2. **Loops**: To **iterate through characters** of the Roman numeral.
3. **Mathematical Operations**: **Addition** and **subtraction** based on the position of Roman symbols.
4. **Edge Case Handling**: Understanding how to **handle input** like \`"I"\` and **empty strings**.

#### Real-World Concepts Covered
1. **Ancient Number Systems**: Exploring **numerical representations** that have been used for centuries.
2. **Pattern Recognition**: Identifying when to **add** or **subtract** values based on **symbol positions**.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **structured analysis** of character sequences.

#### How Does the Program Work?
1. Define **values** for each Roman symbol.
2. **Iterate** through each **character** of the Roman numeral.
3. **Compare** each character with the **next**.
4. If the **current value** is **less than the next**, **subtract** it.
5. If the **current value** is **greater or equal**, **add** it.
6. **Accumulate the total** to get the final **integer value**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use an **object** to **store Roman numeral values**.
2. **Hint 2**: Think about how to **handle special cases** where a **smaller symbol** comes **before** a **larger one**.
3. **Hint 3**: **Iterate** through the Roman numeral, **comparing each character** with the **next**.
4. **Hint 4**: **Edge cases** like **empty strings** should **return 0**.

#### Why Do We Do This?
We **perform this conversion** to **understand** how **Roman numerals** map to **integer values**, and to **enhance logical thinking** through **pattern recognition**.
`;

const initialAlgorithm = `1. Define a **lookup object** to store Roman numeral values.
2. Initialize **total** to **0** and **previousValue** to **0**.
3. Iterate through **each character** of the Roman numeral **from right to left**:
   - a. Get the **value** of the **current Roman numeral**.
   - b. If **current value** is **less than previousValue**, **subtract** it from **total**.
   - c. Otherwise, **add** the **current value** to **total**.
   - d. Update **previousValue** to **current value**.
4. **Return** the **total** as the integer equivalent of the Roman numeral.
`;

const initialProblemCode = `1  function romanToInteger(roman) {
2    const romanValues = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
3    let total = 0;
4    let previousValue = 0;
5    for (let i = roman.length - 1; i >= 0; i--) {
6      const currentSymbol = roman[i];
7      const currentValue = romanValues[currentSymbol];
8      if (currentValue < previousValue) {
9        total -= currentValue;
10     } else {
11       total += currentValue;
12     }
13     previousValue = currentValue;
14   }
15   return total;
16 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
// Converts object-type variables to JSON strings for better readability in trace
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the RomanToIntegerDemo
const RomanToIntegerDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    const romanValues = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
    let roman = numberInput.toUpperCase().trim();
    if (!roman) {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: 'Invalid input: Please enter a valid Roman numeral!',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function romanToInteger is called with roman = "${roman}"`,
      { roman }
    );

    let total = 0;
    let previousValue = 0;

    // Line 3: Initialize total and previousValue
    addTrace(executionTrace, 3, `Initialize total = 0 and previousValue = 0`, {
      total,
      previousValue,
    });

    // Line 5: Iterate through each character from right to left
    for (let i = roman.length - 1; i >= 0; i--) {
      const currentSymbol = roman[i];
      const currentValue = romanValues[currentSymbol] || 0;

      // Line 6: Get the value of the current Roman numeral
      addTrace(
        executionTrace,
        6,
        `Get the value of currentSymbol "${currentSymbol}" = ${currentValue}`,
        { currentSymbol, currentValue }
      );

      // Line 8: Check if currentValue is less than previousValue
      if (currentValue < previousValue) {
        total -= currentValue;
        addTrace(
          executionTrace,
          9,
          `currentValue < previousValue (${currentValue} < ${previousValue}), subtract: total = ${total}`,
          { currentValue, previousValue, total }
        );
      } else {
        total += currentValue;
        addTrace(
          executionTrace,
          11,
          `currentValue >= previousValue (${currentValue} >= ${previousValue}), add: total = ${total}`,
          { currentValue, previousValue, total }
        );
      }

      // Line 13: Update previousValue to currentValue
      previousValue = currentValue;
      addTrace(executionTrace, 13, `Update previousValue to ${previousValue}`, {
        previousValue,
      });
    }

    // Line 15: Return the total as the final result
    addTrace(executionTrace, 15, `Return the total value = ${total}`, {
      total,
    });

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The integer value of Roman numeral "${roman}" is ${total}.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default RomanToIntegerDemo;
