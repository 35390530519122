import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Projects = () => {
  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h3>My Projects</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={6} lg={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Project 1</Card.Title>
              <Card.Text>Details about project 1</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Project 2</Card.Title>
              <Card.Text>Details about project 2</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
