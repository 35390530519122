import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import the Navbar component
import Footer from './Footer.js'; // Import the Footer component
import './Clubs.css'; // Import Clubs component-specific CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const Clubs = ({ hideHeaderFooter }) => {
  const clubs = [
    {
      id: 1,
      name: 'Web Development Club',
      description:
        'Join fellow enthusiasts to learn and build web development projects together (Coming Soon).',
      members: 0,
      upcomingEvent: 'TBD',
    },
    {
      id: 2,
      name: 'AI & Machine Learning Club',
      description:
        'Explore the latest trends and technologies in AI and Machine Learning (Coming Soon).',
      members: 0,
      upcomingEvent: 'TBD',
    },
    {
      id: 3,
      name: 'Data Science Club',
      description:
        'Dive deep into data analysis, visualization, and machine learning with like-minded peers (Coming Soon).',
      members: 0,
      upcomingEvent: 'TBD',
    },
  ];

  return (
    <div className="clubs-container d-flex flex-column min-vh-100">
      {!hideHeaderFooter && <Navbar />} {/* Conditionally render Navbar */}
      <Container className="flex-grow-1 mt-5">
        <h1 className="text-center mb-4 clubs-title">Student Clubs</h1>
        <p className="text-center clubs-description">
          Our student clubs are coming soon! Connect with peers, share
          knowledge, and participate in hands-on projects once they are
          launched.
        </p>
        <Row>
          {clubs.map((club) => (
            <Col md={4} key={club.id} className="mb-4">
              <Card className="h-100 shadow-sm clubs-card">
                <Card.Body>
                  <Card.Title>{club.name}</Card.Title>
                  <Card.Text>{club.description}</Card.Text>
                  <p>
                    <strong>Members:</strong> {club.members}
                    <br />
                    <strong>Next Event:</strong> {club.upcomingEvent}
                  </p>
                  <Button variant="primary" disabled>
                    Coming Soon
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {!hideHeaderFooter && <Footer />} {/* Conditionally render Footer */}
    </div>
  );
};

// Set default prop for hideHeaderFooter
Clubs.defaultProps = {
  hideHeaderFooter: false,
};

export default Clubs;
