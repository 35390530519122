import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  ListGroup,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap';
import { getProjectActivityById } from '../../services/api.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectView = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getProjectActivityById(token, projectId);
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project:', error);
        setError('Failed to load project.');
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h2>{project.title}</h2>
          <p>{project.description}</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <h4>Evaluation Criteria</h4>
          <ListGroup variant="flush">
            {project.evaluationCriterias.map((criteria) => (
              <ListGroup.Item key={criteria.id}>
                {criteria.criteria_name} - Max Score: {criteria.max_score}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col md={6}>
          <h4>Submissions</h4>
          <ListGroup variant="flush">
            {project.submissions.map((submission) => (
              <ListGroup.Item key={submission.id}>
                <strong>User:</strong> {submission.user_id},{' '}
                <strong>Score:</strong> {submission.grade},{' '}
                <strong>Feedback:</strong> {submission.feedback}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button variant="secondary" onClick={() => window.history.back()}>
            Back
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectView;
