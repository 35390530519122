export const problemStatementName = 'Palindrome Number';

export const questions = [
  {
    question:
      'The problem requires checking if a given integer _______ the same from front to back.',
    options: ['reads', 'avoids', 'calculates', 'ignores'],
    correct: 0,
    explanation:
      '✅ A palindrome number reads the same forwards and backwards.',
  },
  {
    question: 'If an integer is a palindrome, we return _______.',
    options: ['true', 'false', 'undefined', 'null'],
    correct: 0,
    explanation:
      '✅ The problem statement specifies returning true if x is a palindrome.',
  },
  {
    question: 'If an integer is not a palindrome, we return _______.',
    options: ['false', 'true', 'NaN', '0'],
    correct: 0,
    explanation:
      '✅ The problem states that if x is not a palindrome, return false.',
  },
  {
    question:
      'Negative numbers are not considered palindromes because the negative _______ does not match when reversed.',
    options: ['sign', 'digit', 'character', 'space'],
    correct: 0,
    explanation:
      '✅ The negative sign "-" makes the number read differently backward.',
  },
  {
    question:
      'The given problem involves using purely _______ operations to determine if an integer is a palindrome.',
    options: ['mathematical', 'string', 'boolean', 'bitwise'],
    correct: 0,
    explanation:
      '✅ The approach should rely on mathematical operations, not string conversions.',
  },
  {
    question:
      'The input will be a single integer, and we must _______ if it reads the same backward and forward.',
    options: ['check', 'ignore', 'concatenate', 'sort'],
    correct: 0,
    explanation:
      '✅ We need to check if the integer is the same when reversed.',
  },
  {
    question:
      'Since negative numbers have a leading "-", when reversed, they will not form the same sequence, thus they are not _______.',
    options: ['palindromes', 'factors', 'positive', 'digits'],
    correct: 0,
    explanation: '✅ Negative integers cannot be palindromes due to the sign.',
  },
  {
    question:
      'Example 1: Input = 121. Since reversing 121 gives 121, we return _______.',
    options: ['true', 'false', 'null', 'NaN'],
    correct: 0,
    explanation:
      '✅ 121 is the same forwards and backwards, so it is a palindrome.',
  },
  {
    question:
      'Example 2: Input = -121. Because it is a negative number, the output is _______.',
    options: ['false', 'true', 'undefined', 'null'],
    correct: 0,
    explanation: '✅ Negative numbers are not considered palindromes.',
  },
  {
    question:
      'Example 3: Input = 123. Reversing it gives 321, which is different from 123, so we return _______.',
    options: ['false', 'true', '0', 'NaN'],
    correct: 0,
    explanation:
      '✅ Since the reversed number does not match the original, it is not a palindrome.',
  },
  {
    question:
      'A palindrome number is a number that reads the same _______ and backward.',
    options: ['forward', 'upwards', 'downwards', 'sideways'],
    correct: 0,
    explanation: '✅ Palindrome numbers are symmetrical front-to-back.',
  },
  {
    question: 'If a number is 121, when reversed, it remains _______.',
    options: ['121', '112', '211', '123'],
    correct: 0,
    explanation: '✅ 121 reversed is still 121.',
  },
  {
    question:
      'If a number like 123 is reversed, it becomes 321, which is _______ from the original.',
    options: ['different', 'equal', 'similar', 'identical'],
    correct: 0,
    explanation: '✅ Since 321 != 123, it’s not a palindrome.',
  },
  {
    question:
      'Palindromic checks here should be done with mathematical operations rather than _______ operations.',
    options: ['string', 'loop-only', 'binary', 'sorting'],
    correct: 0,
    explanation:
      '✅ The problem explicitly mentions using purely mathematical operations.',
  },
  {
    question:
      'One of the JavaScript topics covered is using _______ statements like if-else to handle conditions.',
    options: ['conditional', 'arithmetic', 'iterable', 'class'],
    correct: 0,
    explanation:
      '✅ Conditional statements are needed to check negative numbers and equality.',
  },
  {
    question:
      'Loops can be used to reverse the number by repeatedly extracting _______.',
    options: ['digits', 'strings', 'objects', 'arrays'],
    correct: 0,
    explanation:
      '✅ Each digit is extracted using modulo and division operations.',
  },
  {
    question:
      'Mathematical operations like the modulo (%) operator help to _______ the last digit of the number.',
    options: ['extract', 'remove all', 'add randomness', 'convert'],
    correct: 0,
    explanation: '✅ Using modulo 10 gives the last digit of the integer.',
  },
  {
    question:
      'Division and the Math.floor() function are used to _______ the number by removing its last digit.',
    options: ['shorten', 'append', 'double', 'inflate'],
    correct: 0,
    explanation:
      '✅ floor division by 10 removes the last digit from the number.',
  },
  {
    question:
      'Edge case handling includes numbers like 0, which is considered a _______ because it reads the same forward and backward.',
    options: ['palindrome', 'negative', 'incorrect', 'skipped'],
    correct: 0,
    explanation: '✅ Zero reversed is zero, so it is a palindrome.',
  },
  {
    question: 'The main steps are: if the number is negative, return _______.',
    options: ['false', 'true', 'continue', 'undefined'],
    correct: 0,
    explanation: '✅ Negative numbers are not palindromes.',
  },
  {
    question: 'We store the original number in a variable called _______.',
    options: ['originalNumber', 'tempVar', 'reverse', 'currentDigit'],
    correct: 0,
    explanation: '✅ The code uses originalNumber = x for later comparison.',
  },
  {
    question: 'We initialize reversedNumber to _______.',
    options: ['0', '1', 'null', 'x'],
    correct: 0,
    explanation:
      '✅ reversedNumber starts at 0 and is built up as we extract digits.',
  },
  {
    question:
      'While x > 0, we repeatedly extract the last digit using x % _______.',
    options: ['10', '2', '100', 'x'],
    correct: 0,
    explanation: '✅ x % 10 gives the last digit of x.',
  },
  {
    question:
      'After extracting the digit, we update reversedNumber by multiplying current reversedNumber by 10 and adding the _______.',
    options: ['digit', 'originalNumber', 'floor', 'boolean'],
    correct: 0,
    explanation:
      '✅ reversedNumber = reversedNumber * 10 + digit reconstructs the reversed number.',
  },
  {
    question:
      'We then remove the last digit from x by setting x to Math.floor(x / _______).',
    options: ['10', '2', '5', '100'],
    correct: 0,
    explanation: '✅ Dividing by 10 and using floor drops the last digit.',
  },
  {
    question:
      'After the loop finishes, we compare the reversedNumber with _______.',
    options: ['originalNumber', 'digit', 'reversedString', 'negativeSign'],
    correct: 0,
    explanation: '✅ We check if reversedNumber equals originalNumber.',
  },
  {
    question: 'If reversedNumber and originalNumber are equal, return _______.',
    options: ['true', 'false', 'NaN', 'undefined'],
    correct: 0,
    explanation: '✅ Equality indicates a palindrome.',
  },
  {
    question:
      'If reversedNumber and originalNumber are not equal, return _______.',
    options: ['false', 'true', 'undefined', 'NaN'],
    correct: 0,
    explanation: '✅ Inequality means it is not a palindrome.',
  },
  {
    question:
      'In Hint 1, we are reminded that the modulo operator (%) can be used to _______ the last digit.',
    options: ['get', 'remove', 'concatenate', 'truncate all'],
    correct: 0,
    explanation: '✅ x % 10 yields the last digit of x.',
  },
  {
    question:
      'In Hint 2, we see that Math.floor(x / 10) can remove the _______ digit of the number.',
    options: ['last', 'first', 'middle', 'all'],
    correct: 0,
    explanation: '✅ Integer division by 10 removes the last digit from x.',
  },
  {
    question:
      'Hint 3 suggests that reversing the number involves constructing a new number digit by _______.',
    options: ['digit', 'index', 'character', 'block'],
    correct: 0,
    explanation: '✅ Each extracted digit is added to reversedNumber.',
  },
  {
    question:
      'Hint 4 reminds us to handle edge cases like 0, which is technically a _______.',
    options: ['palindrome', 'negative', 'false', 'non-integer'],
    correct: 0,
    explanation: '✅ 0 reversed is still 0, so it’s a palindrome.',
  },
  {
    question:
      'The algorithm step 1: If the number is _______ (less than 0), it is not a palindrome.',
    options: ['negative', 'positive', 'zero', 'ten'],
    correct: 0,
    explanation: '✅ Negative numbers are immediately disqualified.',
  },
  {
    question:
      'In the algorithm, originalNumber is set to x to store the _______ input.',
    options: ['original', 'modified', 'random', 'inverted'],
    correct: 0,
    explanation:
      '✅ originalNumber holds the initial value of x for later comparison.',
  },
  {
    question:
      'reversedNumber starts at 0 because we will _______ build it up from extracted digits.',
    options: ['gradually', 'instantly', 'never', 'erratically'],
    correct: 0,
    explanation:
      '✅ We start from 0 and accumulate digits to form the reversed number.',
  },
  {
    question:
      'Inside the while loop, "digit" is obtained using x % 10, which gives the _______ digit of the current x.',
    options: ['last', 'first', 'middle', 'random'],
    correct: 0,
    explanation: '✅ The remainder of division by 10 is the last digit.',
  },
  {
    question:
      'We update reversedNumber by doing reversedNumber = reversedNumber * 10 + digit, effectively shifting the current reversedNumber and _______ the new digit.',
    options: ['adding', 'removing', 'discarding', 'splitting'],
    correct: 0,
    explanation:
      '✅ Multiplying by 10 shifts digits left, then adding the new digit appends it.',
  },
  {
    question:
      'We then remove the last digit of x by setting x = Math.floor(x / 10), which shortens the number from the _______.',
    options: ['right', 'left', 'middle', 'top'],
    correct: 0,
    explanation: '✅ Dividing by 10 and flooring removes the rightmost digit.',
  },
  {
    question:
      'After the loop ends, we have reversedNumber as the reversed version of the original _______.',
    options: ['integer', 'string', 'array', 'function'],
    correct: 0,
    explanation:
      '✅ reversedNumber now holds the reversed digits of the original integer.',
  },
  {
    question:
      'We compare originalNumber and reversedNumber, and if they match, the integer is a _______.',
    options: ['palindrome', 'prime', 'composite', 'factorial'],
    correct: 0,
    explanation: '✅ Matching reversed and original means it’s a palindrome.',
  },
  {
    question:
      'If originalNumber ≠ reversedNumber, then the integer is not a _______.',
    options: ['palindrome', 'even number', 'perfect square', 'object'],
    correct: 0,
    explanation: '✅ Non-matching means no palindrome.',
  },
  {
    question:
      'In the code snippet, if (x < 0) return _______; is used to handle negative inputs.',
    options: ['false', 'true', 'x', 'digit'],
    correct: 0,
    explanation: '✅ Immediately returns false for negative numbers.',
  },
  {
    question:
      'line 3: let originalNumber = x; stores the _______ input number.',
    options: ['original', 'next', 'previous', 'reversed'],
    correct: 0,
    explanation: '✅ originalNumber keeps track of the initial value.',
  },
  {
    question:
      'line 4: let reversedNumber = 0; initializes the reversed number to _______.',
    options: ['0', 'null', '1', '-1'],
    correct: 0,
    explanation: '✅ We start building from 0.',
  },
  {
    question:
      'line 5: while (x > 0) { ... } runs the loop until x is reduced to _______.',
    options: ['0', '1', '-1', '10'],
    correct: 0,
    explanation: '✅ The loop stops when x no longer has digits to extract.',
  },
  {
    question: 'line 6: let digit = x % 10; extracts the _______ digit of x.',
    options: ['last', 'first', 'second', 'middle'],
    correct: 0,
    explanation: '✅ Modulo 10 returns the last digit.',
  },
  {
    question:
      'line 7: reversedNumber = reversedNumber * 10 + digit; appends the extracted digit to the _______ number.',
    options: ['reversed', 'original', 'negative', 'random'],
    correct: 0,
    explanation: '✅ This step builds the reversed number digit by digit.',
  },
  {
    question:
      'line 8: x = Math.floor(x / 10); removes the last digit from x by integer _______.',
    options: ['division', 'multiplication', 'addition', 'exponentiation'],
    correct: 0,
    explanation: '✅ Division by 10 and floor drops the last digit.',
  },
  {
    question:
      'line 10: return originalNumber === reversedNumber; returns true if they are equal, otherwise _______.',
    options: ['false', 'undefined', 'true again', 'NaN'],
    correct: 0,
    explanation:
      '✅ Equality check returns a boolean; if not equal, it returns false.',
  },
  {
    question:
      'The logic ensures that for any integer input, we determine if it is a palindrome without converting it to a _______.',
    options: ['string', 'boolean', 'function', 'array'],
    correct: 0,
    explanation:
      '✅ The instruction was to use purely mathematical operations, not string conversion.',
  },
  {
    question:
      'A negative number like -121 fails immediately because x < 0 leads to returning _______.',
    options: ['false', 'true', '0', 'digit'],
    correct: 0,
    explanation: '✅ Negative numbers are not palindromes.',
  },
  {
    question:
      'For a number like 0, reversedNumber would end up also being 0, so the function returns _______.',
    options: ['true', 'false', 'null', 'NaN'],
    correct: 0,
    explanation: '✅ 0 is a palindrome.',
  },
  {
    question:
      'For a number like 10, reversed is 01 which is 1, not equal to 10, so it returns _______.',
    options: ['false', 'true', 'undefined', '0'],
    correct: 0,
    explanation: '✅ 10 reversed is 1, not 10, hence not a palindrome.',
  },
  {
    question:
      'The approach avoids string manipulation and relies solely on _______ operations.',
    options: ['arithmetic', 'logical', 'bitwise', 'asynchronous'],
    correct: 0,
    explanation:
      '✅ Arithmetic operations are used to find and rebuild digits.',
  },
  {
    question:
      'We use conditional statements to handle cases like negative input or final comparison. This relates to the JS topic of _______ statements.',
    options: ['conditional', 'iterable', 'class-based', 'template'],
    correct: 0,
    explanation: '✅ if-else statements determine different outcomes.',
  },
  {
    question: 'The loop used is often a _______ loop that runs while x > 0.',
    options: ['while', 'for', 'do-while', 'for...of'],
    correct: 0,
    explanation:
      '✅ A while loop is suitable for this repeated digit extraction until no digits remain.',
  },
  {
    question:
      'Using modulo 10 repeatedly extracts digits in reverse order, helping to _______ the number.',
    options: ['reverse', 'double', 'increment', 'sort'],
    correct: 0,
    explanation:
      '✅ Extracting last digits in sequence helps build the reversed number.',
  },
  {
    question:
      'Dividing by 10 and using Math.floor ensures we move to the next digit by removing the _______ digit each iteration.',
    options: ['last', 'first', 'middle', 'largest'],
    correct: 0,
    explanation: '✅ Floor division by 10 chops off the last digit.',
  },
  {
    question:
      'At the end, originalNumber and reversedNumber are compared: if they match, the function returns _______.',
    options: ['true', 'false', 'NaN', '0'],
    correct: 0,
    explanation: '✅ Equality indicates a palindrome.',
  },
  {
    question: 'If they do not match, the function returns _______.',
    options: ['false', 'true', 'null', 'reversedNumber'],
    correct: 0,
    explanation: '✅ Non-match means not a palindrome, hence false.',
  },
  {
    question:
      'The concept of a palindrome number is that it remains _______ even when its digits are reversed.',
    options: ['unchanged', 'bigger', 'smaller', 'undefined'],
    correct: 0,
    explanation: '✅ Same forward and backward means unchanged when reversed.',
  },
  {
    question:
      'Because negative numbers have a "-" sign, reading backward would place the sign at the _______ end, making it different.',
    options: ['end', 'start', 'middle', 'top'],
    correct: 0,
    explanation:
      '✅ A negative sign at the end of the reversed number does not match the original format.',
  },
  {
    question:
      'For the example input 121, reversedNumber after processing is also 121, so the condition originalNumber === reversedNumber is _______.',
    options: ['true', 'false', 'NaN', 'null'],
    correct: 0,
    explanation: '✅ They are equal, so it’s true.',
  },
  {
    question:
      'For the example input -121, we do not even proceed to reversing, because x < 0 immediately returns _______.',
    options: ['false', 'true', '0', 'digit'],
    correct: 0,
    explanation: '✅ Negative numbers are handled upfront.',
  },
  {
    question:
      'For the example input 123, after reversing we get 321, which does not equal 123, so we return _______.',
    options: ['false', 'true', '321', '123'],
    correct: 0,
    explanation: '✅ Non-palindrome returns false.',
  },
  {
    question:
      'The definition: A palindrome number reads the same backward as _______.',
    options: ['forward', 'sideways', 'repeatedly', 'on another line'],
    correct: 0,
    explanation: '✅ That’s the core definition of a palindrome.',
  },
  {
    question:
      'We must handle edge cases like the number 0, which reads the same forward and backward. Thus, the function would return _______ for 0.',
    options: ['true', 'false', 'null', 'NaN'],
    correct: 0,
    explanation: '✅ 0 is a palindrome.',
  },
  {
    question:
      'The problem also highlights that we should understand how to handle inputs like negative numbers and zero, falling under _______ case handling.',
    options: ['edge', 'normal', 'random', 'complex'],
    correct: 0,
    explanation: '✅ Negative numbers and 0 are considered edge cases.',
  },
  {
    question:
      'A key hint is that we must use the modulo operator (%) to extract digits. For instance, 123 % 10 = _______.',
    options: ['3', '12', '1', '2'],
    correct: 0,
    explanation: '✅ The last digit of 123 is 3.',
  },
  {
    question: 'Another hint: using Math.floor(x / 10) on 123 gives _______.',
    options: ['12', '3', '1', '0'],
    correct: 0,
    explanation: '✅ Floor(123/10) = Floor(12.3) = 12.',
  },
  {
    question:
      'Reversing the number involves constructing reversedNumber digit by digit, starting from _______.',
    options: ['0', '1', 'the last digit of originalNumber', 'a string'],
    correct: 0,
    explanation: '✅ We initialize reversedNumber at 0 and build it up.',
  },
  {
    question:
      'In practice, if the input is 1, the reversed number after processing will also be _______.',
    options: ['1', '0', '10', '11'],
    correct: 0,
    explanation:
      '✅ Single-digit numbers are palindromes since they read the same in either direction.',
  },
  {
    question:
      'If the input is 10, after reversal we get 1, which is not equal to 10, resulting in _______.',
    options: ['false', 'true', '1', 'an error'],
    correct: 0,
    explanation: '✅ Not a palindrome, so we return false.',
  },
  {
    question:
      'This approach does not require converting the integer to a _______, which some other solutions might do.',
    options: ['string', 'float', 'boolean', 'bigint'],
    correct: 0,
    explanation:
      '✅ We are explicitly using mathematical operations, not strings.',
  },
  {
    question:
      'Using conditionals, we can quickly rule out any number less than 0 by returning _______ immediately.',
    options: ['false', 'true', 'x', 'undefined'],
    correct: 0,
    explanation: '✅ Negative input gets false directly.',
  },
  {
    question:
      'A loop allows repeated extraction of digits until no digits remain. Without the loop, we could not progressively _______ the number.',
    options: ['reverse', 'add', 'subtract', 'multiply'],
    correct: 0,
    explanation: '✅ We need a loop to continuously extract digits.',
  },
  {
    question:
      'The modulo 10 operation always gives the _______ digit of the current number.',
    options: ['last', 'first', 'middle', 'largest'],
    correct: 0,
    explanation: '✅ That’s how we isolate the last digit.',
  },
  {
    question:
      'By multiplying reversedNumber by 10 and adding the extracted digit, we effectively shift digits and _______ the new one.',
    options: ['append', 'remove', 'ignore', 'replace'],
    correct: 0,
    explanation: '✅ This step appends the new digit to the reversed number.',
  },
  {
    question:
      'Dividing x by 10 and flooring it ensures that we are moving on to the _______ digit of the original number in the next iteration.',
    options: ['next', 'previous', 'same', 'first'],
    correct: 0,
    explanation: '✅ Each iteration processes one digit, then moves on.',
  },
  {
    question:
      'Once x is 0, it means we have processed all _______ of the original number.',
    options: ['digits', 'letters', 'objects', 'booleans'],
    correct: 0,
    explanation:
      '✅ All digits have been reversed and placed into reversedNumber.',
  },
  {
    question:
      'Comparing originalNumber with reversedNumber determines if the number is a _______.',
    options: ['palindrome', 'prime', 'composite', 'perfect number'],
    correct: 0,
    explanation: '✅ If they are equal, it’s a palindrome.',
  },
  {
    question:
      'If originalNumber !== reversedNumber, we know the sequence of digits changed when reversed, so it is not a _______.',
    options: ['palindrome', 'string', 'function', 'undefined variable'],
    correct: 0,
    explanation: '✅ A mismatch indicates not a palindrome.',
  },
  {
    question:
      'Checking negative numbers first saves time, as we do not need to attempt _______ the digits of a negative number.',
    options: ['reversing', 'adding', 'removing', 'multiplying'],
    correct: 0,
    explanation: '✅ Negative numbers are quickly ruled out.',
  },
  {
    question:
      'Numbers like 101 are palindromes since reversing 101 still gives _______.',
    options: ['101', '110', '11', '10'],
    correct: 0,
    explanation: '✅ 101 reversed is still 101.',
  },
  {
    question:
      'Numbers like 100 are not palindromes since reversing them yields 001 (which is 1), not _______.',
    options: ['100', '10', '0', '101'],
    correct: 0,
    explanation: '✅ 100 reversed is effectively 1, not 100.',
  },
  {
    question:
      'By using math instead of strings, we practice handling conditions and loops, reinforcing basic _______ concepts in JavaScript.',
    options: ['algorithmic', 'GUI-based', 'API-based', 'event-driven'],
    correct: 0,
    explanation:
      '✅ Basic algorithmic concepts: loops, conditions, arithmetic.',
  },
  {
    question:
      'One key concept is that reversing a number mathematically involves iterating until the number is _______.',
    options: ['0', 'negative', 'equal to reversedNumber', 'huge'],
    correct: 0,
    explanation: '✅ We process until no digits remain.',
  },
  {
    question:
      'When we say "reads the same forward and backward," we imply a symmetrical pattern of digits, defining a _______ number.',
    options: ['palindromic', 'random', 'even', 'odd'],
    correct: 0,
    explanation: '✅ Palindromic means symmetrical digit order.',
  },
  {
    question: 'To illustrate, 454 reversed is still 454, making it a _______.',
    options: ['palindrome', 'non-palindrome', 'negative', 'string'],
    correct: 0,
    explanation: '✅ 454 is a palindrome.',
  },
  {
    question:
      'Conversely, 450 reversed is 054 (which is 54), not matching 450, hence _______.',
    options: ['not a palindrome', 'a palindrome', 'negative', 'infinite'],
    correct: 0,
    explanation: '✅ They do not match, so not a palindrome.',
  },
  {
    question:
      'The code snippet provided shows a function isPalindromeUsingMath(x) that returns a boolean value: _______ for palindrome and false otherwise.',
    options: ['true', 'undefined', 'null', 'NaN'],
    correct: 0,
    explanation: '✅ Returns true if palindrome, else false.',
  },
  {
    question:
      'This method can handle any integer input within the limits of JavaScript number range as long as it is not _______.',
    options: ['negative', 'positive', 'a palindrome', '0'],
    correct: 0,
    explanation: '✅ Negative numbers are immediately false.',
  },
  {
    question:
      'If we consider the input 999, reversed is also 999, so it returns _______.',
    options: ['true', 'false', 'NaN', 'undefined'],
    correct: 0,
    explanation: '✅ A triple nine is a palindrome.',
  },
  {
    question:
      'For input 998, reversed is 899, which is not equal to 998, so it returns _______.',
    options: ['false', 'true', 'NaN', 'digit'],
    correct: 0,
    explanation: '✅ Not a palindrome means false.',
  },
  {
    question:
      'Since we rely on arithmetic, we must ensure that we do not introduce floating-point errors. Using Math.floor ensures we get a proper _______ integer division.',
    options: ['integer', 'fractional', 'bitwise', 'infinite'],
    correct: 0,
    explanation: '✅ Math.floor converts a float to an integer.',
  },
  {
    question:
      'The problem is essentially about verifying the _______ of the original and the reversed number.',
    options: ['equality', 'inequality', 'length', 'type'],
    correct: 0,
    explanation: '✅ Equality check determines palindrome status.',
  },
  {
    question:
      'If at the end originalNumber === reversedNumber, then we have successfully confirmed it is a _______.',
    options: ['palindrome', 'negative', 'non-palindrome', 'complex number'],
    correct: 0,
    explanation: '✅ Equal values indicate a palindrome.',
  },
  {
    question: 'If they are not equal, we confirm it is _______ a palindrome.',
    options: ['not', 'always', 'definitely', 'certainly'],
    correct: 0,
    explanation: '✅ Inequality means not palindrome.',
  },
  {
    question:
      'This approach works for single-digit numbers as well because any single-digit number (e.g., 7) reversed is still _______.',
    options: ['7', '0', '1', '70'],
    correct: 0,
    explanation: '✅ Single-digit numbers are palindromes by definition.',
  },
  {
    question:
      'We do not need extra space besides a few variables: originalNumber, reversedNumber, and _______.',
    options: ['digit', 'stringBuffer', 'arrayOfDigits', 'tempFile'],
    correct: 0,
    explanation: '✅ The digit variable is used to hold the extracted digit.',
  },
  {
    question:
      'The main time complexity of this solution is O(n), where n is the number of _______ in the integer.',
    options: ['digits', 'lines of code', 'booleans', 'functions'],
    correct: 0,
    explanation: '✅ We process each digit once.',
  },
  {
    question:
      'No additional complex data structures are needed, just basic _______ and comparisons.',
    options: ['arithmetic', 'events', 'classes', 'APIs'],
    correct: 0,
    explanation: '✅ Only arithmetic and comparison operations are used.',
  },
  {
    question:
      'Ensuring we return false immediately for negative numbers simplifies the logic because we do not waste time _______ negative values.',
    options: ['reversing', 'adding', 'subtracting', 'printing'],
    correct: 0,
    explanation: '✅ No reversal needed for negative inputs.',
  },
  {
    question:
      'The logic is straightforward: check sign, build reversed number, then _______.',
    options: ['compare', 'concatenate strings', 'format', 'alert'],
    correct: 0,
    explanation:
      '✅ After building reversedNumber, we compare it to originalNumber.',
  },
  {
    question:
      'In conclusion, a palindrome number is one where reversedNumber === _______.',
    options: ['originalNumber', '0', '10', 'digit'],
    correct: 0,
    explanation: '✅ That equality defines a palindrome.',
  },
  {
    question:
      'For clarity, if x = 202, reversedNumber will end up being _______ after the loop.',
    options: ['202', '20', '2', '200'],
    correct: 0,
    explanation: '✅ 202 reversed is still 202.',
  },
  {
    question:
      'For x = 230, reversedNumber will be 32 (leading zeros dropped), which does not equal 230, so result is _______.',
    options: ['false', 'true', '32', '230'],
    correct: 0,
    explanation: '✅ 230 is not a palindrome.',
  },
  {
    question:
      'By processing each digit via modulo 10 and division by 10, we employ a classic method of digit _______.',
    options: ['extraction', 'insertion', 'deletion', 'sorting'],
    correct: 0,
    explanation:
      '✅ Extracting digits from a number is commonly done with % 10 and / 10.',
  },
  {
    question:
      'After completion, we have a simple boolean return value: _______ or false.',
    options: ['true', 'undefined', 'string', 'NaN'],
    correct: 0,
    explanation: '✅ Ultimately, it returns true or false.',
  },
  {
    question:
      'This problem also reinforces the concept that we do not need string conversion to check _______ for palindromes.',
    options: ['integers', 'arrays', 'objects', 'functions'],
    correct: 0,
    explanation: '✅ We can determine palindrome just using math for integers.',
  },
  {
    question:
      'A negative example: x = -101 returns false immediately because x < _______.',
    options: ['0', '1', '-1', '10'],
    correct: 0,
    explanation: '✅ Any negative number returns false.',
  },
  {
    question:
      'A positive but non-palindromic example: x = 1234 reversed is 4321, so we return _______.',
    options: ['false', 'true', '0', 'NaN'],
    correct: 0,
    explanation: '✅ 4321 ≠ 1234, so false.',
  },
  {
    question:
      'A trick example: x = 11 reversed is also 11, so we return _______.',
    options: ['true', 'false', '11', 'undefined'],
    correct: 0,
    explanation: '✅ 11 is a palindrome.',
  },
  {
    question:
      'If the input is very large but still within integer range, the logic still applies: we simply _______ each digit until done.',
    options: ['extract', 'skip', 'multiply', 'append zero'],
    correct: 0,
    explanation: '✅ The same process works for any length number.',
  },
  {
    question:
      'This method does not rely on any built-in palindrome checker; it uses basic arithmetic and a _______ loop.',
    options: ['while', 'for', 'do-while', 'for...in'],
    correct: 0,
    explanation: '✅ A while loop is used to process each digit.',
  },
  {
    question:
      'The final step always involves comparing the stored originalNumber with the computed reversedNumber using an _______ operator.',
    options: ['equality', 'inequality', 'logical AND', 'bitwise OR'],
    correct: 0,
    explanation: '✅ We check equality with ===.',
  },
  {
    question:
      'If the equality holds, we know the integer is a palindrome and return _______.',
    options: ['true', 'false', 'x', 'originalNumber'],
    correct: 0,
    explanation: '✅ Palindromes yield true.',
  },
  {
    question:
      'If the equality does not hold, we know it is not a palindrome and return _______.',
    options: ['false', 'true', 'undefined', 'NaN'],
    correct: 0,
    explanation: '✅ Non-palindromes yield false.',
  },
  {
    question:
      'Consider a large palindrome like 123454321. Reversed, it remains 123454321, so we would return _______.',
    options: ['true', 'false', '123454321', '0'],
    correct: 0,
    explanation: '✅ The reversed equals the original.',
  },
  {
    question:
      'Consider a large non-palindrome like 123450321. Reversed, it might be 123054321 (which is 123054321), not equal to original, so return _______.',
    options: ['false', 'true', 'NaN', 'undefined'],
    correct: 0,
    explanation: '✅ They do not match, hence false.',
  },
  {
    question:
      'This exercise demonstrates using arithmetic manipulation to solve what might initially seem like a _______ problem.',
    options: ['string', 'database', 'API', 'network'],
    correct: 0,
    explanation: '✅ Many would think of strings, but we use math instead.',
  },
  {
    question:
      'We have covered conditions, loops, arithmetic operators, and edge cases, all fundamental topics in _______.',
    options: ['JavaScript', 'HTML', 'CSS', 'SQL'],
    correct: 0,
    explanation: '✅ The solution is coded in JavaScript.',
  },
  {
    question:
      'Implementing the logic in JavaScript shows how basic language features can address algorithmic _______.',
    options: ['challenges', 'styles', 'events', 'HTML tags'],
    correct: 0,
    explanation:
      '✅ Determining palindrome status is an algorithmic challenge.',
  },
  {
    question:
      'No additional libraries or complex functions are needed, just the built-in Math.floor function and basic _______.',
    options: ['operators', 'DOM methods', 'fetch calls', 'regex'],
    correct: 0,
    explanation: '✅ Only simple operations and Math.floor.',
  },
  {
    question:
      'The final code returns a boolean, which is either _______ or false.',
    options: ['true', 'undefined', 'a string', 'NaN'],
    correct: 0,
    explanation: '✅ Only true or false.',
  },
  {
    question:
      'In summary, negative input -> false; same number when reversed -> true; different number when reversed -> _______.',
    options: ['false', 'true', 'NaN', 'null'],
    correct: 0,
    explanation: '✅ Different reversed means false.',
  },
  {
    question:
      'The steps can be condensed into: Check sign, reverse number, compare, return _______ if same.',
    options: ['true', 'false', 'digit', 'string'],
    correct: 0,
    explanation: '✅ If same, return true.',
  },
  {
    question: 'If not the same, _______ is returned.',
    options: ['false', 'true', 'originalNumber', 'reversedNumber'],
    correct: 0,
    explanation: '✅ Not same means false.',
  },
  {
    question:
      'This solution handles all integer inputs consistently using the described mathematical _______.',
    options: ['procedure', 'guessing', 'inheritance', 'caching'],
    correct: 0,
    explanation: '✅ It’s a straightforward procedure.',
  },
  {
    question:
      'We have effectively created a palindrome checker without resorting to _______ methods.',
    options: [
      'string conversion',
      'API calls',
      'DOM manipulation',
      'unrelated code',
    ],
    correct: 0,
    explanation: '✅ Pure math, no string conversion.',
  },
  {
    question:
      'Thus, any integer input x will yield a correct boolean result indicating its palindrome _______.',
    options: ['status', 'length', 'error', 'index'],
    correct: 0,
    explanation: '✅ True if palindrome, false otherwise.',
  },
  {
    question:
      'Examples revisited: 121 -> true, -121 -> false, 123 -> false, 0 -> _______.',
    options: ['true', 'false', 'NaN', 'undefined'],
    correct: 0,
    explanation: '✅ 0 is a palindrome.',
  },
  {
    question:
      'We must remember that any leading zero in a reversed number is dropped because we are dealing with _______.',
    options: ['integers', 'strings', 'floats', 'arrays'],
    correct: 0,
    explanation: '✅ Leading zeros in integers vanish (e.g., 010 -> 10).',
  },
  {
    question:
      'In conclusion, the approach is reliable for determining palindrome status using only arithmetic and basic _______.',
    options: ['control flow', 'fetch APIs', 'CSS styles', 'metadata'],
    correct: 0,
    explanation:
      '✅ Control flow structures (if, while) and arithmetic operations.',
  },
];
