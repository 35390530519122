import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Profile.css'; // Import the CSS file for light and dark mode
import 'bootstrap/dist/css/bootstrap.min.css';

const Profile = () => {
  return (
    <Container className="profile-container mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="profile-card">
            <Card.Body>
              <Card.Title as="h2" className="text-center profile-title">
                Profile
              </Card.Title>
              <Card.Text className="text-center profile-text">
                Your profile information.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
