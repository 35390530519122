export const problemTitle = `### Understanding and Solving **Fibonacci Number** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Fibonacci Number**:`,
  `###### **Step 2:** Program to Calculate **Fibonacci Number**:`,
  `###### **Step 3:** Input a number to calculate its **Fibonacci Number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a number \`n\`, return the \`n\`th **Fibonacci number**.

#### Problem Statement(Elaborated):
Write a program that calculates the \`n\`th **Fibonacci number**. The **Fibonacci sequence** is a sequence of numbers where each number is the sum of the two preceding ones, starting from 0 and 1:
\`\`\`
F(0) = 0, F(1) = 1
F(n) = F(n - 1) + F(n - 2), for n > 1
\`\`\`

#### Testing Examples
1. **Example 1: Input = 5**
   - **Step-by-step**:
     - F(0) = 0, F(1) = 1
     - F(2) = F(1) + F(0) = 1 + 0 = 1
     - F(3) = F(2) + F(1) = 1 + 1 = 2
     - F(4) = F(3) + F(2) = 2 + 1 = 3
     - F(5) = F(4) + F(3) = 3 + 2 = 5
   - **Output**: 5.
2. **Example 2: Input = 10**
   - **Output**: 55.

#### What is the Fibonacci Sequence?
The **Fibonacci sequence** is a series of numbers where the next number is found by adding up the two numbers before it. It is commonly used in nature, art, and mathematical patterns.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate the Fibonacci number iteratively.
2. **Mathematical Operations**: To add numbers sequentially.
3. **Edge Case Handling**: Understanding how to handle small inputs like \`n = 0\` or \`n = 1\`.

#### Real-World Concepts Covered
1. **Recursion Simplified**: Iteratively solving a traditionally recursive problem.
2. **Sequence Generation**: Building patterns based on rules.

#### How Does the Program Work?
1. If \`n = 0\`, return 0.
2. If \`n = 1\`, return 1.
3. Otherwise, calculate the Fibonacci sequence iteratively up to \`n\`.
4. Return the \`n\`th Fibonacci number.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use two variables to keep track of the previous two Fibonacci numbers.
2. **Hint 2**: Iteratively update these two variables to find the next Fibonacci number.
3. **Hint 3**: Handle edge cases for \`n = 0\` and \`n = 1\`.

#### Why Do We Do This?
This problem helps in understanding sequence generation and mathematical patterns while simplifying recursive problems into iterative solutions.
`;

export const initialAlgorithm = `1. If \`n = 0\`, return 0.
2. If \`n = 1\`, return 1.
3. Initialize two variables:
   - \`prev1 = 1\`
   - \`prev2 = 0\`
4. For each number from 2 to \`n\`:
   - a. Calculate \`current = prev1 + prev2\`.
   - b. Update \`prev2 = prev1\` and \`prev1 = current\`.
5. Return \`prev1\`, which holds the \`n\`th Fibonacci number.`;

export const initialProblemCode = `1  function fibonacci(n) {
2    if (n === 0) return 0;
3    if (n === 1) return 1;
4    let prev1 = 1, prev2 = 0;
5    for (let i = 2; i <= n; i++) {
6      let current = prev1 + prev2;
7      prev2 = prev1;
8      prev1 = current;
9    }
10   return prev1;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid non-negative integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function fibonacci is called with n = ${n}`, {
    n,
  });

  // Line 2: Check if n is 0
  addTrace(executionTrace, 2, `Check if n === 0: ${n === 0}`, { n });
  if (n === 0) {
    addTrace(executionTrace, 2, `n === 0, return 0`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The ${n}th Fibonacci number is 0.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Check if n is 1
  addTrace(executionTrace, 3, `Check if n === 1: ${n === 1}`, { n });
  if (n === 1) {
    addTrace(executionTrace, 3, `n === 1, return 1`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The ${n}th Fibonacci number is 1.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let prev1 = 1,
    prev2 = 0;
  addTrace(executionTrace, 4, `Initialize prev1 = ${prev1}, prev2 = ${prev2}`, {
    prev1,
    prev2,
  });

  for (let i = 2; i <= n; i++) {
    let current = prev1 + prev2;
    addTrace(
      executionTrace,
      6,
      `Iteration ${i}: current = prev1 + prev2 = ${prev1} + ${prev2} = ${current}`,
      { current, prev1, prev2 }
    );
    prev2 = prev1;
    prev1 = current;
    addTrace(executionTrace, 8, `Update prev2 = ${prev2}, prev1 = ${prev1}`, {
      prev1,
      prev2,
    });
  }

  addTrace(
    executionTrace,
    10,
    `Return prev1 as the ${n}th Fibonacci number: ${prev1}`,
    { prev1 }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The ${n}th Fibonacci number is ${prev1}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
