export const problemTitle = `### Understanding and Solving **Assign Cookies** Problem`;
export const initialInputValue = '{"g": [1,2], "s": [1,2,3]}';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve the **Assign Cookies** problem using Greedy approach:`,
  `###### **Step 2:** Program to Solve **Assign Cookies** Problem:`,
  `###### **Step 3:** Input values for children greed and cookie sizes:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You are given two arrays:
1. \`g\` (children's greed factors).
2. \`s\` (sizes of cookies).

Each child \`i\` has a greed factor \`g[i]\`, and each cookie \`j\` has a size \`s[j]\`. A child \`i\` can be satisfied with a cookie \`j\` if \`s[j] >= g[i]\`. The goal is to maximize the number of children who can be satisfied.

#### Testing Examples
1. **Example 1**: \`g = [1, 2]\`, \`s = [1, 2, 3]\`
   - **Step-by-step**: 
     - Give cookie of size 1 to child with greed 1.
     - Give cookie of size 2 to child with greed 2.
     - **Result**: 2 children satisfied.
2. **Example 2**: \`g = [1, 2, 3]\`, \`s = [1, 1]\`
   - **Step-by-step**: 
     - Only one child with greed 1 can be satisfied.
     - **Result**: 1 child satisfied.

#### What is the Assign Cookies Problem?
The **Assign Cookies** problem involves distributing cookies to children in a way that satisfies the **maximum number of children**. The solution should focus on greedily assigning the smallest sufficient cookie to each child.

#### What Topics of JavaScript Will Be Covered?
1. **Sorting**: Ordering the greed factors and cookie sizes.
2. **Loops**: Iterating over the children and cookies.
3. **Conditional Statements**: Checking if a cookie can satisfy a child.
4. **Mathematical Comparisons**: Using basic comparisons to match greed factors and cookie sizes.

#### Real-World Concepts Covered
1. **Optimization**: Understanding how to make efficient assignments.
2. **Resource Allocation**: Learning to distribute limited resources effectively.
3. **Logical Thinking**: Enhancing problem-solving skills through greedy algorithms.

#### How Does the Program Work?
1. **Sort** both the greed factors and cookie sizes in **ascending order**.
2. Use two **pointers** (one for children and one for cookies) to iterate through both arrays.
3. Assign the smallest sufficient cookie to each child until either:
   - All children are satisfied, or
   - No cookies are left.
4. Return the total number of children who were satisfied.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Assign the **smallest sufficient cookie** to ensure larger cookies remain available for greedier children.
2. **Hint 2**: If no cookie can satisfy a child, move to the next child.
3. **Hint 3**: Sorting helps in efficiently comparing greed factors and cookie sizes.
4. **Hint 4**: Use two pointers to iterate instead of complex data structures.
5. **Hint 5**: Consider edge cases like empty input arrays.

#### Why Do We Do This?
The **Assign Cookies** problem illustrates how to use a **greedy strategy** to solve optimization problems efficiently. It builds skills for solving real-world problems involving resource allocation.
`;

export const initialAlgorithm = `1. **Sort** the array \`g\` (greed factors) and \`s\` (cookie sizes) in ascending order.
2. Initialize two pointers:
   - **i = 0** (for \`g\`).
   - **j = 0** (for \`s\`).
3. Initialize \`satisfiedChildren = 0\`.
4. While \`i < g.length\` and \`j < s.length\`:
   - a. If \`s[j] >= g[i]\`:
     - **Assign cookie \`j\` to child \`i\`**.
     - Increment \`i\` and \`j\`.
     - Increment \`satisfiedChildren\`.
   - b. Else:
     - Increment \`j\` (move to the next cookie).
5. Return \`satisfiedChildren\`.`;

export const initialProblemCode = `1  function assignCookies(g, s) {
2    g = bubbleSort(g); // Sort greed factors
3    s = bubbleSort(s); // Sort cookie sizes
4    let i = 0, j = 0, satisfiedChildren = 0;
5    while (i < g.length && j < s.length) {
6      if (s[j] >= g[i]) {
7        satisfiedChildren++;
8        i++;
9        j++;
10     } else {
11       j++;
12     }
13   }
14   return satisfiedChildren;
15 }
16 
17 function bubbleSort(arr) {
18   for (let i = 0; i < arr.length; i++) {
19     for (let j = 0; j < arr.length - i - 1; j++) {
20       if (arr[j] > arr[j + 1]) {
21         let temp = arr[j];
22         arr[j] = arr[j + 1];
23         arr[j + 1] = temp;
24       }
25     }
26   }
27   return arr;
28 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let parsedInput;
  try {
    parsedInput = JSON.parse(userInput);
    if (
      !Array.isArray(parsedInput.g) ||
      !Array.isArray(parsedInput.s) ||
      parsedInput.g.some(isNaN) ||
      parsedInput.s.some(isNaN)
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid input: Please enter valid arrays for g and s in JSON format!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let g = parsedInput.g;
  let s = parsedInput.s;

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function assignCookies is called with g = [${g}] and s = [${s}]`,
    { g, s }
  );

  // Sort g and s
  g = bubbleSort(g, executionTrace, 'g');
  s = bubbleSort(s, executionTrace, 's');

  let i = 0,
    j = 0,
    satisfiedChildren = 0;

  addTrace(
    executionTrace,
    4,
    `Initialize i = ${i}, j = ${j}, satisfiedChildren = ${satisfiedChildren}`,
    {
      i,
      j,
      satisfiedChildren,
    }
  );

  while (i < g.length && j < s.length) {
    addTrace(
      executionTrace,
      5,
      `Check while condition: i (${i}) < g.length (${g.length}) and j (${j}) < s.length (${s.length})`,
      {
        i,
        j,
      }
    );
    if (s[j] >= g[i]) {
      satisfiedChildren++;
      i++;
      j++;
      addTrace(
        executionTrace,
        6,
        `s[j] (${s[j]}) >= g[i] (${g[i]}): Increment satisfiedChildren = ${satisfiedChildren}, i = ${i}, j = ${j}`,
        {
          satisfiedChildren,
          i,
          j,
        }
      );
    } else {
      j++;
      addTrace(
        executionTrace,
        11,
        `s[j] (${s[j]}) < g[i] (${g[i]}): Increment j = ${j}`,
        {
          j,
        }
      );
    }
  }

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Maximum number of satisfied children: ${satisfiedChildren}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });

  function bubbleSort(arr, executionTrace, name) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length - i - 1; j++) {
        if (arr[j] > arr[j + 1]) {
          let temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
          addTrace(
            executionTrace,
            21,
            `BubbleSort (${name}): Swap arr[${j}] (${arr[j + 1]}) with arr[${j + 1}] (${arr[j]})`,
            {
              arr,
            }
          );
        }
      }
    }
    return arr;
  }
};
