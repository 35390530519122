import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ChooseHome = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  // Get half of the screen height
  const cardHeight = `${window.innerHeight / 2}px`;

  return (
    <Container
      fluid
      className="d-flex flex-column justify-content-center align-items-center vh-100"
    >
      <Row className="align-items-center mb-4">
        {/* Image on the Left */}
        <Col md={4} className="d-flex justify-content-center">
          <img
            src="/logo.png"
            alt="HLS"
            style={{ width: '200px', height: '200px' }}
          />
        </Col>

        {/* Title and Motivational Tagline on the Right */}
        <Col md={8}>
          <div className="text-center text-md-start">
            <h1
              className="text-primary"
              style={{ fontSize: '2.5rem', fontWeight: 'bold' }}
            >
              🎉 Welcome to Happy Learning Solutions 🎓
            </h1>
            <h5 className="text-secondary" style={{ color: '#ff6f61' }}>
              🌟 Empowering knowledge, shaping futures – take the first step
              today! 🚀
            </h5>
          </div>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md={6} lg={5}>
          <Card
            className="text-center shadow"
            onClick={() => handleNavigate('/btechspace')}
            style={{
              cursor: 'pointer',
              height: cardHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: '1.8rem' }}>
                📘 BTech Space
              </Card.Title>
              <Card.Text>
                <p>
                  Dive into a world of knowledge tailored for BTech students.
                  Access academic resources, programming guides, engineering
                  projects, and exam preparation materials to excel in your
                  studies.
                </p>
                <ul style={{ textAlign: 'left' }}>
                  <li>📚 Programming Tutorials and Notes</li>
                  <li>🛠️ Engineering Projects and Labs</li>
                  <li>📝 Mock Tests and Past Exam Papers</li>
                  <li>📑 Seminar and Research Paper Resources</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={5}>
          <Card
            className="text-center shadow"
            onClick={() => handleNavigate('/jobspace')}
            style={{
              cursor: 'pointer',
              height: cardHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Card.Body>
              <Card.Title style={{ fontSize: '1.8rem' }}>
                💼 Job Space
              </Card.Title>
              <Card.Text>
                <p>
                  Discover the essential tools to launch your career. Prepare
                  for interviews, build your resume, and explore job
                  opportunities tailored to your skills and interests.
                </p>
                <ul style={{ textAlign: 'left' }}>
                  <li>📝 Resume Building Guides and Templates</li>
                  <li>🎤 Mock Interview Preparation</li>
                  <li>🔗 Job Portals and Networking Tips</li>
                  <li>💡 Industry Insights and Career Advice</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChooseHome;
