// Sample dynamic data to be passed to the LargestPerimeterTriangleDemo component

export const problemTitle = `### Understanding and Solving **Largest Perimeter Triangle** Problem`;

export const initialInputValue = '3,6,2';

export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Largest Perimeter Triangle**:`,

  `###### **Step 2:** Program to Calculate **Largest Perimeter Triangle**:`,

  `###### **Step 3:** Input a set of sides to calculate the **Largest Perimeter Triangle**:`,

  `###### **Step 4:** Code Execution Trace Table:`,

  `###### **Step 5:** Result:`,
];

export const initialGuidedDescriptionContent = `

#### Problem Statement:

Given an array of positive integers representing the lengths of sides, find the **largest perimeter** of a triangle that can be formed with three of these lengths. If it is impossible to form a triangle, return \`0\`.



#### Problem Statement(Elaborated):

To form a triangle:

1. The sum of the lengths of any two sides must be **greater** than the length of the third side.



#### Testing Examples

1. **Example 1: Input = [3, 6, 2]**

   - **Step-by-step**:

     - Arrange sides in descending order: [6, 3, 2].

     - Check triangle condition:

       - 6 + 3 > 2 ✅

       - 6 + 2 > 3 ✅

       - 3 + 2 > 6 ❌

     - A triangle cannot be formed. Return 0.

   - **Output**: 0.



2. **Example 2: Input = [3, 6, 8]**

   - **Output**: 17.



#### What is the Triangle Inequality Theorem?

The **triangle inequality theorem** states that for any triangle, the sum of the lengths of any two sides must be **greater** than or equal to the length of the third side.



#### What Topics of JavaScript Will Be Covered?

1. **Sorting**: Manually sorting numbers in descending order.

2. **Loops**: Iterating through the sorted array to check triangle conditions.

3. **Mathematical Comparisons**: Applying inequalities.



#### Real-World Concepts Covered

1. **Geometry**: Understanding triangle properties.

2. **Optimization**: Maximizing the perimeter under given conditions.



#### How Does the Program Work?

1. Sort the sides in descending order.

2. Iterate over the sorted array to check the triangle condition for any three consecutive numbers.

3. Return the largest perimeter if a triangle can be formed. Otherwise, return 0.



#### Hints to Consider While Solving the Problem

1. **Hint 1**: Sorting helps ensure that the largest number is the third side.

2. **Hint 2**: Start with the largest numbers to maximize the perimeter.

3. **Hint 3**: If no triangle can be formed, return 0.

`;

export const initialAlgorithm = `1. Sort the input lengths in descending order.

2. Initialize a variable for the largest perimeter as 0.

3. For each group of three consecutive sides in the sorted array:

   - a. Check if the sum of the two smaller sides is greater than the largest side.

   - b. If the condition is satisfied, calculate the perimeter.

   - c. Return the perimeter immediately as it's the largest due to the sorted order.

4. If no triangle can be formed, return 0.`;

export const initialProblemCode = `1  function largestPerimeterTriangle(sides) {

2    // Sort sides in descending order manually

3    for (let i = 0; i < sides.length - 1; i++) {

4      for (let j = i + 1; j < sides.length; j++) {

5        if (sides[i] < sides[j]) {

6          let temp = sides[i];

7          sides[i] = sides[j];

8          sides[j] = temp;

9        }

10     }

11   }

12   // Check triangle conditions

13   for (let i = 0; i < sides.length - 2; i++) {

14     let a = sides[i], b = sides[i + 1], c = sides[i + 2];

15     if (a < b + c) {

16       return a + b + c;

17     }

18   }

19   return 0;

20 }`;

export const handleProblemRunClick = (
  userInput,

  dispatch,

  ACTION_TYPES,

  addTrace
) => {
  let sides;

  try {
    sides = userInput.split(',').map((num) => parseInt(num.trim(), 10));

    if (sides.some(isNaN)) throw new Error();
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,

      payload:
        'Invalid userInput: Please enter valid side lengths as integers separated by commas!',
    });

    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });

    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });

    return;
  }

  let executionTrace = [];

  addTrace(
    executionTrace,

    1,

    `Function largestPerimeterTriangle is called with sides = [${sides}]`,

    { sides }
  );

  // Line 3-11: Sort sides in descending order manually

  for (let i = 0; i < sides.length - 1; i++) {
    for (let j = i + 1; j < sides.length; j++) {
      if (sides[i] < sides[j]) {
        let temp = sides[i];

        sides[i] = sides[j];

        sides[j] = temp;

        addTrace(
          executionTrace,

          6,

          `Swapped sides[${i}] = ${sides[i]} and sides[${j}] = ${sides[j]}`,

          { sides }
        );
      }
    }
  }

  // Line 13-18: Check triangle conditions

  for (let i = 0; i < sides.length - 2; i++) {
    let a = sides[i],
      b = sides[i + 1],
      c = sides[i + 2];

    addTrace(
      executionTrace,

      14,

      `Checking sides: a = ${a}, b = ${b}, c = ${c}`,

      { a, b, c }
    );

    if (a < b + c) {
      let perimeter = a + b + c;

      addTrace(
        executionTrace,

        16,

        `Triangle can be formed. Largest perimeter = ${perimeter}`,

        { perimeter }
      );

      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,

        payload: `The largest perimeter is ${perimeter}.`,
      });

      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });

      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });

      return;
    }
  }

  // Line 19: No triangle can be formed

  addTrace(
    executionTrace,

    19,

    `No valid triangle can be formed. Returning 0.`,

    { sides }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,

    payload: `No triangle can be formed. The largest perimeter is 0.`,
  });

  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });

  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
