import React from 'react';
import MonacoEditor from '@monaco-editor/react';
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

// Guided Description Component
// This component renders a description or code editor based on the content type
const GuidedDescription = ({
  content,
  handleEditorDidMount,
  contentType = 'TEXT',
}) => {
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
      }}
    >
      <div>
        {contentType === 'TEXT' ? (
          <ReactMarkdown>{content}</ReactMarkdown>
        ) : (
          <MonacoEditor
            height={`400px`}
            language="javascript"
            value={content}
            onMount={handleEditorDidMount}
            options={{
              readOnly: true,
              scrollBeyondLastLine: false,
              wordWrap: 'on',
              minimap: { enabled: false },
              automaticLayout: true,
              fontSize: 15,
              theme: 'vs-light',
              lineNumbers: 'off', // Line numbers are included in the value
              fontFamily: 'Fira Code, monospace', // Suggested appealing font for JavaScript language
            }}
          />
        )}
      </div>
    </div>
  );
};

// Modal Component to Show More Information
const ShowMoreModal = ({
  showGuideModal,
  handleCloseModal,
  guidedModalTitle,
  guidedDescriptionContent,
  handleEditorDidMount,
  contentType = 'TEXT',
}) => {
  return (
    <Modal show={showGuideModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <ReactMarkdown>{guidedModalTitle}</ReactMarkdown>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GuidedDescription
          content={guidedDescriptionContent}
          handleEditorDidMount={handleEditorDidMount}
          contentType={contentType}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Main Component for the Palindrome Number Demo
const ProblemComponent = ({
  problemTitle,
  stepsTitles,
  initialInputValue,
  initialAlgorithm,
  initialProblemCode,
  ACTION_TYPES,
  dispatch,
  trace,
  animationIndex,
  handleInputChange,
  handleRunClick,
  isTraceGenerated,
  ExecutionTraceTable,
  traceRef,
  message,
  handleNext,
  handlePrevious,
  handleReset,
  showGuideModal,
  contentType,
  modalTitle,
  modalDescription,
  handleEditorDidMount,
  handleShowGuidedDescription,
  handleShowProblemAlgorithm,
  handleShowProblemCode,
  isCodeAutoScrollingEnabled,
  handleCodeAutoScrollCheckboxChange,
  isCodeVisible,
  handleCodeVisibleCheckboxChange,
  isTraceTableAutoScrollingEnabled,
  handleTraceTableAutoScrollCheckboxChange,
}) => {
  return (
    <div
      className="container-fluid d-flex flex-column"
      style={{
        fontFamily: 'Roboto, Arial',
        fontSize: '18px',
        paddingTop: '10px',
        paddingLeft: '25px',
        paddingRight: '25px',
        minHeight: '100vh', // Ensure minimum height is always 100% of the viewport
        overflow: 'hidden', // Prevent overflow issues when zooming
      }}
    >
      {/* Use row and col classes to manage columns */}
      <div className="row flex-grow-1 g-4">
        {/* First Column with custom margin-end */}
        <div
          className="col-12 col-lg-5 mb-4 p-3 d-flex flex-column"
          style={{
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div className="row align-items-center mb-2">
              <div className="col" style={{ textAlign: 'left' }}>
                <ReactMarkdown>{problemTitle}</ReactMarkdown>
              </div>
              <div className="col-auto">
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => handleShowGuidedDescription()}
                  className="d-flex align-items-center"
                >
                  📄
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div className="row align-items-center mb-2">
              <div className="col" style={{ textAlign: 'left' }}>
                <ReactMarkdown>{stepsTitles[0]}</ReactMarkdown>
              </div>
              <div className="col-auto">
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => handleShowProblemAlgorithm()}
                >
                  🧠
                </Button>
              </div>
            </div>
            <div
              style={{ height: '70px', overflowY: 'auto', textAlign: 'left' }}
            >
              <ReactMarkdown>{initialAlgorithm}</ReactMarkdown>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div className="row align-items-center mb-2">
              <div className="col" style={{ textAlign: 'left' }}>
                <ReactMarkdown>{stepsTitles[1]}</ReactMarkdown>
              </div>
              <div className="col-auto">
                <label>
                  <input
                    type="checkbox"
                    checked={isCodeVisible}
                    onChange={handleCodeVisibleCheckboxChange}
                  />
                  <strong>Show-Code</strong>
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={isCodeAutoScrollingEnabled}
                    onChange={handleCodeAutoScrollCheckboxChange}
                  />
                  <strong>Auto-Scrolling</strong>
                </label>
                <Button
                  variant="info"
                  size="sm"
                  onClick={() => handleShowProblemCode()}
                >
                  💻
                </Button>
              </div>
            </div>

            {isCodeVisible && (
              <MonacoEditor
                height={`265px`}
                language="javascript"
                value={initialProblemCode}
                onMount={handleEditorDidMount}
                options={{
                  readOnly: true,
                  scrollBeyondLastLine: false,
                  wordWrap: 'on',
                  minimap: { enabled: false },
                  automaticLayout: true,
                  fontSize: 15,
                  theme: 'vs-light',
                  lineNumbers: 'off', // Line numbers are included in the value
                  fontFamily: 'Fira Code, monospace', // Suggested appealing font for JavaScript language
                }}
              />
            )}
          </div>
          <div
            className="d-flex justify-content-between flex-wrap"
            style={{
              borderRadius: '10px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              overflowY: 'auto',
              padding: '10px',
              gap: '10px',
            }}
          >
            <button
              className="btn btn-danger"
              onClick={handleReset}
              aria-label="Reset"
              style={{ transition: 'all 0.3s ease' }}
            >
              🔄 Reset
            </button>
            <button
              className="btn btn-primary"
              onClick={handleRunClick}
              style={{ fontWeight: 'bold', transition: 'all 0.3s ease' }}
              aria-label="Run Code"
            >
              ▶️ Run
            </button>
            <button
              className="btn btn-secondary"
              onClick={handlePrevious}
              disabled={!isTraceGenerated || animationIndex === 0}
              style={{
                visibility: isTraceGenerated ? 'visible' : 'hidden',
                transition: 'all 0.3s ease',
              }}
              aria-label="Previous Step"
            >
              ⬅️ Previous
            </button>
            <button
              className="btn btn-secondary"
              onClick={handleNext}
              disabled={!isTraceGenerated || animationIndex >= trace.length - 1}
              style={{
                visibility: isTraceGenerated ? 'visible' : 'hidden',
                transition: 'all 0.3s ease',
              }}
              aria-label="Next Step"
            >
              Next ➡️
            </button>
          </div>
        </div>

        {/* Second column */}
        <div
          className="col-12 col-lg-7 mb-4 p-3 d-flex flex-column"
          style={{
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div style={{ textAlign: 'left' }}>
              <ReactMarkdown>{stepsTitles[2]}</ReactMarkdown>
            </div>
            <input
              type="text"
              value={initialInputValue}
              onChange={handleInputChange}
              placeholder="Enter an integer"
              className="form-control mb-3"
              style={{
                padding: '15px',
                fontSize: '15px',
                boxSizing: 'border-box',
                border: '2px solid #007bff',
                borderRadius: '10px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              aria-label="Input Number"
            />
          </div>
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div className="row align-items-center mb-2">
              <div className="col" style={{ textAlign: 'left' }}>
                <ReactMarkdown>{stepsTitles[3]}</ReactMarkdown>
              </div>
              <div className="col-auto">
                <label>
                  <input
                    type="checkbox"
                    checked={isTraceTableAutoScrollingEnabled}
                    onChange={handleTraceTableAutoScrollCheckboxChange}
                  />
                  <strong>Auto-Scrolling</strong>
                </label>
              </div>
            </div>
            {isTraceGenerated ? (
              <ExecutionTraceTable
                trace={trace}
                animationIndex={animationIndex}
                traceRef={traceRef} // Pass the ref to ExecutionTraceTable
              />
            ) : (
              <p>Please run the code to see the execution trace.</p>
            )}
          </div>
          <div
            style={{
              border: '1px solid #007bff',
              padding: '5px',
              borderRadius: '10px',
              marginBottom: '5px',
            }}
          >
            <div style={{ textAlign: 'left' }}>
              <ReactMarkdown>{stepsTitles[4]}</ReactMarkdown>
            </div>
            <MonacoEditor
              height={`70px`}
              language="text"
              value={message}
              options={{
                readOnly: true,
                scrollBeyondLastLine: false,
                wordWrap: 'on',
                minimap: { enabled: false },
                automaticLayout: true,
                fontSize: 15,
                theme: 'vs-light',
                fontFamily: 'Courier New, monospace', // Suggested appealing font for text language
              }}
            />
          </div>
        </div>
      </div>

      {/* Modal for Guided Description */}
      <ShowMoreModal
        showGuideModal={showGuideModal}
        handleCloseModal={() =>
          dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: false })
        }
        guidedModalTitle={modalTitle}
        guidedDescriptionContent={modalDescription}
        handleEditorDidMount={handleEditorDidMount}
        contentType={contentType}
      />
      {/* Inline style for highlighting the line */}
      <style>{`
                .myLineHighlight {
                    background-color: rgb(166, 231, 166); /* Green background for highlight */
                }
                .myLineHighlightText {
                    font-size: 15px;
                    font-weight: bold;
                }
            `}</style>
    </div>
  );
};

export default ProblemComponent;
