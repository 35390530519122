import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import Home from '../Home/Home.js';
import MyLearningPage from '../Home/MyLearningPage.js';
import Events from '../Events/Events.js';
import Projects from '../Projects/Projects.js';
import Workspaces from '../Workspaces/Workspaces.js';
import Navbar from '../../components/Navbar.js';
import Footer from '../../components/Footer.js';
import './LearnerDashboard.css'; // Import the CSS specific to this component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const LearnerDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeComponent, setActiveComponent] = useState('home');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 'home';
    setActiveComponent(page);
  }, [location]);

  const renderComponent = () => {
    switch (activeComponent) {
      case 'home':
        return <Home />;
      case 'mylearning':
        return <MyLearningPage />;
      case 'events':
        return <Events hideHeaderFooter={true} />;
      case 'projects':
        return <Projects />;
      case 'workspaces':
        return <Workspaces />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100 learner-dashboard">
      <Navbar />
      <Container>
        <Row className="no-gutters flex-grow-1">
          <Col md={3} lg={2} className="sidebar p-3">
            <Nav className="flex-column">
              <Nav.Item>
                <Button
                  variant="link"
                  className={`nav-link text-start ${activeComponent === 'home' ? 'active' : ''}`}
                  onClick={() => navigate('/learn?page=home')}
                >
                  <i className="bi bi-house"></i> Dashboard
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="link"
                  className={`nav-link text-start ${activeComponent === 'mylearning' ? 'active' : ''}`}
                  onClick={() => navigate('/learn?page=mylearning')}
                >
                  <i className="bi bi-book"></i> My Learning
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="link"
                  className={`nav-link text-start ${activeComponent === 'events' ? 'active' : ''}`}
                  onClick={() => navigate('/learn?page=events')}
                >
                  <i className="bi bi-calendar-event"></i> Events
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="link"
                  className={`nav-link text-start ${activeComponent === 'projects' ? 'active' : ''}`}
                  onClick={() => navigate('/learn?page=projects')}
                >
                  <i className="bi bi-briefcase"></i> Projects
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button
                  variant="link"
                  className={`nav-link text-start ${activeComponent === 'workspaces' ? 'active' : ''}`}
                  onClick={() => navigate('/learn?page=workspaces')}
                >
                  <i className="bi bi-kanban"></i> Workspaces
                </Button>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={9} lg={10} className="p-3 content">
            {renderComponent()}
          </Col>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

export default LearnerDashboard;
