export const problemTitle = `### Understanding and Solving **Climbing Stairs** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate ways to climb stairs:`,
  `###### **Step 2:** Program to Calculate Ways:`,
  `###### **Step 3:** Input the number of steps:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, representing the number of steps in a staircase, return the number of distinct ways you can climb to the top. You can climb either 1 step or 2 steps at a time.

#### Problem Statement(Elaborated):
Write a program that **takes an integer as input** and calculates the number of distinct ways to reach the top of a staircase with \`n\` steps, given that you can either take a single step or two steps at a time.

#### Testing Examples
1. **Example 1: Input = 2**
   - **Step-by-step**:
     - Possible ways: (1 step + 1 step), (2 steps).
     - Total ways: 2.
   - Output: \`2\`.
2. **Example 2: Input = 3**
   - **Step-by-step**:
     - Possible ways: (1 step + 1 step + 1 step), (1 step + 2 steps), (2 steps + 1 step).
     - Total ways: 3.
   - Output: \`3\`.

#### What is the **Climbing Stairs Problem**?
The goal is to find the total number of distinct ways to climb a staircase with \`n\` steps by taking either 1 or 2 steps at a time.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through numbers to calculate ways.
2. **Dynamic Programming**: Breaking the problem into smaller subproblems.
3. **Mathematical Operations**: Using addition to calculate possible ways.

#### Real-World Concepts Covered:
1. **Optimization**: Understanding how to solve problems efficiently.
2. **Dynamic Programming Basics**: Using past results to compute new ones.
3. **Logical Thinking**: Enhancing problem-solving skills through logical breakdown.

#### How Does the Program Work?
1. If \`n\` is 1 or 2, return \`n\` (base cases).
2. Use dynamic programming to compute the total number of ways for larger \`n\`.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Base cases for \`n = 1\` and \`n = 2\` are straightforward (\`1\` and \`2\` ways, respectively).
2. **Hint 2**: Use a loop to compute the ways for \`n > 2\`.
3. **Hint 3**: Keep track of the last two results to avoid recomputation.

#### Why Do We Do This?
This problem introduces the fundamentals of dynamic programming and recursion, which are essential for solving a wide range of computational problems efficiently.
`;

export const initialAlgorithm = `1. If \`n = 1\`, return 1 (only 1 way to climb 1 step).
2. If \`n = 2\`, return 2 (2 ways: [1 step + 1 step], [2 steps]).
3. Initialize two variables:
   - \`prev2 = 1\` (ways to climb to step 1).
   - \`prev1 = 2\` (ways to climb to step 2).
4. Iterate from step 3 to step \`n\`:
   - a. Calculate \`currentWays = prev1 + prev2\`.
   - b. Update \`prev2 = prev1\` and \`prev1 = currentWays\`.
5. Return \`prev1\` as the total ways to climb to step \`n\`.`;

export const initialProblemCode = `1  function climbStairs(n) {
2    if (n === 1) return 1; // Base case for 1 step
3    if (n === 2) return 2; // Base case for 2 steps
4    let prev2 = 1; // Ways to climb to step 1
5    let prev1 = 2; // Ways to climb to step 2
6    for (let i = 3; i <= n; i++) {
7      let currentWays = prev1 + prev2; // Total ways to climb current step
8      prev2 = prev1; // Update for next iteration
9      prev1 = currentWays;
10    }
11    return prev1; // Total ways to climb to step n
12 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 1) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer greater than 0!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function climbStairs is called with n = ${n}`, {
    n,
  });

  if (n === 1) {
    addTrace(executionTrace, 2, `Base case: n = 1, return 1`, { result: 1 });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Total ways to climb ${n} step: 1`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  if (n === 2) {
    addTrace(executionTrace, 3, `Base case: n = 2, return 2`, { result: 2 });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Total ways to climb ${n} steps: 2`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let prev2 = 1;
  let prev1 = 2;
  addTrace(executionTrace, 4, `Initialize prev2 = 1, prev1 = 2`, {
    prev2,
    prev1,
  });

  for (let i = 3; i <= n; i++) {
    let currentWays = prev1 + prev2;
    addTrace(
      executionTrace,
      7,
      `Calculate currentWays for step ${i}: prev1 (${prev1}) + prev2 (${prev2}) = ${currentWays}`,
      { currentWays, step: i }
    );
    prev2 = prev1;
    prev1 = currentWays;
    addTrace(
      executionTrace,
      8,
      `Update prev2 = prev1, prev1 = currentWays: prev2 = ${prev2}, prev1 = ${prev1}`,
      { prev2, prev1 }
    );
  }

  addTrace(
    executionTrace,
    11,
    `Final result: Total ways to climb ${n} steps = ${prev1}`,
    { result: prev1 }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Total ways to climb ${n} steps: ${prev1}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
