import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CenteredText from './CenteredText.js';
import './Testimonials.css'; // Import the component-specific CSS file

const Testimonials = () => {
  return (
    <section className="testimonials mt-5">
      <Container>
        <CenteredText as="h3" centered className="text-center display-5">
          What Our Learners Say
        </CenteredText>
        <Row className="mt-4">
          <Col md={6}>
            <Card className="testimonial-card mb-3">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p>
                    This platform helped me land my first developer job. Highly
                    recommended!
                  </p>
                  <footer className="blockquote-footer">John Doe</footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="testimonial-card mb-3">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <p>
                    The interactive exercises made learning fun and effective.
                  </p>
                  <footer className="blockquote-footer">Jane Smith</footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
