export const problemTitle = `### Understanding and Solving **Divisor Game** Problem`;
export const initialInputValue = '2';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine the winner in the **Divisor Game**:`,
  `###### **Step 2:** Program to Check the Winner of the **Divisor Game**:`,
  `###### **Step 3:** Input a number to determine the **Divisor Game** winner:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Alice and Bob play a game with a number \`n\`. Alice always starts first. In each turn, a player does the following:
1. Choose a positive divisor \`x\` of the current number \`n\` such that \`x < n\`.
2. Replace \`n\` with \`n - x\`.

A player loses the game if they cannot make a move. Return \`true\` if Alice wins the game, assuming both players play optimally. Otherwise, return \`false\`.

#### Testing Examples
1. **Example 1: Input = 2**
   - Alice takes \`x = 1\` (a divisor of 2) and subtracts it from \`n = 2\`, making \`n = 1\`.
   - Bob cannot make a move since no divisor exists for 1. Alice wins.
   - Output: **true**

2. **Example 2: Input = 3**
   - Alice takes \`x = 1\`, making \`n = 2\`.
   - Bob takes \`x = 1\`, making \`n = 1\`.
   - Alice cannot make a move. Bob wins.
   - Output: **false**

3. **Example 3: Input = 4**
   - Alice takes \`x = 1\`, making \`n = 3\`.
   - Bob takes \`x = 1\`, making \`n = 2\`.
   - Alice takes \`x = 1\`, making \`n = 1\`.
   - Bob cannot make a move. Alice wins.
   - Output: **true**

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through divisors.
2. **Mathematical Operations**: To check divisors and manipulate \`n\`.
3. **Conditional Statements**: To evaluate winning conditions.
4. **Modulus Operation**: To identify divisors.

#### Real-World Concepts Covered
1. **Game Theory**: Understanding optimal moves and strategies.
2. **Number Properties**: Analyzing divisors and number behavior.
3. **Logical Thinking**: Strategically determining the winner.

#### How Does the Program Work?
1. If \`n\` is 1, Alice loses immediately since no divisor exists.
2. If \`n\` is even, Alice can always force a win.
3. If \`n\` is odd, Bob wins if both play optimally.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Notice that Alice wins when \`n\` is even and loses when \`n\` is odd.
2. **Hint 2**: The game alternates turns, so starting with an even or odd number determines the winner.
`;

export const initialAlgorithm = `1. Check if \`n\` is even or odd:
   - a. If \`n % 2 === 0\`, return **true** (Alice wins).
   - b. Otherwise, return **false** (Bob wins).`;

export const initialProblemCode = `1  function divisorGame(n) {
2    return n % 2 === 0;
3  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 1) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function divisorGame is called with n = ${n}`, {
    n,
  });

  addTrace(
    executionTrace,
    2,
    `Check if n % 2 === 0: ${n} % 2 === 0 = ${n % 2 === 0}`,
    { n }
  );

  let result = n % 2 === 0;
  addTrace(
    executionTrace,
    3,
    `Return ${result ? 'true' : 'false'} (Alice wins if true, Bob wins if false)`,
    { result }
  );

  let resultMessage = result
    ? `Alice wins since ${n} is even.`
    : `Bob wins since ${n} is odd.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
