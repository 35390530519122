import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../services/api.js';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
} from 'react-bootstrap';
import Navbar from '../../components/Navbar.js';
import Footer from '../../components/Footer.js';
import './Login.css'; // Import the CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showGuestInfo, setShowGuestInfo] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginUser(email, password);
      const { token, role } = response.data;

      // Store the token and role in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);

      // Redirect based on user role
      if (role === 'admin') {
        navigate('/admin');
      } else {
        navigate('/learn');
      }
    } catch (error) {
      console.error(
        'Error logging in:',
        error.response ? error.response.data : error.message
      );
    }
  };

  // Function to toggle guest info visibility
  const handleGuestLogin = () => {
    setShowGuestInfo(true);
  };

  return (
    <div className="d-flex flex-column min-vh-100 login-page">
      <Navbar />
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center flex-grow-1"
      >
        <Row className="flex-grow-1 d-flex align-items-center justify-content-center w-100">
          <Col xs={12} md={8} lg={6} xl={4}>
            <Card className="login-card p-4 mx-auto">
              <h2 className="text-center mb-4">Sign In</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button type="submit" variant="primary" className="w-100 mb-3">
                  Sign In
                </Button>
                <Button
                  variant="secondary"
                  className="w-100"
                  onClick={handleGuestLogin}
                >
                  Show Guest Credentials
                </Button>
              </Form>
              {showGuestInfo && (
                <Alert variant="info" className="mt-3">
                  <p className="mb-1">
                    <strong>Guest Email:</strong> guest@hls.com
                  </p>
                  <p className="mb-0">
                    <strong>Guest Password:</strong> Jai@HLS@123
                  </p>
                </Alert>
              )}
              <div className="text-center mt-3">
                Don&apos;t have an account? <a href="/register">Sign Up</a>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Login;
