import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllCourses, getAllCoursesPublic } from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './CourseCatalog.css'; // Import the CSS file for light/dark mode

const CourseCatalog = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [sortCriteria, setSortCriteria] = useState('popularity');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        let response;

        // Check if the user is logged in
        if (token) {
          response = await getAllCourses(token);
        } else {
          response = await getAllCoursesPublic(); // Call the public API if not logged in
        }

        setCourses(response.data);
        setFilteredCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    let filtered = courses;

    if (searchTerm) {
      filtered = filtered.filter((course) =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (category) {
      filtered = filtered.filter((course) => course.category === category);
    }

    if (difficulty) {
      filtered = filtered.filter((course) => course.difficulty === difficulty);
    }

    if (sortCriteria === 'popularity') {
      filtered = filtered.sort((a, b) => b.popularity - a.popularity);
    } else if (sortCriteria === 'newest') {
      filtered = filtered.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sortCriteria === 'rating') {
      filtered = filtered.sort((a, b) => b.rating - a.rating);
    }

    setFilteredCourses(filtered);
  }, [searchTerm, category, difficulty, sortCriteria, courses]);

  const handleCourseClick = (courseId) => {
    const token = localStorage.getItem('token');

    if (token) {
      // Redirect to the authenticated course page
      navigate(`/course/${courseId}`);
    } else {
      // Redirect to the public course page
      navigate(`/public/course/${courseId}`);
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100 course-catalog">
      <Navbar />
      <Container className="mt-5">
        <Row className="mb-4">
          <Col>
            <Form.Control
              type="text"
              placeholder="Search courses"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-bar"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={4}>
            <Form.Select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="category-select"
            >
              <option value="">All Categories</option>
              <option value="web-development">Web Development</option>
              <option value="data-science">Data Science</option>
              <option value="computer-science">Computer Science</option>
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              className="difficulty-select"
            >
              <option value="">All Levels</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Select
              value={sortCriteria}
              onChange={(e) => setSortCriteria(e.target.value)}
              className="sort-select"
            >
              <option value="popularity">Sort by Popularity</option>
              <option value="newest">Sort by Newest</option>
              <option value="rating">Sort by Rating</option>
            </Form.Select>
          </Col>
        </Row>
        <Row>
          {filteredCourses.map((course) => (
            <Col md={4} key={course.course_id} className="mb-4">
              <Card className="course-card shadow-sm">
                <Card.Img
                  variant="top"
                  src="course-thumbnail.jpg" // Replace with dynamic image if available
                  alt="Course Thumbnail"
                  className="course-thumbnail"
                />
                <Card.Body>
                  <Card.Title className="course-title">
                    {course.title}
                  </Card.Title>
                  <Card.Text className="course-description">
                    {course.description}
                  </Card.Text>
                  <Button
                    onClick={() => handleCourseClick(course.course_id)} // Handle course click
                    variant="primary"
                    className="course-button"
                  >
                    View Details
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CourseCatalog;
