import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Container,
  Spinner,
  Alert,
  Row,
  Col,
} from 'react-bootstrap';
import { getQuizById, updateQuiz } from '../../services/api.js';
import './QuizView.css'; // Import the CSS for light and dark mode styling
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  handleCourseNextActivity,
  handleCoursePreviousActivity,
} from '../../helpers/componentsHelper.js';

const QuizView = ({ courseId, activityId, quizId }) => {
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getQuizById(token, quizId);
        setQuiz(response.data);
        setLoading(false);
      } catch (error) {
        console.error(
          'Error fetching quiz:',
          error.response ? error.response.data : error.message
        );
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const totalScore = Object.values(answers).reduce(
        (acc, answer) => acc + (answer.correct ? 1 : 0),
        0
      );
      await updateQuiz(token, quizId, totalScore);
      setScore(totalScore);
    } catch (error) {
      console.error(
        'Error submitting quiz:',
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading) {
    return (
      <Container className="loading-container d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (!quiz) {
    return (
      <Container className="quiz-container">
        <Alert variant="danger">Failed to load quiz. Please try again.</Alert>
      </Container>
    );
  }

  return (
    <Container className="quiz-container mt-5">
      <h2 className="text-center quiz-title mb-4">{quiz.title}</h2>

      {/* Scrollable Form Container */}
      <div
        className="quiz-form-container"
        style={{ maxHeight: '55vh', overflowY: 'auto' }}
      >
        <Form onSubmit={handleSubmit} className="quiz-form">
          {quiz.questions.map((question) => (
            <div key={question.question_id} className="mb-4 quiz-question">
              <h4>{question.question_text}</h4>
              <h3>{question.code_snippet}</h3>
              {question.options.map((option) => (
                <Form.Check
                  key={option.option_id}
                  type="radio"
                  id={`question_${question.question_id}_option_${option.option_id}`}
                  name={`question_${question.question_id}`}
                  label={option.option_text}
                  value={option.option_id}
                  onChange={() =>
                    setAnswers({
                      ...answers,
                      [question.question_id]: {
                        id: option.option_id,
                        correct: option.is_correct,
                      },
                    })
                  }
                  className="quiz-option"
                />
              ))}
            </div>
          ))}
          <Button variant="primary" type="submit" className="w-100 mb-3">
            Submit Quiz
          </Button>
        </Form>
      </div>

      {score !== null && (
        <Alert variant="info" className="text-center">
          Your score: {score} / {quiz.questions.length}
        </Alert>
      )}
      <Row>
        <Col>
          <Button
            variant="secondary"
            className="back-button"
            onClick={() =>
              handleCoursePreviousActivity(navigate, courseId, activityId)
            }
          >
            Previous
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            className="back-button"
            onClick={() =>
              handleCourseNextActivity(navigate, courseId, activityId)
            }
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

QuizView.propTypes = {
  quizId: PropTypes.number.isRequired,
};

export default QuizView;
