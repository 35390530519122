export const problemTitle = `### Understanding and Solving **Ugly Number** Problem`;
export const initialInputValue = '6';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a number is an **ugly number**:`,
  `###### **Step 2:** Program to Check **Ugly Number**:`,
  `###### **Step 3:** Input a number to check if it is an **ugly number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Write a program to check whether a given number \`num\` is an **ugly number**.

#### Problem Statement(Elaborated):
An **ugly number** is a positive integer whose only prime factors are \`2\`, \`3\`, or \`5\`. For example, \`6\` is an ugly number because its prime factors are \`2\` and \`3\`, while \`14\` is not because it includes a prime factor of \`7\`.

#### Testing Examples
1. **Example 1: Input = 6**
   - **Step-by-step**:
     - \`6\` is divisible by \`2\` → Reduce \`6\` to \`3\`.
     - \`3\` is divisible by \`3\` → Reduce \`3\` to \`1\`.
     - Result: **True**.
2. **Example 2: Input = 14**
   - **Step-by-step**:
     - \`14\` is divisible by \`2\` → Reduce \`14\` to \`7\`.
     - \`7\` is not divisible by \`2\`, \`3\`, or \`5\`.
     - Result: **False**.

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if\` and \`while\` to evaluate divisibility.
2. **Loops**: To repeatedly divide the number by \`2\`, \`3\`, or \`5\`.
3. **Mathematical Operations**: Using modulo (\`%\`) and division (\`/\`) to determine divisibility and reduce the number.

#### Real-World Concepts Covered
1. **Number Theory**: Exploring factors and divisibility.
2. **Problem Simplification**: Reducing a number by repeatedly dividing it by allowed factors.

#### How Does the Program Work?
1. If the number is less than or equal to \`0\`, it is **not an ugly number**.
2. While the number is divisible by \`2\`, \`3\`, or \`5\`, divide it by these numbers.
3. If the number reduces to \`1\`, it is an ugly number. Otherwise, it is not.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use \`num % factor === 0\` to check if \`num\` is divisible by a factor.
2. **Hint 2**: Use \`num = num / factor\` to reduce the number when divisible.
3. **Hint 3**: If the number reduces to \`1\`, it means all its prime factors were \`2\`, \`3\`, or \`5\`.

#### Why Do We Do This?
This problem helps explore factorization, modular arithmetic, and iterative problem-solving techniques.
`;

export const initialAlgorithm = `1. If the number is less than or equal to \`0\`, return \`false\`.
2. While the number is divisible by \`2\`, divide it by \`2\`.
3. While the number is divisible by \`3\`, divide it by \`3\`.
4. While the number is divisible by \`5\`, divide it by \`5\`.
5. If the number equals \`1\` at the end, it is an ugly number. Otherwise, it is not.`;

export const initialProblemCode = `1  function isUgly(num) {
2    if (num <= 0) return false;
3    while (num % 2 === 0) {
4      num = num / 2;
5    }
6    while (num % 3 === 0) {
7      num = num / 3;
8    }
9    while (num % 5 === 0) {
10     num = num / 5;
11   }
12   return num === 1;
13 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let num;
  try {
    num = parseInt(userInput, 10);
    if (isNaN(num)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function isUgly is called with num = ${num}`, {
    num,
  });

  // Line 2: Check if num <= 0
  addTrace(executionTrace, 2, `Check if num <= 0: ${num <= 0}`, { num });
  if (num <= 0) {
    addTrace(executionTrace, 2, `num <= 0, return false`, { num });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${num} is not an ugly number.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3-5: Divide by 2
  while (num % 2 === 0) {
    addTrace(executionTrace, 3, `Check if num % 2 === 0: ${num % 2 === 0}`, {
      num,
    });
    let previousNum = num;
    num = num / 2;
    addTrace(
      executionTrace,
      4,
      `Divide num by 2: num = ${previousNum} / 2 = ${num}`,
      {
        num,
      }
    );
  }

  // Line 6-8: Divide by 3
  while (num % 3 === 0) {
    addTrace(executionTrace, 6, `Check if num % 3 === 0: ${num % 3 === 0}`, {
      num,
    });
    let previousNum = num;
    num = num / 3;
    addTrace(
      executionTrace,
      7,
      `Divide num by 3: num = ${previousNum} / 3 = ${num}`,
      {
        num,
      }
    );
  }

  // Line 9-11: Divide by 5
  while (num % 5 === 0) {
    addTrace(executionTrace, 9, `Check if num % 5 === 0: ${num % 5 === 0}`, {
      num,
    });
    let previousNum = num;
    num = num / 5;
    addTrace(
      executionTrace,
      10,
      `Divide num by 5: num = ${previousNum} / 5 = ${num}`,
      {
        num,
      }
    );
  }

  // Line 12: Check if num === 1
  addTrace(executionTrace, 12, `Check if num === 1: ${num === 1}`, { num });

  let isUgly = num === 1;
  let resultMessage = isUgly
    ? `${userInput} is an ugly number.`
    : `${userInput} is not an ugly number.`;

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: resultMessage,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
