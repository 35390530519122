export const problemTitle = `### Understanding and Solving **Prime Number of Set Bits in Binary Representation** Problem`;
export const initialInputValue = '6 10';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find numbers with prime number of set bits:`,
  `###### **Step 2:** Program to Count **Prime Set Bits**:`,
  `###### **Step 3:** Input a range to count numbers with prime set bits:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two integers \`left\` and \`right\`, return the count of numbers in the inclusive range \`[left, right]\` that have a prime number of \`1\` bits in their binary representation.

#### Problem Statement(Elaborated):
For each number in the range \`[left, right]\`, calculate the number of \`1\` bits in its binary representation. Check if the count is a prime number. Return the count of such numbers.

#### Testing Examples
1. **Example 1: Input = 6 10**
   - **Step-by-step**: 
     - Binary representations: \`6 = 110\`, \`7 = 111\`, \`8 = 1000\`, \`9 = 1001\`, \`10 = 1010\`.
     - Number of \`1\` bits: \`6 (2)\`, \`7 (3)\`, \`8 (1)\`, \`9 (2)\`, \`10 (2)\`.
     - Prime counts: \`7 (3)\`, \`9 (2)\`, \`10 (2)\`.
   - Output: \`4\`.
2. **Example 2: Input = 10 15**
   - **Step-by-step**:
     - Binary representations: \`10 = 1010\`, \`11 = 1011\`, \`12 = 1100\`, \`13 = 1101\`, \`14 = 1110\`, \`15 = 1111\`.
     - Number of \`1\` bits: \`10 (2)\`, \`11 (3)\`, \`12 (2)\`, \`13 (3)\`, \`14 (3)\`, \`15 (4)\`.
     - Prime counts: \`11 (3)\`, \`13 (3)\`, \`14 (3)\`.
   - Output: \`3\`.

#### What is a Prime Number of Set Bits?
A **prime number** is a number greater than 1 with no divisors other than \`1\` and itself. For example:
- **Prime**: \`2, 3, 5, 7, 11\`.
- **Not Prime**: \`1, 4, 6\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through numbers and bits.
2. **Conditional Statements**: To check primality and set bit conditions.
3. **Bit Manipulation**: Counting \`1\` bits in binary representation.

#### Real-World Concepts Covered
1. **Number Theory**: Understanding prime numbers.
2. **Binary Systems**: Exploring binary representation and bit manipulation.
3. **Optimization**: Efficiently checking primality and counting bits.

#### How Does the Program Work?
1. Define a helper function to count the number of \`1\` bits in a number.
2. Define another helper function to check if a number is prime.
3. Loop through each number in the range \`[left, right]\`.
4. For each number:
   - Count the \`1\` bits.
   - Check if the count is a prime number.
   - Increment the result if the count is prime.
5. Return the final count.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use bitwise AND (\`&\`) to check individual bits.
2. **Hint 2**: Use right shift (\`>>\`) to iterate through all bits of a number.
3. **Hint 3**: Use a helper function to check for primality.

#### Why Do We Do This?
This problem combines binary representation and primality testing, demonstrating the importance of both mathematical and computational skills.
`;

export const initialAlgorithm = `1. Define a helper function \`countSetBits(num)\`:
   - a. Initialize \`count = 0\`.
   - b. While \`num > 0\`:
     - Use \`count += num & 1\` to add the least significant bit to \`count\`.
     - Right shift \`num\` by 1.
   - c. Return \`count\`.
2. Define a helper function \`isPrime(num)\`:
   - a. Return \`false\` if \`num <= 1\`.
   - b. Check divisors from \`2\` to \`sqrt(num)\`. Return \`false\` if \`num % i == 0\`.
   - c. Return \`true\`.
3. Initialize \`result = 0\`.
4. Loop through numbers from \`left\` to \`right\`:
   - a. Count set bits using \`countSetBits\`.
   - b. If \`isPrime(count)\`, increment \`result\`.
5. Return \`result\`.`;

export const initialProblemCode = `1  function countSetBits(num) {
2    let count = 0;
3    while (num > 0) {
4      count += num & 1;
5      num = num >> 1;
6    }
7    return count;
8  }
9
10 function isPrime(num) {
11   if (num <= 1) return false;
12   for (let i = 2; i * i <= num; i++) {
13     if (num % i === 0) return false;
14   }
15   return true;
16 }
17
18 function primeSetBits(left, right) {
19   let result = 0;
20   for (let i = left; i <= right; i++) {
21     let setBits = countSetBits(i);
22     if (isPrime(setBits)) {
23       result++;
24     }
25   }
26   return result;
27 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let inputParts;
  try {
    inputParts = userInput.split(' ').map((x) => parseInt(x, 10));
    if (inputParts.length !== 2 || inputParts.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid range in the format "left right"!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  const [left, right] = inputParts;

  let executionTrace = [];
  addTrace(
    executionTrace,
    18,
    `Function primeSetBits is called with left = ${left}, right = ${right}`,
    { left, right }
  );

  let result = 0;
  addTrace(executionTrace, 19, `Initialize result = 0`, { result });

  for (let i = left; i <= right; i++) {
    addTrace(
      executionTrace,
      20,
      `Check number ${i} in range [${left}, ${right}]`,
      { i }
    );

    let setBits = 0;
    let num = i;
    addTrace(executionTrace, 1, `Call countSetBits(${num})`, { num });

    while (num > 0) {
      setBits += num & 1;
      addTrace(
        executionTrace,
        4,
        `Add least significant bit: setBits = ${setBits}`,
        { setBits, num }
      );
      num = num >> 1;
      addTrace(executionTrace, 5, `Right shift num: num = ${num}`, { num });
    }
    addTrace(executionTrace, 7, `Return setBits = ${setBits}`, { setBits });

    let isPrimeNum = true;
    if (setBits <= 1) {
      isPrimeNum = false;
      addTrace(
        executionTrace,
        11,
        `Check isPrime(${setBits}): Not prime, <= 1`,
        { setBits }
      );
    } else {
      for (let j = 2; j * j <= setBits; j++) {
        if (setBits % j === 0) {
          isPrimeNum = false;
          addTrace(
            executionTrace,
            13,
            `Check isPrime(${setBits}): Not prime, divisible by ${j}`,
            { setBits, j }
          );
          break;
        }
      }
    }
    addTrace(executionTrace, 15, `Return isPrime(${setBits}) = ${isPrimeNum}`, {
      isPrimeNum,
    });

    if (isPrimeNum) {
      result++;
      addTrace(executionTrace, 23, `Increment result: result = ${result}`, {
        result,
      });
    }
  }

  addTrace(executionTrace, 26, `Return result = ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The count of numbers with prime set bits in the range is: ${result}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
