export const problemTitle = `### Understanding and Solving **Toeplitz Matrix** Problem`;
export const initialInputValue = `[[1,2,3,4],[5,1,2,3],[9,5,1,2]]`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a matrix is a **Toeplitz Matrix**:`,
  `###### **Step 2:** Program to Check **Toeplitz Matrix**:`,
  `###### **Step 3:** Input a matrix to check if it is a **Toeplitz Matrix**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D matrix, return \`true\` if it is a **Toeplitz Matrix**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
A matrix is called a **Toeplitz Matrix** if every diagonal from top-left to bottom-right has the same elements. 

#### Testing Examples
1. **Example 1: Input = [[1,2,3,4],[5,1,2,3],[9,5,1,2]]**
   - **Output**: \`true\`
2. **Example 2: Input = [[1,2],[2,2]]**
   - **Output**: \`false\`

#### What is a Toeplitz Matrix?
A **Toeplitz Matrix** is a matrix where all elements along a diagonal are the same.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To traverse the matrix diagonally.
2. **Conditional Statements**: To check if elements on a diagonal are equal.
3. **Matrix Traversal**: To navigate and compare values in a 2D matrix.

#### Real-World Concepts Covered
1. **Matrix Properties**: Understanding unique patterns in matrices.
2. **Logical Thinking**: Breaking down the problem into smaller components.

#### How Does the Program Work?
1. Loop through each element of the matrix (except the last row and column).
2. For each element, check if it matches the element diagonally below and to the right.
3. Return \`false\` if any mismatch is found; otherwise, return \`true\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse rows and columns.
2. **Hint 2**: Compare each element with the element in the next row and column.
3. **Hint 3**: Avoid checking elements in the last row and last column since they don't have valid diagonals.

#### Why Do We Do This?
Identifying Toeplitz Matrices is useful in areas like image processing and data analysis where such patterns are significant.
`;

export const initialAlgorithm = `1. Loop through each row (except the last row) and each column (except the last column).
2. For each element at position \`matrix[i][j]\`:
   - Check if it equals the element diagonally below it (\`matrix[i + 1][j + 1]\`).
3. If any mismatch is found, return \`false\`.
4. If all elements match their diagonally adjacent elements, return \`true\`.`;

export const initialProblemCode = `1  function isToeplitzMatrix(matrix) {
2    let rows = matrix.length;
3    let cols = matrix[0].length;
4    for (let i = 0; i < rows - 1; i++) { // Line 4: Traverse rows (except last row)
5      for (let j = 0; j < cols - 1; j++) { // Line 5: Traverse columns (except last column)
6        if (matrix[i][j] !== matrix[i + 1][j + 1]) { // Line 6: Check diagonal elements
7          return false; // Line 7: Return false on mismatch
8        }
9      }
10   }
11   return true; // Line 11: Return true if all diagonals are consistent
12 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let matrix;
  try {
    matrix = JSON.parse(userInput);
    if (!Array.isArray(matrix) || matrix.some((row) => !Array.isArray(row))) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid matrix.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let rows = matrix.length;
  let cols = matrix[0].length;
  let executionTrace = [];

  addTrace(
    executionTrace,
    1,
    `Function isToeplitzMatrix is called with matrix = ${JSON.stringify(matrix)}`,
    { matrix }
  );

  addTrace(
    executionTrace,
    2,
    `Matrix dimensions: rows = ${rows}, cols = ${cols}`,
    { rows, cols }
  );

  for (let i = 0; i < rows - 1; i++) {
    addTrace(executionTrace, 4, `Processing row ${i}`, { i });
    for (let j = 0; j < cols - 1; j++) {
      addTrace(executionTrace, 5, `Processing cell matrix[${i}][${j}]`, {
        i,
        j,
      });

      if (matrix[i][j] !== matrix[i + 1][j + 1]) {
        addTrace(
          executionTrace,
          6,
          `Mismatch found: matrix[${i}][${j}] (${matrix[i][j]}) !== matrix[${i + 1}][${j + 1}] (${matrix[i + 1][j + 1]})`,
          { i, j, value: matrix[i][j], diagonalValue: matrix[i + 1][j + 1] }
        );
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `The matrix is not a Toeplitz Matrix.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }
    }
  }

  addTrace(
    executionTrace,
    11,
    `All diagonals matched. The matrix is a Toeplitz Matrix.`
  );
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The matrix is a Toeplitz Matrix.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
