import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import your Navbar component
import Footer from './Footer.js'; // Import your Footer component
import './Terms.css'; // Import the updated CSS for this component
import 'bootstrap/dist/css/bootstrap.min.css';

const Terms = () => {
  return (
    <div className="d-flex flex-column min-vh-100 terms-container">
      <Navbar /> {/* Add the Navbar at the top */}
      <Container className="mt-5 flex-grow-1">
        <Row>
          <Col>
            <h1>Terms and Conditions</h1>
            <p>
              Welcome to LearnSkill! These terms and conditions outline the
              rules and regulations for the use of our website and services.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>Introduction</h3>
                <p>
                  By accessing this website, you agree to be bound by these
                  terms and conditions. If you do not agree with any of these
                  terms, you are prohibited from using our website.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>Intellectual Property Rights</h3>
                <p>
                  Unless otherwise stated, LearnSkill owns the intellectual
                  property rights for all content and materials on the website.
                  You may access the content for personal use but must not
                  republish, sell, or duplicate any content without our written
                  consent.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>User Obligations</h3>
                <p>
                  As a user of our platform, you agree not to engage in any
                  activities that may harm the website or violate any applicable
                  laws. You must not use the website in any way that causes
                  damage to the website or impairs the availability or
                  accessibility of the website.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>Termination</h3>
                <p>
                  We may terminate or suspend access to our website or services
                  immediately, without prior notice, if you breach the terms and
                  conditions. Upon termination, your right to use our services
                  will cease immediately.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>Governing Law</h3>
                <p>
                  These terms are governed by and construed in accordance with
                  the laws of [Your Location], and you submit to the
                  non-exclusive jurisdiction of the courts in that location.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 terms-card">
              <Card.Body>
                <h3>Contact Information</h3>
                <p>
                  If you have any questions or concerns about these terms,
                  please contact us at:
                </p>
                <p>Email: info@learnskill.com</p>
                <p>Phone: +91 (991) 217-8555</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <p>&copy; 2024 LearnSkill. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Add the Footer at the bottom */}
    </div>
  );
};

export default Terms;
