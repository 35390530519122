import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ActivityNavbar.css';

const ActivityNavbar = ({ toggleSidebar, showSidebar }) => {
  const navigate = useNavigate();

  const toggleNightMode = () => {
    document.body.classList.toggle('night-mode');
    // Store preference in localStorage
    const currentMode = document.body.classList.contains('night-mode')
      ? 'dark'
      : 'light';
    localStorage.setItem('theme', currentMode);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    window.location.href = '/';
  };

  return (
    <Navbar bg="primary" variant="dark" expand="lg" className="mb-4">
      <Container fluid>
        <div className="d-flex align-items-center">
          <Button onClick={() => navigate('/learn')} className="me-2">
            <img
              src="/logo.png"
              alt="HLS"
              style={{ width: '20px', height: '20px' }}
            />
          </Button>
          <Button onClick={() => navigate('/learn')} className="me-2">
            My Home
          </Button>
          <Button onClick={toggleSidebar} className="me-auto">
            {showSidebar ? 'Hide' : 'Show'} Syllabus
          </Button>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="align-items-center">
            <Button
              variant="outline-light"
              className="me-2"
              onClick={toggleNightMode}
            >
              Toggle Night Mode
            </Button>
            <Button variant="link" onClick={() => navigate('/profile')}>
              Profile
            </Button>
            <Button variant="link" onClick={handleLogout}>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ActivityNavbar;
