import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { updateQuiz } from '../../services/api.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const QuizSubmission = () => {
  const [quizId, setQuizId] = useState('');
  const [score, setScore] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await updateQuiz(token, quizId, score);
      alert('Quiz submitted successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error(
        'Error submitting quiz:',
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <h2 className="text-center mb-4">Submit Quiz</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="quizId" className="mb-3">
              <Form.Label>Quiz ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Quiz ID"
                value={quizId}
                onChange={(e) => setQuizId(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="score" className="mb-3">
              <Form.Label>Score</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Score"
                value={score}
                onChange={(e) => setScore(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default QuizSubmission;
