export const problemTitle = `### Understanding and Solving **Maximum Product of Three Numbers** Problem`;
export const initialInputValue = '[-10, -10, 5, 2]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the maximum product of three numbers:`,
  `###### **Step 2:** Program to Calculate **Maximum Product**:`,
  `###### **Step 3:** Input an array to find the maximum product of three numbers:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer array \`nums\`, find three numbers whose product is maximum and return the maximum product.

#### Problem Statement(Elaborated):
Write a program that finds the **maximum product** obtainable by multiplying three integers in the array. The array may contain both positive and negative integers.

#### Testing Examples
1. **Example 1: Input = [-10, -10, 5, 2]**
   - **Step-by-step**: 
     - The product of the three largest values \`[-10, -10, 5]\` is \`-10 * -10 * 5 = 500\`.
   - Output: \`500\`.
2. **Example 2: Input = [1, 2, 3]**
   - **Step-by-step**:
     - The largest product is \`1 * 2 * 3 = 6\`.
   - Output: \`6\`.
3. **Example 3: Input = [-1, -2, -3, -4]**
   - **Step-by-step**:
     - The largest product is \`-1 * -2 * -3 = -6\`.
   - Output: \`-6\`.

#### What is the Maximum Product of Three Numbers?
The **maximum product** of three numbers can be calculated by considering:
1. The product of the three largest numbers.
2. The product of the two smallest (most negative) numbers and the largest positive number.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through the array and find key numbers.
2. **Conditional Statements**: To update minimum and maximum values.
3. **Mathematical Operations**: Calculating products for different combinations.

#### Real-World Concepts Covered
1. **Optimization**: Finding the most favorable numbers for a desired result.
2. **Mathematical Reasoning**: Understanding the interplay between positive and negative numbers.
3. **Problem-Solving**: Simplifying complex problems into smaller manageable steps.

#### How Does the Program Work?
1. Traverse the array to find:
   - The three largest numbers.
   - The two smallest numbers (most negative).
2. Compute:
   - The product of the three largest numbers.
   - The product of the two smallest numbers and the largest number.
3. Return the maximum of the two computed products.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Track the three largest numbers and two smallest numbers during one pass.
2. **Hint 2**: Use comparisons to update the tracked values.
3. **Hint 3**: Compute both potential maximum products and return the larger.

#### Why Do We Do This?
This problem teaches critical thinking about mathematical relationships and how to solve problems involving multiple conditions efficiently.
`;

export const initialAlgorithm = `1. Initialize variables:
   - \`max1\`, \`max2\`, \`max3\` to smallest possible numbers to track the three largest numbers.
   - \`min1\`, \`min2\` to largest possible numbers to track the two smallest numbers.
2. Traverse the array:
   - Update \`max1\`, \`max2\`, \`max3\` as necessary to track the largest values.
   - Update \`min1\`, \`min2\` as necessary to track the smallest values.
3. Compute:
   - \`product1 = max1 * max2 * max3\`.
   - \`product2 = min1 * min2 * max1\`.
4. Return the maximum of \`product1\` and \`product2\`.`;

export const initialProblemCode = `1  function maximumProduct(nums) {
2    let max1 = -Infinity, max2 = -Infinity, max3 = -Infinity;
3    let min1 = Infinity, min2 = Infinity;
4    for (let i = 0; i < nums.length; i++) {
5      let n = nums[i];
6      if (n > max1) {
7        max3 = max2;
8        max2 = max1;
9        max1 = n;
10     } else if (n > max2) {
11       max3 = max2;
12       max2 = n;
13     } else if (n > max3) {
14       max3 = n;
15     }
16     if (n < min1) {
17       min2 = min1;
18       min1 = n;
19     } else if (n < min2) {
20       min2 = n;
21     }
22   }
23   let product1 = max1 * max2 * max3;
24   let product2 = min1 * min2 * max1;
25   return Math.max(product1, product2);
26 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let nums;
  try {
    nums = JSON.parse(userInput);
    if (!Array.isArray(nums) || nums.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid array of numbers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function maximumProduct is called with nums = ${JSON.stringify(nums)}`,
    { nums }
  );

  // Line 2-3: Initialize variables
  let max1 = -Infinity,
    max2 = -Infinity,
    max3 = -Infinity;
  let min1 = Infinity,
    min2 = Infinity;
  addTrace(
    executionTrace,
    2,
    `Initialize max1 = ${max1}, max2 = ${max2}, max3 = ${max3}, min1 = ${min1}, min2 = ${min2}`,
    { max1, max2, max3, min1, min2 }
  );

  // Line 4: Start loop
  for (let i = 0; i < nums.length; i++) {
    let n = nums[i];
    addTrace(executionTrace, 5, `Iteration ${i + 1}: n = ${n}`, { n });

    // Update max1, max2, max3
    if (n > max1) {
      addTrace(
        executionTrace,
        6,
        `Update max values: n (${n}) > max1 (${max1})`,
        { n, max1, max2, max3 }
      );
      max3 = max2;
      max2 = max1;
      max1 = n;
      addTrace(
        executionTrace,
        9,
        `Update max1 = ${max1}, max2 = ${max2}, max3 = ${max3}`,
        { max1, max2, max3 }
      );
    } else if (n > max2) {
      addTrace(
        executionTrace,
        10,
        `Update max values: n (${n}) > max2 (${max2})`,
        { n, max2, max3 }
      );
      max3 = max2;
      max2 = n;
      addTrace(executionTrace, 12, `Update max2 = ${max2}, max3 = ${max3}`, {
        max2,
        max3,
      });
    } else if (n > max3) {
      addTrace(
        executionTrace,
        13,
        `Update max values: n (${n}) > max3 (${max3})`,
        { n, max3 }
      );
      max3 = n;
      addTrace(executionTrace, 14, `Update max3 = ${max3}`, { max3 });
    }

    // Update min1, min2
    if (n < min1) {
      addTrace(
        executionTrace,
        16,
        `Update min values: n (${n}) < min1 (${min1})`,
        { n, min1, min2 }
      );
      min2 = min1;
      min1 = n;
      addTrace(executionTrace, 18, `Update min1 = ${min1}, min2 = ${min2}`, {
        min1,
        min2,
      });
    } else if (n < min2) {
      addTrace(
        executionTrace,
        19,
        `Update min values: n (${n}) < min2 (${min2})`,
        { n, min2 }
      );
      min2 = n;
      addTrace(executionTrace, 20, `Update min2 = ${min2}`, { min2 });
    }
  }

  // Line 23-24: Compute products
  let product1 = max1 * max2 * max3;
  let product2 = min1 * min2 * max1;
  addTrace(
    executionTrace,
    23,
    `Compute product1 = max1 * max2 * max3 = ${max1} * ${max2} * ${max3} = ${product1}`,
    { product1 }
  );
  addTrace(
    executionTrace,
    24,
    `Compute product2 = min1 * min2 * max1 = ${min1} * ${min2} * ${max1} = ${product2}`,
    { product2 }
  );

  // Line 25: Return result
  let result = Math.max(product1, product2);
  addTrace(
    executionTrace,
    25,
    `Return result: Math.max(product1, product2) = Math.max(${product1}, ${product2}) = ${result}`,
    { result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The maximum product of three numbers is ${result}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
