import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getCourseByIdPublic } from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import { Card, Button, Accordion, ListGroup, Spinner } from 'react-bootstrap';
import './PublicCoursePage.css'; // Import the CSS file

const PublicCoursePage = () => {
  const { id } = useParams(); // 'id' is the courseId
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [syllabus, setSyllabus] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const courseResponse = await getCourseByIdPublic(id);
        setCourse(courseResponse.data);

        // Sort sections by order
        const sortedSyllabus = {
          ...courseResponse.data.syllabus,
          sections: courseResponse.data.syllabus.sections.sort(
            (a, b) => a.order - b.order
          ),
        };

        // Sort activities within each section by order
        sortedSyllabus.sections = sortedSyllabus.sections.map((section) => ({
          ...section,
          activities: section.activities.sort((a, b) => a.order - b.order),
        }));

        setSyllabus(sortedSyllabus);
      } catch (error) {
        console.error(
          'Error fetching course details:',
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchCourseDetails();
  }, [id]);

  const handleEnrollRedirect = () => {
    navigate('/login'); // Redirect to login page when "Enroll" button is clicked
  };

  if (!course) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '80vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div className="container-fluid d-flex flex-column p-0 public-course-page">
      <Navbar />
      <div className="container mt-4 mb-5">
        <Card className="mb-4 shadow-sm">
          <Card.Body>
            <Card.Title as="h2" className="mb-3">
              {course.title}
            </Card.Title>
            <Card.Text>{course.description}</Card.Text>
            <Button variant="primary" onClick={handleEnrollRedirect}>
              Enroll in this course
            </Button>
          </Card.Body>
        </Card>

        {syllabus && (
          <Card className="shadow-sm">
            <Card.Body>
              <Card.Title as="h3" className="mb-3">
                Syllabus: {syllabus.title}
              </Card.Title>
              <Accordion defaultActiveKey="0">
                {syllabus.sections.map((section, index) => (
                  <Accordion.Item eventKey={`${index}`} key={section.title}>
                    <Accordion.Header>
                      {section.order}. {section.title}
                    </Accordion.Header>
                    <Accordion.Body>
                      {section.activities && section.activities.length > 0 ? (
                        <ListGroup variant="flush">
                          {section.activities.map((activity) => (
                            <ListGroup.Item
                              key={`${activity.title}-${activity.order}`}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  {activity.activity_type === 'Lesson' &&
                                    activity && (
                                      <Link
                                        to={`/login`}
                                        className="text-decoration-none"
                                      >
                                        <i className="bi bi-journal-text me-2 text-primary"></i>
                                        {activity.title}
                                      </Link>
                                    )}
                                  {activity.activity_type === 'Quiz' &&
                                    activity && (
                                      <Link
                                        to={`/login`}
                                        className="text-decoration-none"
                                      >
                                        <i className="bi bi-question-circle me-2 text-success"></i>
                                        {activity.title}
                                      </Link>
                                    )}
                                  {activity.activity_type ===
                                    'ProblemStatement' &&
                                    activity && (
                                      <Link
                                        to={`/login`}
                                        className="text-decoration-none"
                                      >
                                        <i className="bi bi-code-slash me-2 text-danger"></i>
                                        {activity.title}
                                      </Link>
                                    )}
                                  {activity.activity_type ===
                                    'CodingActivity' &&
                                    activity && (
                                      <Link
                                        to={`/login`}
                                        className="text-decoration-none"
                                      >
                                        <i className="bi bi-code-slash me-2 text-danger"></i>
                                        {activity.title}
                                      </Link>
                                    )}
                                </div>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <p className="text-muted">
                          No activities in this section.
                        </p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        )}

        <div className="d-flex justify-content-end mt-4">
          <Button variant="secondary" onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PublicCoursePage;
