import MinCostClimbingStairsDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/746MinCostClimbingStairsDPEasyDemo.js';
import CountingBitsDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/338CountingBitsDPEasyDemo.js';
import BestTimetoBuyandSellStockDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/121BestTimetoBuyandSellStockDPEasyDemo.js';
import PascalsTriangleDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/118PascalsTriangleDPEasyDemo.js';
import DivisorGameDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/1025DivisorGameDPEasyDemo.js';
import PascalsTriangleIIDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/119PascalsTriangleIIDPEasyDemo.js';
import ClimbingStairsDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/70ClimbingStairsDPEasyDemo.js';
import IsSubsequenceDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/392IsSubsequenceDPEasyDemo.js';
import FibonacciNumberDPEasyDemo from '../../feedComponents/leet-code-problem-statements/JavaScript-Highlighted/Easy/DynamicProgramming/509FibonacciNumberDPEasyDemo.js';

export const dynamicProgrammingEasyProblemStatements = (
  title,
  setComponent
) => {
  if (title === 'Min Cost Climbing Stairs') {
    setComponent(() => MinCostClimbingStairsDPEasyDemo);
  } else if (title === 'Counting Bits') {
    setComponent(() => CountingBitsDPEasyDemo);
  } else if (title === 'Best Time to Buy and Sell Stock') {
    setComponent(() => BestTimetoBuyandSellStockDPEasyDemo);
  } else if (title === 'Pascal s Triangle') {
    setComponent(() => PascalsTriangleDPEasyDemo);
  } else if (title === 'Divisor Game') {
    setComponent(() => DivisorGameDPEasyDemo);
  } else if (title === 'Pascal s Triangle II') {
    setComponent(() => PascalsTriangleIIDPEasyDemo);
  } else if (title === 'Climbing Stairs') {
    setComponent(() => ClimbingStairsDPEasyDemo);
  } else if (title === 'Is Subsequence') {
    setComponent(() => IsSubsequenceDPEasyDemo);
  } else if (title === 'Fibonacci Number') {
    setComponent(() => FibonacciNumberDPEasyDemo);
  }
};
