export const problemTitle = `### Understanding and Solving **Lemonade Change** Problem`;
export const initialInputValue = '[5,5,5,10,20]';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to solve the **Lemonade Change** problem using Greedy approach:`,
  `###### **Step 2:** Program to Solve **Lemonade Change** Problem:`,
  `###### **Step 3:** Input an array of bills to check if change can be provided:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You are working at a lemonade stand where each cup of lemonade costs $5. Customers can pay using $5, $10, or $20 bills. You must provide exact change for each transaction using the bills you have.

Write a program that determines whether it is possible to serve all customers while providing the correct change.

#### Testing Examples
1. **Example 1**: Input = [5,5,5,10,20]
   - **Step-by-step**:
     - Take $5 from the first three customers.
     - Use one $5 to provide change for the fourth customer ($10 bill).
     - Use one $5 and one $10 to provide change for the fifth customer ($20 bill).
     - **Result**: true.
2. **Example 2**: Input = [5,5,10,10,20]
   - **Step-by-step**:
     - Take $5 from the first two customers.
     - Use one $5 to provide change for the third customer ($10 bill).
     - Cannot provide change for the fifth customer ($20 bill).
     - **Result**: false.

#### What is the Lemonade Change Problem?
The **Lemonade Change** problem is about determining if it�s possible to serve all customers by providing exact change using a greedy strategy to minimize the bills given.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through the array of bills.
2. **Conditional Statements**: Handling transactions based on the bill value.
3. **Mathematical Operations**: Calculating the required change for transactions.

#### Real-World Concepts Covered
1. **Resource Management**: Understanding how to manage limited resources (bills) to meet requirements.
2. **Optimization**: Using a greedy approach to make optimal decisions for each transaction.

#### How Does the Program Work?
1. Start with zero $5 and $10 bills.
2. For each bill:
   - If it�s a $5 bill, add it to your $5 count.
   - If it�s a $10 bill, use one $5 bill as change.
   - If it�s a $20 bill, use one $10 and one $5 as change, or use three $5 bills if a $10 is unavailable.
3. If at any point you cannot provide the correct change, return false.
4. If you can serve all customers, return true.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Always use the largest bills available to make change.
2. **Hint 2**: Keep track of the count of $5 and $10 bills.
3. **Hint 3**: If a $20 bill cannot be handled, return false.

#### Why Do We Do This?
The **Lemonade Change** problem demonstrates how greedy strategies can optimize resource allocation. It teaches logical thinking and decision-making under constraints.
`;

export const initialAlgorithm = `1. Initialize counters for $5 bills (\`fiveCount\`) and $10 bills (\`tenCount\`) to 0.
2. Loop through the array of bills:
   - a. If the bill is $5, increment \`fiveCount\`.
   - b. If the bill is $10:
     - Decrement \`fiveCount\` by 1 (use one $5 bill for change).
     - If \`fiveCount < 0\`, return false (not enough change).
     - Increment \`tenCount\`.
   - c. If the bill is $20:
     - First, use one $10 and one $5 for change (if available).
     - If not, use three $5 bills for change.
     - If neither is possible, return false.
3. If the loop completes, return true.`;

export const initialProblemCode = `1  function lemonadeChange(bills) {
2    let fiveCount = 0;
3    let tenCount = 0;
4    for (let i = 0; i < bills.length; i++) {
5      if (bills[i] === 5) {
6        fiveCount++;
7      } else if (bills[i] === 10) {
8        if (fiveCount > 0) {
9          fiveCount--;
10         tenCount++;
11       } else {
12         return false; // Not enough $5 bills for change
13       }
14     } else if (bills[i] === 20) {
15       if (tenCount > 0 && fiveCount > 0) {
16         tenCount--;
17         fiveCount--;
18       } else if (fiveCount >= 3) {
19         fiveCount -= 3;
20       } else {
21         return false; // Not enough change for $20 bill
22       }
23     }
24   }
25   return true; // Successfully served all customers
26 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let bills;
  try {
    bills = JSON.parse(userInput);
    if (
      !Array.isArray(bills) ||
      bills.some((val) => ![5, 10, 20].includes(val))
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid input: Please provide an array of bills containing only 5, 10, or 20.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function lemonadeChange is called with bills = [${bills}]`,
    { bills }
  );

  let fiveCount = 0;
  let tenCount = 0;
  addTrace(
    executionTrace,
    2,
    `Initialize fiveCount = ${fiveCount}, tenCount = ${tenCount}`,
    { fiveCount, tenCount }
  );

  for (let i = 0; i < bills.length; i++) {
    let bill = bills[i];
    addTrace(executionTrace, 4, `Processing bill ${bill} at index ${i}`, {
      bill,
    });

    if (bill === 5) {
      fiveCount++;
      addTrace(executionTrace, 6, `Increment fiveCount = ${fiveCount}`, {
        fiveCount,
      });
    } else if (bill === 10) {
      if (fiveCount > 0) {
        fiveCount--;
        tenCount++;
        addTrace(
          executionTrace,
          9,
          `Use one $5 for change, update fiveCount = ${fiveCount}, tenCount = ${tenCount}`,
          { fiveCount, tenCount }
        );
      } else {
        addTrace(executionTrace, 12, `Cannot provide change for $10 bill`, {});
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `Cannot provide change for $10 bill.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }
    } else if (bill === 20) {
      if (tenCount > 0 && fiveCount > 0) {
        tenCount--;
        fiveCount--;
        addTrace(
          executionTrace,
          16,
          `Use one $10 and one $5 for change, update tenCount = ${tenCount}, fiveCount = ${fiveCount}`,
          { fiveCount, tenCount }
        );
      } else if (fiveCount >= 3) {
        fiveCount -= 3;
        addTrace(
          executionTrace,
          19,
          `Use three $5 bills for change, update fiveCount = ${fiveCount}`,
          { fiveCount }
        );
      } else {
        addTrace(executionTrace, 21, `Cannot provide change for $20 bill`, {});
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `Cannot provide change for $20 bill.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }
    }
  }

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Successfully provided change for all customers.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
