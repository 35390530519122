export const problemTitle = `### Understanding and Solving **Projection Area of 3D Shapes** Problem`;
export const initialInputValue = '2,4,3';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the **Projection Area of 3D Shapes**:`,
  `###### **Step 2:** Program to Calculate **Projection Area**:`,
  `###### **Step 3:** Input grid to calculate **Projection Area**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D grid of integers where each value represents the height of a stack of cubes, calculate the total projection area of the 3D shape.

#### Problem Statement(Elaborated):
The **projection area** consists of three parts:
1. **Top View**: The number of cells in the grid that have a non-zero value.
2. **Front View**: The sum of the maximum heights in each row.
3. **Side View**: The sum of the maximum heights in each column.

#### Testing Examples
1. **Example 1: Grid = [[1,2],[3,4]]**
   - **Step-by-step**:
     - Top View = 4 (4 non-zero values).
     - Front View = 3 + 4 = 7.
     - Side View = 2 + 4 = 6.
     - Total = 4 + 7 + 6 = 17.
2. **Example 2: Grid = [[2]]**
   - **Step-by-step**:
     - Top View = 1.
     - Front View = 2.
     - Side View = 2.
     - Total = 5.

#### What is the Projection Area?
The **projection area** is the sum of the areas of projections of a 3D shape on the xy-plane, yz-plane, and zx-plane.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through 2D grids.
2. **Mathematical Comparisons**: Identifying maximum values in rows and columns.
3. **Arithmetic Operations**: Adding the projection areas.

#### Real-World Concepts Covered
1. **Geometry**: Understanding projections in 3D shapes.
2. **Logical Thinking**: Breaking down 3D problems into 2D projections.

#### How Does the Program Work?
1. Parse the input to extract the grid dimensions and values.
2. Calculate the **Top View**, **Front View**, and **Side View** projections.
3. Sum up all three projections to get the total projection area.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: The **Top View** counts all non-zero values in the grid.
2. **Hint 2**: The **Front View** is calculated by summing the maximum values in each row.
3. **Hint 3**: The **Side View** is calculated by summing the maximum values in each column.

#### Why Do We Do This?
This problem is an excellent exercise in breaking down complex 3D problems into manageable 2D components, showcasing the practical application of computational geometry.
`;

export const initialAlgorithm = `1. Parse the input to construct a 2D grid of integers.
2. Initialize variables to store the areas for:
   - **Top View**: Count of non-zero values.
   - **Front View**: Maximum height in each row.
   - **Side View**: Maximum height in each column.
3. Calculate the **Top View**:
   - For each cell in the grid, increment the count if the value is non-zero.
4. Calculate the **Front View**:
   - For each row in the grid, find the maximum value and add it to the sum.
5. Calculate the **Side View**:
   - For each column in the grid, find the maximum value and add it to the sum.
6. Add the three areas to get the total projection area.
7. Return the total projection area.`;

export const initialProblemCode = `1  function projectionArea(grid) {
2    let topView = 0, frontView = 0, sideView = 0;
3    let rows = grid.length;
4    let cols = grid[0].length;
5    
6    for (let i = 0; i < rows; i++) {
7      let rowMax = 0;
8      for (let j = 0; j < cols; j++) {
9        if (grid[i][j] > 0) topView++;
10       if (grid[i][j] > rowMax) rowMax = grid[i][j];
11      }
12      frontView += rowMax;
13    }
14    
15    for (let j = 0; j < cols; j++) {
16      let colMax = 0;
17      for (let i = 0; i < rows; i++) {
18        if (grid[i][j] > colMax) colMax = grid[i][j];
19      }
20      sideView += colMax;
21    }
22    
23    return topView + frontView + sideView;
24  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let grid;
  try {
    grid = userInput.split(';').map((row) => row.split(',').map(Number));
    if (!grid.length || grid.some((row) => row.some((cell) => isNaN(cell)))) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid grid in the format "a,b,c;d,e,f"',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    2,
    `Function projectionArea is called with grid = ${JSON.stringify(grid)}`,
    { grid }
  );

  let topView = 0,
    frontView = 0,
    sideView = 0;
  let rows = grid.length;
  let cols = grid[0].length;

  addTrace(
    executionTrace,
    3,
    `Grid dimensions are rows = ${rows}, cols = ${cols}`,
    { rows, cols }
  );

  for (let i = 0; i < rows; i++) {
    let rowMax = 0;
    for (let j = 0; j < cols; j++) {
      if (grid[i][j] > 0) {
        topView++;
        addTrace(
          executionTrace,
          9,
          `Incrementing topView for grid[${i}][${j}] = ${grid[i][j]} (topView = ${topView})`,
          { topView }
        );
      }
      if (grid[i][j] > rowMax) {
        rowMax = grid[i][j];
        addTrace(
          executionTrace,
          10,
          `Updating rowMax for row ${i} to ${rowMax}`,
          { rowMax }
        );
      }
    }
    frontView += rowMax;
    addTrace(
      executionTrace,
      12,
      `Adding rowMax to frontView (frontView = ${frontView})`,
      { frontView }
    );
  }

  for (let j = 0; j < cols; j++) {
    let colMax = 0;
    for (let i = 0; i < rows; i++) {
      if (grid[i][j] > colMax) {
        colMax = grid[i][j];
        addTrace(
          executionTrace,
          18,
          `Updating colMax for column ${j} to ${colMax}`,
          { colMax }
        );
      }
    }
    sideView += colMax;
    addTrace(
      executionTrace,
      20,
      `Adding colMax to sideView (sideView = ${sideView})`,
      { sideView }
    );
  }

  let totalArea = topView + frontView + sideView;
  addTrace(
    executionTrace,
    23,
    `Calculating total area (topView + frontView + sideView = ${totalArea})`,
    { totalArea }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The total projection area is ${totalArea}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
