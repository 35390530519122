import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { careerPathsDetails } from '../../data/careerPathDetails.js';
import Navbar from '../Navbar.js';
import Footer from '../Footer.js';
import LoadPDF from '../LoadPDF.js';
import './CareerPathDetails.css';

const CareerPathDetails = () => {
  const { coursePath } = useParams();
  const courseData = careerPathsDetails[coursePath];
  const cardRefs = useRef([]); // Ref to store each card element
  const navigate = useNavigate();

  useEffect(() => {
    if (!courseData) return; // Handle cases where courseData is missing

    // Store a stable reference to the current cards
    const currentCardRefs = cardRefs.current;

    // Set up IntersectionObserver
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
            entry.target.classList.remove('out-of-view');
          } else {
            entry.target.classList.remove('in-view');
            entry.target.classList.add('out-of-view');
          }
        });
      },
      {
        threshold: 0.2, // Trigger when 20% of the card is in view
      }
    );

    // Observe each card
    currentCardRefs.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    // Clean up observer on component unmount
    return () => {
      currentCardRefs.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, [courseData]);

  if (!courseData) {
    return <h2>Course not found</h2>;
  }

  const handleEnrollClick = () => {
    navigate('/contact');
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar />
      <Container className="my-5">
        <Row className="align-items-center mb-5">
          <Col lg={6}>
            <Row className="mb-4">
              <Card
                className="zoom-card"
                ref={(el) => (cardRefs.current[1] = el)}
              >
                <Card.Body>
                  <h1 className="text-center mb-4">{courseData.title}</h1>
                  <p className="lead text-center">{courseData.description}</p>
                </Card.Body>
              </Card>
            </Row>
            <Row className="mb-4">
              <Card
                className="zoom-card mb-5"
                ref={(el) => (cardRefs.current[2] = el)}
              >
                <Card.Body>
                  <h3 className="mb-3">Program Highlights</h3>
                  <ul>
                    {courseData.highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Row>
          </Col>

          <Col lg={6} className="mb-4">
            <Card
              className="zoom-card"
              ref={(el) => (cardRefs.current[0] = el)}
            >
              <Card.Img
                variant="top"
                src={courseData.image}
                alt={courseData.title}
                className="img-fluid rounded"
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Card
            className="zoom-card mb-5"
            ref={(el) => (cardRefs.current[3] = el)}
          >
            <Card.Body>
              <h3 className="mb-3">Skills You Will Master</h3>
              <Row>
                {courseData.skills.map((skill, index) => (
                  <Col md={6} key={index} className="mb-4">
                    <Card
                      className="h-100 shadow-sm zoom-card"
                      ref={(el) => (cardRefs.current[index + 4] = el)}
                    >
                      <Card.Body>
                        <h5>
                          {skill.icon} {skill.title}
                        </h5>
                        <Card.Text>{skill.description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <Row>
          <Col md={6}>
            <Card
              className="zoom-card mb-5"
              ref={(el) =>
                (cardRefs.current[courseData.skills.length + 5] = el)
              }
            >
              <Card.Body>
                <h3 className="mb-3">Program Details</h3>
                <ul>
                  <li>
                    <strong>Skill Level:</strong>{' '}
                    {courseData.programDetails.skillLevel}
                  </li>
                  <li>
                    <strong>Time to Complete:</strong>{' '}
                    {courseData.programDetails.timeToComplete}
                  </li>
                  <li>
                    <strong>Professional Certification:</strong>{' '}
                    {courseData.programDetails.certification}
                  </li>
                  <li>
                    <strong>Prerequisites:</strong>{' '}
                    {courseData.programDetails.prerequisites}
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card
              className="zoom-card mb-5"
              ref={(el) =>
                (cardRefs.current[courseData.skills.length + 6] = el)
              }
            >
              <Card.Body>
                <h3 className="mb-3">Career Support</h3>
                <ul>
                  {courseData.careerSupport.map((support, index) => (
                    <li key={index}>{support}</li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card
              className="zoom-card mb-5"
              ref={(el) =>
                (cardRefs.current[courseData.skills.length + 7] = el)
              }
            >
              <Card.Body>
                <h3 className="mb-3">
                  Why Choose Our Skill Development Program?
                </h3>
                <ul>
                  {courseData.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card
              className="text-center zoom-card mb-5"
              ref={(el) =>
                (cardRefs.current[courseData.skills.length + 8] = el)
              }
            >
              <Card.Body>
                <h4>Join the {courseData.title} Today</h4>
                <p>{courseData.callToAction}</p>
                <Button variant="primary" size="lg" onClick={handleEnrollClick}>
                  Enroll Now
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <LoadPDF title={courseData.title} fileName={courseData.pdf} />
      </Container>
      <Footer />
    </div>
  );
};

export default CareerPathDetails;
