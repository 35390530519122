export const problemTitle = `### Understanding and Solving **Square Root (Sqrt(x)) Using Mathematics** Problem`;
export const initialInputValue = '16';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the square root of a number using mathematics:`,
  `###### **Step 2:** Program to Calculate **Square Root** Using Mathematics:`,
  `###### **Step 3:** Input a number to find its **square root**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a non-negative integer \`x\`, compute and return the square root of \`x\`. The square root should be truncated to the nearest integer if the result is not a perfect square.

#### Problem Statement(Elaborated):
Write a program that **calculates the square root** of a given **non-negative integer** without using built-in functions like \`Math.sqrt()\`. The program should use **mathematical operations** and a **binary search** approach to efficiently calculate the square root.

#### Testing Examples
1. **Example 1: Input = 16**
   - **Step-by-step**: The square root of \`16\` is exactly \`4\`. Therefore, the **output** is **4**.
2. **Example 2: Input = 8**
   - **Step-by-step**: The square root of \`8\` is approximately \`2.828\`. After truncating to the nearest integer, the **output** is **2**.
3. **Example 3: Input = 1**
   - **Step-by-step**: The square root of \`1\` is \`1\`. Therefore, the **output** is **1**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iteratively narrow down the range of potential square roots.
2. **Conditional Statements**: To compare and adjust the search bounds during the calculation.
3. **Mathematical Operations**: Using multiplication and integer division to approximate the square root.

#### Real-World Concepts Covered
1. **Binary Search**: A divide-and-conquer technique to efficiently find the square root.
2. **Truncation**: Handling non-integer results by truncating them to the nearest integer.

#### How Does the Program Work?
1. If \`x\` is 0 or 1, return \`x\` (since the square root of 0 is 0 and the square root of 1 is 1).
2. Use a **binary search approach** to find the square root:
   - Start with \`left = 0\` and \`right = x\`.
   - Check the midpoint: If the square of the midpoint equals \`x\`, return the midpoint.
   - If the square is less than \`x\`, move the left bound up.
   - If the square is greater than \`x\`, move the right bound down.
3. Return the largest integer whose square is less than or equal to \`x\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use integer division (\`Math.floor()\`) to calculate the midpoint of the current range.
2. **Hint 2**: Square the midpoint to compare it against \`x\`.
3. **Hint 3**: Narrow the search range using the binary search logic.

#### Why Do We Do This?
We use this approach to efficiently compute the square root of a number without relying on built-in functions. This method demonstrates a fundamental application of **binary search** and **mathematical reasoning**.
`;

export const initialAlgorithm = `1. If \`x <= 1\`, return \`x\`.
2. Initialize:
   - \`left = 0\`
   - \`right = x\`
   - \`result = 0\`
3. While \`left <= right\`:
   - a. Compute the midpoint: \`mid = Math.floor((left + right) / 2)\`.
   - b. Compute the square of \`mid\`: \`square = mid * mid\`.
   - c. If \`square == x\`, return \`mid\`.
   - d. If \`square < x\`, set \`left = mid + 1\` and \`result = mid\`.
   - e. If \`square > x\`, set \`right = mid - 1\`.
4. Return \`result\`.`;

export const initialProblemCode = `1  function sqrt(x) {
2    if (x <= 1) return x;
3    let left = 0, right = x, result = 0;
4    while (left <= right) {
5      let mid = Math.floor((left + right) / 2);
6      let square = mid * mid;
7      if (square === x) {
8        return mid;
9      } else if (square < x) {
10       left = mid + 1;
11       result = mid;
12     } else {
13       right = mid - 1;
14     }
15   }
16   return result;
17 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let x;
  try {
    x = parseInt(userInput, 10);
    if (isNaN(x) || x < 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a non-negative integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function sqrt is called with x = ${x}`, { x });

  if (x <= 1) {
    addTrace(
      executionTrace,
      2,
      `Check if x <= 1: ${x} <= 1 = ${x <= 1}. Return ${x}.`,
      { x }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The square root of ${x} is ${x}.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let left = 0,
    right = x,
    result = 0;
  addTrace(executionTrace, 3, `Initialize left = 0, right = ${x}, result = 0`, {
    left,
    right,
    result,
  });

  while (left <= right) {
    addTrace(
      executionTrace,
      4,
      `Check while condition: left (${left}) <= right (${right}) = ${
        left <= right
      }`,
      { left, right }
    );

    let mid = Math.floor((left + right) / 2);
    let square = mid * mid;

    addTrace(
      executionTrace,
      5,
      `Calculate mid = Math.floor((left + right) / 2) = ${mid}`,
      { mid }
    );

    addTrace(
      executionTrace,
      6,
      `Calculate square = mid * mid = ${mid} * ${mid} = ${square}`,
      { square }
    );

    if (square === x) {
      addTrace(
        executionTrace,
        7,
        `Check if square === x: ${square} === ${x} = ${square === x}. Return ${mid}.`,
        { square, mid }
      );
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: `The square root of ${x} is ${mid}.`,
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    } else if (square < x) {
      left = mid + 1;
      result = mid;
      addTrace(
        executionTrace,
        10,
        `Check if square < x: ${square} < ${x} = ${square < x}. Update left = ${left}, result = ${result}.`,
        { left, result }
      );
    } else {
      right = mid - 1;
      addTrace(
        executionTrace,
        13,
        `Check if square > x: ${square} > ${x} = ${square > x}. Update right = ${right}.`,
        { right }
      );
    }
  }

  addTrace(executionTrace, 16, `Return result: ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The square root of ${x} is ${result}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
