export const problemTitle = `### Understanding and Solving **Power of Two Using Mathematics** Problem`;
export const initialInputValue = '8';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a number is a **power of two**:`,
  `###### **Step 2:** Program to Check **Power of Two** Using Mathematics:`,
  `###### **Step 3:** Input a number to check if it is a **power of two**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Write a program to determine if a number \`n\` is a **power of two**. A number is a **power of two** if there exists an integer \`x\` such that \`n = 2^x\`.

#### Problem Statement(Elaborated):
If \`n\` is a power of two, it means dividing \`n\` repeatedly by \`2\` (until \`n\` becomes \`1\`) should leave no remainder at any step.

#### Testing Examples
1. **Example 1: Input = 8**
   - **Step-by-step**: 
     - 8 ÷ 2 = 4 (remainder 0)
     - 4 ÷ 2 = 2 (remainder 0)
     - 2 ÷ 2 = 1 (remainder 0)
     - Therefore, the **output** is **true**.
2. **Example 2: Input = 5**
   - **Step-by-step**: 5 ÷ 2 leaves a remainder, so it is **not a power of two**.
   - Therefore, the **output** is **false**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To divide the number iteratively until it becomes 1.
2. **Conditional Statements**: To check remainders and termination conditions.
3. **Mathematical Operations**: Using modulo (\`%\`) and division (\`/\`) to determine divisibility.

#### Real-World Concepts Covered
1. **Bit Manipulation**: Powers of two are often used in binary computations.
2. **Divisibility Rules**: Exploring how numbers can be divided repeatedly to satisfy conditions.

#### How Does the Program Work?
1. If \`n <= 0\`, return \`false\`.
2. Use a loop to repeatedly divide \`n\` by \`2\`:
   - Check if \`n % 2 === 0\`.
   - Update \`n = n / 2\`.
3. If \`n\` becomes \`1\`, return \`true\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Any number less than or equal to \`0\` cannot be a power of two.
2. **Hint 2**: Use \`n % 2\` to check if \`n\` is divisible by \`2\`.
3. **Hint 3**: Stop the loop as soon as \`n\` is no longer divisible by \`2\`.

#### Why Do We Do This?
This problem helps understand divisibility, iterative problem solving, and mathematical reasoning.
`;

export const initialAlgorithm = `1. If \`n <= 0\`, return \`false\`.
2. While \`n > 1\`:
   - a. Check if \`n % 2 !== 0\`. If true, return \`false\`.
   - b. Update \`n = n / 2\`.
3. If the loop completes and \`n == 1\`, return \`true\`.`;

export const initialProblemCode = `1  function isPowerOfTwo(n) {
2    if (n <= 0) return false;
3    while (n > 1) {
4      if (n % 2 !== 0) return false;
5      n = n / 2;
6    }
7    return true;
8  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function isPowerOfTwo is called with n = ${n}`, {
    n,
  });

  // Line 2: Check if n <= 0
  if (n <= 0) {
    addTrace(
      executionTrace,
      2,
      `Check if n <= 0: ${n} <= 0 = ${n <= 0}, return false`,
      { n }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${n} is not a power of two.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Start of while loop
  while (n > 1) {
    addTrace(
      executionTrace,
      3,
      `Check while condition: n (${n}) > 1 = ${n > 1}`,
      { n }
    );

    // Line 4: Check if n % 2 !== 0
    if (n % 2 !== 0) {
      addTrace(
        executionTrace,
        4,
        `Check if n % 2 !== 0: ${n} % 2 !== 0 = ${n % 2 !== 0}, return false`,
        { n }
      );
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: `${n} is not a power of two.`,
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    // Line 5: Update n = n / 2
    let previousN = n;
    n = n / 2;
    addTrace(executionTrace, 5, `Update n: n = ${previousN} / 2 = ${n}`, { n });
  }

  // Line 7: Return true
  addTrace(executionTrace, 7, `Return true: n has been reduced to 1`, { n });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `${userInput} is a power of two.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
