export const conceptName = 'Loops';
export const quiz_collection = [
  {
    question:
      'In JavaScript, a loop is used to _______ a block of code multiple times.',
    options: ['repeat', 'remove', 'ignore', 'shorten'],
    correct: 0,
    explanation:
      '✅ Loops allow you to repeat the same code block multiple times, making the code more efficient.',
  },
  {
    question:
      'Loops are commonly used to _______ tasks such as printing sequences, iterating arrays, and repeating actions until conditions are met.',
    options: ['repeat', 'prevent', 'hide', 'reverse'],
    correct: 0,
    explanation:
      '✅ Loops let you repeat tasks, reducing repetition and enhancing efficiency.',
  },
  {
    question:
      'Apart from printing numbers, loops are also used to _______ through arrays.',
    options: ['iterate', 'erase', 'split', 'compress'],
    correct: 0,
    explanation:
      '✅ Loops are frequently used to iterate through arrays and process each element.',
  },
  {
    question:
      'JavaScript provides several loops (`for`, `while`, `do-while`, `for...of`, `for...in`). One commonly mistaken "loop" not actually mentioned as a loop keyword is _______.',
    options: ['foreach', 'forEachBlock', 'loopof', 'turn'],
    correct: 0,
    explanation:
      '✅ The guide mentions `for`, `while`, `do-while`, `for...of`, and `for...in`, but not `foreach` as a loop keyword.',
  },
  {
    question:
      'The _______ loop runs a block of code a specific number of times, given initialization, condition, and increment steps.',
    options: ['for', 'while', 'do-while', 'for...in'],
    correct: 0,
    explanation:
      '✅ The `for` loop is ideal when you know exactly how many times you want to run the code.',
  },
  {
    question:
      'A standard `for` loop in JavaScript has three parts: initialization, condition, and _______.',
    options: ['increment', 'assignment', 'comparison', 'deletion'],
    correct: 0,
    explanation:
      '✅ The three parts are initialization (e.g., let i=0), condition (i<10), and increment (i++).',
  },
  {
    question:
      'In the loop `for (let i = 1; i <= 5; i++)`, the expression `i <= 5` acts as the _______ that determines how long the loop runs.',
    options: ['condition', 'initializer', 'incrementer', 'terminator'],
    correct: 0,
    explanation:
      '✅ The condition `i <= 5` controls when the loop stops executing.',
  },
  {
    question:
      'In a `for` loop, the loop variable is typically initialized in the _______ section of the loop declaration.',
    options: ['initialization', 'condition', 'body', 'increment'],
    correct: 0,
    explanation:
      '✅ The initialization section sets up the starting point for the loop variable.',
  },
  {
    question:
      'The _______ loop repeats its block as long as a specified condition remains true, checking the condition before each iteration.',
    options: ['while', 'for', 'do-while', 'for...of'],
    correct: 0,
    explanation:
      '✅ The `while` loop checks the condition first. If true, it runs; if false, it stops.',
  },
  {
    question:
      'In a `while` loop, the condition is checked _______ the loop’s code block executes each iteration.',
    options: ['before', 'after', 'twice', 'only once at the start'],
    correct: 0,
    explanation:
      '✅ `while` loops evaluate the condition before running the code each time.',
  },
  {
    question:
      'With `i = 1` and `while (i <= 5) { console.log(i); i++; }`, the loop prints the numbers from 1 to _______.',
    options: ['5', '0', '10', '6'],
    correct: 0,
    explanation:
      '✅ Starting at i=1 and stopping after i>5, it prints 1,2,3,4,5.',
  },
  {
    question:
      'The _______ loop guarantees that the code block runs at least once before checking the condition.',
    options: ['do-while', 'for', 'while', 'for...in'],
    correct: 0,
    explanation:
      '✅ `do-while` executes the code once and then checks the condition.',
  },
  {
    question:
      'In a `do-while` loop, the condition is checked _______ the code block has already executed once.',
    options: ['after', 'before', 'never', 'randomly'],
    correct: 0,
    explanation:
      '✅ The `do-while` loop runs first, then checks the condition at the end.',
  },
  {
    question:
      'Starting with `i = 1` in a `do { console.log(i); i++; } while (i <= 5);` loop, it prints 1 through _______.',
    options: ['5', '4', '6', '10'],
    correct: 0,
    explanation:
      '✅ Since the code runs first, it prints 1 to 5 before stopping.',
  },
  {
    question:
      'The _______ loop is best for iterating over arrays, giving direct access to each element without using indexes.',
    options: ['for...of', 'for...in', 'while', 'do-while'],
    correct: 0,
    explanation:
      '✅ The `for...of` loop directly accesses each element of an iterable like an array.',
  },
  {
    question:
      'The syntax for a `for...of` loop is `for (element _______ iterable) { ... }`.',
    options: ['of', 'in', 'to', 'on'],
    correct: 0,
    explanation:
      '✅ Use the keyword `of` to iterate over each element of an iterable.',
  },
  {
    question:
      'Given `let fruits = ["apple", "banana", "cherry"];` the loop `for (let fruit _______ fruits) { console.log(fruit); }` logs each fruit.',
    options: ['of', 'in', 'from', 'through'],
    correct: 0,
    explanation:
      '✅ Replacing the blank with `of` forms `for (let fruit of fruits)`.',
  },
  {
    question:
      'The _______ loop is used to iterate over the keys (property names) of an object.',
    options: ['for...in', 'for...of', 'while', 'for'],
    correct: 0,
    explanation:
      '✅ `for...in` loops through all enumerable property keys of an object.',
  },
  {
    question:
      '`for (let key in object)` iterates over the _______ of the object.',
    options: ['keys', 'values', 'indexes', 'methods'],
    correct: 0,
    explanation:
      '✅ A `for...in` loop gives you each property name (key) of the object.',
  },
  {
    question:
      'For `let person = { name: "Alice", age: 25 };` using `for (let key in person)`, we access "name" and _______ as keys.',
    options: ['age', 'Alice', '25', 'city'],
    correct: 0,
    explanation: '✅ The keys are "name" and "age".',
  },
  {
    question:
      'The `break` statement inside a loop is used to _______ the loop entirely.',
    options: ['stop', 'skip', 'reset', 'double'],
    correct: 0,
    explanation: '✅ `break` exits the loop immediately.',
  },
  {
    question:
      'If we `break` a `for` loop when `i === 3`, starting from i=1 to i=5, the numbers 1 and _______ will be printed before stopping.',
    options: ['2', '3', '4', '5'],
    correct: 0,
    explanation:
      '✅ The loop prints 1, 2 and then stops at i=3 due to `break`.',
  },
  {
    question:
      'The `continue` statement is used to _______ the current iteration and move on to the next iteration of the loop.',
    options: ['skip', 'stop', 'reverse', 'repeat'],
    correct: 0,
    explanation:
      '✅ `continue` skips the rest of the current iteration’s code.',
  },
  {
    question:
      'If we use `continue` at i=3 in a loop from 1 to 5, the number _______ is not printed.',
    options: ['3', '2', '4', '5'],
    correct: 0,
    explanation: '✅ The iteration where i=3 is skipped, so 3 is not printed.',
  },
  {
    question:
      'When you know the exact number of iterations, the _______ loop is typically used.',
    options: ['for', 'while', 'do-while', 'for...in'],
    correct: 0,
    explanation:
      '✅ For loops are ideal when the number of iterations is predetermined.',
  },
  {
    question:
      'To ensure that code inside the loop executes at least once before checking the condition, use a _______ loop.',
    options: ['do-while', 'while', 'for', 'for...of'],
    correct: 0,
    explanation:
      '✅ `do-while` ensures one execution before evaluating the condition.',
  },
  {
    question:
      'To iterate through an array and print each element directly, a _______ loop is straightforward.',
    options: ['for...of', 'for...in', 'while', 'do-while'],
    correct: 0,
    explanation: '✅ `for...of` makes it easy to access each array element.',
  },
  {
    question:
      'To iterate over the property keys of an object, we use the _______ loop.',
    options: ['for...in', 'for...of', 'while', 'do-while'],
    correct: 0,
    explanation: '✅ `for...in` provides all keys of the object.',
  },
  {
    question: 'To end a loop early, we use the _______ statement.',
    options: ['break', 'continue', 'stop', 'halt'],
    correct: 0,
    explanation: '✅ `break` stops the loop immediately.',
  },
  {
    question:
      'To skip just the current iteration of a loop, we use the _______ statement.',
    options: ['continue', 'break', 'pass', 'quit'],
    correct: 0,
    explanation:
      '✅ `continue` moves to the next iteration without finishing the current one.',
  },
  {
    question:
      'In the multiplication table challenge for the number 5, a _______ loop was used to print the table.',
    options: ['for', 'while', 'do-while', 'for...in'],
    correct: 0,
    explanation:
      '✅ A `for` loop was chosen to print the multiplication table of 5.',
  },
  {
    question:
      'To print each element of `["dog", "cat", "rabbit"]`, the _______ loop was suggested.',
    options: ['for...of', 'for...in', 'while', 'do-while'],
    correct: 0,
    explanation: '✅ `for...of` is ideal for looping through array elements.',
  },
  {
    question: 'To count down from 10 to 1, a _______ loop was demonstrated.',
    options: ['while', 'for', 'do-while', 'for...of'],
    correct: 0,
    explanation:
      '✅ A `while` loop was used to keep looping until the value reached 1.',
  },
  {
    question:
      'To print only odd numbers between 1 and 10, we can use a loop and the _______ statement to skip even numbers.',
    options: ['continue', 'break', 'stop', 'return'],
    correct: 0,
    explanation:
      '✅ `continue` was used to skip even numbers and print only odds.',
  },
  {
    question: 'Loops help reduce repetition and make code more _______.',
    options: ['efficient', 'complicated', 'misleading', 'fragile'],
    correct: 0,
    explanation:
      '✅ By automating repetitive tasks, loops increase efficiency.',
  },
  {
    question:
      'When the number of iterations is unknown, you often use a _______ loop that runs based on a condition.',
    options: ['while', 'for', 'do-while', 'for...of'],
    correct: 0,
    explanation:
      '✅ `while` loops continue until a condition is no longer met.',
  },
  {
    question:
      'The _______ loop structure runs its block once before checking if it should repeat, ensuring at least one execution.',
    options: ['do-while', 'while', 'for', 'for...in'],
    correct: 0,
    explanation:
      '✅ `do-while` executes once, then checks the condition afterward.',
  },
  {
    question:
      'For the loop `for (let i = 1; i <= 5; i++)`, the code executes _______ times.',
    options: ['5', '6', '1', '10'],
    correct: 0,
    explanation: '✅ It runs from i=1 to i=5, inclusive, which is 5 times.',
  },
  {
    question:
      'To iterate over each character in a string directly, you can use the _______ loop.',
    options: ['for...of', 'for...in', 'while', 'do-while'],
    correct: 0,
    explanation: '✅ `for...of` treats strings as iterables of characters.',
  },
  {
    question:
      'It is recommended to use the keyword _______ to define the loop variable in a `for` loop initialization for proper scoping.',
    options: ['let', 'var', 'const', 'none'],
    correct: 0,
    explanation:
      '✅ `let` creates a block-scoped variable suitable for loop counters.',
  },
  {
    question:
      'You might choose a _______ loop over a `for` loop when you do not know the exact number of iterations beforehand.',
    options: ['while', 'for...of', 'for...in', 'do-while'],
    correct: 0,
    explanation:
      '✅ `while` loops are useful when the iteration count is uncertain.',
  },
  {
    question:
      'A key difference is that a _______ loop checks its condition first, while a `do-while` loop checks its condition after running the block.',
    options: ['while', 'for', 'for...of', 'for...in'],
    correct: 0,
    explanation: '✅ `while` checks before, `do-while` checks after execution.',
  },
  {
    question:
      'To prevent infinite loops, ensure the loop’s condition will eventually become _______.',
    options: ['false', 'true', 'undefined', 'infinite'],
    correct: 0,
    explanation:
      '✅ The loop should have a condition that fails at some point.',
  },
  {
    question:
      'If you want to skip to the next iteration of the loop, use the _______ statement.',
    options: ['continue', 'break', 'stop', 'pass'],
    correct: 0,
    explanation:
      '✅ `continue` bypasses the remaining code in the current iteration.',
  },
  {
    question:
      'In `for (let fruit of fruits)`, the variable `fruit` represents the current _______ of the array.',
    options: ['element', 'index', 'key', 'method'],
    correct: 0,
    explanation:
      '✅ Each iteration, `fruit` holds the current element of the `fruits` array.',
  },
  {
    question:
      'In `for (let key in person)`, `person[key]` accesses the _______ associated with that key.',
    options: ['value', 'index', 'condition', 'loop counter'],
    correct: 0,
    explanation:
      '✅ `person[key]` returns the value of the property named by `key`.',
  },
  {
    question:
      'If a loop never updates its counter or condition, it may run _______.',
    options: ['forever', 'zero times', 'once', 'odd times only'],
    correct: 0,
    explanation:
      '✅ Without updating the condition or counter, the loop never ends.',
  },
  {
    question:
      'To ensure a user prompt runs at least once before checking if they want to continue, use a _______ loop.',
    options: ['do-while', 'while', 'for', 'for...in'],
    correct: 0,
    explanation:
      '✅ `do-while` ensures the prompt runs at least once before the condition is checked.',
  },
  {
    question:
      'To stop iterating over an array once a matching element is found, use the _______ statement.',
    options: ['break', 'continue', 'return', 'skip'],
    correct: 0,
    explanation: '✅ `break` stops the loop as soon as you find what you need.',
  },
  {
    question:
      'By incrementing `i` in `while (i <= 5)`, eventually the condition becomes _______ and the loop stops.',
    options: ['false', 'true', 'always true', 'a string'],
    correct: 0,
    explanation:
      '✅ As `i` increments, eventually `i <= 5` is no longer true, stopping the loop.',
  },
];
