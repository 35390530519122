import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import './PrivacyPolicy.css'; // Import the PrivacyPolicy-specific CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const PrivacyPolicy = () => {
  return (
    <div className="d-flex flex-column min-vh-100 privacy-policy-container">
      <Navbar /> {/* Add the Navbar at the top */}
      <Container className="mt-5 flex-grow-1">
        <Row>
          <Col>
            <h1 className="privacy-policy-title">Privacy Policy</h1>
            <p>
              Your privacy is important to us. This privacy policy document
              outlines the types of personal information that are received and
              collected by our website and how they are used.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Information We Collect</h3>
                <p>
                  We collect information that you provide directly to us when
                  you register for an account, update your profile, enroll in a
                  course, or communicate with us. This information may include
                  your name, email address, phone number, and other contact
                  details.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>How We Use Your Information</h3>
                <p>
                  We may use the information we collect for various purposes,
                  including:
                </p>
                <ul>
                  <li>
                    To provide, operate, and maintain our website and services.
                  </li>
                  <li>To process your transactions and manage your orders.</li>
                  <li>
                    To send you updates, newsletters, and promotional content.
                  </li>
                  <li>To improve and personalize your experience.</li>
                  <li>
                    To analyze usage trends and measure the effectiveness of our
                    services.
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Sharing Your Information</h3>
                <p>
                  We do not sell, trade, or rent your personal information to
                  third parties. We may share your information with trusted
                  service providers who assist us in operating our website and
                  delivering our services, provided they agree to keep your
                  information confidential.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Data Security</h3>
                <p>
                  We take appropriate security measures to protect against
                  unauthorized access, alteration, disclosure, or destruction of
                  your personal information. However, please note that no method
                  of transmission over the internet is 100% secure.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Cookies and Tracking Technologies</h3>
                <p>
                  Our website may use cookies and similar tracking technologies
                  to enhance your experience and collect information about your
                  usage patterns. You can choose to disable cookies through your
                  browser settings, but this may affect your ability to use
                  certain features of our site.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Your Rights and Choices</h3>
                <p>
                  You have the right to access, update, or delete your personal
                  information at any time. You can also opt out of receiving
                  promotional communications from us by following the
                  unsubscribe link in our emails.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Changes to This Privacy Policy</h3>
                <p>
                  We may update this privacy policy from time to time. Any
                  changes will be posted on this page with an updated revision
                  date. We encourage you to review this policy periodically to
                  stay informed about how we are protecting your information.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Card className="mb-4 privacy-policy-card">
              <Card.Body>
                <h3>Contact Us</h3>
                <p>
                  If you have any questions or concerns about this privacy
                  policy, please contact us at:
                </p>
                <p>Email: privacy@learnskill.com</p>
                <p>Phone: +91 (991) 217-8555</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <p>&copy; 2024 LearnSkill. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Add the Footer at the bottom */}
    </div>
  );
};

export default PrivacyPolicy;
