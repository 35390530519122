import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCodingSubmission } from '../../services/api.js';
import { Container, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const CodingSubmission = () => {
  const [activityId, setActivityId] = useState('');
  const [code, setCode] = useState('');
  const [result, setResult] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await createCodingSubmission(token, activityId, code, result);
      alert('Coding activity submitted successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error(
        'Error submitting coding activity:',
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <Container>
      <h2 className="my-4">Submit Coding Activity</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="activityId" className="mb-3">
          <Form.Label>Activity ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Activity ID"
            value={activityId}
            onChange={(e) => setActivityId(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="code" className="mb-3">
          <Form.Label>Code</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            placeholder="Enter your code here"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="result" className="mb-3">
          <Form.Label>Result</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the result/output"
            value={result}
            onChange={(e) => setResult(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default CodingSubmission;
