import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the RemoveElementDemo component
const problemTitle = `### Understanding and Solving **Remove Element** Problem`;
const initialInputValue = '3, 2, 2, 3|3'; // Format: numbers separated by commas | element to remove
const stepsTitles = [
  `###### **Step 1:** Algorithm to **remove a specific element** from an **array**:`,
  `###### **Step 2:** Program to Remove **Element**:`,
  `###### **Step 3:** Input the **array** and **element** to **remove**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an **array** \`nums\` and an **integer** \`val\`, **remove all occurrences** of \`val\` **in-place** such that the **remaining elements** appear **at the start** of the array, and return the **new length** of the array.

#### Problem Statement (Elaborated):
Write a program that **removes all occurrences** of a given **element** from an **array**. The program should **take an array and an integer** as input and **modify the array in-place** so that **all elements** not equal to the specified **integer** are **shifted to the front**. **Return the length** of the **modified array**.

#### Testing Examples
1. **Example 1: Input = [3, 2, 2, 3], val = 3**
   - **Step-by-step**: After **removing all occurrences** of **3**, the array is **[2, 2]**. The **output** is **2**.
2. **Example 2: Input = [0, 1, 2, 2, 3, 0, 4, 2], val = 2**
   - **Step-by-step**: After **removing all occurrences** of **2**, the array is **[0, 1, 3, 0, 4]**. The **output** is **5**.
3. **Example 3: Input = [4, 5, 6], val = 7**
   - **Step-by-step**: Since **7** is **not present**, the **output** is **3**.

#### What is the Remove Element Problem?
The **remove element** problem involves **modifying an array** to ensure all occurrences of a **specified element** are **removed**. It requires **in-place modifications** and **basic iteration**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate through the array**.
2. **Conditional Statements**: Using \`if\` to **check conditions** and **skip elements**.
3. **Basic Index Handling**: Understanding how to **move elements** within the array.
4. **In-place Modifications**: Modifying an **array** without using **additional space**.

#### Real-World Concepts Covered
1. **Data Cleanup**: Understanding how to **filter out unwanted elements**.
2. **Logical Thinking**: Enhancing **problem-solving skills** through **conditional checks**.
3. **Array Manipulation**: Understanding how to **work with arrays**.

#### How Does the Program Work?
1. **Iterate** through the **array**.
2. **Track** the **position** of the **next available spot**.
3. For each **element not equal** to the **specified value**, **move it** to the **next available spot**.
4. **Return the new length** of the **filtered elements**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a **pointer** to **track the next available spot**.
2. **Hint 2**: Iterate through the array and **skip over the specified value**.
3. **Hint 3**: Remember to **shift elements** as you **find non-matching elements**.
4. **Hint 4**: Handle **edge cases** like **empty arrays** or **value not present**.
5. **Hint 5**: Avoid using **additional arrays** to store results.

#### Why Do We Do This?
We **perform this task** to **clean up data** by ensuring **unwanted elements are removed**, similar to **data filtering** in **real-world scenarios**.
`;

const initialAlgorithm = `1. Initialize **pointer** \`index\` to **0** (tracks the next position to place a non-\`val\` element).
2. **Iterate** through the array using a **loop**:
   - a. If the **current element** is **not equal** to \`val\`, **copy it** to \`nums[index]\` and **increment** \`index\`.
3. **Return** \`index\` as the **new length** of the array.`;

const initialProblemCode = `1  function removeElement(nums, val) {
2    let index = 0;
3    for (let i = 0; i < nums.length; i++) {
4      if (nums[i] !== val) {
5        nums[index] = nums[i];
6        index++;
7      }
8    }
9    return index;
10 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the RemoveElementDemo
const RemoveElementDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let nums, val;
    try {
      const [numsStr, valStr] = numberInput.split('|');
      nums = numsStr.split(',').map((str) => parseInt(str.trim(), 10));
      val = parseInt(valStr.trim(), 10);

      if (nums.some(isNaN) || isNaN(val)) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter numbers and a value in the format "2,3,3|3".',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function removeElement is called with nums = [${nums}] and val = ${val}`,
      { nums, val }
    );

    let index = 0;
    addTrace(
      executionTrace,
      2,
      `Initialize index = 0 to track the next position for non-val elements.`,
      { index }
    );

    for (let i = 0; i < nums.length; i++) {
      addTrace(
        executionTrace,
        3,
        `Loop iteration ${i}: checking nums[${i}] = ${nums[i]}`,
        { i, nums }
      );

      if (nums[i] !== val) {
        addTrace(
          executionTrace,
          4,
          `nums[${i}] (${nums[i]}) is not equal to val (${val}). Copying nums[${i}] to nums[${index}].`,
          { i, val, nums }
        );

        nums[index] = nums[i];
        addTrace(
          executionTrace,
          5,
          `Placed nums[${i}] at nums[${index}]. Incrementing index to ${index + 1}.`,
          { index }
        );
        index++;
      }
    }

    addTrace(
      executionTrace,
      9,
      `Finished processing. The new length is ${index}.`,
      { index }
    );

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `New length is ${index}. The array after removal is [${nums.slice(
        0,
        index
      )}].`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default RemoveElementDemo;
