export const problemTitle = `### Understanding and Solving **Similar RGB Color** Problem`;
export const initialInputValue = '#09f166';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the closest similar RGB color:`,
  `###### **Step 2:** Program to Find **Similar RGB Color**:`,
  `###### **Step 3:** Input an RGB color to find its similar color:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a hexadecimal RGB color, return the closest color with each component (red, green, blue) represented by identical digits. For example, \`#11ee33\` is valid because each component is made up of identical hex digits.

#### Problem Statement(Elaborated):
Write a program that calculates the closest similar RGB color. For example:
- Input: \`#09f166\`
- Output: \`#11ee66\`

#### Testing Examples
1. **Example 1: Input = #09f166**
   - **Step-by-step**:
     - Closest similar values:
       - Red (\`09\`) → \`11\`.
       - Green (\`f1\`) → \`ee\`.
       - Blue (\`66\`) → \`66\`.
     - Result: \`#11ee66\`.
2. **Example 2: Input = #abcdef**
   - **Step-by-step**:
     - Closest similar values:
       - Red (\`ab\`) → \`aa\`.
       - Green (\`cd\`) → \`cc\`.
       - Blue (\`ef\`) → \`ee\`.
     - Result: \`#aaccee\`.

#### What is a Similar RGB Color?
A **similar RGB color** is defined as having each of its red, green, and blue components represented by identical hexadecimal digits. For example:
- Valid: \`#11ee33\`, \`#22ff99\`.
- Invalid: \`#12ef34\`.

#### What Topics of JavaScript Will Be Covered?
1. **String Manipulation**: To process hex values.
2. **Mathematical Operations**: To calculate distances and rounding.
3. **Conditional Statements**: To determine the closest valid hex values.

#### Real-World Concepts Covered
1. **Color Theory**: Understanding RGB color representation.
2. **Hexadecimal System**: Exploring base-16 number representation.
3. **Optimization**: Finding closest matches using minimal computations.

#### How Does the Program Work?
1. Extract the red, green, and blue components from the input.
2. For each component:
   - Find the closest valid hex value with identical digits.
   - Replace the original value with the closest valid value.
3. Combine the components to form the output color.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Hexadecimal digits range from \`0\` to \`f\`.
2. **Hint 2**: A valid component has both digits identical (\`11, 22, ..., ff\`).
3. **Hint 3**: Use rounding and mathematical operations to find the closest valid value.

#### Why Do We Do This?
This problem combines concepts of string manipulation, hexadecimal arithmetic, and problem-solving in a real-world scenario like color adjustments.
`;

export const initialAlgorithm = `1. Extract the red, green, and blue components from the input string.
2. For each component:
   - a. Convert the component from hexadecimal to decimal.
   - b. Divide the value by 17 to find the closest multiple.
   - c. Round the result and multiply back by 17 to get the closest valid value.
   - d. Convert the value back to a two-character hexadecimal string.
3. Combine the components into the final color string and return.`;

export const initialProblemCode = `1  function closestComponent(component) {
2    let value = parseInt(component, 16);
3    let closest = Math.round(value / 17) * 17;
4    let hex = closest.toString(16);
5    return hex.length === 1 ? '0' + hex : hex;
6  }
7
8  function similarRGB(color) {
9    let red = closestComponent(color.slice(1, 3));
10   let green = closestComponent(color.slice(3, 5));
11   let blue = closestComponent(color.slice(5, 7));
12   return '#' + red + green + blue;
13 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  if (!/^#[0-9a-fA-F]{6}$/.test(userInput)) {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid RGB color in hex format!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    8,
    `Function similarRGB is called with color = ${userInput}`,
    { color: userInput }
  );

  function closestComponent(component) {
    let value = parseInt(component, 16);
    addTrace(
      executionTrace,
      2,
      `Convert ${component} to decimal: value = ${value}`,
      { component, value }
    );

    let closest = Math.round(value / 17) * 17;
    addTrace(
      executionTrace,
      3,
      `Find closest multiple of 17: closest = Math.round(${value} / 17) * 17 = ${closest}`,
      { value, closest }
    );

    let hex = closest.toString(16);
    addTrace(
      executionTrace,
      4,
      `Convert closest (${closest}) back to hex: hex = ${hex}`,
      { closest, hex }
    );

    let paddedHex = hex.length === 1 ? '0' + hex : hex;
    addTrace(
      executionTrace,
      5,
      `Ensure two-character hex: paddedHex = ${paddedHex}`,
      { paddedHex }
    );

    return paddedHex;
  }

  let red = closestComponent(userInput.slice(1, 3));
  addTrace(executionTrace, 9, `Calculate closest for red: red = ${red}`, {
    red,
  });

  let green = closestComponent(userInput.slice(3, 5));
  addTrace(
    executionTrace,
    10,
    `Calculate closest for green: green = ${green}`,
    {
      green,
    }
  );

  let blue = closestComponent(userInput.slice(5, 7));
  addTrace(executionTrace, 11, `Calculate closest for blue: blue = ${blue}`, {
    blue,
  });

  let result = '#' + red + green + blue;
  addTrace(
    executionTrace,
    12,
    `Combine components into result: result = ${result}`,
    { result }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The closest similar RGB color is: ${result}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
