export const problemTitle = `### Understanding and Solving **Is Subsequence** Problem`;
export const initialInputValue = 'abc -> ahbgdc';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if one string is a **subsequence** of another:`,
  `###### **Step 2:** Program to Check Subsequence:`,
  `###### **Step 3:** Input strings to check subsequence:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two strings \`s\` and \`t\`, return \`true\` if \`s\` is a subsequence of \`t\`, and \`false\` otherwise.

#### Problem Statement (Elaborated):
Write a program that checks whether all characters in string \`s\` appear in string \`t\` in the same order. Characters do not need to be consecutive but must be in the correct order.

#### Testing Examples
1. **Example 1**: Input: \`s = "abc", t = "ahbgdc"\`
   - **Output**: true (All characters of "abc" appear in "ahbgdc" in order).
2. **Example 2**: Input: \`s = "axc", t = "ahbgdc"\`
   - **Output**: false ("x" does not appear in "ahbgdc").

#### What Topics of JavaScript Will Be Covered?
1. **Conditional Statements**: Using \`if-else\` to compare characters.
2. **Loops**: To iterate through strings \`s\` and \`t\`.
3. **Indexes and Pointers**: Using variables to track positions in strings.
4. **Edge Case Handling**: Understanding how to handle empty strings.

#### Real-World Concepts Covered:
1. **Order Sensitivity**: Ensuring sequence while comparing data.
2. **Pattern Matching**: Identifying ordered patterns in larger datasets.

#### How Does the Program Work?
1. Start with two pointers, one for \`s\` and one for \`t\`.
2. Iterate through \`t\` and check if each character matches the current character of \`s\`.
3. If all characters of \`s\` are found in \`t\` in order, return true. Otherwise, return false.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Use a pointer for \`s\` to track matched characters.
2. **Hint 2**: Increment the pointer for \`s\` only when a match is found.
3. **Hint 3**: If the pointer for \`s\` reaches the end, all characters have been matched.

#### Why Do We Do This?
This concept is commonly used in searching and matching problems, such as validating patterns in sequences, and understanding order dependencies.
`;

export const initialAlgorithm = `1. Initialize two variables:
   - \`i = 0\`: Pointer for string \`s\`.
   - \`j = 0\`: Pointer for string \`t\`.
2. Loop through \`t\` while \`i < length of s\` and \`j < length of t\`:
   - a. If \`s[i] == t[j]\`, increment \`i\`.
   - b. Increment \`j\` in every iteration.
3. If \`i == length of s\`, return \`true\`. Otherwise, return \`false\`.`;

export const initialProblemCode = `1  function isSubsequence(s, t) {
2    let i = 0, j = 0;
3    while (i < s.length && j < t.length) {
4      if (s[i] === t[j]) {
5        i++;
6      }
7      j++;
8    }
9    return i === s.length;
10 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let inputs = userInput.split('->').map((str) => str.trim());
  let s = inputs[0] || '';
  let t = inputs[1] || '';

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isSubsequence is called with s = "${s}", t = "${t}"`,
    {
      s,
      t,
    }
  );

  let i = 0;
  let j = 0;
  addTrace(executionTrace, 2, `Initialize i = 0, j = 0`, { i, j });

  while (i < s.length && j < t.length) {
    addTrace(
      executionTrace,
      3,
      `Check while condition: i (${i}) < s.length (${s.length}) && j (${j}) < t.length (${t.length})`,
      { i, j, s_length: s.length, t_length: t.length }
    );

    if (s[i] === t[j]) {
      addTrace(
        executionTrace,
        4,
        `Match found: s[${i}] ("${s[i]}") == t[${j}] ("${t[j]}"), increment i`,
        { currentCharS: s[i], currentCharT: t[j], i, j }
      );
      i++;
    } else {
      addTrace(
        executionTrace,
        4,
        `No match: s[${i}] ("${s[i]}") != t[${j}] ("${t[j]}"), no increment for i`,
        { currentCharS: s[i], currentCharT: t[j], i, j }
      );
    }

    addTrace(executionTrace, 7, `Increment j`, { j });
    j++;
  }

  let result = i === s.length;
  addTrace(
    executionTrace,
    9,
    `Check if i (${i}) == s.length (${s.length}): ${result}`,
    { i, s_length: s.length }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: result
      ? `"${s}" is a subsequence of "${t}".`
      : `"${s}" is NOT a subsequence of "${t}".`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
