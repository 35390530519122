export const problemTitle = `### Understanding and Solving **Largest Triangle Area** Problem`;
export const initialInputValue = '0,0 0,1 1,0 0,2 2,0';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the largest triangle area:`,
  `###### **Step 2:** Program to Calculate **Largest Triangle Area**:`,
  `###### **Step 3:** Input a set of points to calculate the largest triangle area:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a set of points on a 2D plane, return the largest possible area of a triangle formed by three different points.

#### Problem Statement(Elaborated):
Write a program that calculates the largest triangle area using given points. For example:
- Input: \`(0,0), (0,1), (1,0), (0,2), (2,0)\`
- Output: Largest triangle area.

#### Testing Examples
1. **Example 1: Input = (0,0), (0,1), (1,0), (0,2), (2,0)**
   - **Step-by-step**:
     - Area using points \`(0,0), (0,1), (1,0)\`: \`0.5\`.
     - Area using points \`(0,0), (0,1), (0,2)\`: \`0.0\`.
     - Area using points \`(0,0), (0,1), (2,0)\`: \`1.0\`.
     - Result: \`Largest area = 2.0\`.
2. **Example 2: Input = (0,0), (1,1), (1,0)**
   - **Step-by-step**:
     - Area using points \`(0,0), (1,1), (1,0)\`: \`0.5\`.
     - Result: \`Largest area = 0.5\`.

#### What is the Area of a Triangle in a 2D Plane?
The area of a triangle with vertices \`(x1, y1)\`, \`(x2, y2)\`, and \`(x3, y3)\` is given by:
\`
Area = 0.5 * |x1*(y2-y3) + x2*(y3-y1) + x3*(y1-y2)|
\`

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through combinations of points.
2. **Mathematical Operations**: To calculate the triangle area.
3. **Conditional Statements**: To find the maximum area.

#### Real-World Concepts Covered
1. **Geometry**: Understanding triangle area computation.
2. **Combinatorics**: Iterating through combinations of points.
3. **Optimization**: Finding the maximum value among results.

#### How Does the Program Work?
1. Parse the input to extract coordinates.
2. Use a nested loop to generate all combinations of three points.
3. For each combination:
   - Compute the triangle's area.
   - Track the maximum area found so far.
4. Return the maximum area.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use the area formula for triangles in a 2D plane.
2. **Hint 2**: A triangle cannot have zero area if its vertices are collinear.
3. **Hint 3**: Avoid precomputing all combinations; use nested loops directly.

#### Why Do We Do This?
This problem demonstrates the application of geometry in computational scenarios, showcasing problem-solving techniques in a mathematical context.
`;

export const initialAlgorithm = `1. Parse the input points.
2. Initialize \`maxArea = 0\`.
3. Use nested loops to iterate through all combinations of three points.
4. For each combination:
   - a. Compute the triangle's area using the formula:
     \`area = 0.5 * Math.abs(x1*(y2-y3) + x2*(y3-y1) + x3*(y1-y2))\`.
   - b. If \`area > maxArea\`, update \`maxArea\`.
5. Return \`maxArea\`.`;

export const initialProblemCode = `1  function largestTriangleArea(points) {
2    let maxArea = 0;
3    for (let i = 0; i < points.length; i++) {
4      for (let j = i + 1; j < points.length; j++) {
5        for (let k = j + 1; k < points.length; k++) {
6          let [x1, y1] = points[i];
7          let [x2, y2] = points[j];
8          let [x3, y3] = points[k];
9          let area = Math.abs(
10            x1 * (y2 - y3) +
11            x2 * (y3 - y1) +
12            x3 * (y1 - y2)
13          ) / 2;
14          if (area > maxArea) {
15            maxArea = area;
16          }
17        }
18      }
19    }
20    return maxArea;
21  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let points;
  try {
    points = userInput
      .split(' ')
      .map((p) => p.split(',').map((x) => parseInt(x, 10)));
    if (points.some((p) => p.length !== 2 || p.some((coord) => isNaN(coord)))) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter valid points in the format "x1,y1 x2,y2 x3,y3 ..."!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    2,
    `Function largestTriangleArea is called with points = ${JSON.stringify(
      points
    )}`,
    { points }
  );

  let maxArea = 0;
  addTrace(executionTrace, 2, `Initialize maxArea = 0`, { maxArea });

  for (let i = 0; i < points.length; i++) {
    for (let j = i + 1; j < points.length; j++) {
      for (let k = j + 1; k < points.length; k++) {
        let [x1, y1] = points[i];
        let [x2, y2] = points[j];
        let [x3, y3] = points[k];
        addTrace(
          executionTrace,
          6,
          `Consider points (${x1},${y1}), (${x2},${y2}), (${x3},${y3})`,
          { x1, y1, x2, y2, x3, y3 }
        );

        let area =
          Math.abs(x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2;
        addTrace(executionTrace, 13, `Calculate area: area = ${area}`, {
          area,
        });

        if (area > maxArea) {
          maxArea = area;
          addTrace(executionTrace, 15, `Update maxArea: maxArea = ${maxArea}`, {
            maxArea,
          });
        }
      }
    }
  }

  addTrace(executionTrace, 20, `Return maxArea = ${maxArea}`, { maxArea });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The largest triangle area is: ${maxArea}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
