export const problemTitle = `### Understanding and Solving **Valid Palindrome II** Problem`;
export const initialInputValue = '"abca"';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to check if a string is a **valid palindrome** after at most one deletion:`,
  `###### **Step 2:** Program to Check **Valid Palindrome II**:`,
  `###### **Step 3:** Input a string to check if it can become a **palindrome**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string \`s\`, return \`true\` if \`s\` can become a **palindrome** after deleting **at most one character**. Otherwise, return \`false\`.

#### Problem Statement (Elaborated):
Write a program to check if a given string can be turned into a **palindrome** by removing **at most one character**. A **palindrome** is a string that reads the same forward and backward.

#### Testing Examples
1. **Example 1**: Input = "abca"
   - **Step-by-step**:
     - If you remove 'b', the string becomes "aca", which is a palindrome.
     - **Result**: true.
2. **Example 2**: Input = "racecar"
   - **Step-by-step**:
     - The string is already a palindrome. No deletion is needed.
     - **Result**: true.
3. **Example 3**: Input = "abc"
   - **Step-by-step**:
     - Even after removing any one character, the string cannot become a palindrome.
     - **Result**: false.

#### What is Valid Palindrome II?
A **valid palindrome II** problem checks if removing at most **one character** can turn the string into a **palindrome**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating over the string to check for mismatches.
2. **Conditional Statements**: Handling mismatches and recursive checks.
3. **String Manipulation**: Checking substrings by skipping characters.

#### Real-World Concepts Covered
1. **Symmetry**: Understanding how symmetry in strings is used to identify palindromes.
2. **Logical Thinking**: Solving problems with recursive strategies and condition handling.

#### How Does the Program Work?
1. Use two pointers to check if the string is a palindrome.
2. If a mismatch is found:
   - Skip the left character and check the remaining string.
   - Skip the right character and check the remaining string.
3. Return true if either case results in a palindrome, else return false.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use two pointers, one starting from the beginning and the other from the end.
2. **Hint 2**: If a mismatch is found, recursively check two cases: removing the left character or the right character.
3. **Hint 3**: A string with no mismatches is a palindrome.
4. **Hint 4**: Handle edge cases like an empty string or a single character directly.

#### Why Do We Do This?
The **Valid Palindrome II** problem illustrates the use of a **greedy strategy** and recursion to solve real-world optimization problems efficiently. It builds understanding of symmetry, recursion, and logical reasoning.
`;

export const initialAlgorithm = `1. Use two pointers, \`left\` and \`right\`, starting at the beginning and end of the string.
2. While \`left < right\`:
   - a. If the characters at \`left\` and \`right\` are equal, move the pointers inward.
   - b. If they are not equal:
     - Check if skipping the character at \`left\` makes the rest of the string a palindrome.
     - Check if skipping the character at \`right\` makes the rest of the string a palindrome.
     - Return \`true\` if either check succeeds, else return \`false\`.
3. Return \`true\` if no mismatches are found.`;

export const initialProblemCode = `1  function validPalindrome(s) {
2    function isPalindrome(low, high) {
3      while (low < high) {
4        if (s[low] !== s[high]) return false;
5        low++;
6        high--;
7      }
8      return true;
9    }
10 
11   let left = 0, right = s.length - 1;
12   while (left < right) {
13     if (s[left] !== s[right]) {
14       return (
15         isPalindrome(left + 1, right) || isPalindrome(left, right - 1)
16       );
17     }
18     left++;
19     right--;
20   }
21   return true;
22 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let s;
  try {
    s = JSON.parse(userInput);
    if (typeof s !== 'string') {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid string!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function validPalindrome is called with s = "${s}"`,
    { s }
  );

  function isPalindrome(low, high) {
    while (low < high) {
      addTrace(
        executionTrace,
        4,
        `Checking if s[${low}] (${s[low]}) == s[${high}] (${s[high]})`,
        { low, high }
      );
      if (s[low] !== s[high]) {
        addTrace(
          executionTrace,
          4,
          `Mismatch found: s[${low}] (${s[low]}) != s[${high}] (${s[high]})`,
          { low, high }
        );
        return false;
      }
      low++;
      high--;
    }
    return true;
  }

  let left = 0;
  let right = s.length - 1;

  addTrace(
    executionTrace,
    11,
    `Initialize pointers: left = ${left}, right = ${right}`,
    { left, right }
  );

  while (left < right) {
    addTrace(
      executionTrace,
      12,
      `Checking if s[${left}] (${s[left]}) == s[${right}] (${s[right]})`,
      { left, right }
    );

    if (s[left] !== s[right]) {
      let skipLeft = isPalindrome(left + 1, right);
      let skipRight = isPalindrome(left, right - 1);

      addTrace(
        executionTrace,
        14,
        `Mismatch found: Check by skipping left (${skipLeft}) or right (${skipRight})`,
        { skipLeft, skipRight }
      );

      if (skipLeft || skipRight) {
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `"${s}" can become a palindrome after at most one deletion.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      } else {
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `"${s}" cannot become a palindrome after at most one deletion.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }
    }

    left++;
    right--;
  }

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `"${s}" is already a palindrome.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
