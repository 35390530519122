export const problemTitle = `### Understanding and Solving **Add Binary Using Mathematics** Problem`;
export const initialInputValue = `'101' and '11'`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to add two binary numbers using mathematics:`,
  `###### **Step 2:** Program to Add **Binary Numbers** Using Mathematics:`,
  `###### **Step 3:** Input two binary numbers to add:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two binary numbers as strings, return their sum as a binary number without using any built-in functions for binary conversion or arithmetic.

#### Problem Statement (Elaborated):
Write a program to **add two binary numbers** represented as strings. The binary numbers should be **processed bit by bit** and their sum computed manually using **mathematical operations**. The result should be returned as a binary string.

#### Testing Examples
1. **Example 1: Input = '101', '11'**
   - **Step-by-step**: The sum of \`101\` and \`11\` is \`1000\`. Therefore, the **output** is \`1000\`.
2. **Example 2: Input = '1', '1'**
   - **Step-by-step**: The sum of \`1\` and \`1\` is \`10\`. Therefore, the **output** is \`10\`.
3. **Example 3: Input = '111', '111'**
   - **Step-by-step**: The sum of \`111\` and \`111\` is \`1110\`. Therefore, the **output** is \`1110\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **process each bit** of the binary numbers.
2. **Mathematical Operations**: Using modulo (\`%\`) and integer division (\`/\`) to compute sums bit by bit.
3. **Conditional Statements**: To handle the **carry** when summing two bits.

#### Real-World Concepts Covered
1. **Binary Arithmetic**: Simulating binary addition without built-in functions.
2. **Carryover Logic**: Understanding how carry works in binary addition.
3. **Bit Manipulation**: Using arithmetic to process binary numbers bit by bit.

#### How Does the Program Work?
1. Start from the **least significant bit** (rightmost bit) of both binary numbers.
2. Compute the **sum** for each bit along with a **carry**.
3. Append the resulting bit to the **result**.
4. If there’s a carry after processing all bits, **prepend it** to the final result.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use modulo (\`%\`) to extract the last digit of each binary number.
2. **Hint 2**: Use integer division (\`Math.floor()\`) to remove the last digit.
3. **Hint 3**: Handle carry carefully when the sum of two bits exceeds 1.
4. **Hint 4**: Pad the shorter binary number with zeroes for easier addition.
`;

export const initialAlgorithm = `1. Initialize variables:
   - **result** = ''
   - **carry** = 0
2. While there are still bits in either binary number or a carry exists:
   - a. Extract the last digit of each binary number (or 0 if no bits remain).
   - b. Compute the sum of the two bits and the carry.
   - c. Determine the resulting bit: **bit = sum % 2**.
   - d. Update the carry: **carry = Math.floor(sum / 2)**.
   - e. Append the resulting bit to the result.
3. Reverse the result to get the final binary sum.
4. Return the result.`;

export const initialProblemCode = `1  function addBinary(binary1, binary2) {
2    let result = '';
3    let carry = 0;
4    let i = binary1.length - 1;
5    let j = binary2.length - 1;
6    while (i >= 0 || j >= 0 || carry > 0) {
7      let bit1 = i >= 0 ? parseInt(binary1[i], 10) : 0;
8      let bit2 = j >= 0 ? parseInt(binary2[j], 10) : 0;
9      let sum = bit1 + bit2 + carry;
10     let bit = sum % 2;
11     carry = Math.floor(sum / 2);
12     result = bit + result;
13     i--;
14     j--;
15   }
16   return result;
17 }`;

export const handleProblemRunClick = (
  userInput1,
  userInput2,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  if (
    !userInput1 ||
    !userInput2 ||
    typeof userInput1 !== 'string' ||
    typeof userInput2 !== 'string'
  ) {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter valid binary strings!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function addBinary is called with binary1 = '${userInput1}', binary2 = '${userInput2}'`,
    { binary1: userInput1, binary2: userInput2 }
  );

  let result = '';
  let carry = 0;
  let i = userInput1.length - 1;
  let j = userInput2.length - 1;

  addTrace(
    executionTrace,
    2,
    `Initialize result = '', carry = 0, i = ${i}, j = ${j}`,
    { result, carry, i, j }
  );

  while (i >= 0 || j >= 0 || carry > 0) {
    addTrace(
      executionTrace,
      6,
      `Check while condition: i (${i}) >= 0 || j (${j}) >= 0 || carry (${carry}) > 0 = ${i >= 0 || j >= 0 || carry > 0}`,
      { i, j, carry }
    );

    let bit1 = i >= 0 ? parseInt(userInput1[i], 10) : 0;
    let bit2 = j >= 0 ? parseInt(userInput2[j], 10) : 0;

    addTrace(
      executionTrace,
      7,
      `Extract bits: bit1 = ${bit1}, bit2 = ${bit2}`,
      { bit1, bit2 }
    );

    let sum = bit1 + bit2 + carry;
    addTrace(
      executionTrace,
      9,
      `Compute sum: sum = bit1 + bit2 + carry = ${bit1} + ${bit2} + ${carry} = ${sum}`,
      { sum }
    );

    let bit = sum % 2;
    carry = Math.floor(sum / 2);

    addTrace(
      executionTrace,
      10,
      `Calculate bit and carry: bit = sum % 2 = ${bit}, carry = Math.floor(sum / 2) = ${carry}`,
      { bit, carry }
    );

    result = bit + result;
    addTrace(
      executionTrace,
      12,
      `Update result: result = bit + result = ${result}`,
      { result }
    );

    i--;
    j--;
    addTrace(executionTrace, 13, `Decrement indices: i = ${i}, j = ${j}`, {
      i,
      j,
    });
  }

  addTrace(executionTrace, 16, `Return result: ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The binary sum of '${userInput1}' and '${userInput2}' is '${result}'.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
