export const problemTitle = `### Understanding and Solving **Self Dividing Numbers** Problem`;
export const initialInputValue = '1 22';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find all self-dividing numbers in a range:`,
  `###### **Step 2:** Program to Identify **Self Dividing Numbers**:`,
  `###### **Step 3:** Input a range to find all **Self Dividing Numbers**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
A **self-dividing number** is a number that is divisible by every digit it contains. For example, \`128\` is a self-dividing number because \`128 % 1 = 0\`, \`128 % 2 = 0\`, and \`128 % 8 = 0\`. Write a program to find all self-dividing numbers within a given range of integers \`left\` and \`right\` inclusive.

#### Problem Statement(Elaborated):
The program should identify and return a list of all integers in the range \`[left, right]\` that satisfy the self-dividing property.

#### Testing Examples
1. **Example 1: Input = 1 22**
   - **Step-by-step**: Check each number between \`1\` and \`22\`:
     - Self-dividing numbers: \`1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 15, 22\`.
   - Output: \`[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 15, 22]\`.
2. **Example 2: Input = 10 20**
   - **Step-by-step**: Check numbers from \`10\` to \`20\`:
     - Self-dividing numbers: \`11, 12, 15\`.
   - Output: \`[11, 12, 15]\`.

#### What is a Self-Dividing Number?
A **self-dividing number** satisfies these properties:
1. It is divisible by each digit in the number.
2. It does not contain the digit \`0\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate through numbers and digits.
2. **Conditional Statements**: To check divisibility and digit constraints.
3. **Mathematical Operations**: Using modulo and division to extract digits.

#### Real-World Concepts Covered
1. **Number Properties**: Exploring divisibility and numerical characteristics.
2. **Optimization**: Efficiently handling digit extraction and checks.
3. **Problem-Solving**: Breaking down a range-based problem into individual checks.

#### How Does the Program Work?
1. For each number in the range \`[left, right]\`:
   - Extract its digits.
   - Check if it is divisible by all its digits.
   - Skip the number if any digit is \`0\` or divisibility fails.
2. Add the number to the result list if it satisfies the criteria.
3. Return the list of all self-dividing numbers.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use modulo (\`%\`) to extract the last digit of a number.
2. **Hint 2**: Use integer division (\`Math.floor()\`) to remove the last digit.
3. **Hint 3**: A number is invalid if any digit is \`0\` or \`n % digit != 0\`.
4. **Hint 4**: Skip numbers that fail early to optimize performance.

#### Why Do We Do This?
This problem improves understanding of digit-based constraints and properties. It provides a practical application for mathematical concepts in programming.
`;

export const initialAlgorithm = `1. Define a helper function to check if a number is self-dividing:
   - a. Extract each digit of the number.
   - b. Check if the number is divisible by each digit.
   - c. Return false if any digit is \`0\` or divisibility fails.
2. Initialize an empty list to store self-dividing numbers.
3. Loop through each number in the range \`[left, right]\`:
   - a. Use the helper function to check if the number is self-dividing.
   - b. If true, add the number to the list.
4. Return the list of self-dividing numbers.`;

export const initialProblemCode = `1  function isSelfDividing(num) {
2    let original = num;
3    while (num > 0) {
4      let digit = num % 10;
5      if (digit === 0 || original % digit !== 0) return false;
6      num = Math.floor(num / 10);
7    }
8    return true;
9  }
10 
11 function selfDividingNumbers(left, right) {
12   let result = [];
13   for (let i = left; i <= right; i++) {
14     if (isSelfDividing(i)) {
15       result.push(i);
16     }
17   }
18   return result;
19 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let inputParts;
  try {
    inputParts = userInput.split(' ').map((x) => parseInt(x, 10));
    if (inputParts.length !== 2 || inputParts.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid range in the format "left right"!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  const [left, right] = inputParts;

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function selfDividingNumbers is called with left = ${left}, right = ${right}`,
    { left, right }
  );

  let result = [];
  addTrace(executionTrace, 12, `Initialize result = []`, { result });

  for (let i = left; i <= right; i++) {
    addTrace(
      executionTrace,
      13,
      `Check number ${i} in range [${left}, ${right}]`,
      { i }
    );

    // Call isSelfDividing for current number
    let num = i;
    let original = num;
    let isSelfDividing = true;
    addTrace(executionTrace, 2, `Call isSelfDividing(${num})`, {
      original,
      num,
    });

    while (num > 0) {
      let digit = num % 10;
      addTrace(
        executionTrace,
        4,
        `Extract digit = num % 10 = ${num} % 10 = ${digit}`,
        { digit, num }
      );

      if (digit === 0 || original % digit !== 0) {
        addTrace(
          executionTrace,
          5,
          `Check failed: digit = ${digit}, original % digit = ${original} % ${digit} = ${
            original % digit
          }`,
          { digit, original }
        );
        isSelfDividing = false;
        break;
      }

      num = Math.floor(num / 10);
      addTrace(
        executionTrace,
        6,
        `Update num = Math.floor(num / 10) = Math.floor(${num} / 10)`,
        { num }
      );
    }

    addTrace(executionTrace, 8, `Return isSelfDividing = ${isSelfDividing}`, {
      isSelfDividing,
    });

    if (isSelfDividing) {
      result.push(i);
      addTrace(executionTrace, 15, `Add ${i} to result`, { result });
    }
  }

  addTrace(executionTrace, 18, `Return result = ${JSON.stringify(result)}`, {
    result,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The self-dividing numbers in the range are: ${JSON.stringify(
      result
    )}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
