import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the FindFirstOccurrenceDemo component
const problemTitle = `### Understanding and Solving **Find the Index of the First Occurrence in a String** Problem`;
const initialInputValue = '{"haystack": "sadbutsad", "needle": "sad"}';
const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Index of First Occurrence**:`,
  `###### **Step 2:** Program to Find the **Index of First Occurrence**:`,
  `###### **Step 3:** Input the **haystack** and **needle** strings:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two strings **haystack** and **needle**, return the **index of the first occurrence** of **needle** in **haystack**, or **-1** if **needle** is not part of **haystack**.

#### Problem Statement (Elaborated):
Write a program that **searches** for the **first occurrence** of a **substring (needle)** within a **larger string (haystack)**. The function should **return the starting index** of the **first occurrence** of the **needle**. If the **needle** is not found, return **-1**.

#### Testing Examples
1. **Example 1: Input = {"haystack": "sadbutsad", "needle": "sad"}**
   - **Step-by-step**: The **first occurrence** of \`"sad"\` in \`"sadbutsad"\` is at **index 0**.
2. **Example 2: Input = {"haystack": "leetcode", "needle": "leeto"}**
   - **Step-by-step**: \`"leeto"\` does not appear in \`"leetcode"\`, so the **output** is **-1**.

#### What is Finding the Index of a Substring?
Finding the **index of a substring** means determining where the **substring** begins within a **larger string**. For example, in the word \`"sadbutsad"\`, the substring \`"sad"\` starts at index **0**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate** through the characters of the strings.
2. **Conditional Statements**: Using \`if-else\` to **check matches**.
3. **String Comparison**: Checking **character-by-character** for a match.

#### Real-World Concepts Covered
1. **Search Problems**: Understanding how to **search** for patterns.
2. **Pattern Matching**: Learning to **match sequences** of characters.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **iterative comparisons**.

#### How Does the Program Work?
1. Iterate through the **haystack** to find a **starting point**.
2. Compare **each character** of the **needle** with the **corresponding character** in the **haystack**.
3. If **all characters match**, return the **starting index**.
4. If **no match is found**, return **-1**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Start comparing **needle** with the **haystack** from each position.
2. **Hint 2**: If characters do not match, **move to the next position**.
3. **Hint 3**: Think about how to **stop early** if the remaining **haystack** is too short.

#### Why Do We Do This?
We **perform this check** to **find specific patterns** within a **larger text**, a concept useful in **text searching algorithms**.
`;

const initialAlgorithm = `1. Iterate through the **haystack** string:
   - a. Check if the **remaining length** of **haystack** is **less than the length** of **needle**.
   - b. If it is, **stop** as the needle **cannot fit**.
2. For each **position** in the **haystack**, compare **each character** with the **corresponding character** in the **needle**.
   - a. If **all characters match**, return the **current index**.
   - b. If a **character does not match**, move to the **next position** in **haystack**.
3. If **no match is found**, return **-1**.
`;

const initialProblemCode = `1  function findFirstOccurrence(haystack, needle) {
2    if (needle === "") return 0; // Edge case: empty needle
3    for (let i = 0; i <= haystack.length - needle.length; i++) {
4      let match = true;
5      for (let j = 0; j < needle.length; j++) {
6        if (haystack[i + j] !== needle[j]) {
7          match = false;
8          break;
9        }
10     }
11     if (match) return i;
12   }
13   return -1;
14 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component (same as PalindromeNumberDemo)
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the FindIndexOfFirstOccurrenceDemo
const FindIndexOfFirstOccurrenceDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);

  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };
      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let input;
    try {
      input = JSON.parse(numberInput);
      if (
        !input ||
        typeof input.haystack !== 'string' ||
        typeof input.needle !== 'string'
      ) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter a valid JSON with "haystack" and "needle"!',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    const { haystack, needle } = input;

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function findFirstOccurrence is called with haystack = "${haystack}", needle = "${needle}"`,
      { haystack, needle }
    );

    if (needle === '') {
      addTrace(executionTrace, 2, 'Edge case: Empty needle, return 0', {});
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload: 'The index of the first occurrence is 0.',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    for (let i = 0; i <= haystack.length - needle.length; i++) {
      let match = true;
      addTrace(executionTrace, 4, `Check haystack starting at index ${i}`, {
        i,
      });

      for (let j = 0; j < needle.length; j++) {
        addTrace(
          executionTrace,
          6,
          `Compare haystack[${i + j}] = "${haystack[i + j]}" with needle[${j}] = "${needle[j]}"`,
          { i, j, haystackChar: haystack[i + j], needleChar: needle[j] }
        );

        if (haystack[i + j] !== needle[j]) {
          match = false;
          addTrace(
            executionTrace,
            7,
            `Characters do not match, break and continue with the next index`,
            { match }
          );
          break;
        }
      }

      if (match) {
        addTrace(executionTrace, 11, `Match found at index ${i}, return ${i}`, {
          i,
        });
        dispatch({
          type: ACTION_TYPES.SET_MESSAGE,
          payload: `The index of the first occurrence is ${i}.`,
        });
        dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
        dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
        return;
      }
    }

    addTrace(executionTrace, 13, 'No match found, return -1', {});

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'The needle was not found in the haystack. Return -1.',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default FindIndexOfFirstOccurrenceDemo;
