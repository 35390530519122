import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Modal, Form, Button, Accordion } from 'react-bootstrap';
import MonacoEditor from '@monaco-editor/react';
import courseData from '../../data/courseData.js';
import {
  createCourse,
  getCourseById,
  updateCourse,
  getAllProgrammingLanguages,
} from '../../services/api.js';
import Navbar from '../../components/Navbar.js';
import './CourseContentForm.css'; // Import the CSS file

const CourseContentForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalVariant, setModalVariant] = useState('success');
  const [jsonInput, setJsonInput] = useState('');
  const [programmingLanguages, setProgrammingLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProgrammingLanguages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getAllProgrammingLanguages(token);
        setProgrammingLanguages(response.data);
      } catch (error) {
        console.error('Error fetching programming languages:', error);
      }
    };

    fetchProgrammingLanguages();
  }, []);

  useEffect(() => {
    if (id) {
      const fetchCourseData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await getCourseById(token, id);
          const courseData = response.data.content;
          setFormData({ course: courseData });
          setJsonInput(JSON.stringify({ course: courseData }, null, 2));
          setSelectedLanguages(courseData.programming_languages || []);
        } catch (error) {
          console.error('Error fetching course data:', error);
        }
      };

      fetchCourseData();
    } else {
      setFormData(courseData);
      setJsonInput(JSON.stringify(courseData, null, 2));
    }
  }, [id]);

  const handleJsonInputChange = (value) => {
    setJsonInput(value);
    try {
      const parsedJson = JSON.parse(value);
      setFormData(parsedJson);
    } catch (error) {
      console.error('Invalid JSON:', error);
    }
  };

  const handleChange = (e, path) => {
    const { value } = e.target;
    const keys = path.split('.');
    const updatedFormData = { ...formData };
    let current = updatedFormData;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    setFormData(updatedFormData);
    setJsonInput(JSON.stringify(updatedFormData, null, 2));
  };

  const handleLanguageChange = (e) => {
    const { options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    console.log(`selectedOptions: ${selectedOptions}`);
    setSelectedLanguages(selectedOptions); // Update selected languages
  };

  const handleAddSection = () => {
    const newSection = {
      title: '',
      order: formData.course.syllabus.sections.length + 1,
      activities: [],
    };
    const updatedFormData = {
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          ...formData.course.syllabus,
          sections: [...formData.course.syllabus.sections, newSection],
        },
      },
    };
    setFormData(updatedFormData);
    setJsonInput(JSON.stringify(updatedFormData, null, 2));
  };

  const handleAddActivity = (sectionIndex) => {
    const newActivity = {
      activity_type: '',
      title: '',
      order:
        formData.course.syllabus.sections[sectionIndex].activities.length + 1,
      content: '',
      lesson_id: null,
      quiz_id: null,
      coding_activity_id: null,
      instructions: [],
      questions: [],
      code_template: '',
    };

    const updatedSections = formData.course.syllabus.sections.map(
      (section, index) => {
        if (index === sectionIndex) {
          return {
            ...section,
            activities: [...section.activities, newActivity],
          };
        }
        return section;
      }
    );

    const updatedFormData = {
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          ...formData.course.syllabus,
          sections: updatedSections,
        },
      },
    };
    setFormData(updatedFormData);
    setJsonInput(JSON.stringify(updatedFormData, null, 2));
  };

  const handleAddQuestion = (sectionIndex, activityIndex) => {
    const newQuestion = {
      question_text: '',
      options: [
        { option_text: '', is_correct: false },
        { option_text: '', is_correct: false },
      ],
    };

    const updatedSections = [...formData.course.syllabus.sections];
    updatedSections[sectionIndex].activities[activityIndex].questions.push(
      newQuestion
    );

    setFormData({
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          sections: updatedSections,
        },
      },
    });
    setJsonInput(JSON.stringify(formData, null, 2));
  };

  const handleAddInstruction = (sectionIndex, activityIndex) => {
    const newInstruction = {
      step_number:
        formData.course.syllabus.sections[sectionIndex].activities[
          activityIndex
        ].instructions?.length + 1 || 1, // Use optional chaining for instructions
      description: '',
      code_snippet: '',
      hints: [],
      test_cases: [],
    };

    const updatedSections = [...formData.course.syllabus.sections];
    updatedSections[sectionIndex].activities[activityIndex].instructions = [
      ...(updatedSections[sectionIndex].activities[activityIndex]
        .instructions || []), // Fallback to empty array
      newInstruction,
    ];

    setFormData({
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          sections: updatedSections,
        },
      },
    });
    setJsonInput(JSON.stringify(formData, null, 2));
  };

  const handleAddHint = (sectionIndex, activityIndex, instructionIndex) => {
    const newHint = {
      hint_text: '',
      test_cases: [], // New structure to include test cases directly under hints
    };

    const updatedSections = [...formData.course.syllabus.sections];
    updatedSections[sectionIndex].activities[activityIndex].instructions[
      instructionIndex
    ].hints = [
      ...(updatedSections[sectionIndex].activities[activityIndex]
        .instructions?.[instructionIndex]?.hints || []), // Use fallback array
      newHint,
    ];

    setFormData({
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          sections: updatedSections,
        },
      },
    });

    setJsonInput(JSON.stringify(formData, null, 2));
  };

  const handleAddTestCase = (
    sectionIndex,
    activityIndex,
    instructionIndex,
    hintIndex
  ) => {
    const newTestCase = {
      input: '',
      test_type: 'componentTest',
      test_code: '',
    };

    const updatedSections = [...formData.course.syllabus.sections];
    updatedSections[sectionIndex].activities[activityIndex].instructions[
      instructionIndex
    ].hints[hintIndex].test_cases = [
      ...(updatedSections[sectionIndex].activities[activityIndex]
        .instructions?.[instructionIndex]?.hints?.[hintIndex]?.test_cases ||
        []), // Fallback to empty array
      newTestCase,
    ];

    setFormData({
      ...formData,
      course: {
        ...formData.course,
        syllabus: {
          sections: updatedSections,
        },
      },
    });

    setJsonInput(JSON.stringify(formData, null, 2));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const coursePayload = {
        title: formData.course.title,
        short_description: formData.course.shortDescription,
        long_description: formData.course.longDescription,
        content: {
          ...formData.course,
          programming_languages: selectedLanguages,
        },
      };

      if (id) {
        await updateCourse(token, id, coursePayload);
        setModalMessage(
          `Course "${formData.course.title}" updated successfully!`
        );
        setModalVariant('success');
      } else {
        await createCourse(token, coursePayload);
        setModalMessage(
          `Course "${formData.course.title}" created successfully!`
        );
        setModalVariant('success');
      }

      setShowModal(true);
      setTimeout(() => {
        navigate('/admin/courses');
      }, 2000);
    } catch (error) {
      console.error('Error saving course:', error);
      setModalMessage('There was an error saving the course.');
      setModalVariant('danger');
      setShowModal(true);
    }
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex flex-column min-vh-100 course-content-form-container">
      <Navbar />

      <Container>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          backdrop="static"
          className={`text-${modalVariant}`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant={modalVariant === 'success' ? 'primary' : 'danger'}
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <h2 className="mb-4">{id ? 'Edit Course' : 'Create Course'}</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>JSON Input</Form.Label>
            <MonacoEditor
              height="300px"
              defaultLanguage="json"
              value={jsonInput}
              onChange={handleJsonInputChange}
              options={{
                minimap: { enabled: false },
                wordWrap: 'on',
                scrollBeyondLastLine: false,
              }}
              className="monaco-editor"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Course Title</Form.Label>
            <Form.Control
              type="text"
              value={formData.course.title}
              onChange={(e) => handleChange(e, 'course.title')}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Short Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.course.shortDescription}
              onChange={(e) => handleChange(e, 'course.shortDescription')}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Long Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.course.longDescription}
              onChange={(e) => handleChange(e, 'course.longDescription')}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              value={formData.course.category}
              onChange={(e) => handleChange(e, 'course.category')}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Programming Languages</Form.Label>
            <Form.Select
              multiple
              value={selectedLanguages}
              onChange={handleLanguageChange}
              required
              className="form-select"
            >
              {programmingLanguages.map((lang) => (
                <option
                  key={lang.programming_language_id}
                  value={lang.programming_language_id}
                >
                  {lang.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Difficulty</Form.Label>
            <Form.Control
              type="text"
              value={formData.course.difficulty}
              onChange={(e) => handleChange(e, 'course.difficulty')}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Instructor ID</Form.Label>
            <Form.Control
              type="text"
              value={formData.course.instructor_id}
              onChange={(e) => handleChange(e, 'course.instructor_id')}
              required
            />
          </Form.Group>

          {/* Syllabus Sections */}
          <h3>Syllabus</h3>
          {formData.course.syllabus.sections.map((section, sectionIndex) => (
            <Accordion key={sectionIndex} className="mb-3">
              <Accordion.Item eventKey={sectionIndex.toString()}>
                <Accordion.Header>Section {sectionIndex + 1}</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Section Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={section.title}
                      onChange={(e) =>
                        handleChange(
                          e,
                          `course.syllabus.sections.${sectionIndex}.title`
                        )
                      }
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Section Order</Form.Label>
                    <Form.Control
                      type="number"
                      value={section.order}
                      onChange={(e) =>
                        handleChange(
                          e,
                          `course.syllabus.sections.${sectionIndex}.order`
                        )
                      }
                      required
                    />
                  </Form.Group>

                  {/* Activities */}
                  <h4>Activities</h4>
                  {section.activities.map((activity, activityIndex) => (
                    <Accordion key={activityIndex} className="mb-3">
                      <Accordion.Item eventKey={activityIndex.toString()}>
                        <Accordion.Header>
                          Activity {activityIndex + 1}
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form.Group className="mb-3">
                            <Form.Label>Activity Type</Form.Label>
                            <Form.Select
                              value={activity.activity_type}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.activity_type`
                                )
                              }
                              required
                            >
                              <option value="">Select Activity Type</option>
                              <option value="Lesson">Lesson</option>
                              <option value="Quiz">Quiz</option>
                              <option value="CodingActivity">
                                Coding Activity
                              </option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>Activity Title</Form.Label>
                            <Form.Control
                              type="text"
                              value={activity.title}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.title`
                                )
                              }
                              required
                            />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label>Activity Order</Form.Label>
                            <Form.Select
                              value={activity.order}
                              onChange={(e) =>
                                handleChange(
                                  e,
                                  `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.order`
                                )
                              }
                              required
                            >
                              {[...Array(22)].map((_, i) => (
                                <option key={i + 1} value={i + 1}>
                                  {i + 1}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>

                          {/* Lesson Activity Content */}
                          {activity.activity_type === 'Lesson' && (
                            <Form.Group className="mb-3">
                              <Form.Label>Content</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                value={activity.content}
                                onChange={(e) =>
                                  handleChange(
                                    e,
                                    `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.content`
                                  )
                                }
                              />
                            </Form.Group>
                          )}

                          {/* Quiz Activity Questions and Options */}
                          {activity.activity_type === 'Quiz' && (
                            <>
                              <h5>Questions</h5>
                              {activity.questions.map(
                                (question, questionIndex) => (
                                  <div key={questionIndex} className="mb-3">
                                    <Form.Group>
                                      <Form.Label>Question Text</Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={question.question_text}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.questions.${questionIndex}.question_text`
                                          )
                                        }
                                        required
                                      />
                                    </Form.Group>
                                    <h6>Options</h6>
                                    {question.options.map(
                                      (option, optionIndex) => (
                                        <Form.Group key={optionIndex}>
                                          <Form.Control
                                            type="text"
                                            value={option.option_text}
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.questions.${questionIndex}.options.${optionIndex}.option_text`
                                              )
                                            }
                                            placeholder={`Option ${optionIndex + 1}`}
                                            required
                                          />
                                          <Form.Check
                                            type="checkbox"
                                            label="Correct Answer"
                                            checked={option.is_correct}
                                            onChange={(e) =>
                                              handleChange(
                                                {
                                                  target: {
                                                    value: e.target.checked,
                                                  },
                                                },
                                                `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.questions.${questionIndex}.options.${optionIndex}.is_correct`
                                              )
                                            }
                                          />
                                        </Form.Group>
                                      )
                                    )}
                                  </div>
                                )
                              )}
                              <Button
                                variant="secondary"
                                onClick={() =>
                                  handleAddQuestion(sectionIndex, activityIndex)
                                }
                              >
                                Add Question
                              </Button>
                            </>
                          )}

                          {/* Coding Activity Instructions, Hints, and Test Cases */}
                          {activity.activity_type === 'CodingActivity' && (
                            <>
                              <Form.Group className="mb-3">
                                <Form.Label>Code Template</Form.Label>
                                <MonacoEditor
                                  height="150px"
                                  defaultLanguage="javascript"
                                  value={activity.code_template}
                                  onChange={(value) =>
                                    handleChange(
                                      { target: { value } },
                                      `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.code_template`
                                    )
                                  }
                                  options={{
                                    minimap: { enabled: false },
                                    wordWrap: 'on',
                                    scrollBeyondLastLine: false,
                                  }}
                                />
                              </Form.Group>

                              <h5>Instructions</h5>
                              {(activity.instructions || []).map(
                                (instruction, instructionIndex) => (
                                  <Accordion key={instructionIndex}>
                                    <Accordion.Item
                                      eventKey={instructionIndex.toString()}
                                    >
                                      <Accordion.Header>
                                        Step {instruction.step_number}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <Form.Group className="mb-3">
                                          <Form.Label>
                                            Step Description
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={instruction.description}
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.description`
                                              )
                                            }
                                          />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Code Snippet</Form.Label>
                                          <MonacoEditor
                                            height="100px"
                                            defaultLanguage="javascript"
                                            value={instruction.code_snippet}
                                            onChange={(value) =>
                                              handleChange(
                                                { target: { value } },
                                                `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.code_snippet`
                                              )
                                            }
                                            options={{
                                              minimap: { enabled: false },
                                              wordWrap: 'on',
                                              scrollBeyondLastLine: false,
                                            }}
                                          />
                                        </Form.Group>

                                        <h6>Hints</h6>
                                        {(instruction.hints || []).map(
                                          (hint, hintIndex) => (
                                            <Form.Group
                                              key={hintIndex}
                                              className="mb-3"
                                            >
                                              <Form.Label>Hint Text</Form.Label>
                                              <Form.Control
                                                as="textarea"
                                                rows={2}
                                                value={hint.hint_text}
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.hints.${hintIndex}.hint_text`
                                                  )
                                                }
                                              />
                                              <h6>Test Cases</h6>
                                              {(hint.test_cases || []).map(
                                                (testCase, testCaseIndex) => (
                                                  <div
                                                    key={testCaseIndex}
                                                    className="mb-3"
                                                  >
                                                    <Form.Group>
                                                      <Form.Label>
                                                        Input
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        value={testCase.input}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.hints.${hintIndex}.test_cases.${testCaseIndex}.input`
                                                          )
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <Form.Group>
                                                      <Form.Label>
                                                        Test Type
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        value={
                                                          testCase.test_type
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.hints.${hintIndex}.test_cases.${testCaseIndex}.test_type`
                                                          )
                                                        }
                                                      />
                                                    </Form.Group>
                                                    <Form.Group>
                                                      <Form.Label>
                                                        Test Code
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        value={
                                                          testCase.test_code
                                                        }
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            `course.syllabus.sections.${sectionIndex}.activities.${activityIndex}.instructions.${instructionIndex}.hints.${hintIndex}.test_cases.${testCaseIndex}.test_code`
                                                          )
                                                        }
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                )
                                              )}
                                              <Button
                                                variant="secondary"
                                                onClick={() =>
                                                  handleAddTestCase(
                                                    sectionIndex,
                                                    activityIndex,
                                                    instructionIndex,
                                                    hintIndex
                                                  )
                                                }
                                              >
                                                Add Test Case
                                              </Button>
                                            </Form.Group>
                                          )
                                        )}
                                        <Button
                                          variant="secondary"
                                          onClick={() =>
                                            handleAddHint(
                                              sectionIndex,
                                              activityIndex,
                                              instructionIndex
                                            )
                                          }
                                        >
                                          Add Hint
                                        </Button>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                )
                              )}

                              <Button
                                variant="secondary"
                                className="mt-2"
                                onClick={() =>
                                  handleAddInstruction(
                                    sectionIndex,
                                    activityIndex
                                  )
                                }
                              >
                                Add Instruction
                              </Button>
                            </>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}

                  <Button
                    variant="primary"
                    onClick={() => handleAddActivity(sectionIndex)}
                  >
                    Add Activity
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}

          <Button variant="primary" onClick={handleAddSection}>
            Add Section
          </Button>

          <Button type="submit" variant="success" className="mt-3">
            Save Course
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default CourseContentForm;
