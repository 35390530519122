export const problemTitle = `### Understanding and Solving **Surface Area of 3D Shapes** Problem`;
export const initialInputValue = '1,2;3,4';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the **Surface Area of 3D Shapes**:`,
  `###### **Step 2:** Program to Calculate **Surface Area**:`,
  `###### **Step 3:** Input grid to calculate **Surface Area**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a 2D grid of integers where each value represents the height of a stack of cubes, calculate the surface area of the 3D shape formed.

#### Problem Statement(Elaborated):
The **surface area** of the shape is calculated as the sum of the areas of all visible surfaces. Each cube contributes to:
1. **Top and Bottom Areas**: Each non-zero cell contributes 2 to the surface area.
2. **Side Areas**: Adjacent cubes overlap, and only the difference in height contributes to the area between them.

#### Testing Examples
1. **Example 1: Grid = [[1,2],[3,4]]**
   - **Step-by-step**:
     - Top/Bottom = 4 * 2 = 8.
     - Sides = (1 + 2) + (3 + 4) + 2 (for row overlap) + 2 (for column overlap) = 14.
     - Total = 8 + 14 = 22.
2. **Example 2: Grid = [[2]]**
   - **Step-by-step**:
     - Top/Bottom = 2 * 2 = 4.
     - Sides = 4 sides of height 2 = 8.
     - Total = 4 + 8 = 12.

#### What is the Surface Area?
The **surface area** is the sum of the exposed faces of all the cubes that form the 3D shape.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: Iterating through 2D grids.
2. **Mathematical Comparisons**: Calculating differences in heights.
3. **Arithmetic Operations**: Adding areas of all surfaces.

#### Real-World Concepts Covered
1. **Geometry**: Calculating areas in 3D shapes.
2. **Logical Thinking**: Determining visible faces of 3D shapes.

#### How Does the Program Work?
1. Parse the input to extract the grid dimensions and values.
2. Calculate the surface area contributed by the **top, bottom, and side views**.
3. Handle overlaps between adjacent cubes.
4. Sum up all areas to get the total surface area.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: The **top and bottom areas** are always \`2 * number of non-zero cells\`.
2. **Hint 2**: For **side areas**, consider differences in heights between adjacent cells.
3. **Hint 3**: Add the heights of the outermost cubes directly to the surface area.

#### Why Do We Do This?
This problem introduces the concept of surface area calculation for complex shapes and reinforces computational geometry.
`;

export const initialAlgorithm = `1. Parse the input to construct a 2D grid of integers.
2. Initialize a variable to store the total surface area.
3. Calculate the **Top and Bottom Areas**:
   - For each cell in the grid, add 2 to the surface area if the cell value is non-zero.
4. Calculate the **Side Areas**:
   - For each cell, calculate the differences in height with its neighbors (left, right, top, bottom).
   - Add the absolute differences to the surface area.
5. Handle **Boundary Cells**:
   - Add the heights of the outermost cubes directly to the surface area.
6. Return the total surface area.`;

export const initialProblemCode = `1  function surfaceArea(grid) {
2    let rows = grid.length;
3    let cols = grid[0].length;
4    let totalArea = 0;
5    
6    for (let i = 0; i < rows; i++) {
7      for (let j = 0; j < cols; j++) {
8        if (grid[i][j] > 0) {
9          totalArea += 2; // Top and bottom areas
10         totalArea += grid[i][j]; // Add height for current cube
11         
12         if (i === 0) totalArea += grid[i][j]; // Front boundary
13         if (j === 0) totalArea += grid[i][j]; // Left boundary
14         if (i === rows - 1) totalArea += grid[i][j]; // Back boundary
15         if (j === cols - 1) totalArea += grid[i][j]; // Right boundary
16         
17         // Compare with adjacent cubes
18         if (i > 0) totalArea += Math.max(0, grid[i][j] - grid[i - 1][j]);
19         if (j > 0) totalArea += Math.max(0, grid[i][j] - grid[i][j - 1]);
20       }
21      }
22    }
23    return totalArea;
24  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let grid;
  try {
    grid = userInput.split(';').map((row) => row.split(',').map(Number));
    if (!grid.length || grid.some((row) => row.some((cell) => isNaN(cell)))) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid userInput: Please enter a valid grid in the format "a,b,c;d,e,f"',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    2,
    `Function surfaceArea is called with grid = ${JSON.stringify(grid)}`,
    { grid }
  );

  let rows = grid.length;
  let cols = grid[0].length;
  let totalArea = 0;

  addTrace(
    executionTrace,
    3,
    `Grid dimensions are rows = ${rows}, cols = ${cols}`,
    { rows, cols }
  );

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      if (grid[i][j] > 0) {
        totalArea += 2; // Top and bottom areas
        addTrace(
          executionTrace,
          9,
          `Adding top and bottom areas for grid[${i}][${j}] = ${grid[i][j]} (totalArea = ${totalArea})`,
          { totalArea }
        );

        totalArea += grid[i][j]; // Height for current cube
        addTrace(
          executionTrace,
          10,
          `Adding height for current cube (totalArea = ${totalArea})`,
          { totalArea }
        );

        if (i === 0) {
          totalArea += grid[i][j]; // Front boundary
          addTrace(
            executionTrace,
            12,
            `Adding front boundary for row ${i} (totalArea = ${totalArea})`,
            { totalArea }
          );
        }
        if (j === 0) {
          totalArea += grid[i][j]; // Left boundary
          addTrace(
            executionTrace,
            13,
            `Adding left boundary for column ${j} (totalArea = ${totalArea})`,
            { totalArea }
          );
        }
        if (i === rows - 1) {
          totalArea += grid[i][j]; // Back boundary
          addTrace(
            executionTrace,
            14,
            `Adding back boundary for row ${i} (totalArea = ${totalArea})`,
            { totalArea }
          );
        }
        if (j === cols - 1) {
          totalArea += grid[i][j]; // Right boundary
          addTrace(
            executionTrace,
            15,
            `Adding right boundary for column ${j} (totalArea = ${totalArea})`,
            { totalArea }
          );
        }

        // Compare with adjacent cubes
        if (i > 0) {
          totalArea += Math.max(0, grid[i][j] - grid[i - 1][j]);
          addTrace(
            executionTrace,
            18,
            `Comparing with top neighbor at grid[${i - 1}][${j}] (totalArea = ${totalArea})`,
            { totalArea }
          );
        }
        if (j > 0) {
          totalArea += Math.max(0, grid[i][j] - grid[i][j - 1]);
          addTrace(
            executionTrace,
            19,
            `Comparing with left neighbor at grid[${i}][${j - 1}] (totalArea = ${totalArea})`,
            { totalArea }
          );
        }
      }
    }
  }

  addTrace(
    executionTrace,
    23,
    `Returning total surface area (totalArea = ${totalArea})`,
    { totalArea }
  );

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The total surface area is ${totalArea}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
