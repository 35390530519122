export const problemTitle = `### Understanding and Solving **Island Perimeter** Problem`;
export const initialInputValue = `[[0,1,0,0],[1,1,1,0],[0,1,0,0],[1,1,0,0]]`;
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the perimeter of an **island** in a matrix:`,
  `###### **Step 2:** Program to Calculate **Island Perimeter**:`,
  `###### **Step 3:** Input a matrix to calculate the **island perimeter**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a **grid** where:
- \`1\` represents land
- \`0\` represents water

Write a program to calculate the **perimeter** of the **island**.

#### Problem Statement(Elaborated):
The grid is surrounded by water, and there is **only one island**. The island does not have "lakes," meaning that the island is **connected land cells only**.

#### Testing Examples
1. **Example 1: Input = [[0,1,0,0],[1,1,1,0],[0,1,0,0],[1,1,0,0]]**
   - **Step-by-step**: The **perimeter** of the island is **16**.
2. **Example 2: Input = [[1]]**
   - **Step-by-step**: The **perimeter** of the single land cell is **4**.
3. **Example 3: Input = [[1,0]]**
   - **Step-by-step**: The **perimeter** of the single land cell is **4**.

#### What is an Island Perimeter?
The **perimeter** of an **island** is the **sum** of the edges of land cells (\`1\`) that are adjacent to water (\`0\`) or the **grid boundary**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iterate over the **matrix** and check each cell.
2. **Conditional Statements**: Using \`if-else\` to check **cell neighbors**.
3. **Edge Case Handling**: Properly handling **grid boundaries**.

#### Real-World Concepts Covered
1. **Grid Traversal**: Exploring how to navigate and validate data in a **grid** structure.
2. **Geometric Concepts**: Understanding how **perimeters** are calculated.
3. **Logical Thinking**: Building logic to ensure edge cases are handled correctly.

#### How Does the Program Work?
1. Traverse the grid **cell by cell**.
2. For each land cell (\`1\`), calculate its contribution to the **perimeter**:
   - Add **4** to the perimeter for each land cell.
   - Subtract **1** for each **adjacent land cell** (shared edge).
3. Return the **total perimeter** after processing all cells.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use nested loops to traverse the matrix.
2. **Hint 2**: Use conditional checks to handle **grid boundaries**.
3. **Hint 3**: Check **each neighbor** of a land cell and adjust the perimeter accordingly.

#### Why Do We Do This?
We **perform this check** to understand how **land cells interact with water** and to calculate their **outer edges**, which gives the **perimeter** of the island.
`;

export const initialAlgorithm = `1. Initialize a variable \`perimeter\` to 0.
2. Loop through each **cell** in the grid:
   - a. If the cell is **land** (\`1\`):
     - Add 4 to the \`perimeter\`.
     - Check its **neighbors**:
       - Subtract 1 for each adjacent **land cell** (shared edge).
3. Return the total \`perimeter\`.`;

export const initialProblemCode = `1  function islandPerimeter(grid) {
2    let perimeter = 0; // Line 1: Initialize perimeter
3    let rows = grid.length;
4    let cols = grid[0].length;
5    for (let i = 0; i < rows; i++) { // Line 5: Loop through each row
6      for (let j = 0; j < cols; j++) { // Line 6: Loop through each column
7        if (grid[i][j] === 1) { // Line 7: Check if the cell is land
8          perimeter += 4; // Line 8: Add 4 for the land cell
9          if (i > 0 && grid[i - 1][j] === 1) perimeter--; // Line 9: Check top neighbor
10         if (i < rows - 1 && grid[i + 1][j] === 1) perimeter--; // Line 10: Check bottom neighbor
11         if (j > 0 && grid[i][j - 1] === 1) perimeter--; // Line 11: Check left neighbor
12         if (j < cols - 1 && grid[i][j + 1] === 1) perimeter--; // Line 12: Check right neighbor
13       }
14     }
15   }
16   return perimeter; // Line 16: Return total perimeter
17 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let grid;
  try {
    grid = JSON.parse(userInput);
    if (
      !Array.isArray(grid) ||
      grid.some(
        (row) =>
          !Array.isArray(row) || row.some((cell) => cell !== 0 && cell !== 1)
      )
    ) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid matrix of 0s and 1s!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function islandPerimeter is called with grid = ${JSON.stringify(grid)}`,
    { grid }
  );

  let perimeter = 0;
  let rows = grid.length;
  let cols = grid[0].length;

  addTrace(executionTrace, 2, `Initialize perimeter = ${perimeter}`, {
    perimeter,
  });
  addTrace(executionTrace, 3, `Initialize rows = ${rows}, cols = ${cols}`, {
    rows,
    cols,
  });

  for (let i = 0; i < rows; i++) {
    addTrace(executionTrace, 5, `Processing row ${i}`, { i });
    for (let j = 0; j < cols; j++) {
      addTrace(executionTrace, 6, `Processing cell grid[${i}][${j}]`, { i, j });

      if (grid[i][j] === 1) {
        perimeter += 4;
        addTrace(
          executionTrace,
          8,
          `Land cell found at grid[${i}][${j}], add 4 to perimeter: ${perimeter}`,
          { perimeter }
        );

        if (i > 0 && grid[i - 1][j] === 1) {
          perimeter--;
          addTrace(
            executionTrace,
            9,
            `Top neighbor grid[${i - 1}][${j}] is land, subtract 1: ${perimeter}`,
            { perimeter }
          );
        }
        if (i < rows - 1 && grid[i + 1][j] === 1) {
          perimeter--;
          addTrace(
            executionTrace,
            10,
            `Bottom neighbor grid[${i + 1}][${j}] is land, subtract 1: ${perimeter}`,
            { perimeter }
          );
        }
        if (j > 0 && grid[i][j - 1] === 1) {
          perimeter--;
          addTrace(
            executionTrace,
            11,
            `Left neighbor grid[${i}][${j - 1}] is land, subtract 1: ${perimeter}`,
            { perimeter }
          );
        }
        if (j < cols - 1 && grid[i][j + 1] === 1) {
          perimeter--;
          addTrace(
            executionTrace,
            12,
            `Right neighbor grid[${i}][${j + 1}] is land, subtract 1: ${perimeter}`,
            { perimeter }
          );
        }
      }
    }
  }

  addTrace(executionTrace, 16, `Return total perimeter: ${perimeter}`, {
    perimeter,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The total perimeter of the island is ${perimeter}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
