export const problemTitle = `### Understanding and Solving **Construct Rectangle** Problem`;
export const initialInputValue = '12';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the dimensions of the rectangle:`,
  `###### **Step 2:** Program to Calculate Rectangle Dimensions:`,
  `###### **Step 3:** Input the area to determine the dimensions:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`area\`, return the dimensions \`[L, W]\` (length and width) of a rectangle such that:
1. The area of the rectangle equals the given \`area\` (\`L * W = area\`).
2. \`L\` is as small as possible.
3. \`L >= W\`.

#### Problem Statement (Elaborated):
Write a program that calculates the **dimensions** of a rectangle that has a given **area**. The dimensions should be returned as a pair \`[L, W]\`, where \`L >= W\` and \`L * W = area\`. The goal is to **minimize** the difference between \`L\` and \`W\`.

#### Testing Examples
1. **Example 1: Input = 12**
   - **Step-by-step**: The area is 12. Possible dimensions are:
     - (12, 1), difference = 11
     - (6, 2), difference = 4
     - (4, 3), difference = 1
   - The optimal dimensions are **[4, 3]**.
2. **Example 2: Input = 1**
   - **Step-by-step**: The only possible dimensions are **[1, 1]**.
3. **Example 3: Input = 36**
   - **Step-by-step**: The area is 36. Possible dimensions are:
     - (36, 1), difference = 35
     - (18, 2), difference = 16
     - (12, 3), difference = 9
     - (9, 4), difference = 5
     - (6, 6), difference = 0
   - The optimal dimensions are **[6, 6]**.

#### What Does This Problem Involve?
The problem involves finding factors of a number (\`area\`) and choosing the pair that satisfies \`L >= W\` and minimizes the difference \`L - W\`.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To find factors of the given number.
2. **Conditional Statements**: To check conditions like \`L * W = area\` and \`L >= W\`.
3. **Mathematical Operations**: Using division to compute factors.

#### Real-World Concepts Covered
1. **Optimization**: Finding the most suitable dimensions based on constraints.
2. **Factorization**: Understanding the properties of numbers and their factors.
3. **Problem-Solving**: Breaking down a problem into mathematical and logical steps.

#### How Does the Program Work?
1. Start with \`W = 1\` and incrementally increase \`W\`.
2. For each \`W\`, calculate \`L = area / W\` and check if \`L * W = area\`.
3. Stop when \`W > sqrt(area)\`, as factors repeat after the square root.
4. Return the dimensions \`[L, W]\`.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use a loop to check each possible \`W\` starting from 1.
2. **Hint 2**: Ensure \`L * W = area\` before considering the pair.
3. **Hint 3**: Stop the loop when \`W > sqrt(area)\` to avoid redundant calculations.
4. **Hint 4**: To find the smallest \`L\`, check pairs in increasing order of \`W\`.

#### Why Do We Do This?
This problem demonstrates optimization through factorization. It provides a simple introduction to real-world applications of mathematics, such as optimizing dimensions for a given area.
`;

export const initialAlgorithm = `1. Initialize **W** = 1.
2. While **W** is less than or equal to the square root of the area:
   - a. If **area % W = 0**, calculate **L = area / W**.
   - b. Ensure **L * W = area** and **L >= W**.
   - c. Keep track of the pair \`[L, W]\`.
3. Return the pair \`[L, W]\` that satisfies the conditions.`;

export const initialProblemCode = `1  function constructRectangle(area) {
2    let L = area;
3    let W = 1;
4    while (W * W <= area) {
5      if (area % W === 0) {
6        L = area / W;
7      }
8      W++;
9    }
10   return [L, W - 1];
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let area;
  try {
    area = parseInt(userInput, 10);
    if (isNaN(area) || area <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function constructRectangle is called with area = ${area}`,
    { area }
  );

  let L = area;
  let W = 1;
  // Line 2: Initialize L
  addTrace(executionTrace, 2, `Initialize L = ${L}`, { L });

  // Line 3: Initialize W
  addTrace(executionTrace, 3, `Initialize W = ${W}`, { W });

  // Line 4: Start while loop
  while (W * W <= area) {
    addTrace(
      executionTrace,
      4,
      `Check while condition: W * W (${W * W}) <= area (${area}) = ${
        W * W <= area
      }`,
      { W }
    );

    // Line 5: Check if area % W === 0
    if (area % W === 0) {
      addTrace(
        executionTrace,
        5,
        `Check if area % W === 0 (${area} % ${W} = ${area % W}) = ${
          area % W === 0
        }`,
        { area, W }
      );

      if (area % W === 0) {
        // Line 6: Calculate L
        // let previousL = L;
        L = area / W;
        addTrace(
          executionTrace,
          6,
          `Update L = area / W = ${area} / ${W} = ${L}`,
          { L }
        );
      }
    }

    // Line 8: Increment W
    let previousW = W;
    W++;
    addTrace(executionTrace, 8, `Increment W: W = ${previousW} + 1 = ${W}`, {
      W,
    });
  }

  // Line 10: Return result
  addTrace(executionTrace, 10, `Return [L, W - 1] = [${L}, ${W - 1}]`, {
    L,
    W,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `For an area of ${area}, the dimensions are [${L}, ${W - 1}].`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
