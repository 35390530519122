/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import ConceptsList from './ConceptsList.js';
import Navbar from '../components/Navbar.js';

const TopicDetails = () => {
  const { topicName } = useParams();

  if (!topicName) {
    return <h2 className="text-center mt-5">Loading...</h2>;
  }

  return (
    <div>
      <Navbar />
      <Container>
        <ConceptsList topicName={topicName} />
      </Container>
    </div>
  );
};

export default TopicDetails;
