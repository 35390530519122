export const problemTitle = `### Understanding and Solving **Longest Palindrome** Problem`;
export const initialInputValue = 'abccccdd';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the **Longest Palindrome** using Greedy approach:`,
  `###### **Step 2:** Program to Find **Longest Palindrome**:`,
  `###### **Step 3:** Input a string to find the **Longest Palindrome**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string \`s\`, return the **length** of the **longest palindrome** that can be built with the characters in \`s\`.
#### Problem Statement(Elaborated):
Write a program that **determines** the **length of the longest palindrome** that can be built by **rearranging the characters** of a string. The program should **take a string as input** and **calculate the longest possible palindrome**.
#### Testing Examples
1. **Example 1: Input = "abccccdd"**
   - **Step-by-step**: The longest palindrome that can be formed is **"dccaccd"**. Its length is **7**.
2. **Example 2: Input = "a"**
   - **Step-by-step**: The longest palindrome that can be formed is **"a"** itself. Its length is **1**.
3. **Example 3: Input = "bb"**
   - **Step-by-step**: The longest palindrome that can be formed is **"bb"**. Its length is **2**.
#### What is the Longest Palindrome?
A **palindrome** is a **string** that **reads the same** backward and forward. In this problem, we want to **rearrange the characters** of a given string to form the **longest possible palindrome**.
#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **count occurrences** of characters.
2. **Mathematical Operations**: Using the **modulo** (\`%\`) operator and **division** (\`/\`) to **compute even/odd values**.
3. **Conditional Statements**: Using \`if-else\` to handle **conditions** while counting.
4. **String Manipulation**: Accessing and working with **characters** in the string.
5. **Edge Case Handling**: Understanding cases like an **empty string** or a **single-character string**.
#### Real-World Concepts Covered
1. **Symmetry**: Exploring how **symmetry** contributes to forming **palindromes**.
2. **Character Counting**: Learning how to **count characters** in a given set and use the counts effectively.
3. **Logical Thinking**: Understanding how to use **character occurrences** to determine **possible palindrome lengths**.
#### How Does the Program Work?
1. Count the **frequency** of each character.
2. For characters with **even frequencies**, add their count directly to the **palindrome length**.
3. For characters with **odd frequencies**, add their **largest even part** to the length and leave one **extra character** as a potential middle character.
4. Return the total **length of the longest palindrome**.
#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use an **array of size 128** to **store character frequencies** (assuming ASCII characters only).
2. **Hint 2**: To **extract even parts** of an odd number, use the formula: \`n - 1\` (e.g., for 5, take 4).
3. **Hint 3**: A palindrome can have at most **one character with an odd frequency** in the center.
4. **Hint 4**: If **all characters** have even frequencies, the longest palindrome will simply be the sum of all their frequencies.
5. **Hint 5**: Consider edge cases like an **empty string** or all **unique characters**, where only one character can form the palindrome.
#### Why Do We Do This?
We **perform this check** to **understand how to maximize** the length of a **palindrome** using the given **characters**. This is useful for understanding **string patterns** and **symmetry in character arrangement**.
`;
export const initialAlgorithm = `1. Initialize an **array of size 128** (for ASCII) to store the **frequency of characters**.
2. Loop through each **character** in the string:
   - **Increment** the **count** of the character in the array.
3. Initialize **palindromeLength** = 0 and **oddFound** = false.
4. Loop through the **frequency array**:
   - a. If the count is **even**, add it to **palindromeLength**.
   - b. If the count is **odd**, add the **largest even part** (count - 1) to **palindromeLength** and set **oddFound** = true.
5. If **oddFound** is true, **add 1** to **palindromeLength** (for the center character).
6. Return **palindromeLength**.`;
export const initialProblemCode = `1  function longestPalindrome(s) {
2    let freq = new Array(128).fill(0);
3    for (let i = 0; i < s.length; i++) {
4      freq[s.charCodeAt(i)]++;
5    }
6    let palindromeLength = 0;
7    let oddFound = false;
8    for (let count of freq) {
9      if (count % 2 === 0) {
10       palindromeLength += count;
11     } else {
12       palindromeLength += count - 1;
13       oddFound = true;
14     }
15   }
16   if (oddFound) {
17     palindromeLength++;
18   }
19   return palindromeLength;
20 }`;
export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let s = userInput || '';
  if (typeof s !== 'string') {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid string!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }
  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function longestPalindrome is called with s = "${s}"`,
    {
      s,
    }
  );
  let freq = new Array(128).fill(0);
  addTrace(executionTrace, 2, `Initialize frequency array with 0s`, { freq });
  for (let i = 0; i < s.length; i++) {
    freq[s.charCodeAt(i)]++;
    addTrace(executionTrace, 3, `Increment frequency of '${s[i]}'`, { freq });
  }
  let palindromeLength = 0;
  let oddFound = false;
  addTrace(
    executionTrace,
    6,
    `Initialize palindromeLength = ${palindromeLength} and oddFound = ${oddFound}`,
    {
      palindromeLength,
      oddFound,
    }
  );
  for (let count of freq) {
    if (count % 2 === 0) {
      palindromeLength += count;
      addTrace(
        executionTrace,
        10,
        `Add even count ${count} to palindromeLength`,
        { palindromeLength }
      );
    } else {
      palindromeLength += count - 1;
      oddFound = true;
      addTrace(
        executionTrace,
        12,
        `Add ${count - 1} (largest even part of odd count ${count}) to palindromeLength and set oddFound = true`,
        {
          palindromeLength,
          oddFound,
        }
      );
    }
  }
  if (oddFound) {
    palindromeLength++;
    addTrace(
      executionTrace,
      17,
      `Add 1 to palindromeLength for the center character`,
      { palindromeLength }
    );
  }
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The longest palindrome length that can be formed from "${s}" is ${palindromeLength}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
