export const problemTitle = `### Understanding and Solving **Fibonacci Number** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to compute the **n-th Fibonacci number**:`,
  `###### **Step 2:** Program to Compute Fibonacci Number:`,
  `###### **Step 3:** Input a number to compute its **Fibonacci value**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, compute the \`n-th Fibonacci number\`.

#### Problem Statement (Elaborated):
The Fibonacci sequence is defined as:
- \`F(0) = 0\`
- \`F(1) = 1\`
- \`F(n) = F(n - 1) + F(n - 2)\` for \`n > 1\`.

Write a program to compute the Fibonacci number at position \`n\`. The program should **take an integer as input** and **return the n-th Fibonacci number**.

#### Testing Examples
1. **Example 1**: Input: \`n = 5\`
   - **Output**: 5 (The Fibonacci sequence up to position 5 is 0, 1, 1, 2, 3, 5).
2. **Example 2**: Input: \`n = 10\`
   - **Output**: 55 (The Fibonacci sequence up to position 10 includes the number 55).
3. **Example 3**: Input: \`n = 0\`
   - **Output**: 0 (The first Fibonacci number is 0).

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To compute Fibonacci numbers iteratively.
2. **Conditional Statements**: Handling base cases like \`n = 0\` or \`n = 1\`.
3. **Mathematical Operations**: Adding two numbers to generate the next Fibonacci number.
4. **Variable Management**: Using variables to store intermediate results.

#### Real-World Concepts Covered:
1. **Recurrence Relations**: Understanding how a sequence is built using previous terms.
2. **Efficient Computation**: Avoiding recursion to compute Fibonacci numbers iteratively.
3. **Memory Optimization**: Using variables instead of arrays for simple iteration.

#### How Does the Program Work?
1. Handle base cases (\`n = 0\` or \`n = 1\`).
2. Use a loop to compute Fibonacci numbers iteratively.
3. Keep track of the last two Fibonacci numbers to generate the next.

#### Hints to Consider While Solving the Problem:
1. **Hint 1**: Initialize two variables to store \`F(0)\` and \`F(1)\`.
2. **Hint 2**: Use a loop to compute \`F(n)\` by summing the last two Fibonacci numbers.
3. **Hint 3**: Update variables in each iteration to store the next Fibonacci numbers.

#### Why Do We Do This?
Computing Fibonacci numbers demonstrates how iterative algorithms can solve problems efficiently while maintaining clarity and simplicity.
`;

export const initialAlgorithm = `1. If \`n == 0\`, return 0.
2. If \`n == 1\`, return 1.
3. Initialize two variables:
   - \`a = 0\`: Represents \`F(0)\`.
   - \`b = 1\`: Represents \`F(1)\`.
4. Use a loop to compute Fibonacci numbers from 2 to \`n\`:
   - a. Compute the next Fibonacci number: \`next = a + b\`.
   - b. Update \`a = b\` and \`b = next\`.
5. After the loop, return \`b\` as the \`n-th Fibonacci number\`.`;

export const initialProblemCode = `1  function fibonacci(n) {
2    if (n === 0) return 0;
3    if (n === 1) return 1;
4    let a = 0, b = 1;
5    for (let i = 2; i <= n; i++) {
6      let next = a + b;
7      a = b;
8      b = next;
9    }
10   return b;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n < 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a non-negative integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function fibonacci is called with n = ${n}`, {
    n,
  });

  if (n === 0) {
    addTrace(executionTrace, 2, `n == 0, return 0`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Fibonacci(${n}) = 0`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  if (n === 1) {
    addTrace(executionTrace, 3, `n == 1, return 1`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Fibonacci(${n}) = 1`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let a = 0,
    b = 1;
  addTrace(executionTrace, 4, `Initialize a = 0, b = 1`, { a, b });

  for (let i = 2; i <= n; i++) {
    addTrace(executionTrace, 5, `Loop iteration i = ${i}`, { i });
    let next = a + b;
    addTrace(
      executionTrace,
      6,
      `Compute next = a + b = ${a} + ${b} = ${next}`,
      { next }
    );
    a = b;
    addTrace(executionTrace, 7, `Update a = b, a = ${a}`, { a });
    b = next;
    addTrace(executionTrace, 8, `Update b = next, b = ${b}`, { b });
  }

  addTrace(executionTrace, 10, `Return b as the Fibonacci(${n})`, { b });
  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Fibonacci(${n}) = ${b}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
