export const problemTitle = `### Understanding and Solving **Excel Sheet Column Number Using Mathematics** Problem`;
export const initialInputValue = 'AB';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to convert an Excel sheet column title to number using mathematics:`,
  `###### **Step 2:** Program to Convert **Excel Column Title to Number** Using Mathematics:`,
  `###### **Step 3:** Input an Excel column title to find its **number**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given a string \`columnTitle\` that represents the column title as appears in an Excel sheet, return its corresponding column number.

#### Problem Statement(Elaborated):
Write a program that converts a **column title** in Excel to its corresponding **number**. The column titles in Excel are represented as follows:

| Column Title | Column Number |
|--------------|---------------|
| A            | 1             |
| B            | 2             |
| ...          | ...           |
| Z            | 26            |
| AA           | 27            |
| AB           | 28            |

#### Testing Examples
1. **Example 1: Input = "A"**
   - **Step-by-step**: The column title \`A\` corresponds to the number \`1\`. Therefore, the **output** is **1**.
2. **Example 2: Input = "AB"**
   - **Step-by-step**: The column title \`AB\` corresponds to the number \`28\`. Therefore, the **output** is **28**.
3. **Example 3: Input = "ZY"**
   - **Step-by-step**: The column title \`ZY\` corresponds to the number \`701\`. Therefore, the **output** is **701**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To process each character of the column title.
2. **Mathematical Operations**: Using base-26 arithmetic to compute the column number.
3. **Character Arithmetic**: Converting characters to numerical values using their ASCII codes.

#### Real-World Concepts Covered
1. **Base Conversion**: Understanding how Excel column titles map numbers in a base-26 system.
2. **Character Arithmetic**: Using ASCII values to calculate the position of letters.

#### How Does the Program Work?
1. Start with a result variable initialized to 0.
2. For each character in the column title:
   - Convert the character to its corresponding numerical value (A = 1, B = 2, ..., Z = 26).
   - Multiply the current result by 26 and add the numerical value of the character.
3. Return the final result.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use \`charCodeAt()\` to find the ASCII value of a character.
2. **Hint 2**: Subtract \`'A'.charCodeAt(0) - 1\` from the character's ASCII value to get its position.
3. **Hint 3**: Update the result iteratively by multiplying by 26 and adding the value of the current character.

#### Why Do We Do This?
This problem demonstrates how to translate a string-based column title into a numerical value using base-26 arithmetic, a concept frequently used in spreadsheets and other real-world applications.
`;

export const initialAlgorithm = `1. Initialize \`result = 0\`.
2. For each character in \`columnTitle\`:
   - a. Convert the character to its numerical value: \`value = char.charCodeAt(0) - 'A'.charCodeAt(0) + 1\`.
   - b. Update the result: \`result = result * 26 + value\`.
3. Return the \`result\`.`;

export const initialProblemCode = `1  function excelColumnToNumber(columnTitle) {
2    let result = 0;
3    for (let i = 0; i < columnTitle.length; i++) {
4      let value = columnTitle[i].charCodeAt(0) - 'A'.charCodeAt(0) + 1;
5      result = result * 26 + value;
6    }
7    return result;
8  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  if (
    !userInput ||
    typeof userInput !== 'string' ||
    !/^[A-Z]+$/.test(userInput)
  ) {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload:
        'Invalid input: Please enter a valid Excel column title (e.g., A, AB, ZY)!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function excelColumnToNumber is called with columnTitle = '${userInput}'`,
    { columnTitle: userInput }
  );

  let result = 0;
  addTrace(executionTrace, 2, `Initialize result = 0`, { result });

  for (let i = 0; i < userInput.length; i++) {
    let value = userInput[i].charCodeAt(0) - 'A'.charCodeAt(0) + 1;
    addTrace(
      executionTrace,
      4,
      `Calculate value for character '${userInput[i]}': value = ${userInput[i]}.charCodeAt(0) - 'A'.charCodeAt(0) + 1 = ${value}`,
      { char: userInput[i], value }
    );

    let previousResult = result;
    result = result * 26 + value;
    addTrace(
      executionTrace,
      5,
      `Update result: result = ${previousResult} * 26 + ${value} = ${result}`,
      { result }
    );
  }

  addTrace(executionTrace, 7, `Return result: ${result}`, { result });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The column number for '${userInput}' is ${result}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
