import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from 'react-bootstrap';
import {
  getProjectActivityById,
  createProjectSubmission,
} from '../../services/api.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectSubmissionView = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [submissionContent, setSubmissionContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await getProjectActivityById(token, projectId);
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching project:', error);
        setError('Failed to load project.');
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await createProjectSubmission(
        token,
        projectId,
        'userId',
        submissionContent
      );
      setSuccess(true);
    } catch (error) {
      console.error('Error submitting project:', error);
      setError('Failed to submit project.');
    }
  };

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      {success && (
        <Alert variant="success">Project submitted successfully!</Alert>
      )}
      <h2>{project.title}</h2>
      <p>{project.description}</p>
      <Row className="mt-4">
        <Col md={8}>
          <h4>Submit Your Project</h4>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="submissionContent" className="mb-3">
              <Form.Label>Submission Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={submissionContent}
                onChange={(e) => setSubmissionContent(e.target.value)}
                required
              />
            </Form.Group>
            <Button type="submit" variant="primary">
              Submit
            </Button>
            <Button
              variant="secondary"
              className="ms-2"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectSubmissionView;
