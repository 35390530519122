export const problemTitle = `### Understanding and Solving **Power of Four** Problem`;
export const initialInputValue = '16';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to determine if a number is a **power of four**:`,
  `###### **Step 2:** Program to Check **Power of Four**:`,
  `###### **Step 3:** Input a number to check if it is a **power of four**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an integer \`n\`, return \`true\` if \`n\` is a **power of four**, and \`false\` otherwise.

#### Problem Statement(Elaborated):
Write a program to check if a given integer is a power of four. A number \`n\` is a power of four if there exists an integer \`x\` such that \`n = 4^x\`.

#### Testing Examples
1. **Example 1: Input = 16**
   - **Step-by-step**: 
     - 16 can be written as \`4^2\`. 
     - Therefore, the output is **true**.
2. **Example 2: Input = 0**
   - **Step-by-step**: 
     - 0 is not a power of four. 
     - Therefore, the output is **false**.
3. **Example 3: Input = 64**
   - **Step-by-step**: 
     - 64 can be written as \`4^3\`. 
     - Therefore, the output is **true**.

#### What is a Power of Four?
A **power of four** is any number that can be expressed as \`4^x\`, where \`x\` is an integer. For example, \`1\`, \`4\`, \`16\`, \`64\`, etc., are all powers of four.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To iteratively divide the number by 4.
2. **Mathematical Operations**: Using division (\`/\`) to determine if a number is divisible by 4.
3. **Conditional Statements**: Using \`if-else\` to check conditions.

#### Real-World Concepts Covered
1. **Exponential Growth**: Understanding numbers that grow by powers of a base.
2. **Number Properties**: Exploring divisibility and powers.

#### How Does the Program Work?
1. If \`n\` is less than or equal to 0, return \`false\`.
2. Iteratively divide \`n\` by 4 while it is divisible by 4.
3. If the resulting number is 1, \`n\` is a power of four; otherwise, it is not.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Any number \`n\` that is a power of four must be divisible by 4 until \`n = 1\`.
2. **Hint 2**: If at any step \`n % 4 !== 0\` (but \`n > 1\`), \`n\` is not a power of four.
`;

export const initialAlgorithm = `1. If the number (\`n\`) is less than or equal to 0, return \`false\`.
2. While \`n % 4 == 0\`:
   - a. Divide \`n\` by 4: \`n = n / 4\`.
3. Check if \`n == 1\`:
   - If true, return \`true\`.
   - Otherwise, return \`false\`.`;

export const initialProblemCode = `1  function isPowerOfFour(n) {
2    if (n <= 0) return false;
3    while (n % 4 === 0) {
4      n = n / 4;
5    }
6    return n === 1;
7  }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid userInput: Please enter a valid integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function isPowerOfFour is called with n = ${n}`,
    {
      n,
    }
  );

  // Line 2: Check if n <= 0
  addTrace(
    executionTrace,
    2,
    `Check if n is less than or equal to 0: n <= 0 = ${n <= 0}`,
    {
      n,
    }
  );
  if (n <= 0) {
    addTrace(executionTrace, 2, `Since n <= 0, return false`, { n });
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `${n} is not a power of four.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  // Line 3: Start of while loop
  while (n % 4 === 0) {
    addTrace(
      executionTrace,
      3,
      `Check while condition: n % 4 == 0 = ${n} % 4 == 0 = ${n % 4 === 0}`,
      { n }
    );

    let previousN = n;
    n = n / 4;
    addTrace(executionTrace, 4, `Update n: n = ${previousN} / 4 = ${n}`, { n });
  }

  // Line 6: Check if n === 1
  let isPower = n === 1;
  addTrace(executionTrace, 6, `Check if n == 1: n == 1 = ${n == 1}`, { n });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: isPower
      ? `${n} is a power of four.`
      : `${n} is not a power of four.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
