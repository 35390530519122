/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Register from './pages/Auth/Register.js';
import Login from './pages/Auth/Login.js';
import QuizSubmission from './pages/Quizzes/QuizSubmission.js';
import CodingSubmission from './pages/Coding/CodingSubmission.js';
import LearnerDashboard from './pages/Dashboard/LearnerDashboard.js';
import Homepage from './pages/Home/Homepage.js';
import CourseCatalog from './pages/Course/CourseCatalog.js';
// import OngoingCoursePage from './pages/Course/OngoingCoursePage.js';
import OngoingCoursePageNew from './pages/Course/OngoingCoursePageNew.js';
import ProfileDashboard from './pages/Profile/Profile.js';
import CourseContentPage from './pages/Course/CourseContentPage.js';
import ProjectView from './pages/Projects/ProjectView.js';
import ProjectSubmissionView from './pages/Projects/ProjectSubmissionView.js';
// import ActivityPage from './pages/Course/ActivityPage.js';
import ActivityPageNew from './pages/Course/ActivityPageNew.js';
import './App.css';
import AdminDashboard from './pages/Admin/AdminDashboard.js';
import UserManagement from './pages/Admin/UserManagement.js';
import CourseManagement from './pages/Admin/CourseManagement.js';
import BadgeManagement from './pages/Admin/BadgeManagement.js';
import CourseContentForm from './pages/Admin/CourseContentForm.js';
import AboutUs from './components/AboutUs.js';
import Careers from './components/Careers.js';
import Terms from './components/Terms.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import Contact from './components/Contact.js';
import Pricing from './components/Pricing.js';
import Blog from './components/Blog.js';
import Community from './components/Community.js';
import SuccessStories from './components/SuccessStories.js';
import PublicCoursePage from './pages/Course/PublicCoursePage.js';
import Events from './pages/Events/Events.js';
import Forums from './components/Forums.js';
import Clubs from './components/Clubs.js';
import CustomModal from './components/CustomModal.js'; // Import your CustomModal component
import CareerPathDetails from './components/static/CareerPathDetails.js';
import OngoingCareerPathPageNew from './pages/CareerPath/OngoingCareerPathPageNew.js';
import CareerPathActivityPageNew from './pages/CareerPath/CareerPathActivityPageNew.js';
import TopicsList from './resourcesDocs/TopicsList.js';
import TopicDetails from './resourcesDocs/TopicDetails.js';
import ChooseHome from './pages/Home/ChooseHome.js';
import BTechHome from './resourcesDocs/BTechHome.js';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [redirectPath, setRedirectPath] = useState('/'); // Store the path to redirect after closing the modal

  // Get the role directly from localStorage
  const userRole = localStorage.getItem('role');

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log = console.warn = console.error = () => {};
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && userRole) {
      if (userRole === 'admin') {
        navigate('/admin');
      } else if (userRole === 'student') {
        navigate('/learn');
      }
    }
  }, [location.pathname, userRole, navigate]);

  useEffect(() => {
    // Display the unauthorized access modal if needed
    if (location.state?.unauthorized) {
      setModalMessage(
        location.state.message || 'You do not have access to this page.'
      );
      setRedirectPath(location.state.redirectPath || '/');
      setShowModal(true);
    }
  }, [location.state]);

  const handleModalClose = () => {
    setShowModal(false);
    navigate(redirectPath); // Redirect to the specified path after the modal is closed
  };

  return (
    <div className="wrapper">
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<ChooseHome />} />
        <Route path="/jobspace" element={<Homepage />} />
        <Route path="/btechspace" element={<BTechHome />} />

        <Route path="/resources/docs" element={<TopicsList />} />
        <Route path="/resources/docs/:topicName" element={<TopicDetails />} />

        <Route
          path="/career-paths/:coursePath"
          element={<CareerPathDetails />}
        />
        {/* Pausing User Signup */}
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/register" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/community" element={<Community />} />
        <Route path="/community/events" element={<Events />} />
        <Route path="/community/forums" element={<Forums />} />
        <Route path="/community/clubs" element={<Clubs />} />
        <Route path="/community/success-stories" element={<SuccessStories />} />
        <Route path="/public/courses" element={<CourseCatalog />} />
        <Route path="/public/course/:id" element={<PublicCoursePage />} />
        {/* Admin Routes */}
        {userRole === 'admin' ? (
          <>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/courses" element={<CourseManagement />} />
            <Route path="/admin/badges" element={<BadgeManagement />} />
            <Route path="/admin/courses/new" element={<CourseContentForm />} />
            <Route
              path="/admin/courses/edit/:id"
              element={<CourseContentForm />}
            />
          </>
        ) : (
          userRole && (
            <Route
              path="*"
              element={
                <Navigate
                  to="/"
                  replace
                  state={{
                    unauthorized: true,
                    message:
                      'You are not authorized to access the admin routes.',
                  }}
                />
              }
            />
          )
        )}
        {/* Student Routes */}
        {userRole === 'guest' ||
        userRole === 'promotional' ||
        userRole === 'student' ? (
          <>
            <Route path="/learn" element={<LearnerDashboard />} />
            <Route path="/submit-quiz" element={<QuizSubmission />} />
            <Route path="/submit-coding" element={<CodingSubmission />} />
            <Route path="/catalog" element={<CourseCatalog />} />
            <Route path="/courses" element={<CourseCatalog />} />
            <Route path="/profile" element={<ProfileDashboard />} />
            {/* <Route path="/course/:id" element={<OngoingCoursePage />} /> */}
            <Route path="/course/:id" element={<OngoingCoursePageNew />} />
            <Route
              path="/career-path/:id"
              element={<OngoingCareerPathPageNew />}
            />
            <Route
              path="/course/:courseId/:sectionId/activity/:activityType/:activityId"
              // element={<ActivityPage />}
              element={<ActivityPageNew />}
            />
            <Route
              path="/career-path/:careerPathId/:courseId/:sectionId/activity/:activityType/:activityId"
              // element={<ActivityPage />}
              element={<CareerPathActivityPageNew />}
            />
            <Route
              path="/course/:courseId/lesson/:lessonId"
              element={<CourseContentPage />}
            />
            <Route path="/project/:projectId" element={<ProjectView />} />
            <Route
              path="/project/:projectId/submit"
              element={<ProjectSubmissionView />}
            />
          </>
        ) : (
          userRole && (
            <Route
              path="*"
              element={
                <Navigate
                  to="/"
                  replace
                  state={{
                    unauthorized: true,
                    message:
                      'You are not authorized to access the student routes.',
                  }}
                />
              }
            />
          )
        )}
        {/* Fallback Redirect for Unauthorized Access */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      {/* Modal for Unauthorized Access */}
      <CustomModal
        show={showModal}
        onHide={handleModalClose}
        title="Access Denied"
        message={modalMessage}
      />
    </div>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
