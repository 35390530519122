import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the BestTimeToBuyAndSellStockDemo component
const problemTitle = `### Understanding and Solving **Best Time to Buy and Sell Stock** Problem`;
const initialInputValue = '7,1,5,3,6,4'; // Input is a list of daily stock prices as a string of comma-separated numbers.
const stepsTitles = [
  `###### **Step 1:** Algorithm to **find the maximum profit** from **buying and selling** a stock:`,
  `###### **Step 2:** Program to **calculate the maximum profit**:`,
  `###### **Step 3:** Input the **daily stock prices**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Resulting Maximum Profit:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
You are given an array \`prices\` where \`prices[i]\` is the **price** of a given stock on the **i-th day**. You want to **maximize your profit** by choosing a **single day to buy one stock** and choosing a **different day in the future to sell** that stock. **Return the maximum profit** you can achieve from this transaction. If no profit can be achieved, return **0**.

#### Problem Statement (Elaborated):
Write a program that finds the **maximum profit** you can make by **buying** on one day and **selling** on a later day. You can **only complete one transaction** (buy once and sell once).

#### Testing Examples
1. **Example 1: Input = [7,1,5,3,6,4]**
   - **Output**: \`5\` (Buy on day 2 (price = 1) and sell on day 5 (price = 6), profit = 6 - 1 = 5)
2. **Example 2: Input = [7,6,4,3,1]**
   - **Output**: \`0\` (No transactions are done, so the profit is 0)

#### What is the Best Time to Buy and Sell Stock Problem?
The **Best Time to Buy and Sell Stock** problem involves **finding the maximum profit** that can be obtained by **buying and selling stock** on specific days. The goal is to **identify the best day to buy** and the **best future day to sell** for **maximum profit**.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate through the price array**.
2. **Conditional Statements**: Using \`if\` to **compare values** and update the **minimum price** and **maximum profit**.
3. **Basic Variable Handling**: Understanding how to **track the minimum price** and **calculate profit**.

#### Real-World Concepts Covered
1. **Optimization**: Understanding how to **maximize gains** by identifying **optimal points**.
2. **Financial Analysis**: Concepts of **buy low, sell high**.
3. **Data Processing**: Iterating through data to **identify trends**.

#### How Does the Program Work?
1. **Initialize** \`minPrice\` to a large value and \`maxProfit\` to **0**.
2. Loop through each **price** in the array.
3. **Update minPrice** if the current price is **lower**.
4. **Calculate potential profit** by subtracting \`minPrice\` from the current price.
5. **Update maxProfit** if the **calculated profit** is **higher**.
6. **Return maxProfit** as the result.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Track the **minimum price** encountered so far.
2. **Hint 2**: Calculate the **profit** if **selling on the current day**.
3. **Hint 3**: **Update maxProfit** only if the **calculated profit** is greater.
4. **Hint 4**: Iterate through the prices only **once** for efficiency.
5. **Hint 5**: Return **0** if no profit can be achieved.

#### Why Do We Do This?
We **perform this task** to understand how to **maximize returns** in stock trading. It teaches **logical decision-making** and **identifying optimal conditions** for **transactions**.
`;

const initialAlgorithm = `1. Initialize \`minPrice\` to a large number and \`maxProfit\` to 0.
2. Loop through each **price** in the \`prices\` array:
   - a. If the **current price** is **lower** than \`minPrice\`, **update minPrice**.
   - b. Calculate **potential profit** as **current price - minPrice**.
   - c. If the **potential profit** is **higher** than \`maxProfit\`, **update maxProfit**.
3. **Return** \`maxProfit\` as the **maximum possible profit**.`;

const initialProblemCode = `1  function maxProfit(prices) {
2    let minPrice = Infinity;
3    let maxProfit = 0;
4    for (let i = 0; i < prices.length; i++) {
5      if (prices[i] < minPrice) {
6        minPrice = prices[i];
7      }
8      let potentialProfit = prices[i] - minPrice;
9      if (potentialProfit > maxProfit) {
10       maxProfit = potentialProfit;
11     }
12   }
13   return maxProfit;
14 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

// Root component to load the BestTimeToBuyAndSellStockDemo
const BestTimeToBuyAndSellStockDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);
  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };

      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let prices;
    try {
      prices = numberInput.split(',').map((str) => parseInt(str.trim(), 10));

      if (prices.some(isNaN) || prices.length === 0) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter daily stock prices as comma-separated numbers.',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function maxProfit is called with prices = [${prices}]`,
      { prices }
    );

    let minPrice = Infinity;
    let maxProfit = 0;
    addTrace(
      executionTrace,
      2,
      `Initialize minPrice to Infinity and maxProfit to 0.`,
      { minPrice, maxProfit }
    );

    for (let i = 0; i < prices.length; i++) {
      addTrace(
        executionTrace,
        4,
        `Loop iteration ${i}: Current price = ${prices[i]}`,
        { i, currentPrice: prices[i] }
      );

      if (prices[i] < minPrice) {
        minPrice = prices[i];
        addTrace(executionTrace, 6, `Update minPrice to ${minPrice}`, {
          minPrice,
        });
      }

      let potentialProfit = prices[i] - minPrice;
      addTrace(
        executionTrace,
        8,
        `Calculate potentialProfit as ${prices[i]} - ${minPrice} = ${potentialProfit}`,
        { potentialProfit }
      );

      if (potentialProfit > maxProfit) {
        maxProfit = potentialProfit;
        addTrace(executionTrace, 10, `Update maxProfit to ${maxProfit}`, {
          maxProfit,
        });
      }
    }

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `Maximum profit is ${maxProfit}.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default BestTimeToBuyAndSellStockDemo;
