export const problemTitle = `### Understanding and Solving **Climbing Stairs Using Mathematics** Problem`;
export const initialInputValue = '5';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to calculate the number of ways to climb stairs using mathematics:`,
  `###### **Step 2:** Program to Calculate **Climbing Stairs** Using Mathematics:`,
  `###### **Step 3:** Input the number of stairs to find the number of ways to climb:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
You are climbing a staircase. It takes \`n\` steps to reach the top. Each time you can either climb 1 step or 2 steps. In how many distinct ways can you climb to the top?

#### Problem Statement(Elaborated):
Write a program that calculates the number of distinct ways to climb to the top of a staircase with \`n\` steps using a mathematical approach. The program should avoid data structures and rely purely on mathematics.

#### Testing Examples
1. **Example 1: Input = 2**
   - **Step-by-step**: There are 2 ways to climb 2 stairs:
     1. 1 step + 1 step.
     2. 2 steps.
     - The **output** is **2**.
2. **Example 2: Input = 3**
   - **Step-by-step**: There are 3 ways to climb 3 stairs:
     1. 1 step + 1 step + 1 step.
     2. 1 step + 2 steps.
     3. 2 steps + 1 step.
     - The **output** is **3**.
3. **Example 3: Input = 5**
   - **Step-by-step**: The number of ways to climb 5 stairs is **8**, calculated using the Fibonacci sequence.

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To calculate Fibonacci numbers iteratively.
2. **Mathematical Operations**: Using addition to compute Fibonacci numbers.
3. **Conditional Statements**: To handle the base cases (\`n = 1\` or \`n = 2\`).

#### Real-World Concepts Covered
1. **Dynamic Programming**: Understanding how current results build on previous calculations.
2. **Recursive Sequences**: Learning how problems like climbing stairs relate to the Fibonacci sequence.

#### How Does the Program Work?
1. If \`n = 1\`, there is only 1 way to climb the stairs.
2. If \`n = 2\`, there are 2 ways to climb the stairs.
3. For larger values of \`n\`, calculate the number of ways iteratively by summing the previous two results, which corresponds to Fibonacci numbers.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Use two variables to store the previous two Fibonacci numbers.
2. **Hint 2**: Update the variables iteratively until you reach \`n\`.
3. **Hint 3**: Handle the base cases \`n = 1\` and \`n = 2\` directly.

#### Why Do We Do This?
We use this approach to solve a problem efficiently without recursion or additional memory. This demonstrates how Fibonacci numbers arise naturally in problems involving combinations of steps.
`;

export const initialAlgorithm = `1. If \`n = 1\`, return \`1\`.
2. If \`n = 2\`, return \`2\`.
3. Initialize:
   - \`first = 1\` (ways to climb 1 step)
   - \`second = 2\` (ways to climb 2 steps)
4. For \`i = 3\` to \`n\`:
   - a. Calculate \`current = first + second\`.
   - b. Update \`first = second\`.
   - c. Update \`second = current\`.
5. Return \`second\` as the number of ways to climb \`n\` stairs.`;

export const initialProblemCode = `1  function climbStairs(n) {
2    if (n === 1) return 1;
3    if (n === 2) return 2;
4    let first = 1, second = 2;
5    for (let i = 3; i <= n; i++) {
6      let current = first + second;
7      first = second;
8      second = current;
9    }
10   return second;
11 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let n;
  try {
    n = parseInt(userInput, 10);
    if (isNaN(n) || n <= 0) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a positive integer!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(executionTrace, 1, `Function climbStairs is called with n = ${n}`, {
    n,
  });

  if (n === 1) {
    addTrace(
      executionTrace,
      2,
      `Check if n === 1: ${n} === 1 = ${n === 1}. Return 1.`,
      { n }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The number of ways to climb ${n} stairs is 1.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  if (n === 2) {
    addTrace(
      executionTrace,
      3,
      `Check if n === 2: ${n} === 2 = ${n === 2}. Return 2.`,
      { n }
    );
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The number of ways to climb ${n} stairs is 2.`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let first = 1,
    second = 2;
  addTrace(executionTrace, 4, `Initialize first = 1, second = 2`, {
    first,
    second,
  });

  for (let i = 3; i <= n; i++) {
    addTrace(
      executionTrace,
      5,
      `Loop iteration i = ${i}: Calculate current = first + second = ${first} + ${second}`,
      { i, first, second }
    );

    let current = first + second;

    addTrace(executionTrace, 6, `Update first = second = ${second}`, {
      first: second,
    });

    first = second;

    addTrace(executionTrace, 7, `Update second = current = ${current}`, {
      second: current,
    });

    second = current;
  }

  addTrace(executionTrace, 10, `Return second: ${second}`, { second });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `The number of ways to climb ${n} stairs is ${second}.`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
