export const problemTitle = `### Understanding and Solving **Best Time to Buy and Sell Stock** Problem`;
export const initialInputValue = '7,1,5,3,6,4';
export const stepsTitles = [
  `###### **Step 1:** Algorithm to find the maximum profit:`,
  `###### **Step 2:** Program to Calculate Maximum Profit:`,
  `###### **Step 3:** Input stock prices:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
export const initialGuidedDescriptionContent = `
#### Problem Statement:
Given an array \`prices\` where \`prices[i]\` is the price of a given stock on the \`i\`th day, return the maximum profit you can achieve from one transaction. If you cannot achieve any profit, return \`0\`.

#### Problem Statement (Elaborated):
You are given an array of stock prices where each element represents the stock price for a specific day. Your task is to determine the maximum profit that can be made by buying and then selling the stock on different days.

#### Testing Examples:
1. **Example 1**: Input = \`[7,1,5,3,6,4]\`
   - **Output**: 5 (Buy on day 2, sell on day 5, profit = 6 - 1 = 5)
2. **Example 2**: Input = \`[7,6,4,3,1]\`
   - **Output**: 0 (No profitable transaction possible)

#### What is Maximum Profit?
The maximum profit is the highest possible difference between a selling price and a buying price, where the buying happens before the selling.

#### Topics Covered:
1. **Dynamic Programming Basics**: Keep track of minimum prices and potential profits.
2. **Loops**: Iterate through the array of stock prices to calculate profits.
3. **Comparisons**: Compare prices to update the minimum price and maximum profit.

#### Real-World Concepts Covered:
1. **Optimization**: Finding the best time to buy and sell to maximize profit.
2. **Logical Thinking**: Applying a systematic approach to solve problems efficiently.

#### How Does the Program Work?
1. Start with the first price as the minimum price.
2. Iterate through the prices, updating the minimum price and calculating potential profit.
3. Return the maximum profit found.

#### Hints:
1. **Hint 1**: If the array has fewer than two elements, no transaction is possible.
2. **Hint 2**: Track the minimum price while iterating through the array.
3. **Hint 3**: For each price, calculate the profit as \`current price - minimum price\`.
4. **Hint 4**: Keep updating the maximum profit found so far.

#### Why Do We Do This?
This problem helps to understand dynamic programming concepts and teaches how to optimize problems with cumulative updates.
`;

export const initialAlgorithm = `1. Initialize \`minPrice\` to a very high value (infinity).
2. Initialize \`maxProfit\` to 0.
3. Loop through the \`prices\` array:
   - a. If the current price is less than \`minPrice\`, update \`minPrice\` to the current price.
   - b. Calculate the potential profit as \`current price - minPrice\`.
   - c. If the calculated profit is greater than \`maxProfit\`, update \`maxProfit\`.
4. Return \`maxProfit\` as the result.`;

export const initialProblemCode = `1  function maxProfit(prices) {
2    let minPrice = Infinity;
3    let maxProfit = 0;
4    for (let i = 0; i < prices.length; i++) {
5      if (prices[i] < minPrice) {
6        minPrice = prices[i]; // Update minimum price
7      }
8      let profit = prices[i] - minPrice; // Calculate profit
9      if (profit > maxProfit) {
10       maxProfit = profit; // Update maximum profit
11     }
12   }
13   return maxProfit;
14 }`;

export const handleProblemRunClick = (
  userInput,
  dispatch,
  ACTION_TYPES,
  addTrace
) => {
  let prices;
  try {
    prices = userInput.split(',').map((num) => parseInt(num, 10));
    if (prices.some(isNaN)) {
      throw new Error();
    }
  } catch {
    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: 'Invalid input: Please enter a valid array of integers!',
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
    return;
  }

  let executionTrace = [];
  addTrace(
    executionTrace,
    1,
    `Function maxProfit is called with prices = [${prices.join(', ')}]`,
    { prices }
  );

  let minPrice = Infinity;
  let maxProfit = 0;
  addTrace(executionTrace, 2, `Initialize minPrice = Infinity`, { minPrice });
  addTrace(executionTrace, 3, `Initialize maxProfit = 0`, { maxProfit });

  for (let i = 0; i < prices.length; i++) {
    addTrace(
      executionTrace,
      4,
      `Iterating prices, current price = prices[${i}] = ${prices[i]}`,
      { currentPrice: prices[i] }
    );

    if (prices[i] < minPrice) {
      minPrice = prices[i];
      addTrace(
        executionTrace,
        6,
        `Update minPrice to current price: minPrice = ${minPrice}`,
        { minPrice }
      );
    }

    let profit = prices[i] - minPrice;
    addTrace(
      executionTrace,
      8,
      `Calculate profit: profit = current price - minPrice = ${prices[i]} - ${minPrice} = ${profit}`,
      { profit }
    );

    if (profit > maxProfit) {
      maxProfit = profit;
      addTrace(
        executionTrace,
        10,
        `Update maxProfit to profit: maxProfit = ${maxProfit}`,
        { maxProfit }
      );
    }
  }

  addTrace(executionTrace, 13, `Return the final maxProfit: ${maxProfit}`, {
    maxProfit,
  });

  dispatch({
    type: ACTION_TYPES.SET_MESSAGE,
    payload: `Maximum profit: ${maxProfit}`,
  });
  dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
  dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
};
