import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Navbar from './Navbar.js'; // Import the Navbar component
import Footer from './Footer.js'; // Import the Footer component
import 'bootstrap/dist/css/bootstrap.min.css';

const Community = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar /> {/* Header/Navbar Component */}
      <Container className="flex-grow-1 mt-5">
        <h1 className="text-center mb-4">Join Our Community</h1>
        <p className="text-center mb-5">
          Connect with like-minded learners, participate in events, join clubs,
          and more!
        </p>

        <Row className="mb-4">
          <Col md={4}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Forums</Card.Title>
                <Card.Text>
                  Engage in discussions, ask questions, and share knowledge with
                  peers in our community forums.
                </Card.Text>
                <Button variant="primary" href="/community/forums">
                  Join the Discussion
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Events</Card.Title>
                <Card.Text>
                  Stay updated on upcoming workshops, webinars, and networking
                  events hosted by our community.
                </Card.Text>
                <Button variant="primary" href="/community/events">
                  View Events
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Clubs</Card.Title>
                <Card.Text>
                  Join clubs focused on specific topics like web development,
                  AI, data science, and more.
                </Card.Text>
                <Button variant="primary" href="/community/clubs">
                  Explore Clubs
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Discord Community</Card.Title>
                <Card.Text>
                  Our Discord community is coming soon! Stay tuned for real-time
                  discussions, coding help, and collaboration opportunities.
                </Card.Text>
                <Button variant="primary" disabled>
                  Coming Soon
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title>Success Stories</Card.Title>
                <Card.Text>
                  Get inspired by stories from learners who achieved their goals
                  through our community and courses.
                </Card.Text>
                <Button variant="primary" href="/community/success-stories">
                  Read Success Stories
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Footer Component */}
    </div>
  );
};

export default Community;
