import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import {
  getActivityJSONByCourseIdActivityID,
  // getLessonById,
} from '../../services/api.js';
import ReactMarkdown from 'react-markdown';
import './LessonView.css'; // Import the CSS file for light and dark mode styling
import {
  handleCourseNextActivity,
  handleCoursePreviousActivity,
} from '../../helpers/componentsHelper.js';

const LessonViewNew = ({ courseId, sectionId, activityType, activityId }) => {
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const token = localStorage.getItem('token');
        const activityResponse = await getActivityJSONByCourseIdActivityID(
          token,
          courseId,
          sectionId,
          activityType,
          activityId
        );
        console.log(
          `activityResponse: ${JSON.stringify(activityResponse, null, 4)}`
        );
        setLesson(activityResponse.data.activity);
      } catch (error) {
        console.error(
          'Error fetching lesson:',
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchLesson();
  }, [activityId]);

  if (!lesson) {
    return (
      <Container className="d-flex justify-content-center align-items-center loading-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="lesson-container">
      <Row style={{ height: '450px', overflowY: 'auto' }}>
        <Col>
          <Card.Title as="h2" className="lesson-title">
            {lesson.title}
          </Card.Title>
          <ReactMarkdown className="lesson-content">
            {lesson.description}
          </ReactMarkdown>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            variant="secondary"
            className="back-button"
            onClick={() =>
              handleCoursePreviousActivity(
                navigate,
                courseId,
                sectionId,
                activityType,
                activityId
              )
            }
          >
            Previous
          </Button>
        </Col>
        <Col>
          <Button
            variant="secondary"
            className="back-button"
            onClick={() =>
              handleCourseNextActivity(
                navigate,
                courseId,
                sectionId,
                activityType,
                activityId
              )
            }
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

// PropTypes validation for the lessonId prop
LessonViewNew.propTypes = {
  courseId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  activityType: PropTypes.number.isRequired,
  activityId: PropTypes.number.isRequired,
};

export default LessonViewNew;
