import React, {
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useState,
} from 'react';
import ProblemComponent from './ProblemComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Sample dynamic data to be passed to the AddBinaryDemo component
const problemTitle = `### Understanding and Solving **Add Binary** Problem`;
const initialInputValue = '{"a": "11", "b": "1"}';
const stepsTitles = [
  `###### **Step 1:** Algorithm to **Add Two Binary Numbers**:`,
  `###### **Step 2:** Program to Add **Two Binary Numbers**:`,
  `###### **Step 3:** Input the **binary strings a** and **b**:`,
  `###### **Step 4:** Code Execution Trace Table:`,
  `###### **Step 5:** Result:`,
];
const initialGuidedDescriptionContent = `
#### Problem Statement:
Given two binary strings \`a\` and \`b\`, return their **sum** as a **binary string**.

#### Problem Statement (Elaborated):
Write a program that **adds two binary numbers** represented as **strings**. The function should **calculate the sum** of the two binary numbers and **return** the result as a **binary string**. Binary numbers consist only of **0s** and **1s**.

#### Testing Examples
1. **Example 1: Input = {"a": "11", "b": "1"}**
   - **Step-by-step**: Adding \`"11"\` and \`"1"\` results in \`"100"\`.
2. **Example 2: Input = {"a": "1010", "b": "1011"}**
   - **Step-by-step**: Adding \`"1010"\` and \`"1011"\` results in \`"10101"\`.

#### What is Adding Binary Numbers?
Adding **binary numbers** follows the same rules as adding **decimal numbers**, but it uses **base 2**. For example:
- **0 + 0 = 0**
- **0 + 1 = 1**
- **1 + 1 = 10** (which means carry 1 to the next digit)

#### What Topics of JavaScript Will Be Covered?
1. **Loops**: To **iterate** through the characters of the strings.
2. **Conditional Statements**: Using \`if-else\` to **handle carries**.
3. **String Manipulation**: Constructing the **result binary string**.

#### Real-World Concepts Covered
1. **Number Systems**: Understanding how to **perform arithmetic** in **binary**.
2. **Bitwise Operations**: Learning to **add** numbers at the **bit level**.
3. **Logical Thinking**: Enhancing **problem-solving skills** through **iterative additions**.

#### How Does the Program Work?
1. Start from the **end** of both **strings** \`a\` and \`b\`.
2. Add corresponding **binary digits** along with any **carry**.
3. **Construct the result** binary string by adding **each digit** from right to left.
4. **Return** the final **binary string**.

#### Hints to Consider While Solving the Problem
1. **Hint 1**: Start from the **rightmost digit** and work your way **left**.
2. **Hint 2**: Keep a **carry** variable to handle **extra sums**.
3. **Hint 3**: Don't forget to **add the carry** if it remains **after the loop**.

#### Why Do We Do This?
We **perform this task** to **simulate binary addition**, which is essential in **computer arithmetic** and understanding **low-level operations**.
`;

const initialAlgorithm = `1. Initialize **result** as an empty string and **carry** as 0.
2. Set **p1** to the **last index** of **a** and **p2** to the **last index** of **b**.
3. While **p1** or **p2** is valid or **carry** is not zero:
   - a. Get the **current digit** of **a** and **b**.
   - b. Calculate the **sum** of **current digits** and **carry**.
   - c. **Update carry** and **add the remainder** to the **result**.
   - d. Move **p1** and **p2** to the **left**.
4. **Return** the **reversed result** as the final **binary sum**.
`;

const initialProblemCode = `1  function addBinary(a, b) {
2    let result = "";
3    let carry = 0;
4    let p1 = a.length - 1;
5    let p2 = b.length - 1;
6    while (p1 >= 0 || p2 >= 0 || carry > 0) {
7      let x = p1 >= 0 ? a[p1] - '0' : 0;
8      let y = p2 >= 0 ? b[p2] - '0' : 0;
9      let sum = x + y + carry;
10     result = (sum % 2) + result;
11     carry = Math.floor(sum / 2);
12     p1--;
13     p2--;
14   }
15   return result;
16 }`;

// Constants for Action Types
const ACTION_TYPES = {
  SET_INPUT: 'SET_INPUT',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_TRACE: 'SET_TRACE',
  RESET: 'RESET',
  SET_ANIMATION_INDEX: 'SET_ANIMATION_INDEX',
  SET_SHOW_MODAL: 'SET_SHOW_MODAL',
  SET_MODAL_TITLE: 'SET_MODAL_TITLE',
  SET_MODAL_DESCRIPTION: 'SET_MODAL_DESCRIPTION',
  SET_MODAL_CONTENT_TYPE: 'SET_MODAL_CONTENT_TYPE',
};

// Initial State for Reducer
const initialState = {
  numberInput: '',
  message: '',
  trace: [],
  animationIndex: 0,
  isTraceGenerated: false,
  showGuideModal: false,
  modalTitle: '',
  modalDescription: '',
  contentType: 'TEXT',
};

// Reducer Function
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INPUT:
      return { ...state, numberInput: action.payload };
    case ACTION_TYPES.SET_MESSAGE:
      return { ...state, message: action.payload };
    case ACTION_TYPES.SET_TRACE:
      return {
        ...state,
        trace: action.payload,
        isTraceGenerated: action.payload.length > 0,
      };
    case ACTION_TYPES.RESET:
      return initialState;
    case ACTION_TYPES.SET_ANIMATION_INDEX:
      return { ...state, animationIndex: action.payload };
    case ACTION_TYPES.SET_SHOW_MODAL:
      return { ...state, showGuideModal: action.payload };
    case ACTION_TYPES.SET_MODAL_TITLE:
      return { ...state, modalTitle: action.payload };
    case ACTION_TYPES.SET_MODAL_DESCRIPTION:
      return { ...state, modalDescription: action.payload };
    case ACTION_TYPES.SET_MODAL_CONTENT_TYPE:
      return { ...state, contentType: action.payload };
    default:
      return state;
  }
}

// Helper Function to Add Trace Entries
const addTrace = (trace, lineNumber, explanation, variables = {}) => {
  const formattedVariables = Object.fromEntries(
    Object.entries(variables).map(([key, value]) => [
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    ])
  );
  trace.push({ lineNumber, explanation, variables: formattedVariables });
};

// Execution Trace Table Component (same as PalindromeNumberDemo)
const ExecutionTraceTableComponent = ({ trace, animationIndex, traceRef }) => (
  <div
    ref={traceRef}
    style={{
      border: '2px solid #ccc',
      marginTop: '10px',
      marginRight: '10px',
      overflowWrap: 'break-word',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      maxHeight: '310px',
      overflowY: 'auto',
    }}
  >
    <table>
      <thead
        style={{
          position: 'sticky',
          top: '0px',
          zIndex: 2,
          border: '2px solid black',
        }}
      >
        <tr>
          <th style={{ border: '2px solid black' }}>Step No</th>
          <th style={{ border: '2px solid black' }}>Line No</th>
          <th style={{ border: '2px solid black' }}>Variables</th>
          <th style={{ border: '2px solid black' }}>Explanation</th>
        </tr>
      </thead>
      <tbody>
        {trace.slice(0, animationIndex + 1).map((entry, index) => (
          <tr
            key={index}
            style={{
              backgroundColor:
                index === animationIndex
                  ? '#a6e7a6'
                  : index === animationIndex - 1
                    ? '#fff8a6'
                    : 'transparent',
            }}
          >
            <td style={{ border: '2px solid black' }}>{index + 1}</td>
            <td style={{ border: '2px solid black' }}>
              <strong>{entry.lineNumber}</strong>
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              {entry.variables
                ? Object.entries(entry.variables)
                    .map(
                      ([key, value]) =>
                        `${key} = ${typeof value === 'object' ? JSON.stringify(value) : value}`
                    )
                    .join(', ')
                : 'N/A'}
            </td>
            <td
              style={{
                border: '2px solid black',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
            >
              <strong>{entry.explanation}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExecutionTraceTable = React.memo(ExecutionTraceTableComponent);

const AddBinaryDemo = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    numberInput: initialInputValue,
    modalDescription: initialGuidedDescriptionContent,
  });

  const {
    numberInput,
    message,
    trace,
    animationIndex,
    isTraceGenerated,
    showGuideModal,
    modalTitle,
    modalDescription,
    contentType,
  } = state;

  const editorRef = useRef(null);
  const [highlightLine, setHighlightLine] = useState([]);
  const traceRef = useRef(null);

  const [isCodeAutoScrollingEnabled, setIsCodeAutoScrollingEnabled] =
    useState(true);
  const [isCodeVisible, setIsCodeVisible] = useState(false);
  const [
    isTraceTableAutoScrollingEnabled,
    setIsTraceTableAutoScrollingEnabled,
  ] = useState(true);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleCodeAutoScrollCheckboxChange = useCallback(() => {
    setIsCodeAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleCodeVisibleCheckboxChange = useCallback(() => {
    setIsCodeVisible((prev) => !prev);
  }, []);

  const handleTraceTableAutoScrollCheckboxChange = useCallback(() => {
    setIsTraceTableAutoScrollingEnabled((prev) => !prev);
  }, []);

  const handleShowGuidedDescription = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Statement Description:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialGuidedDescriptionContent,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemAlgorithm = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Step-By-Step Implementation Algorithm:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialAlgorithm,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'TEXT' });
  }, [dispatch]);

  const handleShowProblemCode = useCallback(() => {
    dispatch({ type: ACTION_TYPES.SET_SHOW_MODAL, payload: true });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_TITLE,
      payload: 'Problem Solution Code:',
    });
    dispatch({
      type: ACTION_TYPES.SET_MODAL_DESCRIPTION,
      payload: initialProblemCode,
    });
    dispatch({ type: ACTION_TYPES.SET_MODAL_CONTENT_TYPE, payload: 'CODE' });
  }, [dispatch]);

  useEffect(() => {
    if (isTraceGenerated && trace[animationIndex]) {
      const highlightSpecificLine = (lineNumber) => {
        if (editorRef.current) {
          const newDecorations = editorRef.current.deltaDecorations(
            highlightLine,
            [
              {
                range: new window.monaco.Range(lineNumber, 1, lineNumber, 1),
                options: {
                  isWholeLine: true,
                  className: 'myLineHighlight',
                  inlineClassName: 'myLineHighlightText',
                },
              },
            ]
          );
          if (
            JSON.stringify(newDecorations) !== JSON.stringify(highlightLine)
          ) {
            setHighlightLine(newDecorations);
          }
        }
      };
      highlightSpecificLine(trace[animationIndex].lineNumber);

      if (isCodeAutoScrollingEnabled && editorRef.current) {
        editorRef.current.revealLineInCenter(trace[animationIndex].lineNumber);
      }
    }
  }, [
    animationIndex,
    trace,
    isTraceGenerated,
    highlightLine,
    isCodeAutoScrollingEnabled,
    isTraceTableAutoScrollingEnabled,
  ]);

  useEffect(() => {
    if (isTraceTableAutoScrollingEnabled && traceRef.current) {
      traceRef.current.scrollTop = traceRef.current.scrollHeight;
    }
  }, [animationIndex, isTraceTableAutoScrollingEnabled]);

  const handleInputChange = useCallback(
    (e) => {
      dispatch({ type: ACTION_TYPES.SET_INPUT, payload: e.target.value });
    },
    [dispatch]
  );

  const handleRunClick = useCallback(() => {
    let input;
    try {
      input = JSON.parse(numberInput);
      if (
        !input ||
        typeof input.a !== 'string' ||
        typeof input.b !== 'string'
      ) {
        throw new Error();
      }
    } catch {
      dispatch({
        type: ACTION_TYPES.SET_MESSAGE,
        payload:
          'Invalid input: Please enter a valid JSON with "a" and "b" as binary strings!',
      });
      dispatch({ type: ACTION_TYPES.SET_TRACE, payload: [] });
      dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
      return;
    }

    const { a, b } = input;

    let executionTrace = [];
    addTrace(
      executionTrace,
      1,
      `Function addBinary is called with a = "${a}", b = "${b}"`,
      { a, b }
    );

    let result = '';
    let carry = 0;
    let p1 = a.length - 1;
    let p2 = b.length - 1;

    addTrace(
      executionTrace,
      2,
      `Initialize result = "", carry = 0, p1 = ${p1}, p2 = ${p2}`,
      { result, carry, p1, p2 }
    );

    while (p1 >= 0 || p2 >= 0 || carry > 0) {
      let x = p1 >= 0 ? a[p1] - '0' : 0;
      let y = p2 >= 0 ? b[p2] - '0' : 0;
      let sum = x + y + carry;

      addTrace(
        executionTrace,
        9,
        `Calculate sum = ${x} + ${y} + ${carry} = ${sum}`,
        { x, y, carry, sum }
      );

      result = (sum % 2) + result;
      carry = Math.floor(sum / 2);

      addTrace(
        executionTrace,
        10,
        `Update result = "${result}", carry = ${carry}`,
        { result, carry }
      );

      p1--;
      p2--;

      addTrace(executionTrace, 12, `Decrement p1 to ${p1}, p2 to ${p2}`, {
        p1,
        p2,
      });
    }

    addTrace(executionTrace, 15, `Return the final result: "${result}"`, {
      result,
    });

    dispatch({
      type: ACTION_TYPES.SET_MESSAGE,
      payload: `The sum of binary numbers is "${result}".`,
    });
    dispatch({ type: ACTION_TYPES.SET_TRACE, payload: executionTrace });
    dispatch({ type: ACTION_TYPES.SET_ANIMATION_INDEX, payload: 0 });
  }, [numberInput, dispatch]);

  const handleNext = useCallback(() => {
    if (animationIndex < trace.length - 1) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex + 1,
      });
    }
  }, [animationIndex, trace.length, dispatch]);

  const handlePrevious = useCallback(() => {
    if (animationIndex > 0) {
      dispatch({
        type: ACTION_TYPES.SET_ANIMATION_INDEX,
        payload: animationIndex - 1,
      });
    }
  }, [animationIndex, dispatch]);

  const handleReset = useCallback(() => {
    dispatch({ type: ACTION_TYPES.RESET });
  }, [dispatch]);

  return (
    <ProblemComponent
      problemTitle={problemTitle}
      stepsTitles={stepsTitles}
      initialInputValue={numberInput}
      initialAlgorithm={initialAlgorithm}
      initialProblemCode={initialProblemCode}
      ACTION_TYPES={ACTION_TYPES}
      dispatch={dispatch}
      trace={trace}
      animationIndex={animationIndex}
      handleInputChange={handleInputChange}
      handleRunClick={handleRunClick}
      isTraceGenerated={isTraceGenerated}
      ExecutionTraceTable={ExecutionTraceTable}
      traceRef={traceRef}
      message={message}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleReset={handleReset}
      showGuideModal={showGuideModal}
      contentType={contentType}
      modalTitle={modalTitle}
      modalDescription={modalDescription}
      handleEditorDidMount={handleEditorDidMount}
      handleShowGuidedDescription={handleShowGuidedDescription}
      handleShowProblemAlgorithm={handleShowProblemAlgorithm}
      handleShowProblemCode={handleShowProblemCode}
      isCodeAutoScrollingEnabled={isCodeAutoScrollingEnabled}
      handleCodeAutoScrollCheckboxChange={handleCodeAutoScrollCheckboxChange}
      isCodeVisible={isCodeVisible}
      handleCodeVisibleCheckboxChange={handleCodeVisibleCheckboxChange}
      isTraceTableAutoScrollingEnabled={isTraceTableAutoScrollingEnabled}
      handleTraceTableAutoScrollCheckboxChange={
        handleTraceTableAutoScrollCheckboxChange
      }
    />
  );
};

export default AddBinaryDemo;
